import {
    Button,
    DATE_FORMAT,
    DateTimeUtils,
    DAY_FORMAT,
    DAY_LONG_FORMAT,
    DeviceUtils,
    IAreaOccupancyStats,
    IRestaurantDateStats,
    Label,
    SERVER_DATE_FORMAT,
} from '@localina/core';
import { HouseWithDots } from '@localina/icons';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../../api/queries/restaurants';
import { RestaurantStatsAllDayTile, RestaurantStatsShiftTile } from './RestaurantStatsTile';

interface IProps {
    stats: IRestaurantDateStats;
    onClick: (date: string) => void;
}

const RestaurantDateStats: React.FC<IProps> = (props) => {
    const { i18n, t } = useTranslation();
    const restaurantQuery = useRestaurant();

    const showArea = (areaOccupancyStat: IAreaOccupancyStats): boolean => {
        return areaOccupancyStat.shiftOccupancyStats.length != 0;
    };

    const filteredAreaOccupancyStats = props.stats.areaOccupancyStats.filter((it) => showArea(it));

    const allShifts = Array.from(
        new Set(
            filteredAreaOccupancyStats
                .flatMap((it) => it.shiftOccupancyStats)
                .sort((a, b) => DateTimeUtils.compareStringDateTimes(a.shift.from, b.shift.from))
                .map((it) => it.shift.id),
        ),
    );

    const getNumberOfReservations = (area: string) => {
        let numberOfReservations = 0;
        props.stats.areaOccupancyStats
            .find((areaOccupancyStat) => areaOccupancyStat.area === area)
            ?.shiftOccupancyStats.forEach(
                (shiftOccupancyStat) => (numberOfReservations += shiftOccupancyStat.stats.numberOfReservations),
            );
        return numberOfReservations;
    };

    const getNumberOfGuests = (area: string) => {
        let numberOfGuests = 0;
        props.stats.areaOccupancyStats
            .find((areaOccupancyStat) => areaOccupancyStat.area === area)
            ?.shiftOccupancyStats.forEach(
                (shiftOccupancyStat) => (numberOfGuests += shiftOccupancyStat.stats.numberOfGuests),
            );
        return numberOfGuests;
    };

    const getAreaById = (id: string) => {
        const virtualAreas = restaurantQuery.data?.configuration.virtualAreas;
        return (
            virtualAreas?.find((virtualArea) => virtualArea.id === id) ??
            virtualAreas?.flatMap((virtualArea) => virtualArea.areas).find((area) => area.id === id)
        );
    };

    return (
        <div className="restaurant-stats__date">
            <Label
                type="title"
                value={DateTime.fromFormat(props.stats.date, SERVER_DATE_FORMAT).toFormat(
                    `${DeviceUtils.isMobile() ? DAY_FORMAT : DAY_LONG_FORMAT}, ${DATE_FORMAT}`,
                    { locale: i18n.language },
                )}
                variant={DeviceUtils.isMobile() ? 'h4' : 'h6'}
                align="center"
            />
            <RestaurantStatsAllDayTile restaurant={restaurantQuery.data} stats={props.stats} />
            <div className="restaurant-stats__date__content">
                {filteredAreaOccupancyStats.map((areaOccupancyStat: IAreaOccupancyStats, index) => (
                    <div className="restaurant-stats__tile" key={areaOccupancyStat.area}>
                        <Grid container={true}>
                            <Grid item={true} xs={12} sm={3}>
                                <div className="restaurant-stats__tile__content">
                                    <div className="restaurant-stats__tile__title">
                                        <HouseWithDots size="big" />
                                        <Label
                                            type="title"
                                            value={getAreaById(areaOccupancyStat.area)?.name}
                                            variant="h6"
                                        />
                                    </div>
                                    <Grid className="restaurant-stats__tile__info" container={true} direction={'row'}>
                                        <Grid item={true}>
                                            <Grid container={true}>
                                                <Label type="label" value={t('dashboard.tile.fields.guests')} />
                                                <div className="grow" />
                                                <Label type="label" value={getNumberOfGuests(areaOccupancyStat.area)} />
                                            </Grid>
                                        </Grid>
                                        <div className="grow" />
                                        <Grid item={true}>
                                            <Grid container={true}>
                                                <Label type="label" value={t('dashboard.tile.fields.reservations')} />
                                                <div className="grow" />
                                                <Label
                                                    type="label"
                                                    value={getNumberOfReservations(areaOccupancyStat.area)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item={true} xs={12} sm={9}>
                                <div className="restaurant-stats__date__shift-wrapper" key={index}>
                                    <RestaurantStatsShiftTile
                                        stats={areaOccupancyStat}
                                        shifts={allShifts}
                                        date={props.stats.date}
                                    ></RestaurantStatsShiftTile>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </div>
            <div className="buttons">
                <Button
                    label={t('dashboard.buttons.showReservations')}
                    onClick={() => props.onClick(props.stats.date)}
                />
            </div>
        </div>
    );
};
export default RestaurantDateStats;
