import React from 'react';
import { Spinner, StringUtils } from '@localina/core';
import { IActionsProps, PageActions } from './Actions';
import { ITitleProps, PageTitle } from './Title';

interface IProps {
    name: string;
    title?: string | ITitleProps;
    actions?: IActionsProps;
    isLoading?: boolean;
    children?: React.ReactNode;
}

export const Page: React.FC<IProps> = (props) => (
    <div className={StringUtils.combineStrings(['service-page', props.title !== undefined && 'has-title'])}>
        {props.title !== undefined &&
            (typeof props.title === 'string' ? <PageTitle value={props.title} /> : <PageTitle {...props.title} />)}
        {props.isLoading && <Spinner />}
        <div className="page-body">
            <div className={StringUtils.combineStrings(['page-content', props.actions && 'has-actions', props.name])}>
                {props.children}
            </div>
            {props.actions && <PageActions {...props.actions} />}
        </div>
    </div>
);
