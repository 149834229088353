import React from 'react';
import { IUser, ListItem, StringUtils } from '@localina/core';

interface IProps {
    user: IUser;
    onClick: (user: IUser) => void;
}

export const UserListItem: React.FC<IProps> = (props) => {
    const handleClick = () => {
        props.onClick(props.user);
    };

    return (
        <ListItem
            title={StringUtils.combineStrings([props.user.lastName, props.user.firstName], ', ')}
            subtitle={props.user.email}
            onClick={handleClick}
        />
    );
};
