import { IOccupancyStats, IRestaurantShift, Label, StringUtils, TIME_FORMAT } from '@localina/core';
import { Grid } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import React from 'react';

interface IProps {
    stats: IOccupancyStats;
    shift?: IRestaurantShift;
    latestSlot?: string;
    showMiddleTimeIndex?: boolean;
    showEndTimeIndex?: boolean;
}

export const RestaurantStatsChart: React.FC<IProps> = (props) => {
    const getTime = (time: string) => DateTime.fromFormat(time, TIME_FORMAT);
    const fromTime = props.shift ? props.shift.from : props.stats.list[0].time;
    const toTime = props.shift
        ? props.shift.to
        : props.latestSlot ?? props.stats.list[props.stats.list.length - 1].time;
    const getMiddleTime = () => {
        return Interval.fromDateTimes(getTime(fromTime), getTime(toTime))
            .divideEqually(2)[1]
            .start?.toFormat(TIME_FORMAT);
    };

    return (
        <div className="restaurant-stats__chart">
            <div className="chart">
                {props.stats.list.map((it) => (
                    <div
                        key={it.time}
                        className={StringUtils.combineStrings([
                            'chart__bar',
                            it.occupancy >= props.stats.average && 'exceeded',
                        ])}
                        style={{ height: `${100 * it.occupancy}%` }}
                    />
                ))}
                <div className="chart__line" style={{ bottom: `${100 * props.stats.average}%` }} />
            </div>
            <div className="chart__time">
                <Grid container={true}>
                    <Label type="info" value={fromTime} />
                    {props.showMiddleTimeIndex && (
                        <>
                            <div className="grow" />
                            <Label type="info" value={getMiddleTime()} />
                        </>
                    )}
                    {props.showEndTimeIndex && (
                        <>
                            <div className="grow" />
                            <Label type="info" value={toTime} />
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
};
