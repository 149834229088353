import { fabric } from 'fabric';
import { grid } from './FabricHelper';

export const onObjMoving = (target: fabric.Object | undefined) => {
    const snap = grid / 10; //Pixels to snap, set to be the grid size but can be changed
    if (!target) {
        return;
    }
    let left = Math.max(0, target.left || 0);
    let top = Math.max(0, target.top || 0);
    left = Math.min(left, (target.canvas?.width || 0) - (target.width || 0));
    top = Math.min(top, (target.canvas?.height || 0) - (target.height || 0));
    left = Math.round(left / snap) * snap;
    top = Math.round(top / snap) * snap;
    target.left = left;
    target.top = top;
};

export const validatePositions = (target: fabric.Object | undefined) => {
    const snap = grid / 10; //Pixels to snap, set to be the grid size but can be changed
    const canvas = target?.canvas;
    if (!canvas) {
        return;
    }

    let left = Math.max(0, target.left || 0);
    let top = Math.max(0, target.top || 0);
    left = Math.min(left, (target.canvas?.width || 0) - (target.width || 0));
    top = Math.min(top, (target.canvas?.height || 0) - (target.height || 0));
    left = Math.round(left / snap) * snap;
    top = Math.round(top / snap) * snap;
    target.left = Math.max(0, left);
    target.top = Math.max(0, top);
    //NOTE: This is used to avoid possible infinite loop
    for (let i = 0; i < 100; i++) {
        const relevantCanvasObjs = getIntersectingObjects(target);
        if (relevantCanvasObjs.length === 0) {
            return;
        }
        relevantCanvasObjs.forEach((object) => {
            const boundingRect = {
                left: target.left || 0,
                top: target.top || 0,
                width: target.width || 0,
                height: target.height || 0,
            };
            let dX = 0;
            let dY = 0;
            const objBoundingRect = {
                left: object.left || 0,
                top: object.top || 0,
                width: object.width || 0,
                height: object.height || 0,
            };
            if (
                boundingRect.left + boundingRect.width > objBoundingRect.left &&
                boundingRect.left + boundingRect.width < objBoundingRect.left + objBoundingRect.width
            ) {
                dX = objBoundingRect.left - boundingRect.left - boundingRect.width;
            }
            if (
                boundingRect.left < objBoundingRect.left + objBoundingRect.width &&
                boundingRect.left + boundingRect.width > objBoundingRect.left + objBoundingRect.width
            ) {
                dX = objBoundingRect.left + objBoundingRect.width - boundingRect.left;
            }
            if (
                boundingRect.top + boundingRect.height > objBoundingRect.top &&
                boundingRect.top + boundingRect.height < objBoundingRect.top + objBoundingRect.height
            ) {
                dY = objBoundingRect.top - boundingRect.top - boundingRect.height;
            }
            if (
                boundingRect.top < objBoundingRect.top + objBoundingRect.height &&
                boundingRect.top + boundingRect.height > objBoundingRect.top + objBoundingRect.height
            ) {
                dY = objBoundingRect.top + objBoundingRect.height - boundingRect.top;
            }
            if (Math.abs(dX) / objBoundingRect.width > Math.abs(dY) / objBoundingRect.height) {
                target.left = (target.left || 0) + dX;
            } else {
                target.top = (target.top || 0) + dY;
            }
        });
        canvas.renderAll();
    }
};

export const getIntersectingObjects = (canvasObject: fabric.Object) => {
    return (
        canvasObject.canvas
            ?.getObjects()
            .filter(
                (o) =>
                    !!o.data?.id &&
                    o.data.id !== canvasObject.data?.id &&
                    o.data.zIndex === canvasObject.data?.zIndex &&
                    o.intersectsWithObject(canvasObject),
            )
            // ignore touching elements
            .filter((object) => {
                if (object.isContainedWithinObject(canvasObject) || canvasObject.isContainedWithinObject(object)) {
                    return true;
                }
                const boundingRect = {
                    left: canvasObject.left || 0,
                    top: canvasObject.top || 0,
                    width: canvasObject.width || 0,
                    height: canvasObject.height || 0,
                };
                const objBoundingRect = {
                    left: object.left || 0,
                    top: object.top || 0,
                    width: object.width || 0,
                    height: object.height || 0,
                };
                return (
                    boundingRect.left + boundingRect.width !== objBoundingRect.left &&
                    boundingRect.left !== objBoundingRect.left + objBoundingRect.width &&
                    boundingRect.top + boundingRect.height !== objBoundingRect.top &&
                    boundingRect.top !== objBoundingRect.top + objBoundingRect.height
                );
            }) || []
    );
};

export function onObjectScaling(object: fabric.Object) {
    const resizeSnap = grid / 4;
    const width = Math.round(object.getScaledWidth() / resizeSnap) * resizeSnap;
    const height = Math.round(object.getScaledHeight() / resizeSnap) * resizeSnap;
    object.set('scaleX', width / (object.width || 0));
    object.set('scaleY', height / (object.height || 1));
}
