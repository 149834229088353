import {
    Button,
    IconButton,
    IReservation,
    ReservationStatus,
    SERVER_DATE_FORMAT,
    Spinner,
    useConfirmContext,
} from '@localina/core';
import { CheckboxDisabledIcon } from '@localina/icons';
import { Check } from '@mui/icons-material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateReservationStatus } from '../../api/queries/reservations';
import { useReservationFormContext } from '../../contexts/ReservationsContext';

interface IRequestedReservationActionButtonsProps {
    reservation: IReservation;
    isProcessed?: boolean;
    displayIconButtons?: boolean;
    relativeSpinner?: boolean;
    onSuccess?: () => void;
}

const RequestedReservationActionButtons = (props: IRequestedReservationActionButtonsProps) => {
    const { snackbar } = useConfirmContext();
    const { t } = useTranslation();
    const { addRecentlyProcessedReservation } = useReservationFormContext();

    const updateReservationStatusMutation = useUpdateReservationStatus({
        onSuccess: (_data, variables) => {
            addRecentlyProcessedReservation({ ...props.reservation, status: variables.status });
        },
    });

    const updateReservationStatus = (newStatus: ReservationStatus) => {
        updateReservationStatusMutation.mutate(
            {
                reservationId: props.reservation.id,
                status: newStatus,
                reservationDate: DateTime.fromISO(props.reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT),
            },
            {
                onSuccess: () => {
                    if (props.onSuccess) {
                        props.onSuccess();
                    }
                },
                onError: () => {
                    snackbar({
                        msg: t('common.infoMessages.commonError'),
                        severity: 'error',
                    });
                },
            },
        );
    };
    const handleStatusCheckboxClick =
        (newStatus: ReservationStatus.APPROVED | ReservationStatus.DECLINED) =>
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (!props.isProcessed) {
                e.stopPropagation();
                updateReservationStatus(newStatus);
            }
        };

    return (
        <div className={'requested-reservation-change-status-options'}>
            {(!props.isProcessed || props.reservation.status === ReservationStatus.DECLINED) && (
                <>
                    {props.displayIconButtons ? (
                        <IconButton
                            icon={<CheckboxDisabledIcon />}
                            onClick={handleStatusCheckboxClick(ReservationStatus.DECLINED)}
                            className={'decline-button'}
                        />
                    ) : (
                        <Button
                            label={t('reservations.requestedReservationView.actions.decline')}
                            onClick={handleStatusCheckboxClick(ReservationStatus.DECLINED)}
                            secondary={!props.isProcessed}
                            disabled={props.isProcessed}
                        />
                    )}
                </>
            )}
            {(!props.isProcessed || props.reservation.status === ReservationStatus.APPROVED) && (
                <>
                    {props.displayIconButtons ? (
                        <IconButton
                            icon={<Check />}
                            onClick={handleStatusCheckboxClick(ReservationStatus.APPROVED)}
                            className={'accept-button'}
                        />
                    ) : (
                        <Button
                            label={t('reservations.requestedReservationView.actions.accept')}
                            onClick={handleStatusCheckboxClick(ReservationStatus.APPROVED)}
                            disabled={props.isProcessed}
                        />
                    )}
                </>
            )}
            {updateReservationStatusMutation.isLoading && (
                <div className={'loading-spinner'}>
                    <Spinner position={props.relativeSpinner ? 'relative' : undefined} />
                </div>
            )}
        </div>
    );
};

export default RequestedReservationActionButtons;
