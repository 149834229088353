import { DATE_FORMAT, IRestaurantConfiguration, Label, RestaurantUtils } from '@localina/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

type TEmptyCaseProps = {
    className?: string;
    noReservations: boolean;
    noFilteredReservations: boolean;
    configuration?: IRestaurantConfiguration;
    dateFormatted: string; // DATE_FORMAT
    clearFilters: () => void;
};
const ReservationsInfoMessages = (props: TEmptyCaseProps) => {
    const infoMessageTranslationKey = useMemo(() => {
        if (props.configuration) {
            const datetime = DateTime.fromFormat(props.dateFormatted, DATE_FORMAT);

            // check if the restaurant is completely closed on date
            if (RestaurantUtils.isClosedAndHasNoShiftsOnDate(datetime, props.configuration)) {
                return 'reservations.closeDay';
            }

            // check if no reservations have been created
            if (props.noReservations) {
                return 'reservations.emptyDay';
            }

            if (props.noFilteredReservations) {
                return 'reservations.noReservationsThatMatchFilter';
            }
        }
        return null;
    }, [props.configuration, props.dateFormatted, props.noReservations, props.noFilteredReservations]);

    return infoMessageTranslationKey ? (
        <div className={props.className}>
            <Label
                type="text"
                value={
                    <Trans
                        i18nKey={infoMessageTranslationKey}
                        components={[
                            <span key="clear-filter-link" className="clear-filter-link" onClick={props.clearFilters} />,
                        ]}
                    />
                }
            />
        </div>
    ) : null;
};

export default ReservationsInfoMessages;
