export var ReservationType;
(function (ReservationType) {
    ReservationType["MANUAL"] = "manual";
    ReservationType["WIDGET"] = "widget";
})(ReservationType || (ReservationType = {}));
export var ReservationStatus;
(function (ReservationStatus) {
    ReservationStatus["APPROVAL_PENDING"] = "approval_pending";
    ReservationStatus["APPROVED"] = "approved";
    ReservationStatus["DECLINED"] = "declined";
    ReservationStatus["FULFILLED"] = "fulfilled";
    ReservationStatus["NO_SHOW"] = "no_show";
    ReservationStatus["GUEST_CANCELLED"] = "guest_cancelled";
    ReservationStatus["RESTAURANT_CANCELLED"] = "restaurant_cancelled";
})(ReservationStatus || (ReservationStatus = {}));
