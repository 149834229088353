import { ILocalizedError, IRestaurant, IStaffTag, TCreateStaffTag } from '@localina/core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { sortItemsBySortedIdsArray } from '../../utils/SortableItemsUtils';
import { queryKeys } from './query-keys';
import { useRestaurant, useRestaurantById } from './restaurants';

function useStaffTags() {
    return useRestaurant({
        select: (data) => ({
            staffTags:
                sortItemsBySortedIdsArray(
                    data?.configuration.staffTags || [],
                    data?.configuration.orderings.staffTags || [],
                    'id',
                ) || [],
            staffTagMandatory: data?.configuration.staffTagMandatory,
            staffTagEnabled: data?.configuration.staffTagEnabled,
        }),
    });
}

const useCreateStaffTagMutation = (options?: UseMutationOptions<string, ILocalizedError, TCreateStaffTag>) => {
    const restaurantId = useRestaurantId();
    const restaurantQuery = useRestaurantById(restaurantId);
    const updateStaffTagOrderingMutation = useUpdateStaffTagOrderingMutation();

    return useMutation({
        mutationFn: createStaffTagMutationFn(restaurantId),
        onSuccess: (staffTagId) => {
            // add staffTagId to orderings.areas array
            return updateStaffTagOrderingMutation.mutateAsync(
                restaurantQuery.data?.configuration.orderings.staffTags
                    ? [...restaurantQuery.data.configuration.orderings.staffTags, staffTagId]
                    : [staffTagId],
            );
        },
        ...options,
    });
};
const useUpdateStaffTagMutation = (options?: UseMutationOptions<string, ILocalizedError, IStaffTag>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: updateStaffTagMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};
const useDeleteStaffTagMutation = (options?: UseMutationOptions<void, ILocalizedError, string>) => {
    const restaurantId = useRestaurantId();
    const restaurantQuery = useRestaurantById(restaurantId);
    const updateStaffTagOrderingMutation = useUpdateStaffTagOrderingMutation();

    return useMutation({
        mutationFn: deleteStaffTagMutationFn(restaurantId),
        onSuccess: (_, staffTagId) => {
            if (restaurantQuery.data) {
                if (restaurantQuery.data.configuration.orderings.staffTags?.includes(staffTagId)) {
                    return updateStaffTagOrderingMutation.mutateAsync([
                        ...restaurantQuery.data.configuration.orderings.staffTags.filter((id) => id !== staffTagId),
                    ]);
                } else {
                    return restaurantQuery.refetch();
                }
            }
        },
        ...options,
    });
};
const useUpdateStaffTagOrderingMutation = (options?: UseMutationOptions<string, ILocalizedError, string[]>) => {
    const restaurantQuery = useRestaurant();
    return useMutation({
        mutationFn:
            restaurantQuery.isSuccess && restaurantQuery.data
                ? updateStaffTagOrderingMutationFunction(restaurantQuery.data)
                : undefined,
        onSuccess: () => restaurantQuery.refetch(),
        ...options,
    });
};
const updateStaffTagOrderingMutationFunction = (restaurant: IRestaurant) => (staffTags: string[]) =>
    LocalinaApiContext.serviceApi.updateRestaurant(restaurant.id, {
        orderings: {
            ...restaurant.configuration.orderings,
            staffTags,
        },
    });

const createStaffTagMutationFn = (restaurantId: string) => (staffTag: TCreateStaffTag) =>
    LocalinaApiContext.serviceApi.createStaffTag(restaurantId, staffTag);
const updateStaffTagMutationFn = (restaurantId: string) => (staffTag: IStaffTag) =>
    LocalinaApiContext.serviceApi.updateStaffTag(restaurantId, staffTag);
const deleteStaffTagMutationFn = (restaurantId: string) => (staffTagId: string) =>
    LocalinaApiContext.serviceApi.deleteStaffTag(restaurantId, staffTagId);

export {
    useStaffTags,
    useCreateStaffTagMutation,
    useUpdateStaffTagMutation,
    useDeleteStaffTagMutation,
    useUpdateStaffTagOrderingMutation,
};
