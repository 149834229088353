import { useUrlParams } from '@localina/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordMutation, useSendResetPasswordEmailMutation } from '../../api/queries/auth';
import { Page } from '../../components';
import { Path } from '../../enums';
import { DefinePassword, Done, UserInfo, UserVerified } from './Steps';

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [queryParams] = useUrlParams();

    const [step, setStep] = React.useState(1);
    const [authCode, setAuthCode] = React.useState<string>('');

    const sendResetPasswordEmailMutation = useSendResetPasswordEmailMutation();
    const resetPasswordMutation = useResetPasswordMutation();

    const isLoading = sendResetPasswordEmailMutation.isLoading || resetPasswordMutation.isLoading;

    React.useEffect(() => {
        const code = queryParams.get('code');

        if (code) {
            setAuthCode(code);
            setStep(3);
        } else {
            setStep(1);
        }
    }, []);

    const scrollToTop = () => {
        const main = document.getElementsByTagName('main')[0];
        main.scrollTop = 0;
    };
    const handleNext = () => {
        setStep(step + 1);
        scrollToTop();
    };
    const handleLogin = () => {
        navigate(Path.LOGIN);
    };

    const getClassName = () => {
        switch (step) {
            case 1:
                return 'userinfo';
            case 2:
                return 'userverified';
            case 3:
                return 'definepassword';
            case 4:
                return 'done';
        }
    };

    return (
        <Page name={`reset-password ${getClassName()}`} isLoading={isLoading}>
            <div className="reset-password__content">
                {step === 1 && (
                    <UserInfo
                        onNext={handleNext}
                        onLogin={handleLogin}
                        sendResetPasswordEmailMutation={sendResetPasswordEmailMutation}
                    />
                )}
                {step === 2 && <UserVerified onLogin={handleLogin} />}
                {step === 3 && (
                    <DefinePassword
                        code={authCode}
                        onNext={handleNext}
                        onLogin={handleLogin}
                        resetPasswordMutation={resetPasswordMutation}
                    />
                )}
                {step === 4 && <Done onLogin={handleLogin} />}
            </div>
        </Page>
    );
};
export default ResetPassword;
