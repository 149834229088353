import {
    DATE_FORMAT,
    DAY_LONG_FORMAT,
    IGuestInfo,
    Label,
    ReservationStatus,
    StringUtils,
    TIME_FORMAT,
    useConfirmContext,
} from '@localina/core';
import {
    CalendarIcon,
    ClockIcon,
    CompanyIcon,
    CookieIcon,
    EmailIcon,
    FoodBankIcon,
    MobileIcon,
    PersonIcon,
    RestaurantIcon,
    TextBlockIcon,
} from '@localina/icons';
import { Alert } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestStatusIcon } from '../GuestStatusIcon';

interface IRequestedReservationDetailsProps {
    reservationDateTime: string;
    restaurantName?: string;
    shiftName?: string;
    area?: {
        name: string;
        code: string;
    };
    guestInfo?: IGuestInfo;
    comment?: string;
    status?: ReservationStatus;
}

const RequestedReservationDetails = (props: IRequestedReservationDetailsProps) => {
    const { t } = useTranslation();
    const { copyOrLinkDialog } = useConfirmContext();

    const time = DateTime.fromISO(props.reservationDateTime).toFormat(TIME_FORMAT);
    const timeAndShiftFormatted = StringUtils.combineStrings([time, props.shiftName && `(${props.shiftName})`]);
    const handlePhoneNumberClick = () => {
        if (props.guestInfo?.phoneNumbers[0]) {
            copyOrLinkDialog({ type: 'phoneNumber', value: props.guestInfo.phoneNumbers[0] });
        }
    };
    const handleEmailClick = () => {
        if (props.guestInfo?.email) {
            copyOrLinkDialog({ type: 'email', value: props.guestInfo?.email });
        }
    };

    const nameFormatted = StringUtils.combineStrings([props.guestInfo?.firstName, props.guestInfo?.lastName]);

    const infoLabelText = useMemo(() => {
        switch (props.status) {
            case ReservationStatus.APPROVED:
                return t('reservations.reviewPendingReservation.infoMessage.accepted');
            case ReservationStatus.DECLINED:
                return t('reservations.reviewPendingReservation.infoMessage.rejected');
            case ReservationStatus.GUEST_CANCELLED:
                return t('reservations.reviewPendingReservation.infoMessage.guestCancelled');
            default:
                return null;
        }
    }, [props.status]);

    return (
        <>
            <div className="requested-reservation-details">
                {Boolean(props.restaurantName) && (
                    <div className="requested-reservation-details-wrapper">
                        <RequestedReservationDetail
                            icon={<RestaurantIcon />}
                            label={t('reservations.requestedReservationView.fields.restaurantName')}
                            value={props.restaurantName}
                        />
                    </div>
                )}
                <div className="requested-reservation-details-wrapper">
                    <RequestedReservationDetail
                        icon={<CalendarIcon />}
                        label={t('reservations.requestedReservationView.fields.date')}
                        value={formatDate(props.reservationDateTime)}
                    />

                    <RequestedReservationDetail
                        icon={<ClockIcon />}
                        label={t('reservations.requestedReservationView.fields.time')}
                        value={timeAndShiftFormatted}
                    />
                    {props.area && (
                        <RequestedReservationDetail
                            icon={<FoodBankIcon />}
                            value={`${props.area.name} (${props.area.code})`}
                            label={t('reservations.requestedReservationView.fields.area')}
                        />
                    )}
                </div>
                {!!props.guestInfo && (
                    <>
                        <div className="requested-reservation-details-wrapper">
                            <RequestedReservationDetail
                                icon={<PersonIcon />}
                                label={t('reservations.requestedReservationView.fields.displayName')}
                                value={
                                    <>
                                        {nameFormatted} <GuestStatusIcon guestStatus={props.guestInfo.guestStatus} />
                                    </>
                                }
                                wrapperClassName={'guest-name-information'}
                            />
                            {props.guestInfo.company && (
                                <RequestedReservationDetail
                                    icon={<CompanyIcon />}
                                    label={t('reservations.requestedReservationView.fields.company')}
                                    value={props.guestInfo.company}
                                />
                            )}
                        </div>
                        {Boolean(props.guestInfo.allergies?.length || props.comment?.length) && (
                            <div className="requested-reservation-details-wrapper">
                                {Boolean(props.guestInfo.allergies?.length) && (
                                    <RequestedReservationDetail
                                        icon={<CookieIcon />}
                                        label={t('reservations.requestedReservationView.fields.allergies')}
                                        value={props.guestInfo.allergies}
                                    />
                                )}
                                {Boolean(props.comment?.length) && (
                                    <RequestedReservationDetail
                                        icon={<TextBlockIcon />}
                                        label={t('reservations.requestedReservationView.fields.comment')}
                                        value={props.comment}
                                    />
                                )}
                            </div>
                        )}
                        <div
                            className={StringUtils.combineStrings([
                                'requested-reservation-details-wrapper',
                                'phone-and-email',
                            ])}
                        >
                            {props.guestInfo.phoneNumbers[0] && (
                                <RequestedReservationDetail
                                    icon={<MobileIcon />}
                                    label={t('reservations.requestedReservationView.fields.phoneNumber')}
                                    value={props.guestInfo.phoneNumbers[0]}
                                    onValueClick={handlePhoneNumberClick}
                                />
                            )}
                            <RequestedReservationDetail
                                icon={<EmailIcon />}
                                label={t('reservations.requestedReservationView.fields.email')}
                                value={props.guestInfo.email}
                                onValueClick={handleEmailClick}
                            />
                        </div>
                    </>
                )}
            </div>
            {infoLabelText && (
                <Alert
                    severity={props.status === ReservationStatus.APPROVED ? 'success' : 'error'}
                    className="review-result-information"
                >
                    <Label type={'text'} align={'center'} value={infoLabelText} />
                </Alert>
            )}
        </>
    );
};

const formatDate = (isoDateTime: string) => {
    const date = DateTime.fromISO(isoDateTime);
    return `${date.toFormat(DATE_FORMAT)} (${date.toFormat(DAY_LONG_FORMAT)})`;
};

interface IRequestedReservationDetailProps {
    icon: React.ReactNode;
    label?: React.ReactNode;
    value?: React.ReactNode;
    wrapperClassName?: string;
    onValueClick?: () => void;
}

const RequestedReservationDetail = (props: IRequestedReservationDetailProps) => {
    return props.value ? (
        <div className={StringUtils.combineStrings(['requested-reservation-detail', props.wrapperClassName])}>
            <Label type="text" extraClassName="detail-description" value={props.label} icon={props.icon} />
            <Label type="text" extraClassName="detail-value" value={props.value} onClick={props.onValueClick} />
        </div>
    ) : null;
};

export default RequestedReservationDetails;
