import { getDefaultArea } from '@localina/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePreservedRestaurant } from '../../../../api/queries/restaurants';

interface IConfirmationMailTemplateProps {
    lng: string;
    text?: string;
    link?: string;
}

const ConfirmationMailTemplate = (props: IConfirmationMailTemplateProps) => {
    const { t } = useTranslation();
    const restaurantQuery = usePreservedRestaurant();

    const textRows = (props.text || '').split('\n');

    const lng = props.lng.toLowerCase();
    const translate = (key: string, options?: object) => t(key, { lng, ...options });

    const address = `${restaurantQuery.data?.info.street} ${restaurantQuery.data?.info.streetNumber}, ${restaurantQuery.data?.info.postalCode} ${restaurantQuery.data?.info.city}`;
    const defaultArea = restaurantQuery.data ? getDefaultArea(restaurantQuery.data.configuration)?.name : '';
    const numberOfPeople = 2;
    const reservationDate = DateTime.now().plus({ day: 1 }).set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
    const reservationDateTime = reservationDate.setLocale(lng).toFormat('dd MMMM yyyy HH:mm');
    const cancellationDateTime = reservationDate.minus({ hour: 4 }).setLocale(lng).toFormat('EEEE dd.MM.yyyy HH:mm');

    const restaurantPhone = restaurantQuery.data?.info.phoneNumber;
    const restaurantEmail = restaurantQuery.data?.info.email;
    const restaurantName = restaurantQuery.data?.info.name;

    return (
        <div style={{ marginLeft: 34 }}>
            <table
                border={0}
                cellPadding={0}
                cellSpacing={0}
                id="x_templateContainer"
                style={{
                    border: '1px solid #b4b4b4',
                    width: 600,
                    borderCollapse: 'collapse',
                }}
            >
                <tbody>
                    <tr>
                        <td align="center" valign="top" style={{ borderCollapse: 'collapse' }}>
                            <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                id="x_templatePreheader"
                                width="100%"
                                style={{
                                    borderBottom: '1px solid rgb(180, 180, 180)',
                                    backgroundColor: 'rgb(180, 180, 180)',
                                    borderCollapse: 'collapse',
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="center" valign="top" style={{ borderCollapse: 'collapse' }}>
                            <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                id="x_templateBody"
                                width="100%"
                                style={{
                                    borderTop: '1px solid rgb(180, 180, 180)',
                                    borderBottom: '0px none rgb(204, 204, 204)',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                <tbody>
                                    <tr style={{}}>
                                        <td
                                            align="left"
                                            className="x_bodyContent"
                                            valign="top"
                                            style={{
                                                padding: 20,
                                                textAlign: 'left',
                                                color: 'rgb(232, 232, 232)',
                                                fontFamily: 'Arial, Helvetica, sans-serif',
                                                fontSize: 14,
                                                background: 'rgb(112, 159, 175)',
                                                borderCollapse: 'collapse',
                                            }}
                                        >
                                            <div style={{ textAlign: 'center', lineHeight: '1.3' }}>
                                                <br aria-hidden="true" />
                                                <span style={{ fontSize: 28 }}>
                                                    <span
                                                        style={{
                                                            fontFamily:
                                                                'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                        }}
                                                    >
                                                        🍽️
                                                        <span>
                                                            <strong>
                                                                <span
                                                                    style={{ color: 'rgb(255, 255, 255) !important' }}
                                                                >
                                                                    <span>
                                                                        <span style={{ margin: '0 10px' }}>
                                                                            {translate(
                                                                                'message.confirmationMailPreview.title',
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </strong>
                                                        </span>
                                                        🍽️
                                                    </span>
                                                </span>
                                                <br aria-hidden="true" />
                                                &nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{}}>
                                        <td
                                            align="left"
                                            className="x_bodyContent"
                                            valign="top"
                                            style={{
                                                padding: 20,
                                                textAlign: 'left',
                                                color: 'rgb(0, 0, 0)',
                                                fontFamily: 'Arial, Helvetica, sans-serif',
                                                fontSize: 14,
                                                background: 'rgb(255, 255, 255)',
                                                borderCollapse: 'collapse',
                                            }}
                                        >
                                            <div style={{ textAlign: 'center' }}>
                                                <p
                                                    style={{
                                                        marginTop: '0pt',
                                                        marginBottom: '0pt',
                                                        marginLeft: '0in',
                                                        direction: 'ltr',
                                                        unicodeBidi: 'embed',
                                                        wordBreak: 'normal',
                                                        textAlign: 'left',
                                                        margin: '1em 0',
                                                    }}
                                                >
                                                    <span style={{ fontSize: 14 }}>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                            }}
                                                        >
                                                            {translate('message.confirmationMailPreview.introLine')}
                                                            {Boolean(textRows.length) && (
                                                                <>
                                                                    <br aria-hidden="true" />
                                                                    <br aria-hidden="true" />
                                                                    {textRows.map((text, index) => (
                                                                        <React.Fragment key={`text-${index}`}>
                                                                            {index !== 0 && <br aria-hidden={'true'} />}
                                                                            {text}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </>
                                                            )}
                                                            {Boolean(props.link) && (
                                                                <>
                                                                    <br aria-hidden="true" />
                                                                    <br aria-hidden="true" />
                                                                    <Link to={' '}>{props.link}</Link>
                                                                </>
                                                            )}
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            {translate(
                                                                'message.confirmationMailPreview.reservationInfo.title',
                                                            )}
                                                        </span>
                                                    </span>
                                                </p>
                                                <ul dir="ltr">
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.reservationInfo.location',
                                                                    { restaurantName },
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.reservationInfo.address',
                                                                    { address },
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.reservationInfo.area',
                                                                    { area: defaultArea },
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.reservationInfo.numberOfPeople',
                                                                    { numberOfPeople },
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.reservationInfo.dateTime',
                                                                    { dateTime: reservationDateTime },
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p
                                                    style={{
                                                        marginTop: '0pt',
                                                        marginBottom: '0pt',
                                                        marginLeft: '0in',
                                                        direction: 'ltr',
                                                        unicodeBidi: 'embed',
                                                        wordBreak: 'normal',
                                                        textAlign: 'left',
                                                        margin: '1em 0',
                                                    }}
                                                >
                                                    <br aria-hidden="true" />
                                                    <span style={{ fontSize: 14 }}>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                            }}
                                                        >
                                                            {translate(
                                                                'message.confirmationMailPreview.guestInfo.title',
                                                            )}
                                                        </span>
                                                    </span>
                                                </p>
                                                <ul dir="ltr">
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.guestInfo.name',
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.guestInfo.email',
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li
                                                        style={{
                                                            marginTop: '0pt',
                                                            marginBottom: '0pt',
                                                            marginLeft: '0in',
                                                            unicodeBidi: 'embed',
                                                            wordBreak: 'normal',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14 }}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                                }}
                                                            >
                                                                {translate(
                                                                    'message.confirmationMailPreview.guestInfo.phoneNumber',
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p
                                                    style={{
                                                        marginTop: '0pt',
                                                        marginBottom: '0pt',
                                                        marginLeft: '0in',
                                                        direction: 'ltr',
                                                        unicodeBidi: 'embed',
                                                        wordBreak: 'normal',
                                                        textAlign: 'left',
                                                        margin: '1em 0',
                                                    }}
                                                >
                                                    <br aria-hidden="true" />
                                                    <span style={{ fontSize: 14 }}>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'Arial, Helvetica, sans-serif, serif, EmojiFont',
                                                            }}
                                                        >
                                                            {translate('message.confirmationMailPreview.paragraph-1', {
                                                                phone: restaurantPhone,
                                                                email: restaurantEmail,
                                                            })}
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            {translate('message.confirmationMailPreview.paragraph-2', {
                                                                dateTime: cancellationDateTime,
                                                            })}
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            {translate('message.confirmationMailPreview.paragraph-3')}
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            <br aria-hidden="true" />
                                                            {translate('message.confirmationMailPreview.paragraph-4')}
                                                            <br aria-hidden="true" />
                                                            {restaurantName}
                                                        </span>
                                                    </span>
                                                    <br aria-hidden="true" />
                                                    <br aria-hidden="true" />
                                                    &nbsp;
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ConfirmationMailTemplate;
