var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StringUtils } from '../../utils';
export var DotsNavigation = function (props) { return (_jsx("div", __assign({ className: "localina-dotsnavigation" }, { children: _jsx("div", __assign({ className: "dots" }, { children: Array.from(Array(props.steps)).map(function (_, index) { return (_jsx("div", { className: StringUtils.combineStrings(['dot', props.activeStep === index && 'active']), onClick: function () { return props.onChange(index); } }, index)); }) })) }))); };
