import {
    Button,
    ButtonGroup,
    DATE_TIME_FORMAT,
    Drawer,
    FormField,
    IAreaTime,
    IAvailableAreaTimeRequested,
    ILocalizedError,
    IReservation,
    Label,
    PlatformName,
    ReservationStatus,
    ReservationType,
    ReservationUtils,
    SERVER_DATE_FORMAT,
    Spinner,
    useConfirmContext,
} from '@localina/core';
import { CloseIcon } from '@localina/icons';
import { Alert, Divider } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useUploadDocument } from '../../api/queries/documents';
import {
    useCreateReservation,
    useCreateWalkinReservation,
    useUpdateReservation,
    useUpdateReservationStatus,
    useUpdateWalkinReservation,
} from '../../api/queries/reservations';
import { useAvailableAreaTimes } from '../../api/queries/restaurantAreas';
import { useRestaurantById } from '../../api/queries/restaurants';
import { useStaffTags } from '../../api/queries/staffTags';
import { ReservationDetailsType } from '../../enums';
import { IReservationForm } from '../../schemas/interfaces';
import { useReservationForm } from '../../schemas/reservation';
import { GuestInfoFormFields } from '../GuestInfo';
import { ReservationFormFields } from '../ReservationFormFields';

interface IProps {
    open: boolean;
    onClose: () => void;
    restaurantId: string;
    isWalkin?: boolean;
    reservation?: IReservation;
    defaultFormValues?: Partial<IReservationForm>;
}

function ReservationView(props: IProps) {
    const { t } = useTranslation();
    const [canChangeGuestStatus] = useHaveAccountFeatures(['guestStatus']);
    const restaurantQuery = useRestaurantById(props.restaurantId, { enabled: false });

    const reservationType: ReservationDetailsType = props.isWalkin
        ? ReservationDetailsType.WALKIN
        : props.reservation?.id
        ? ReservationDetailsType.EDIT
        : ReservationDetailsType.CREATE;

    const methods = useReservationForm({
        isWalkin: Boolean(props.isWalkin),
        isStaffTagMandatory: Boolean(restaurantQuery.data?.configuration.staffTagMandatory),
        open: props.open,
        reservationType,
        reservation: props.reservation,
        defaultFormValues: props.defaultFormValues,
    });

    const { formState, watch } = methods;
    const { isDirty, isValid, errors, dirtyFields } = formState;

    const [reservationDocument, setReservationDocument] = useState<File | undefined>();

    const createReservationMutation = useCreateReservation();
    const updateReservationMutation = useUpdateReservation();
    const createWalkinReservationMutation = useCreateWalkinReservation();
    const updateWalkinReservationMutation = useUpdateWalkinReservation();
    const updateReservationStatusMutation = useUpdateReservationStatus();
    const uploadDocumentMutation = useUploadDocument();

    const [areaTimeVariables, setAreaTimeVariables] = useState<IAvailableAreaTimeRequested[]>([]);

    const availableAreaTimeFetchingEnabled = Boolean(
        areaTimeVariables.length && !errors.participants && !errors.date && !errors.time,
    );
    const availableAreaTimeQueries = useAvailableAreaTimes(areaTimeVariables, {
        enabled: availableAreaTimeFetchingEnabled,
    });

    const availableAreaTimesQueriesSuccess = availableAreaTimeQueries.every(({ isSuccess }) => isSuccess);

    const availableAreaTimes = useMemo(() => {
        return Boolean(areaTimeVariables.length) && availableAreaTimesQueriesSuccess
            ? availableAreaTimeQueries.reduce((acc, { data }) => (data ? [...acc, data] : acc), [] as IAreaTime[])
            : [];
    }, [areaTimeVariables, availableAreaTimesQueriesSuccess]);

    const commonMutationOptions = {
        onSuccess: () => {
            props.onClose();
        },
        onError: () => {
            void handleGenericReservationError();
        },
    };

    const staffTagsQuery = useStaffTags();

    const { snackbar, confirm } = useConfirmContext();

    const confirmCloseDrawer = async () => {
        if (
            !isDirty ||
            (await confirm({
                title: t('reservations.view.confirmDiscard.title'),
            })) === 'yes'
        ) {
            props.onClose();
        }
    };

    const [status] = watch(['status']);
    const isChangedStatus = Boolean(
        reservationType === ReservationDetailsType.EDIT &&
            (dirtyFields as any).status &&
            status === ReservationStatus.RESTAURANT_CANCELLED,
    );

    async function createReservation(forceCreate: boolean) {
        if (restaurantQuery.data) {
            const formValue = methods.getValues();
            if (isValid) {
                let documentUrl = '';
                if (!formValue.walkin && formValue.documents && formValue.documents.length) {
                    if (typeof formValue.documents[0] !== 'string') {
                        documentUrl = await uploadDocumentMutation.mutateAsync(
                            { document: formValue.documents[0] },
                            {
                                onError: () => {
                                    void handleGenericReservationError();
                                    return;
                                },
                            },
                        );
                    } else {
                        documentUrl = formValue.documents[0];
                    }
                }
                const shiftOfSelectedSlots = formValue.areaTimes.length ? formValue.areaTimes[0].shiftId : '';
                const areasOfSelectedSlots = formValue.areaTimes.map(({ areaId }) => areaId);
                const relevantTimeSlot = ReservationUtils.getTimeSlotForReservationTime(
                    availableAreaTimes.find(
                        (at) => at.shiftId === shiftOfSelectedSlots && at.areaId === areasOfSelectedSlots[0],
                    )?.timeSlots || [],
                    formValue.time || '',
                );
                const tables =
                    restaurantQuery.data?.configuration.virtualAreas
                        .flatMap((v) => v.areas)
                        .filter((area) => areasOfSelectedSlots.includes(area.id))
                        .flatMap((a) => a.tablePlans)
                        .flatMap((tp) => tp.tables)
                        .map((ta) => ta.id) || [];
                const reservationDate = Array.isArray(formValue.date)
                    ? formValue.date.reduce((a, b) => (a < b ? a : b), formValue.date[0])
                    : formValue.date;

                const value: IReservation = {
                    dates: Array.isArray(formValue.date) ? formValue.date : undefined,
                    id: props.reservation?.id || '',
                    areaIds: areasOfSelectedSlots,
                    shiftId: shiftOfSelectedSlots,
                    occupancyTime: (formValue.occupancyTime || 0) * 60,
                    participants: Number(formValue.participants),
                    reservationType: ReservationType.MANUAL,
                    confirmedAt: '',
                    contactTracingSubmitted: false,
                    read: true,
                    tableIds: formValue.tableIds.filter((tableId) => tables.includes(tableId)),
                    color: formValue.color,
                    reservationDateTime:
                        DateTime.fromFormat(
                            `${reservationDate} ${relevantTimeSlot?.timeSlot || []}`,
                            DATE_TIME_FORMAT,
                        ).toISO() || '',
                    documents: documentUrl ? [documentUrl] : [],
                    ...(!formValue.walkin
                        ? {
                              status: formValue.status || ReservationStatus.APPROVED,
                              comment: formValue.comment || '',
                              phoneNumber: formValue.phoneNumber?.replaceAll(' ', ''),
                              sendSmsReminder: formValue.sendSmsReminder,
                              sendEmailReminder: formValue.sendEmailReminder,
                              guestInfo: {
                                  id: '',
                                  email: formValue.email,
                                  firstName: formValue.firstName,
                                  salutation: formValue.salutation,
                                  guestStatus: canChangeGuestStatus ? formValue.guestStatus || null : null,
                                  lastName: formValue.lastName || '',
                                  phoneNumbers: [],
                                  subscribeToNewsletter: false,
                                  company: formValue.company,
                                  allergies: formValue.allergies,
                                  restaurantComment: formValue.restaurantComment,
                              },
                              staffTagId:
                                  restaurantQuery.data?.configuration.staffTagEnabled && formValue.staffTagId
                                      ? formValue.staffTagId
                                      : null,
                          }
                        : {
                              status: ReservationStatus.APPROVED,
                              staffTagId: null,
                          }),
                };
                if (value.id !== '') {
                    if (value.guestInfo && !formValue.walkin) {
                        updateReservationMutation.mutate(
                            {
                                reservation: value,
                                oldReservation: props.reservation || value,
                                sendConfirmationMail: Boolean(formValue.sendConfirmationMail),
                            },
                            commonMutationOptions,
                        );
                    } else {
                        updateWalkinReservationMutation.mutate({ reservation: value }, commonMutationOptions);
                    }
                } else if (value.guestInfo && !formValue.walkin) {
                    createReservationMutation.mutate(
                        {
                            reservation: value,
                            sendConfirmationMail: Boolean(formValue.sendConfirmationMail),
                            forceCreate,
                        },
                        {
                            ...commonMutationOptions,
                            onError: (err) => {
                                void handleCreateReservationError(err);
                            },
                        },
                    );
                } else {
                    createWalkinReservationMutation.mutate({ reservation: value }, commonMutationOptions);
                }
            } else if (
                isChangedStatus &&
                props.reservation &&
                !formValue.walkin &&
                formValue.status &&
                (await confirm({
                    title: t('reservations.view.changeReservationStatusPopup.title'),
                    msg: t('reservations.view.changeReservationStatusPopup.message'),
                    confirmLabel: t('reservations.view.changeReservationStatusPopup.confirmLabel'),
                })) === 'yes'
            ) {
                updateReservationStatusMutation.mutate(
                    {
                        reservationId: props.reservation.id,
                        reservationDate: DateTime.fromISO(props.reservation.reservationDateTime).toFormat(
                            SERVER_DATE_FORMAT,
                        ),
                        status: formValue.status,
                    },
                    commonMutationOptions,
                );
            }
        }
    }

    const handleCreateReservationError = async (error: ILocalizedError) => {
        if (error?.errorKey === 'error.message.serialReservationNoShift') {
            const response = await confirm({
                title: t('reservations.view.serialReservationPopup.title', {
                    unavailableDates: error.context?.unavailableDates,
                }),
                confirmLabel: t('reservations.view.serialReservationPopup.confirmLabel'),
                cancelLabel: t('reservations.view.serialReservationPopup.cancelLabel'),
            });

            if (response === 'yes') {
                void createReservation(true);
            }
        } else {
            void handleGenericReservationError();
        }
    };

    const handleGenericReservationError = () => {
        snackbar({ msg: t('reservations.view.error'), severity: 'error' });
    };

    const isLoading =
        createReservationMutation.isLoading ||
        updateReservationMutation.isLoading ||
        createWalkinReservationMutation.isLoading ||
        updateWalkinReservationMutation.isLoading ||
        updateReservationStatusMutation.isLoading ||
        uploadDocumentMutation.isLoading;

    const shouldDisableUpdate = Boolean(
        reservationType === ReservationDetailsType.EDIT &&
            props.reservation &&
            ReservationUtils.isReservationFromGoogle(props.reservation) &&
            ReservationUtils.isReservationCancelled(props.reservation),
    );

    const shouldDisplayLinkToGuestDb = Boolean(
        props.reservation?.guestInfo?.id &&
            props.reservation.guestInfo.email &&
            props.reservation.guestInfo.phoneNumbers.length,
    );

    const isLocalCH = Boolean(
        props.reservation &&
            ReservationUtils.getPlatformName(props.reservation) ===
                ReservationUtils.getPlatformName({ ...props.reservation, platformName: PlatformName.LOCALCH }),
    );

    const isNotMyLocalina = Boolean(
        props.reservation &&
            ReservationUtils.getPlatformName(props.reservation) !==
                ReservationUtils.getPlatformName({ ...props.reservation, platformName: PlatformName.MYLOCALINA }),
    );

    return (
        <Drawer open={props.open} onClose={confirmCloseDrawer} position="right" className={'reservation-view'}>
            <div className="localina-drawer__header">
                <CloseIcon onClick={confirmCloseDrawer} />
                <Label type="title" value={t(`reservations.view.title.${reservationType}`)} variant="h5" />
            </div>
            <FormProvider {...methods}>
                <div className="localina-drawer__body__content">
                    <div className="reservation-details">
                        {shouldDisableUpdate && <div className="disabled-form-mask" />}
                        <ReservationFormFields
                            reservation={props.reservation}
                            isWalkin={props.isWalkin}
                            setAreaTimeVariables={setAreaTimeVariables}
                            availableAreaTimes={availableAreaTimes}
                            availableAreaTimesIsLoading={availableAreaTimeQueries.some(
                                (areaTimeQuery) => areaTimeQuery.isInitialLoading,
                            )}
                            document={reservationDocument}
                            setDocument={setReservationDocument}
                        />
                        {!props.isWalkin && (
                            <>
                                <Divider className="guest-title-divider"></Divider>
                                <Label type={'title'} value={t('reservations.view.fields.guestTitle')} variant={'h5'} />

                                <GuestInfoFormFields
                                    guestId={shouldDisplayLinkToGuestDb ? props.reservation?.guestInfo?.id : undefined}
                                    restaurantId={props.restaurantId}
                                    onSelectGuest={(guest, phoneNumber) => {
                                        methods.setValue('guestStatus', guest.guestStatus, { shouldDirty: true });
                                        methods.setValue('salutation', guest.salutation || undefined, {
                                            shouldDirty: true,
                                        });
                                        methods.setValue('lastName', guest.lastName || '', { shouldDirty: true });
                                        methods.setValue('firstName', guest.firstName || '', { shouldDirty: true });
                                        methods.setValue('company', guest.company || '', { shouldDirty: true });
                                        methods.setValue('email', guest.email || '', { shouldDirty: true });
                                        methods.setValue('phoneNumber', phoneNumber, { shouldDirty: true });
                                        methods.setValue('allergies', guest.allergies || '', { shouldDirty: true });
                                        methods.setValue('restaurantComment', guest.restaurantComment || '', {
                                            shouldDirty: true,
                                        });
                                        void methods.trigger([
                                            'guestStatus',
                                            'salutation',
                                            'lastName',
                                            'firstName',
                                            'company',
                                            'email',
                                            'phoneNumber',
                                            'allergies',
                                            'restaurantComment',
                                        ]);
                                    }}
                                />
                            </>
                        )}

                        {props.reservation?.auditInfo && (
                            <div className="audit-container">
                                <Divider className="guest-title-divider" />
                                <div className="audit-row">
                                    <div className="audit-header">
                                        <Label value={t('reservations.view.createdAt')} type="text" />
                                    </div>
                                    <div>
                                        <div>
                                            <Label
                                                type="text"
                                                value={DateTime.fromISO(props.reservation.auditInfo.createdAt).toFormat(
                                                    DATE_TIME_FORMAT,
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Label
                                                type="text"
                                                value={
                                                    props.reservation.auditInfo.createdBy?.displayName ||
                                                    (isLocalCH ? (
                                                        <a href={ReservationUtils.localCHUrl}>
                                                            {ReservationUtils.getPlatformName(props.reservation)}
                                                        </a>
                                                    ) : isNotMyLocalina ? (
                                                        ReservationUtils.getPlatformName(props.reservation)
                                                    ) : (
                                                        t('reservations.view.online')
                                                    ))
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="audit-row">
                                    <div className="audit-header">
                                        <Label value={t('reservations.view.updatedAt')} type="text" />
                                    </div>
                                    <div>
                                        <div>
                                            <Label
                                                type="text"
                                                value={DateTime.fromISO(props.reservation.auditInfo.updatedAt).toFormat(
                                                    DATE_TIME_FORMAT,
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Label
                                                type="text"
                                                value={
                                                    !props.reservation.auditInfo.updatedBy && isLocalCH ? (
                                                        <a href={ReservationUtils.localCHUrl}>
                                                            {ReservationUtils.getPlatformName(props.reservation)}
                                                        </a>
                                                    ) : (
                                                        props.reservation.auditInfo.updatedBy?.displayName ||
                                                        (isNotMyLocalina
                                                            ? ReservationUtils.getPlatformName(props.reservation)
                                                            : t('reservations.view.online'))
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="localina-drawer__footer">
                    {!props.isWalkin &&
                        !shouldDisableUpdate &&
                        staffTagsQuery.isSuccess &&
                        staffTagsQuery.data.staffTagEnabled &&
                        Boolean(staffTagsQuery.data.staffTags.length) && (
                            <div className={'staff-tag-button-group-wrapper'}>
                                <FormField
                                    name="staffTagId"
                                    accepter={ButtonGroup}
                                    options={staffTagsQuery.data.staffTags.map((tag) => ({
                                        label: tag.staffTag,
                                        value: tag.id,
                                    }))}
                                    enableDeselect={!staffTagsQuery.data.staffTagMandatory}
                                    className="select-staff-tag"
                                />
                            </div>
                        )}

                    {shouldDisableUpdate && (
                        <Alert severity={'error'}>
                            {t('reservations.view.updateDisabled.googleReservationCancelled')}
                        </Alert>
                    )}
                    <div className="buttons">
                        <Button
                            label={t(`common.buttons.cancel`)}
                            secondary
                            type="button"
                            onClick={confirmCloseDrawer}
                        />
                        <Button
                            label={t('common.buttons.save')}
                            type="submit"
                            disabled={!((isValid && isDirty) || isChangedStatus) || shouldDisableUpdate}
                            onClick={() => {
                                void createReservation(false);
                            }}
                        />
                    </div>
                </div>
            </FormProvider>
            {isLoading && <Spinner />}
        </Drawer>
    );
}

export default ReservationView;
