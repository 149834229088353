import { AMOUNT_OF_DATES_FOR_STATS, DotsNavigation, RestaurantUtils } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useRestaurant } from '../../api/queries/restaurants';
import { useRestaurantStats } from '../../api/queries/restaurantStats';
import { Page, RestaurantDateStats } from '../../components';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [index, setIndex] = React.useState(0);

    const restaurantQuery = useRestaurant();

    const dates =
        restaurantQuery.isSuccess && restaurantQuery.data
            ? RestaurantUtils.getNextOpeningDates(restaurantQuery.data, AMOUNT_OF_DATES_FOR_STATS)
            : [];

    const restaurantStatsQuery = useRestaurantStats(dates, {
        enabled: Boolean(restaurantQuery.isSuccess && restaurantQuery.data),
    });

    const handleClick = (date: string) => {
        if (restaurantQuery.isSuccess && restaurantQuery.data) {
            navigate(
                PathUtils.generateUrl(
                    Path.RESTAURANT_RESERVATIONS,
                    { restaurantId: restaurantQuery.data.id },
                    { date },
                ),
            );
        }
    };

    return (
        <Page name="dashboard" title={t('dashboard.title')} isLoading={restaurantStatsQuery.isLoading}>
            {restaurantStatsQuery.isSuccess && restaurantStatsQuery.data.stats.length !== 0 && (
                <>
                    <DotsNavigation
                        activeStep={index}
                        steps={restaurantStatsQuery.data.stats.length}
                        onChange={setIndex}
                    />
                    <SwipeableViews index={index} onChangeIndex={setIndex}>
                        {restaurantStatsQuery.data.stats.map((stats, idx) => (
                            <RestaurantDateStats key={idx} stats={stats} onClick={handleClick} />
                        ))}
                    </SwipeableViews>
                </>
            )}
        </Page>
    );
};

export default Dashboard;
