import { ChevronDownIcon, ChevronUpIcon } from '@localina/icons';
import React, { Dispatch, SetStateAction } from 'react';

interface ICollapseButtonProps {
    collapsed: boolean;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const CollapseButton = (props: ICollapseButtonProps) => {
    const toggleCollapse = () => {
        props.setCollapsed((prevState) => !prevState);
    };

    return (
        <span className="localina-collapse-button" onClick={toggleCollapse}>
            {props.collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </span>
    );
};

export default CollapseButton;
