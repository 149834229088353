import { useMatch } from 'react-router-dom';
import { Path } from '../enums';

function useRestaurantId(restaurantId?: string): string {
    const match = useMatch({
        path: Path.RESTAURANT,
        end: false,
    });

    return restaurantId || match?.params.restaurantId || '';
}

export { useRestaurantId };
