var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog as MuiDialog } from '@mui/material';
import { StringUtils } from '../../utils';
import { Button } from '../Button';
import { Label } from '../Label';
export var Dialog = function (props) { return (_jsxs(MuiDialog, __assign({ className: StringUtils.combineStrings(['localina-dialog', props.className]), open: props.open, onClose: props.onClose }, { children: [_jsx(Label, { type: "text", value: props.title }), _jsx(Label, { type: "text", value: props.message }), _jsxs("div", __assign({ className: props.onCancel && props.onConfirm ? 'buttons' : 'button' }, { children: [props.onCancel && _jsx(Button, { label: props.cancelLabel, secondary: true, onClick: props.onCancel }), props.onConfirm && _jsx(Button, { label: props.confirmLabel, onClick: props.onConfirm })] }))] }))); };
