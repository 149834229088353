import { IRestaurantShift, ListItem, ShiftUtils, TLanguageKey } from '@localina/core';
import { RestaurantMenuIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    shift: IRestaurantShift;
    onClick: (shift: IRestaurantShift) => void;
}

export const ShiftListItem: React.FC<IProps> = (props) => {
    const { i18n } = useTranslation();
    if (props.shift.shiftType !== 'recurrent') {
        return null;
    }
    const title = props.shift.name[i18n.language as TLanguageKey];
    const subtitle = ShiftUtils.getSubtitleForShift(props.shift, i18n.language);

    const handleClick = () => {
        props.onClick(props.shift);
    };

    return <ListItem title={title} subtitle={subtitle} leftIcon={<RestaurantMenuIcon />} onClick={handleClick} />;
};
