import { ILocalizedError, ISupportUser } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useSendResetPasswordEmailMutation } from './auth';
import { queryKeys } from './query-keys';

function useSupportUsers(
    options?: UseQueryOptions<ISupportUser[], ILocalizedError, ISupportUser[], typeof queryKeys.support.users.all>,
) {
    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.supportApi.getUsers();
        },
        queryKey: queryKeys.support.users.all,
        ...options,
    });
}

function useSupportUser(
    userId: string,
    options?: UseQueryOptions<
        ISupportUser,
        ILocalizedError,
        ISupportUser,
        ReturnType<typeof queryKeys.support.users.single>
    >,
) {
    const queryClient = useQueryClient();

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.supportApi.getUser(userId);
        },
        queryKey: queryKeys.support.users.single(userId),
        initialData: () => {
            const usersData = queryClient.getQueryData<ReturnType<typeof useSupportUsers>['data']>(
                queryKeys.support.users.all,
            );
            return usersData?.find((fetchedUser) => fetchedUser.id === userId);
        },
        ...options,
    });
}

const useCreateSupportUser = (options?: UseMutationOptions<ISupportUser, ILocalizedError, ISupportUser>) => {
    const sendResetPasswordEmailMutation = useSendResetPasswordEmailMutation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.supportApi.createUser(user);
        },
        onSuccess: (createdUser) => {
            sendResetPasswordEmailMutation.mutate(createdUser.email);

            queryClient.setQueryData<ReturnType<typeof useSupportUser>['data']>(
                queryKeys.support.users.single(createdUser.id),
                createdUser,
            );
        },
        ...options,
    });
};
const useUpdateSupportUser = (options?: UseMutationOptions<void, ILocalizedError, ISupportUser>) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.supportApi.updateUser(user);
        },
        onSuccess: (_, user) => {
            queryClient.setQueryData<ReturnType<typeof useSupportUsers>['data']>(
                queryKeys.support.users.all,
                (data) => {
                    return data?.map((supportUser) => (supportUser.id === user.id ? user : supportUser));
                },
            );
            queryClient.setQueryData<ReturnType<typeof useSupportUser>['data']>(
                queryKeys.support.users.single(user.id),
                user,
            );
        },
        ...options,
    });
};
const useDeleteSupportUser = (options?: UseMutationOptions<void, ILocalizedError, ISupportUser>) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.supportApi.deleteUser(user);
        },
        onSuccess: (_, user) => {
            queryClient.setQueryData<ReturnType<typeof useSupportUsers>['data']>(
                queryKeys.support.users.all,
                (data) => {
                    return data?.filter((supportUser) => supportUser.id !== user.id);
                },
            );
        },
        ...options,
    });
};

export { useSupportUsers, useSupportUser, useCreateSupportUser, useUpdateSupportUser, useDeleteSupportUser };
