var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListItem } from '../List';
import { Spinner } from '../Spinner';
var infiniteListWrapperId = 'localina-infiniteList-scrollable';
export var InfiniteList = React.forwardRef(function (props, ref) { return (_jsx("div", __assign({ className: "localina-infinitelist", id: infiniteListWrapperId, ref: ref }, { children: _jsx(InfiniteScroll, __assign({ scrollableTarget: props.scrollableTarget || infiniteListWrapperId, dataLength: props.items.length, next: props.loadNext, hasMore: props.hasMore, loader: _jsx(Spinner, { position: "relative" }) }, { children: props.items.length === 0 && props.emptyMessage ? _jsx(ListItem, { title: props.emptyMessage }) : props.items })) }))); });
