import { IReservation, Label, StringUtils, Tooltip } from '@localina/core';
import { CookieIcon, InfoIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IReservationGuestAdditionalInfoProps {
    reservation: IReservation;
    tooltipContainer?: Element;
    allergiesOnly?: boolean;
}

const ReservationGuestAdditionalInfo = ({
    reservation,
    tooltipContainer,
    allergiesOnly,
}: IReservationGuestAdditionalInfoProps) => {
    const { t } = useTranslation();

    const noAdditionalInfo = !(allergiesOnly
        ? reservation.guestInfo?.allergies
        : reservation.comment || reservation.guestInfo?.allergies || reservation.guestInfo?.restaurantComment);
    return (
        <Tooltip
            container={tooltipContainer}
            stopPropagation
            className={StringUtils.combineStrings([
                noAdditionalInfo && 'no-additional-info',
                'reservation-guest-additional-info-icon-container',
            ])}
            popperClassName="tooltip additional-info-poper"
            clickAwayMouseEvent="onPointerDown"
            content={
                <AdditionalInfoTooltip
                    fields={
                        noAdditionalInfo
                            ? [{ name: t('guest.details.view.infoTooltip.noAdditionalInfo'), value: ' ' }]
                            : allergiesOnly
                            ? [
                                  {
                                      name: t('guest.details.view.fields.allergies'),
                                      value: reservation.guestInfo?.allergies,
                                  },
                              ]
                            : [
                                  { name: t('reservations.view.fields.comment'), value: reservation.comment },
                                  {
                                      name: t('guest.details.view.fields.allergies'),
                                      value: reservation.guestInfo?.allergies,
                                  },
                                  {
                                      name: t('guest.details.view.fields.restaurantComment'),
                                      value: reservation.guestInfo?.restaurantComment,
                                  },
                              ]
                    }
                />
            }
        >
            {allergiesOnly ? (
                <CookieIcon className="reservation-guest-additional-info-icon" />
            ) : (
                <InfoIcon className="reservation-guest-additional-info-icon" />
            )}
        </Tooltip>
    );
};

interface ITooltipProps {
    fields: { name: string; value?: string }[];
}

function AdditionalInfoTooltip(props: ITooltipProps) {
    return (
        <div className="additional-info-tooltip">
            {props.fields
                .filter((f) => (f.value?.length || 0) > 0)
                .map((field) => {
                    return (
                        <React.Fragment key={field.name}>
                            <div className="header">
                                <Label type="text" value={field.name} />
                            </div>
                            <div className="main">
                                <Label type="info" value={field.value} />
                            </div>
                        </React.Fragment>
                    );
                })}
        </div>
    );
}

export default ReservationGuestAdditionalInfo;
