import React from 'react';
import { useTranslation } from 'react-i18next';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';
import { Category, ICategoryItem } from './Category';

interface IProps {
    restaurantId: string;
}

export const Reservations: React.FC<IProps> = ({ restaurantId }) => {
    const { t } = useTranslation();

    const categoryItems: ICategoryItem[] = [
        {
            title: t('common.menu.categories.reservations.menu.guests'),
            url: PathUtils.generateUrl(Path.RESTAURANT_GUESTS, { restaurantId }),
        },
        {
            title: t('common.menu.categories.reservations.menu.reservations'),
            url: PathUtils.generateUrl(Path.RESTAURANT_RESERVATIONS, { restaurantId }),
        },
    ];

    return (
        <Category name="reservations" title={t('common.menu.categories.reservations.title')} items={categoryItems} />
    );
};
