import {
    Accordion,
    DATE_TIME_FORMAT,
    IImportMetadataItem,
    ImportStatus,
    ImportType,
    Label,
    StringUtils,
    TIME_DURATION_FORMAT,
    TIME_FORMAT,
} from '@localina/core';
import { Check, Description, Schedule } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    Dialog as MuiDialog,
    LinearProgress,
    SpeedDial as MuiSpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportMetadata } from '../../api/queries/importProgress';
import { queryKeys } from '../../api/queries/query-keys';

interface IImportMetadataProgressProps {
    restaurantId: string;
    className?: string;
    importInitiated: boolean;
    setImportInitiated: Dispatch<SetStateAction<boolean>>;
}

const ImportMetadataProgress = (props: IImportMetadataProgressProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const importMetadataQuery = useImportMetadata(props.restaurantId, props.importInitiated);

    const [selectedType, setSelectedType] = useState<ImportType | null>(null);

    const fabButtonPercentage = useMemo(() => {
        if (importMetadataQuery.data) {
            const fabButtonPercentageSum = importMetadataQuery.data.ongoing.reduce(
                (acc, item) => acc + calculateOngoingImportPercent(item),
                0,
            );
            return fabButtonPercentageSum / importMetadataQuery.data.ongoing.length;
        }
        return 0;
    }, [importMetadataQuery.data]);

    const visibleImports = useMemo(() => {
        return (
            importMetadataQuery.data?.all
                .filter(
                    (item) =>
                        [ImportStatus.ONGOING, ImportStatus.SCHEDULED].includes(item.importStatus) ||
                        (item.importStatus === ImportStatus.SUCCESS &&
                            item.endDateTime &&
                            DateTime.fromISO(item.endDateTime).plus({ minute: 5 }) > DateTime.now()),
                )
                .reduce((acc, item) => ({ ...acc, [item.importType]: [...acc[item.importType], item] }), {
                    [ImportType.GUEST]: [],
                    [ImportType.RESERVATION]: [],
                }) || {
                [ImportType.GUEST]: [],
                [ImportType.RESERVATION]: [],
            }
        );
    }, [importMetadataQuery.data]);

    useEffect(() => {
        if (props.importInitiated) {
            if (importMetadataQuery.data?.ongoing.length) {
                setSelectedType(importMetadataQuery.data.ongoing[0].importType);
            } else if (importMetadataQuery.data?.all.length) {
                setSelectedType(importMetadataQuery.data.all[0].importType);
            }
            props.setImportInitiated(false);
        }
    }, [importMetadataQuery.data?.ongoing.length, importMetadataQuery.data?.all.length]);

    useEffect(() => {
        void queryClient.invalidateQueries(queryKeys.restaurants.single.guests.all(props.restaurantId));
    }, [importMetadataQuery.data?.finished.length]);

    return (
        <>
            {Boolean(visibleImports[ImportType.GUEST].length || visibleImports[ImportType.RESERVATION].length) && (
                <>
                    <MuiSpeedDial
                        className={StringUtils.combineStrings([
                            'localina-speeddial',
                            'import-metadata-speeddial',
                            props.className,
                        ])}
                        ariaLabel={'import-metadata'}
                        icon={
                            importMetadataQuery.data?.ongoing.length ? (
                                <SpeedDialIcon icon={<CircularProgressWithLabel value={fabButtonPercentage} />} />
                            ) : (
                                <Check htmlColor="white" />
                            )
                        }
                    >
                        {Boolean(visibleImports[ImportType.RESERVATION].length) && (
                            <SpeedDialAction
                                icon={<SpeedDialActionIcon items={visibleImports[ImportType.RESERVATION]} />}
                                tooltipTitle={
                                    <Label
                                        type="info"
                                        align="center"
                                        value={t(getTranslationKeyByImportType(ImportType.RESERVATION))}
                                    />
                                }
                                tooltipOpen
                                onClick={() => {
                                    setSelectedType(ImportType.RESERVATION);
                                }}
                            />
                        )}
                        {Boolean(visibleImports[ImportType.GUEST].length) && (
                            <SpeedDialAction
                                icon={<SpeedDialActionIcon items={visibleImports[ImportType.GUEST]} />}
                                tooltipTitle={
                                    <Label
                                        type="info"
                                        align="center"
                                        value={t(getTranslationKeyByImportType(ImportType.GUEST))}
                                    />
                                }
                                tooltipOpen
                                onClick={() => {
                                    setSelectedType(ImportType.GUEST);
                                }}
                            />
                        )}
                    </MuiSpeedDial>
                    <MuiDialog
                        open={Boolean(selectedType)}
                        onClose={() => {
                            setSelectedType(null);
                        }}
                    >
                        {selectedType && (
                            <SelectedTypeDialogContent type={selectedType} items={visibleImports[selectedType]} />
                        )}
                    </MuiDialog>
                </>
            )}
        </>
    );
};

interface IImportMetadataSpeedDialActionItemProps {
    items: IImportMetadataItem[];
}

const SpeedDialActionIcon = (props: IImportMetadataSpeedDialActionItemProps) => {
    const ongoing = props.items.filter((item) => item.importStatus === ImportStatus.ONGOING);
    if (ongoing.length) {
        const percentageSum = ongoing.reduce((acc, item) => acc + calculateOngoingImportPercent(item), 0);
        return <CircularProgressWithLabel value={percentageSum / ongoing.length} />;
    }
    if (props.items.some((item) => item.importStatus === ImportStatus.SCHEDULED)) {
        return <Schedule htmlColor="white" />;
    }
    return <Check htmlColor="white" />;
};

const SelectedTypeDialogContent = ({ items, type }: { items: IImportMetadataItem[]; type: ImportType }) => {
    const { t } = useTranslation();

    const groupedByStatus: Record<ImportStatus, IImportMetadataItem[]> = items.reduce(
        (acc, item) => ({ ...acc, [item.importStatus]: [...acc[item.importStatus], item] }),
        {
            [ImportStatus.ONGOING]: [],
            [ImportStatus.SUCCESS]: [],
            [ImportStatus.SCHEDULED]: [],
            [ImportStatus.ERROR]: [],
        },
    );

    return (
        <Box className="import-group-metadata-dialog">
            <Label type={'title'} extraClassName="dialog-title" value={t(t(getTranslationKeyByImportType(type)))} />
            <Box className="imports-container">
                {[ImportStatus.ONGOING, ImportStatus.SCHEDULED, ImportStatus.SUCCESS]
                    .filter((status) => groupedByStatus[status].length)
                    .map((status) => (
                        <Accordion title={t(getTranslationKeyByImportStatus(status))} key={status} defaultExpanded>
                            {groupedByStatus[status].map((item) => (
                                <SelectedItemDialogContent item={item} key={item.id} />
                            ))}
                        </Accordion>
                    ))}
            </Box>
        </Box>
    );
};
const SelectedItemDialogContent = ({ item }: { item: IImportMetadataItem }) => {
    const { t } = useTranslation();
    const dateTimeStarted = item.startDateTime ? DateTime.fromISO(item.startDateTime) : null;
    const dateTimeCreated = DateTime.fromISO(item.createdDateTime);

    const calculateTimeElapsed = () => {
        const interval = dateTimeStarted
            ? dateTimeStarted.until(item.endDateTime ? DateTime.fromISO(item.endDateTime) : DateTime.now())
            : null;

        return interval && interval.isValid ? interval.toDuration().toFormat(TIME_DURATION_FORMAT) : '';
    };

    const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed);
    const timeoutRef = useRef<number>();

    useEffect(() => {
        if (item.importStatus === ImportStatus.ONGOING) {
            clearTimeout(timeoutRef.current);

            timeoutRef.current = window.setTimeout(() => {
                setTimeElapsed(calculateTimeElapsed);
            }, 1000);
        }
    }, [item.importStatus, timeElapsed]);

    return (
        <Box className="import-metadata-progress-container">
            {Boolean(item.fileName) && (
                <Label type="text" extraClassName="import-file-name" value={item.fileName} icon={<Description />} />
            )}
            {[ImportStatus.ONGOING, ImportStatus.SUCCESS].includes(item.importStatus) && (
                <>
                    <Box className="progress-wrapper">
                        <LinearProgressWithLabel value={calculateOngoingImportPercent(item)} />
                        {item.importStatus === ImportStatus.ONGOING && (
                            <Label
                                type={'label'}
                                value={t('guests.importProgressMetadata.details.progressLabel', {
                                    importedCount: item.importedItemCount,
                                    totalCount: item.totalItemCount,
                                })}
                            />
                        )}
                        {item.importStatus === ImportStatus.SUCCESS && (
                            <>
                                {item.importType === ImportType.GUEST && (
                                    <Label
                                        type={'label'}
                                        value={t('guests.import.success', { guests: item.importedItemCount })}
                                    />
                                )}
                                {item.importType === ImportType.RESERVATION && (
                                    <Label
                                        type={'label'}
                                        value={t('guests.reservationsImport.success', {
                                            reservations: item.importedItemCount,
                                        })}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    <Box className="details-wrapper">
                        <Box className="details-item">
                            <Label type={'label'} value={t('guests.importProgressMetadata.details.started')} />
                            <Label
                                type={'label'}
                                value={dateTimeStarted?.toFormat(
                                    dateTimeStarted.day === DateTime.now().day ? TIME_FORMAT : DATE_TIME_FORMAT,
                                )}
                            />
                        </Box>
                        {item.endDateTime && item.importStatus === ImportStatus.SUCCESS && (
                            <Box className="details-item">
                                <Label type={'label'} value={t('guests.importProgressMetadata.details.timeEnded')} />
                                <Label
                                    type={'label'}
                                    value={DateTime.fromISO(item.endDateTime).toFormat(TIME_FORMAT)}
                                />
                            </Box>
                        )}
                        <Box className="details-item">
                            <Label type={'label'} value={t('guests.importProgressMetadata.details.timeElapsed')} />
                            <Label type={'label'} value={timeElapsed} />
                        </Box>
                    </Box>
                </>
            )}
            {item.importStatus === ImportStatus.SCHEDULED && (
                <>
                    <Box className="scheduled-message-wrapper">
                        <Label
                            type="label"
                            value={t('guests.importProgressMetadata.details.scheduledImportInfoMessage')}
                        />
                    </Box>
                    <Box className="details-wrapper">
                        <Box className="details-item">
                            <Label
                                type={'label'}
                                value={t('guests.importProgressMetadata.details.totalItemsToImport')}
                            />
                            <Label type={'label'} value={item.totalItemCount} />
                        </Box>
                        <Box className="details-item">
                            <Label type={'label'} value={t('guests.importProgressMetadata.details.created')} />
                            <Label
                                type={'label'}
                                value={dateTimeCreated.toFormat(
                                    dateTimeCreated.day === DateTime.now().day ? TIME_FORMAT : DATE_TIME_FORMAT,
                                )}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};
const getTranslationKeyByImportType = (importType: IImportMetadataItem['importType']) =>
    importType === ImportType.RESERVATION
        ? 'guests.importProgressMetadata.title.reservationImports'
        : 'guests.importProgressMetadata.title.guestImports';

const getTranslationKeyByImportStatus = (importStatus: IImportMetadataItem['importStatus']) => {
    switch (importStatus) {
        case ImportStatus.ONGOING:
            return 'guests.importProgressMetadata.title.ongoingImports';
        case ImportStatus.SCHEDULED:
            return 'guests.importProgressMetadata.title.scheduledImports';
        case ImportStatus.SUCCESS:
            return 'guests.importProgressMetadata.title.successImports';
        default:
            return '';
    }
};

const calculateOngoingImportPercent = (item: IImportMetadataItem): number =>
    (item.importedItemCount * 100) / item.totalItemCount;

const LinearProgressWithLabel = (props: { value: number }) => (
    <Box className="linear-progress-with-label-wrapper">
        <Box className="linear-progress">
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box>
            <Label type={'label'} value={`${Math.round(props.value)}%`} />
        </Box>
    </Box>
);

function CircularProgressWithLabel(props: { value: number }) {
    return (
        <Box className="circular-progress-with-label-wrapper">
            <CircularProgress variant="determinate" {...props} />
            <Box className="label-wrapper">
                <Label type={'info'} value={`${Math.round(props.value)}%`} />
            </Box>
        </Box>
    );
}

export default ImportMetadataProgress;
