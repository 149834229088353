var ImportStatus;
(function (ImportStatus) {
    ImportStatus["ONGOING"] = "ONGOING";
    ImportStatus["ERROR"] = "ERROR";
    ImportStatus["SUCCESS"] = "SUCCESS";
    ImportStatus["SCHEDULED"] = "SCHEDULED";
})(ImportStatus || (ImportStatus = {}));
var ImportType;
(function (ImportType) {
    ImportType["GUEST"] = "GUEST";
    ImportType["RESERVATION"] = "RESERVATION";
})(ImportType || (ImportType = {}));
export { ImportType, ImportStatus };
