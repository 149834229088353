import { IGuestInfo, InfiniteList, IReservation, Label, useConfirmContext } from '@localina/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeleteGuest, useGuest, useGuestReservationList, useUpdateGuest } from '../../api/queries/guests';
import { queryKeys } from '../../api/queries/query-keys';
import { GuestReservationListItem, GuestView, Page } from '../../components';
import { GuestStatusIcon } from '../../components/GuestStatusIcon';
import { Path } from '../../enums';
import { GuestUtils, PathUtils } from '../../utils';
import GuestDetails from './GuestDetails';

interface IPathParams {
    restaurantId: string;
    guestId: string;
}

const Guest: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams() as unknown as IPathParams;
    const { restaurantId, guestId } = params;

    const [guestListPageScrollData, setGuestListPageScrollData] = useState<{
        guestListPage?: string;
        guestListScroll?: string;
    }>();

    const [guest, setGuest] = React.useState<IGuestInfo | undefined>(undefined);

    const { snackbar } = useConfirmContext();
    const queryClient = useQueryClient();
    const guestQuery = useGuest(guestId);
    const guestReservationsQuery = useGuestReservationList(guestId);

    const updateGuestMutation = useUpdateGuest();
    const deleteGuestMutation = useDeleteGuest();

    const isLoading =
        guestReservationsQuery.isInitialLoading ||
        guestQuery.isInitialLoading ||
        updateGuestMutation.isLoading ||
        deleteGuestMutation.isLoading;

    const handleBack = () => {
        const guestListPage = guestListPageScrollData?.guestListPage || location.state?.guestListPage;
        if (guestListPage && typeof guestListPage === 'string') {
            navigate(guestListPage, {
                state: {
                    guestListPage: guestListPage,
                    guestListScroll: guestListPageScrollData?.guestListScroll || location.state?.guestListScroll,
                },
            });
        } else {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_GUESTS, { restaurantId }));
        }
    };

    const handleEditClick = () => {
        if (guestQuery.data) {
            setGuest({
                ...guestQuery.data.guest,
                company: guestQuery.data.guest.company || '',
            });
        }
    };

    const handleClose = () => {
        setGuest(undefined);
    };

    const handleSave = (value: IGuestInfo) => {
        updateGuestMutation.mutate(value, {
            onSuccess: () => {
                handleClose();
                return guestReservationsQuery.refetch();
            },
            onError: () => {
                snackbar({
                    msg: t('guest.details.view.errors.save'),
                    severity: 'error',
                });
            },
        });
    };

    const handleDelete = (value: IGuestInfo) => {
        deleteGuestMutation.mutate(value, {
            onSuccess: () => {
                handleClose();
                handleBack();
            },
            onError: () => {
                snackbar({
                    msg: t('guest.details.view.errors.delete'),
                    severity: 'error',
                });
            },
        });
    };

    const handleReservation = (item: IReservation) => {
        queryClient.setQueryData(
            queryKeys.restaurants.single.reservations.single(restaurantId, item.id),
            (oldData: undefined | IReservation) => (oldData ? undefined : item),
        );
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_GUEST_RESERVATION, {
                restaurantId,
                guestId,
                reservationId: item.id,
            }),
        );
    };

    useEffect(() => {
        const guestListPage = location.state?.guestListPage;
        if (guestListPage && typeof guestListPage === 'string') {
            setGuestListPageScrollData({
                guestListPage: location.state?.guestListPage,
                guestListScroll: location.state?.guestListScroll,
            });
        }
    }, [location.state]);

    return (
        <Page
            name="guest"
            title={{
                value: GuestUtils.formatGuestName(guestQuery.data?.guest),
                icon: guestQuery.isSuccess && guestQuery.data.guest && (
                    <GuestStatusIcon guestStatus={guestQuery.data.guest.guestStatus} />
                ),
                onBack: handleBack,
            }}
            isLoading={isLoading}
        >
            {guestQuery.data?.guest && (
                <div id="guest">
                    <GuestDetails details={guestQuery.data} handleEdit={handleEditClick} />

                    <div className="guest__subheader" style={{ position: 'relative' }}>
                        <Label type="title" variant="h6" value={t('guest.reservations.title')} />
                        <Label
                            type="text"
                            value={guestQuery.data.numReservations}
                            align={'center'}
                            extraClassName={'guest-reservations'}
                        />
                    </div>
                    <InfiniteList
                        scrollableTarget="guest"
                        hasMore={Boolean(guestReservationsQuery.hasNextPage)}
                        items={(guestReservationsQuery.data?.pages || []).flatMap((page) =>
                            page.items.map((item) => (
                                <GuestReservationListItem
                                    key={item.id}
                                    reservation={item}
                                    onClick={handleReservation}
                                />
                            )),
                        )}
                        loadNext={guestReservationsQuery.fetchNextPage}
                    />
                </div>
            )}
            <Outlet />
            <GuestView guest={guest} onClose={handleClose} onSave={handleSave} onDelete={handleDelete} />
        </Page>
    );
};

export default Guest;
