import { IAccount, IFeature, ILocalizedError } from '@localina/core';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { LocalinaApiContext } from '../../../index';
import { ACCOUNT_ID, AccountUtils } from '../../utils';
import { useGetAuthUser } from './auth';
import { queryKeys } from './query-keys';
import { useRestaurant } from './restaurants';
import { getOptionsEnabled } from './utils';

function useGetAccount(
    options?: UseQueryOptions<
        IAccount,
        ILocalizedError,
        ReturnType<typeof transformAccountData>,
        ReturnType<typeof queryKeys.accounts.single>
    >,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const authUserQuery = useGetAuthUser({
        enabled: optionsEnabled,
    });

    const query = useQuery(
        queryKeys.accounts.single(authUserQuery.data?.accountId || 'service-account'),
        () => {
            return authUserQuery.data?.accountId
                ? LocalinaApiContext.supportApi.getAccount(authUserQuery.data.accountId)
                : LocalinaApiContext.serviceApi.getAccount();
        },
        {
            ...options,
            select: transformAccountData,
            enabled: authUserQuery.isSuccess && optionsEnabled,
        },
    );

    const queryClient = useQueryClient();

    useEffect(() => {
        if (
            !authUserQuery.data?.accountId &&
            (authUserQuery.data?.hasSupportRole || authUserQuery.data?.hasSalesRole) &&
            query.data?.id
        ) {
            window.sessionStorage.setItem(ACCOUNT_ID, query.data.id);
            void queryClient.invalidateQueries(queryKeys.auth);
        }
    }, [query.data, authUserQuery.data]);

    return query;
}

const transformAccountData = (data: IAccount) => ({
    ...data,
    hasFeature: (feature: IFeature, restaurantId: string) => AccountUtils.hasFeature(feature, data, restaurantId),
});

const useHaveAccountFeatures = (features: IFeature[], restaurantId?: string, readAccountDataFromCache?: boolean) => {
    const restaurantQuery = useRestaurant({ enabled: false });
    const accountQuery = useGetAccount({ enabled: !readAccountDataFromCache });

    const restaurantIdToUse = restaurantId || restaurantQuery.data?.id;

    return features.map((feature) =>
        Boolean(
            restaurantIdToUse && accountQuery.isSuccess && accountQuery.data.hasFeature(feature, restaurantIdToUse),
        ),
    );
};

const useGetAccountSubscription = (restaurantId?: string) => {
    const restaurantQuery = useRestaurant({
        enabled: !restaurantId,
    });
    const accountQuery = useGetAccount();
    const restaurantIdToUse = restaurantId || restaurantQuery.data?.id;

    return AccountUtils.getSubscription(accountQuery.data, restaurantIdToUse);
};

export { useGetAccount, useHaveAccountFeatures, useGetAccountSubscription };
