import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurant } from '@localina/core';
import { SimpleDrawer } from '../Drawer';
import WidgetIntegration from './Integrations';

interface IProps {
    isOpen: boolean;
    restaurant: IRestaurant;
    onClose: () => void;
}

export const WidgetIntegrationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <SimpleDrawer
            className="integration-view"
            open={props.isOpen}
            onClose={props.onClose}
            title={t('integrations.items.widget.title')}
        >
            <WidgetIntegration id={props.restaurant.id} restaurant={props.restaurant} />
        </SimpleDrawer>
    );
};
