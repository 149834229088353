import {
    IGuestDetails,
    IGuestListItem,
    InfiniteList,
    ISelectOption,
    ISort,
    ISpeedDialActionProps,
    SearchField,
    SortField,
    SortOrder,
    SpeedDial,
    Spinner,
    StringUtils,
    useConfirmContext,
    useUrlParams,
} from '@localina/core';
import {
    DownloadIcon,
    FileUploadIcon,
    ImportExportHorizontalIcon,
    ImportExportVerticalIcon,
    UploadIcon,
} from '@localina/icons';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useGetAuthUser } from '../../api/queries/auth';
import { useGuestList, useGuestsDownload } from '../../api/queries/guests';
import { queryKeys } from '../../api/queries/query-keys';
import { useRestaurant } from '../../api/queries/restaurants';
import { GuestListItem, GuestsImportView, Page } from '../../components';
import ImportMetadataProgress from '../../components/ImportMetadataProgress/ImportMetadataProgress';
import ReservationsImportView from '../../components/ReservationImportView/ReservationsImportView';
import { GuestsExportFormat, Path, SortBy } from '../../enums';
import { PathUtils, RoleUtils } from '../../utils';

interface IPathParams {
    restaurantId: string;
}

const Guests: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const listContainerRef = useRef<HTMLDivElement>(null);
    const params = useMatch(Path.RESTAURANT_GUESTS)?.params as IPathParams;
    const { restaurantId } = params;
    const [importInitiated, setImportInitiated] = useState(false);

    const [urlParams, setUrlParams] = useUrlParams();

    const [queryParam, sortByParam, sortOrderParam] = [
        urlParams.get('query') || '',
        urlParams.get('sortBy') || '',
        urlParams.get('sortOrder') || '',
    ];
    const [query, setQuery] = React.useState<string>(queryParam);
    const authUserQuery = useGetAuthUser();

    const [guestsImportRequested, setGuestsImportRequested] = React.useState(false);
    const [reservationsImportViewOpen, setReservationsImportViewOpen] = React.useState(false);

    const restaurantQuery = useRestaurant();

    const guestsDownloadMutation = useGuestsDownload();

    const isSupportUser =
        authUserQuery.isSuccess && authUserQuery.data.user && RoleUtils.hasSupportRole(authUserQuery.data.user);

    const [filters, setFilters] = useState<{ query: string; sort: ISort }>({
        query: queryParam,
        sort: {
            by:
                sortByParam && Object.values(SortBy).includes(sortByParam as SortBy)
                    ? sortByParam
                    : SortBy.LAST_NAME_FIRST_NAME,
            order:
                sortOrderParam && Object.values(SortOrder).includes(sortOrderParam as SortOrder)
                    ? (sortOrderParam as SortOrder)
                    : SortOrder.ASC,
        },
    });

    const queryClient = useQueryClient();
    const guestListQuery = useGuestList(filters);

    const notDebouncedFilter = useMemo(
        () => ({
            ...filters,
            query,
        }),
        [filters, query],
    );

    const handleSearch = (value: string) => {
        setFilters((prevState) => ({ ...prevState, query: value }));
    };

    const handleSort = (sort: ISort) => {
        setFilters((prevState) => ({ ...prevState, sort }));
    };

    const sortOptions: ISelectOption[] = [
        SortBy.LAST_NAME_FIRST_NAME,
        SortBy.FIRST_NAME_LAST_NAME,
        SortBy.RESERVATION_NUMBER,
    ].map((it) => ({
        label: t(`guests.filter.sort.options.${it}`),
        value: it,
    }));

    const handleGuest = (item: IGuestListItem) => {
        queryClient.setQueryData(
            queryKeys.restaurants.single.guests.single(restaurantId, item.guest.id),
            (oldData?: IGuestDetails) =>
                oldData
                    ? undefined
                    : {
                          firstReservationDateTime: '',
                          lastReservationDateTime: '',
                          ...item,
                      },
        );
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_GUEST, {
                restaurantId,
                guestId: item.guest.id,
            }),
            {
                state: {
                    guestListPage: location.pathname + location.search,
                    guestListScroll: listContainerRef.current?.scrollTop,
                },
            },
        );
    };

    const handleImport = () => {
        setGuestsImportRequested(true);
    };

    const openReservationsImportView = () => {
        setReservationsImportViewOpen(true);
    };
    const handleReservationsUploadClose = () => {
        setReservationsImportViewOpen(false);
    };
    const triggerOpenImportDialog = () => {
        setImportInitiated(true);
    };

    const { snackbar } = useConfirmContext();

    const downloadGuests = () => {
        guestsDownloadMutation.mutate(
            {
                format: GuestsExportFormat.XLSX,
                fileName: StringUtils.combineStrings(
                    ['guests', restaurantQuery.data?.info.name, restaurantQuery.data?.id],
                    '_',
                ),
            },
            {
                onSuccess: () => {
                    snackbar({
                        severity: 'success',
                        msg: t('common.infoMessages.downloadStarted'),
                    });
                },
                onError: () => {
                    snackbar({
                        severity: 'error',
                        msg: t('common.infoMessages.downloadError'),
                    });
                },
            },
        );
    };

    const actions: ISpeedDialActionProps[] = [
        {
            label: t('guests.actions.export'),
            icon: <DownloadIcon />,
            onClick: downloadGuests,
        },
    ];

    actions.push({
        label: t('guests.actions.import'),
        icon: <FileUploadIcon />,
        onClick: handleImport,
    });

    if (isSupportUser) {
        actions.push({
            label: t('guests.actions.reservationsImport'),
            icon: <UploadIcon />,
            onClick: openReservationsImportView,
        });
    }

    const handleGuestUploadClose = () => {
        setGuestsImportRequested(false);
    };

    useEffect(() => {
        setUrlParams({
            query: notDebouncedFilter.query,
            sortBy: notDebouncedFilter.sort.by,
            sortOrder: notDebouncedFilter.sort.order,
        });
    }, [notDebouncedFilter]);

    useEffect(() => {
        if (guestListQuery.data && location.state?.guestListScroll && listContainerRef.current) {
            listContainerRef.current.scroll({ top: location.state.guestListScroll, behavior: 'auto' });
            location.state.guestListScroll = 0;
        }
    }, [location.state?.guestListScroll]);

    useEffect(() => {
        if (!importInitiated) {
            handleReservationsUploadClose();
            handleGuestUploadClose();
        }
    }, [importInitiated]);

    const isLoading = guestsDownloadMutation.isLoading;

    const sortOrFilterChangesLoading =
        guestListQuery.isFetching && !guestListQuery.isFetchingNextPage && !guestListQuery.isFetchingPreviousPage;

    return (
        <Page name="guests" title={t('guests.title')} isLoading={isLoading}>
            {guestsImportRequested && (
                <GuestsImportView
                    restaurantId={restaurantId}
                    onClose={handleGuestUploadClose}
                    onStart={triggerOpenImportDialog}
                />
            )}
            {isSupportUser && reservationsImportViewOpen && (
                <ReservationsImportView
                    restaurantId={restaurantId}
                    onClose={handleReservationsUploadClose}
                    onStart={triggerOpenImportDialog}
                />
            )}
            <div id="guests">
                <div className="guests-filters">
                    <SearchField
                        label={t('guests.filter.search')}
                        value={query}
                        onChange={setQuery}
                        onSearch={handleSearch}
                    />
                    <SortField
                        label={t('guests.filter.sort.title')}
                        options={sortOptions}
                        sort={filters.sort}
                        onChange={handleSort}
                    />
                </div>
                <InfiniteList
                    ref={listContainerRef}
                    hasMore={Boolean(guestListQuery.hasNextPage)}
                    items={(guestListQuery.data?.pages || []).flatMap((page) =>
                        page.items.map((item) => (
                            <GuestListItem
                                key={item.guest.id}
                                guest={item}
                                sort={page.sort || filters.sort}
                                onClick={handleGuest}
                            />
                        )),
                    )}
                    emptyMessage={guestListQuery.isLoading ? undefined : t('guests.list.empty')}
                    loadNext={guestListQuery.fetchNextPage}
                />
                <SpeedDial
                    icon={<ImportExportVerticalIcon />}
                    openIcon={<ImportExportHorizontalIcon />}
                    label="import-export"
                    actions={actions}
                    fixed
                />
                <ImportMetadataProgress
                    restaurantId={restaurantId}
                    importInitiated={importInitiated}
                    setImportInitiated={setImportInitiated}
                />
                {sortOrFilterChangesLoading && (
                    <div className="loading-spinner">
                        <Spinner position={'relative'} />
                    </div>
                )}
            </div>
        </Page>
    );
};
export default Guests;
