import { DATE_FORMAT, DateTimeUtils, IRestaurantClosingDay, TIME_FORMAT } from '@localina/core';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { Schema } from 'yup';

const useClosingDaySchema = () => {
    return {
        name: yup.string().min(2).max(20).required(),
        areas: yup.array().of(yup.string()).min(1).required(),
        from: yup
            .string()
            .required()
            .test('lessOrEqualThanTo', (value, context) => {
                if (value) {
                    const dateTo = context.parent['to'];
                    if (dateTo) {
                        return (
                            DateTime.fromFormat(value, DATE_FORMAT).diff(
                                DateTime.fromFormat(dateTo as string, DATE_FORMAT),
                            ).milliseconds <= 0
                        );
                    }
                }
                return true;
            }),
        to: yup
            .string()
            .required()
            .test('greaterOrEqualThanFrom', (value, context) => {
                if (value) {
                    const dateFrom = context.parent['from'];
                    if (dateFrom) {
                        return (
                            DateTime.fromFormat(value, DATE_FORMAT).diff(
                                DateTime.fromFormat(dateFrom as string, DATE_FORMAT),
                            ).milliseconds >= 0
                        );
                    }
                }
                return true;
            }),
        fromTime: yup
            .string()
            .when('isRecurrent', {
                is: true,
                then: (schema) => schema.required().min(5).max(5),
                otherwise: (schema) => schema.notRequired(),
            })
            .test('validTime', (value) => Boolean(!value || DateTime.fromFormat(value, TIME_FORMAT).isValid))
            .test('validTimeRange', (value, context) => {
                const isRecurrent = context.parent['isRecurrent'];
                const dateFrom = context.parent['from'] as string;
                const dateTo = context.parent['to'] as string;
                if (isRecurrent || (dateFrom && dateTo && dateFrom === dateTo)) {
                    const toTime = context.parent['toTime'];
                    if (toTime && value) {
                        return DateTimeUtils.hasValidTimeRange(value, toTime as string);
                    }
                }
                return true;
            }),
        toTime: yup
            .string()
            .when('isRecurrent', {
                is: true,
                then: (schema) => schema.required().min(5).max(5),
                otherwise: (schema) => schema.notRequired(),
            })
            .test('validTime', (value) => Boolean(!value || DateTime.fromFormat(value, TIME_FORMAT).isValid))
            .test('validTimeRange', (value, context) => {
                const isRecurrent = context.parent['isRecurrent'];
                const dateFrom = context.parent['from'] as string;
                const dateTo = context.parent['to'] as string;
                if (isRecurrent || (dateFrom && dateTo && dateFrom === dateTo)) {
                    const fromTime = context.parent['fromTime'];
                    if (value && fromTime) {
                        return DateTimeUtils.hasValidTimeRange(fromTime as string, value);
                    }
                }
                return true;
            }),
        isRecurrent: yup.boolean().notRequired(),
    } as Record<keyof TClosingDayForm, Schema>;
};

type TClosingDayForm = Omit<IRestaurantClosingDay, 'id'>;

export { useClosingDaySchema };
