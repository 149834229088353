import { Accordion, DATE_FORMAT, IGuestDetails, Label, useConfirmContext } from '@localina/core';
import { EditIcon, FavoriteIcon } from '@localina/icons';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Divider from '@mui/material/Divider';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IGuestDetailsProps {
    details: IGuestDetails;
    handleEdit: () => void;
}

const GuestDetails = ({ details, handleEdit }: IGuestDetailsProps) => {
    const { t } = useTranslation();
    const { copyOrLinkDialog } = useConfirmContext();

    const handlePhoneNumberClick = (phone: string) => () => {
        copyOrLinkDialog({ type: 'phoneNumber', value: phone });
    };
    const handleEmailClick = () => {
        if (details.guest.email) {
            copyOrLinkDialog({ type: 'email', value: details.guest.email });
        }
    };

    const getDate = (isoString: string) => {
        const datetime = DateTime.fromISO(isoString, { zone: 'utc' }).toLocal();
        return datetime.isValid ? datetime.toFormat(DATE_FORMAT) : '';
    };

    const salutationTranslationKey = details
        ? `guest.details.view.fields.salutation.${String(details.guest.salutation).toLowerCase()}`
        : '';

    return (
        <Accordion title={t('guest.details.title')} defaultExpanded>
            <Divider variant="fullWidth" />
            <div className="guestDetailsContainer">
                <div className="tablesWrapper">
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Label type="label" value={t('guest.details.view.fields.lastName')} />
                                    </TableCell>
                                    <TableCell>
                                        <Label type="label" value={details.guest.lastName} />
                                    </TableCell>
                                </TableRow>
                                {Boolean(details.guest.firstName) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.view.fields.firstName')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={details.guest.firstName} />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {Boolean(details.guest.email) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.view.fields.email')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label
                                                type="label"
                                                value={details.guest.email}
                                                onClick={handleEmailClick}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {Boolean(details.guest.company) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.view.fields.company')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={details.guest.company} />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {details.guest.phoneNumbers.map((phoneNumber: string, index) => (
                                    <TableRow key={index}>
                                        {index === 0 && (
                                            <React.Fragment>
                                                <TableCell rowSpan={details.guest.phoneNumbers.length}>
                                                    <Label
                                                        type="label"
                                                        value={t('guest.details.view.fields.phoneNumbers')}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <div className="favoritePhoneNumber">
                                                        <Label
                                                            type="label"
                                                            value={phoneNumber}
                                                            onClick={handlePhoneNumberClick(phoneNumber)}
                                                        />
                                                        <FavoriteIcon className="favorite-style" />
                                                    </div>
                                                </TableCell>
                                            </React.Fragment>
                                        )}
                                        {index !== 0 && (
                                            <React.Fragment>
                                                <TableCell>
                                                    <Label
                                                        type="label"
                                                        value={phoneNumber}
                                                        onClick={handlePhoneNumberClick(phoneNumber)}
                                                    />
                                                </TableCell>
                                            </React.Fragment>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                {Boolean(details.guest.salutation) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label
                                                type="label"
                                                value={t('guest.details.view.fields.salutationLabel')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={t(salutationTranslationKey)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {Boolean(details.guest.allergies) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.view.fields.allergies')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={details.guest.allergies} />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {Boolean(details.firstReservationDateTime) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.fields.firstReservation')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={getDate(details.firstReservationDateTime)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {Boolean(details.lastReservationDateTime) && (
                                    <TableRow>
                                        <TableCell>
                                            <Label type="label" value={t('guest.details.fields.lastReservation')} />
                                        </TableCell>
                                        <TableCell>
                                            <Label type="label" value={getDate(details.lastReservationDateTime)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <EditIcon onClick={handleEdit} className="jusitify-content-icon" />
            </div>
        </Accordion>
    );
};

export default GuestDetails;
