import { IReservation, ReservationStatus, Spinner } from '@localina/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHaveAccountFeatures } from '../api/queries/account';
import { useUnreadReservations, useUpdateReservationReadStatus } from '../api/queries/notifications';
import { usePreservedRestaurant } from '../api/queries/restaurants';
import { NotificationsView } from '../components';
import RequestedReservationView from '../components/ReservationView/RequestedReservationView';
import ReservationView from '../components/ReservationView/ReservationView';
import { ReservationDetailsType } from '../enums';
import { IReservationForm } from '../schemas/interfaces';

interface IProps {
    children: React.ReactNode;
}

const POLLING_INTERVAL_IN_MS = 15 * 1000;

export interface IReservationViewProps {
    type?: ReservationDetailsType;
    reservation?: IReservation;
    defaultFormValues?: Partial<IReservationForm>;
}

export interface IReservationContextType {
    openNotifications: () => void;
    closeNotifications: () => void;
    openReservationForm: (
        reservation?: IReservation,
        type?: ReservationDetailsType,
        defaultFormValues?: Partial<IReservationForm>,
    ) => void;
    closeReservationForm: () => void;
    updateReadReservations: (reservations: IReservation[]) => void;
    unreadReservationsCount: number;
    isReservationFormOpen: boolean;
    recentlyProcessedReservations: IReservation[];
    addRecentlyProcessedReservation: (res: IReservation) => void;
}

const ReservationsContext = React.createContext<IReservationContextType | null>(null);

export const useReservationFormContext = () => {
    const context = useContext(ReservationsContext);
    if (!context) {
        throw new Error('useReservationsContext called outside of a provider');
    }
    return context;
};

export function ReservationsContextProvider(props: IProps) {
    const [openNotifications, setOpenNotifications] = useState(false);
    const restaurantQuery = usePreservedRestaurant(false);
    const updateReservationReadStatusMutation = useUpdateReservationReadStatus(restaurantQuery.data?.id);
    const [canMarkRead] = useHaveAccountFeatures(['markRead'], undefined, true);
    const unreadReservationsQuery = useUnreadReservations({
        enabled: Boolean(restaurantQuery.data?.info.name),
        refetchInterval: POLLING_INTERVAL_IN_MS,
    });

    // reservations that have been approved or declined recently
    const [recentlyProcessedReservations, setRecentlyProcessedReservations] = useState<IReservation[]>([]);

    const [reservationViewState, setReservationViewState] = useState<IReservationViewProps & { open: boolean }>({
        open: false,
        type: undefined,
        reservation: undefined,
        defaultFormValues: undefined,
    });
    const updateReadReservations = (reservations: IReservation[]) => {
        if (canMarkRead) {
            if (restaurantQuery.data && reservations && reservations.length > 0) {
                updateReservationReadStatusMutation.mutate(
                    { reservations },
                    {
                        onSuccess: () => {
                            return unreadReservationsQuery.refetch();
                        },
                    },
                );
            }
        }
    };
    const openEditReservationModal = (value: IReservation) => {
        setReservationViewState({
            open: true,
            type: value.guestInfo ? ReservationDetailsType.EDIT : ReservationDetailsType.WALKIN,
            reservation: value,
        });
    };

    const closeReservationView = () => {
        setReservationViewState({ open: false, type: undefined, reservation: undefined });
    };

    useEffect(() => {
        // if reservation is opened doesn't require approval, mark it read
        if (
            reservationViewState?.type === ReservationDetailsType.EDIT &&
            canMarkRead &&
            reservationViewState.reservation &&
            reservationViewState.reservation.status !== ReservationStatus.APPROVAL_PENDING &&
            !reservationViewState.reservation?.read
        ) {
            updateReadReservations([reservationViewState.reservation]);
        }
    }, [reservationViewState]);

    const requestedReservationViewShouldOpen = Boolean(
        reservationViewState.reservation?.status &&
            [
                ReservationStatus.APPROVAL_PENDING,
                ReservationStatus.DECLINED,
                ReservationStatus.GUEST_CANCELLED,
            ].includes(reservationViewState.reservation?.status),
    );

    const isLoading = updateReservationReadStatusMutation.isLoading;
    return (
        <ReservationsContext.Provider
            value={{
                openNotifications: () => setOpenNotifications(true),
                closeNotifications: () => setOpenNotifications(false),
                unreadReservationsCount: (unreadReservationsQuery.data?.reservations || []).length,
                updateReadReservations: updateReadReservations,
                openReservationForm: (res, type = ReservationDetailsType.CREATE, defaultFormValues) => {
                    setReservationViewState({
                        open: true,
                        reservation: res,
                        type,
                        defaultFormValues,
                    });
                },
                closeReservationForm: closeReservationView,
                isReservationFormOpen: reservationViewState.open,
                recentlyProcessedReservations,
                addRecentlyProcessedReservation: (res) => {
                    if (openNotifications) {
                        setRecentlyProcessedReservations((prevState) => [...prevState, res]);
                    }
                },
            }}
        >
            <NotificationsView
                onClose={() => {
                    setOpenNotifications(false);
                    setRecentlyProcessedReservations([]);
                }}
                visible={openNotifications}
                reservations={unreadReservationsQuery.data?.reservations || []}
                onEdit={openEditReservationModal}
                onRead={updateReadReservations}
                hasError={updateReservationReadStatusMutation.isError}
            />
            {Boolean(restaurantQuery.data?.id) && (
                <ReservationView
                    onClose={closeReservationView}
                    isWalkin={reservationViewState?.type === ReservationDetailsType.WALKIN}
                    open={reservationViewState.open && !requestedReservationViewShouldOpen}
                    reservation={reservationViewState?.reservation}
                    restaurantId={restaurantQuery.data!.id}
                    defaultFormValues={reservationViewState?.defaultFormValues}
                />
            )}
            <RequestedReservationView
                open={reservationViewState.open && requestedReservationViewShouldOpen}
                onClose={closeReservationView}
                reservation={reservationViewState?.reservation}
            />
            {isLoading && <Spinner />}
            {props.children}
        </ReservationsContext.Provider>
    );
}
