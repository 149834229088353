var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownIcon, ArrowUpIcon } from '@localina/icons';
import { SelectField } from '../SelectField';
import { SortOrder } from '../../enums';
export var SortField = function (props) {
    var handleSortBy = function (value) {
        props.onChange(__assign(__assign({}, props.sort), { by: value }));
    };
    var handleSortOrder = function (value) { return function () {
        props.onChange(__assign(__assign({}, props.sort), { order: value }));
    }; };
    return (_jsxs("div", __assign({ className: "localina-sortfield" }, { children: [props.sort.order === SortOrder.DESC ? (_jsx(ArrowDownIcon, { onClick: handleSortOrder(SortOrder.ASC) })) : (_jsx(ArrowUpIcon, { onClick: handleSortOrder(SortOrder.DESC) })), _jsx(SelectField, { label: props.label, options: props.options, value: props.sort.by, onChange: handleSortBy })] })));
};
