var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Portal } from '@mui/base';
import { CircularProgress } from '@mui/material';
export var Spinner = function (_a) {
    var _b = _a.position, position = _b === void 0 ? 'fixed' : _b, props = __rest(_a, ["position"]);
    return (_jsx(Portal, __assign({ container: document.body, disablePortal: position === 'relative' }, { children: _jsx("div", __assign({ className: "localina-spinner ".concat(position) }, { children: _jsx(CircularProgress, __assign({}, props)) })) })));
};
