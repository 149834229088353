import { InputFieldUtils } from './InputFieldUtils';
var combineStrings = function (strings, separator) {
    if (separator === void 0) { separator = ' '; }
    return strings.filter(function (s) { return !!s; }).join(separator);
};
var removeSpace = function (string) { return string.replace(/\s/g, ''); };
var removeDigits = function (string) { return string.replace(/\d/g, ''); };
var isValidEmail = function (email) {
    var _a;
    if (!email || email.length < 6 || email.length > 50) {
        return false;
    }
    var isValidFormat = (_a = InputFieldUtils.getFormatForField('email')) === null || _a === void 0 ? void 0 : _a.test(email);
    return !!isValidFormat;
};
var isValidPassword = function (password) {
    var _a, _b;
    return password !== '' && ((_b = (_a = InputFieldUtils.getFormatForField('password')) === null || _a === void 0 ? void 0 : _a.test(password)) !== null && _b !== void 0 ? _b : true);
};
var isValidPhoneNumber = function (phoneNumber) {
    var _a, _b;
    return (phoneNumber !== '' && ((_b = (_a = InputFieldUtils.getFormatForField('phoneNumber')) === null || _a === void 0 ? void 0 : _a.test(removeSpace(phoneNumber))) !== null && _b !== void 0 ? _b : true));
};
var isValidUrl = function (urlString) {
    var url;
    try {
        url = new URL(urlString);
    }
    catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};
export var StringUtils = {
    combineStrings: combineStrings,
    removeSpace: removeSpace,
    removeDigits: removeDigits,
    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhoneNumber: isValidPhoneNumber,
    isValidUrl: isValidUrl,
};
