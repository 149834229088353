import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurant, IRestaurantDateStats, Label, TIME_FORMAT } from '@localina/core';
import { FoodBankIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import { RestaurantStatsChart } from './RestaurantStatsChart';
import { DateTime } from 'luxon';
import { PathUtils } from '../../../../utils';
import { Path } from '../../../../enums';
import { useNavigate } from 'react-router-dom';

interface IProps {
    stats: IRestaurantDateStats;
    restaurant?: IRestaurant;
}

export const RestaurantStatsAllDayTile: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const numberOfGuests = props.stats.occupancyStats.numberOfGuests;
    const numberOfReservations = props.stats.occupancyStats.numberOfReservations;

    const latestSlot = DateTime.max(
        ...props.stats.areaOccupancyStats
            .flatMap((areaOccupancyStat) => areaOccupancyStat.shiftOccupancyStats)
            .map((shiftOccupancyStat) => DateTime.fromFormat(shiftOccupancyStat.shift.to, TIME_FORMAT)),
    )?.toFormat(TIME_FORMAT);

    const openReservations = () => {
        const date = props.stats.date;
        navigate(PathUtils.generateUrl(Path.RESTAURANT_RESERVATIONS, { restaurantId: props.restaurant?.id }, { date }));
    };
    if (!latestSlot) {
        return null;
    }
    return (
        <div className="restaurant-stats__tile">
            <Grid container={true}>
                <Grid item={true} xs={12} sm={6}>
                    <div className="restaurant-stats__tile__content">
                        <div className="restaurant-stats__tile__title">
                            <FoodBankIcon size="big" />
                            <Label type="title" value={t('dashboard.tile.title')} variant="h6" />
                        </div>
                        <Grid className="restaurant-stats__tile__info" container={true} direction={'row'}>
                            <Grid item={true}>
                                <Grid container={true}>
                                    <Label type="label" value={t('dashboard.tile.fields.guests')} />
                                    <div className="grow" />
                                    <Label type="label" value={numberOfGuests} />
                                </Grid>
                            </Grid>
                            <div className="grow" />
                            <Grid item={true}>
                                <Grid container={true}>
                                    <Label type="label" value={t('dashboard.tile.fields.reservations')} />
                                    <div className="grow" />
                                    <Label type="label" value={numberOfReservations} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <div className="restaurant-stats__tile__charts" onClick={openReservations}>
                        <RestaurantStatsChart
                            latestSlot={latestSlot}
                            stats={props.stats.occupancyStats}
                            showMiddleTimeIndex={true}
                            showEndTimeIndex={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
