import {
    Button,
    Drawer as CoreDrawer,
    IAlertProps,
    IconButton,
    Label,
    StringUtils,
    useConfirmContext,
} from '@localina/core';
import { CloseIcon, TrashIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IExtraButton {
    label: string;
    onClick: () => void;
    disabled?: boolean;
}

interface IProps {
    open: boolean;
    className?: string;
    disabled?: boolean;
    title: string;
    error?: Omit<IAlertProps, 'type'>;
    onDelete?: () => void;
    onClose: () => void;
    onSave?: () => void;
    extraButtons?: IExtraButton[];
    keepMounted?: boolean;
    children?: React.ReactNode;
    saveButtonLabel?: string;
}

export const Drawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { snackbar } = useConfirmContext();

    const renderExtraButtons = (): any[] => {
        const renderedButtons: any[] = [];
        if (props.extraButtons) {
            props.extraButtons.forEach((eb) => {
                renderedButtons.push(
                    <Button
                        key={`${StringUtils.removeSpace(eb.label)}-key`}
                        label={t(eb.label)}
                        disabled={eb.disabled}
                        onClick={eb.onClick}
                    />,
                );
            });
        }
        return renderedButtons;
    };

    React.useEffect(() => {
        if (props.error?.isVisible) {
            snackbar({ msg: props.error.message, severity: 'error' });
        }
    }, [props.error?.isVisible, props.error?.message]);

    return (
        <CoreDrawer
            keepMounted={props.keepMounted}
            open={props.open}
            onClose={props.onClose}
            className={props.className}
            position="right"
        >
            <div className="localina-drawer__header">
                <CloseIcon onClick={props.onClose} />
                <Label type="title" value={props.title} variant="h5" />
            </div>
            <div className="localina-drawer__body">
                <div className="localina-drawer__body__content">{props.children}</div>
                <div className="localina-drawer__footer">
                    <div className="buttons">
                        {props.onDelete && <IconButton icon={<TrashIcon size="big" />} onClick={props.onDelete} />}
                        <Button
                            label={t(`common.buttons.${props.onSave ? 'cancel' : 'close'}`)}
                            secondary={true}
                            onClick={props.onClose}
                        />
                        {props.onSave && (
                            <Button
                                label={props.saveButtonLabel || t('common.buttons.save')}
                                disabled={props.disabled}
                                onClick={props.onSave}
                            />
                        )}
                        {props.extraButtons && renderExtraButtons()}
                    </div>
                </div>
            </div>
        </CoreDrawer>
    );
};
