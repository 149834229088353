import { fabric } from 'fabric';
import { v4 } from 'uuid';

import { IImageObjTypes, IObjTypes, IRectangleObjTypes, ITablePlanCanvas, ITablePlanObjMetadata } from '@localina/core';
import { TableObjs, TablePlanPlantImageSources } from '../../interfaces/entities/ITablePlan';
const tableColor = '#009fba';
export const grid = 100;
export const objDetails: { [key in IObjTypes]: IObjDetailsType } = {
    'small-table': {
        width: 100,
        height: 100,
        numberOfSeats: 4,
        color: tableColor,
        paddingX: 5,
        paddingY: 5,
        zIndex: 1,
    },
    'big-table': {
        width: 200,
        height: 100,
        numberOfSeats: 6,
        color: tableColor,
        paddingX: 5,
        paddingY: 5,
        zIndex: 1,
    },
    'small-table-rounded': {
        width: 100,
        height: 100,
        radius: 50,
        numberOfSeats: 4,
        color: tableColor,
        paddingX: 15,
        zIndex: 1,
        paddingY: 15,
    },
    'big-table-rounded': {
        width: 200,
        height: 100,
        radius: 50,
        numberOfSeats: 6,
        color: tableColor,
        paddingX: 30,
        zIndex: 1,
        paddingY: 10,
    },
    floor: {
        width: 50,
        height: 50,
        radius: 0,
        numberOfSeats: 0,
        color: '#e6ceaa',
        paddingX: 0,
        zIndex: 0,
        paddingY: 0,
    },
    wall: {
        width: 25,
        height: 25,
        radius: 0,
        numberOfSeats: 0,
        color: '#eaeaea',
        paddingX: 0,
        zIndex: 1,
        paddingY: 0,
    },
    'plant-1': {
        width: 100,
        height: 100,
        numberOfSeats: 0,
        imageUrl: TablePlanPlantImageSources.plant1,
        paddingX: 0,
        paddingY: 0,
        zIndex: 1,
    },
    'plant-2': {
        width: 100,
        height: 100,
        numberOfSeats: 0,
        imageUrl: TablePlanPlantImageSources.plant2,
        paddingX: 0,
        paddingY: 0,
        zIndex: 1,
    },
    'plant-3': {
        width: 100,
        height: 100,
        numberOfSeats: 0,
        imageUrl: TablePlanPlantImageSources.plant3,
        paddingX: 0,
        paddingY: 0,
        zIndex: 1,
    },
};

const createFabricObjectInner = async (data: ITablePlanObjMetadata): Promise<fabric.Object | undefined> => {
    const details = objDetails[data.objType];
    if (data.type === 'rectangle') {
        const rect = new fabric.Rect({
            width: data.width,
            height: data.height,
            fill: details.color,
            lockRotation: true,
            left: data.left,
            top: data.top,
            selectable: true,
            hasControls: true,
            borderColor: 'red',
            hasRotatingPoint: false,
            hasBorders: false,
            borderScaleFactor: 2,
            rx: data.borderRadius,
            data: { ...data, id: data.id, zIndex: details.zIndex },
        });
        if (data.objType === 'wall') {
            rect.stroke = '#000000';
            rect.strokeWidth = 0.2;
        }
        rect.setControlsVisibility({
            bl: false,
            br: true,
            mb: true,
            ml: false,
            mr: true,
            mt: false,
            tl: false,
            tr: false,
            mtr: false,
        });
        return rect;
    }
    if (data.type === 'circle') {
        const circle = new fabric.Circle({
            radius: data.radius,
            startAngle: 0,
            endAngle: 360,
            fill: details.color,
            left: data.left,
            top: data.top,
            selectable: true,
            hasControls: true,
            lockRotation: true,
            hasRotatingPoint: false,
            hasBorders: false,
            borderColor: 'red',
            borderScaleFactor: 2,
            data: { ...data, id: data.id, zIndex: details.zIndex },
        });
        circle.setControlsVisibility({
            bl: false,
            br: true,
            mb: false,
            ml: false,
            mr: false,
            mt: false,
            tl: false,
            tr: false,
            mtr: false,
        });
        return circle;
    }
    if (data.type === 'image') {
        return new Promise((resolve) => {
            fabric.Image.fromURL(
                data.imageUrl,
                function (image) {
                    image.scaleToWidth(data.width);
                    image.setControlsVisibility({
                        bl: false,
                        br: true,
                        mb: false,
                        ml: false,
                        mr: false,
                        mt: false,
                        tl: false,
                        tr: false,
                        mtr: false,
                    });
                    resolve(image);
                },
                {
                    left: data.left,
                    top: data.top,
                    selectable: true,
                    hasControls: true,
                    lockRotation: true,
                    hasRotatingPoint: false,
                    hasBorders: false,
                    data: { ...data, id: data.id, zIndex: details.zIndex },
                },
            );
        });
    }
    return undefined;
};

export const createFabricObject = async (data: ITablePlanObjMetadata, text: string) => {
    const fabricObject = await createFabricObjectInner(data);
    if (!fabricObject) {
        return undefined;
    }
    if (!TableObjs.includes(data.objType) && data.objType !== 'floor') {
        return fabricObject;
    }
    const fill = data.objType == 'floor' ? '#000000' : '#E6EFF1';
    const textField = new fabric.Text(text || '', {
        originX: 'center',
        originY: 'center',
        fontWeight: 500,
        fontSize: 18,
        fill: fill,
        hasRotatingPoint: false,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        fontFamily: 'Roboto,Helvetica,Arial',
    });
    fabricObject.set({
        originX: 'center',
        originY: 'center',
        top: undefined,
        left: undefined,
    });
    const group = new fabric.Group([fabricObject, textField], {
        width: fabricObject.getScaledWidth(),
        height: fabricObject.getScaledHeight(),
        left: data.left,
        top: data.top,
        hasRotatingPoint: false,
        hasBorders: false,
        data: fabricObject.data,
    });
    group.setControlsVisibility(fabricObject._controlsVisibility);
    return group;
};

export type IObjDetailsType = {
    width: number;
    height: number;
    radius?: number;
    numberOfSeats: number;
    color?: string;
    imageUrl?: string;
    paddingX: number;
    paddingY: number;
    zIndex: number;
};

export const getObjDetails = (canvasObj: ITablePlanObjMetadata): IObjDetailsType | undefined => {
    const details = objDetails[canvasObj.objType];
    if (!details) {
        return undefined;
    }
    let scaleX = 1;
    let scaleY = 1;
    let width = 0;
    let height = 0;
    let radius = details.radius || 0;
    if (canvasObj.type === 'circle') {
        scaleX = canvasObj.radius / (details.radius || 1);
        scaleY = canvasObj.radius / (details.radius || 1);
        width = canvasObj.radius * 2;
        height = canvasObj.radius * 2;
        radius = canvasObj.radius;
    } else {
        scaleX = canvasObj.width / details.width;
        scaleY = canvasObj.height / details.height;
        radius = (details.radius || 0) * scaleX;
        width = canvasObj.width;
        height = canvasObj.height;
    }
    return {
        ...details,
        width,
        height,
        paddingX: details.paddingX * scaleX,
        paddingY: details.paddingY * scaleY,
        radius,
    };
};

export const getTableCanvasFromFabricCanvas = (fabricCanvas: fabric.Canvas): ITablePlanCanvas => {
    return {
        width: fabricCanvas.width || 0,
        height: fabricCanvas.height || 0,
        elements: fabricCanvas
            .getObjects()
            .filter((object) => !!object.data?.id)
            .map((object) => {
                return createTablePlanMetadataObj(
                    object.data.objType as IObjTypes,
                    object.left || 0,
                    object.top || 0,
                    object.data as { [key: string]: any },
                );
            })
            .filter((e) => !!e.id),
    };
};

export const createTablePlanMetadataObj = (
    objType: IObjTypes,
    left: number,
    top: number,
    data: { [key: string]: any },
    recalculatePositions?: boolean,
): ITablePlanObjMetadata => {
    const details = objDetails[objType];

    const id = data.id || v4();
    const tableId = TableObjs.includes(objType) ? data.tableId || v4() : undefined;
    if (objType === 'small-table-rounded') {
        const radius = data.radius || details.radius || 50;
        return {
            ...data,
            radius,
            tableId,
            id,
            type: 'circle',
            left: recalculatePositions ? left - radius : left,
            top: recalculatePositions ? top - radius : top,
            objType: 'small-table-rounded',
        };
    }
    const width = data.width || details.width;
    const height = data.height || details.height;
    left = recalculatePositions ? left - width / 2 : left;
    top = recalculatePositions ? top - height / 2 : top;
    if (objType === 'plant-1' || objType === 'plant-2' || objType === 'plant-3') {
        const imageUrl = data.imagUrl || details.imageUrl || '';
        return {
            borderRadius: details.radius || 0,
            ...data,
            imageUrl,
            height,
            width,
            tableId,
            id,
            type: 'image',
            left: left,
            top,
            objType: objType as IImageObjTypes,
        };
    }
    return {
        borderRadius: details.radius || 0,
        ...data,
        height,
        width,
        tableId,
        id,
        type: 'rectangle',
        left,
        top,
        objType: objType as IRectangleObjTypes,
    };
};
