import React, { Key } from 'react';
import { IRestaurantArea, ListItem, List, Label, IRestaurant } from '@localina/core';
import { Collapse } from '@mui/material';
import { ChevronDownAltIcon, ChevronUpAltIcon } from '@localina/icons';
import TablePlan from './TablePlan';
import { useTranslation } from 'react-i18next';

type IProps = { restaurant?: IRestaurant; area: IRestaurantArea; index?: Key | null | undefined };

const AreaTablePlansItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);

    const handleClickArea = () => {
        setOpen(!open);
    };

    return (
        <>
            <div className="table-plans-list-item">
                <ListItem
                    title={<Label type="title" value={props.area.name} variant="h6" />}
                    key={props.index}
                    onClick={handleClickArea}
                    rightIcon={open ? <ChevronDownAltIcon /> : <ChevronUpAltIcon />}
                />
                <Collapse in={open}>
                    <List>
                        <div className="table-plans-area-list-item">
                            {props.area?.tablePlans?.length ? (
                                props.area.tablePlans.map((tablePlan, index) => (
                                    <TablePlan restaurant={props.restaurant} tablePlan={tablePlan} key={index} />
                                ))
                            ) : (
                                <ListItem title={t('table.plans.empty')} />
                            )}
                        </div>
                    </List>
                </Collapse>
            </div>
        </>
    );
};

export default AreaTablePlansItem;
