import { ILocalizedError, IUser } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useGetAuthUser, useSendResetPasswordEmailMutation } from './auth';
import { queryKeys } from './query-keys';

const useUsers = (options?: UseQueryOptions<IUser[], ILocalizedError>) => {
    const authUserQuery = useGetAuthUser();

    return useQuery({
        queryFn: () => {
            return authUserQuery.data?.accountId
                ? LocalinaApiContext.supportApi.getAccountUsers(authUserQuery.data.accountId)
                : LocalinaApiContext.serviceApi.getUsers();
        },
        queryKey: queryKeys.users.all,
        ...options,
    });
};

const useUser = (userId: string, options?: UseQueryOptions<IUser, ILocalizedError>) => {
    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getUser(userId);
        },
        queryKey: queryKeys.users.single(userId),
        ...options,
    });
};

const useCreateUser = (options?: UseMutationOptions<IUser, ILocalizedError, IUser>) => {
    const queryClient = useQueryClient();
    const sendResetPasswordEmail = useSendResetPasswordEmailMutation();

    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.serviceApi.createUser(user);
        },
        onSuccess: (user) => {
            sendResetPasswordEmail.mutate(user.email);
            queryClient.setQueryData(queryKeys.users.single(user.id), user);
            return queryClient.invalidateQueries(queryKeys.users.all);
        },
        ...options,
    });
};
const useUpdateUser = (options?: UseMutationOptions<void, ILocalizedError, IUser>) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.serviceApi.updateUser(user);
        },
        onSuccess: (_, user) => {
            queryClient.setQueryData<ReturnType<typeof useUsers>['data']>(queryKeys.users.all, (oldData) =>
                oldData ? oldData.map((oldUser) => (oldUser.id === user.id ? user : oldUser)) : undefined,
            );
            queryClient.setQueryData(queryKeys.users.single(user.id), user);
        },
        ...options,
    });
};
const useDeleteUser = (options?: UseMutationOptions<void, ILocalizedError, IUser>) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (user) => {
            return LocalinaApiContext.serviceApi.deleteUser(user);
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKeys.users.all, { exact: true });
        },
        ...options,
    });
};

export { useUsers, useUser, useCreateUser, useUpdateUser, useDeleteUser };
