import { RestaurantStatus, RestaurantSubscription, StringUtils } from '@localina/core';
import {
    BrownDollarCircle,
    BrownEmptyCircle,
    DollarCircle,
    EmptyCircle,
    RedDollarCircle,
    RedEmptyCircle,
} from '@localina/icons';
import React from 'react';

export const getRestaurantPlanIcon = (plan: RestaurantSubscription, classname = ''): React.ReactNode => {
    const className = StringUtils.combineStrings(['restaurants-plan-icon', classname]);
    if (plan === RestaurantSubscription.STANDARD) return <DollarCircle className={className} />;
    if (plan === RestaurantSubscription.FREE) return <EmptyCircle className={className} />;
    return;
};

export const getRestaurantTestPlanIcon = (
    plan: RestaurantSubscription,
    restaurantStatus: RestaurantStatus,
    classname = '',
): React.ReactNode => {
    const className = StringUtils.combineStrings(['restaurants-plan-icon', classname]);
    if (plan === RestaurantSubscription.STANDARD)
        switch (restaurantStatus) {
            case RestaurantStatus.ACTIVE:
                return <DollarCircle className={className} />;
            case RestaurantStatus.INACTIVE:
                return <BrownDollarCircle className={className} />;
            case RestaurantStatus.TEST:
                return <RedDollarCircle className={className} />;
        }
    if (plan === RestaurantSubscription.FREE)
        switch (restaurantStatus) {
            case RestaurantStatus.ACTIVE:
                return <EmptyCircle className={className} />;
            case RestaurantStatus.INACTIVE:
                return <BrownEmptyCircle className={className} />;
            case RestaurantStatus.TEST:
                return <RedEmptyCircle className={className} />;
        }
};

export const getRestaurantTestIcon = (restaurantStatus: RestaurantStatus, classname = ''): React.ReactNode => {
    const className = StringUtils.combineStrings(['restaurants-plan-icon', classname]);
    switch (restaurantStatus) {
        case RestaurantStatus.ACTIVE:
            return <EmptyCircle className={className} />;
        case RestaurantStatus.INACTIVE:
            return <BrownEmptyCircle className={className} />;
        case RestaurantStatus.TEST:
            return <RedEmptyCircle className={className} />;
    }
};

export const createRandomPassword = (passwordLength = 12) => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

    function getRandomCharFrom(characters: string) {
        return characters.charAt(Math.floor(Math.random() * characters.length));
    }

    function shuffleString(string: string) {
        const array = string.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    let password = '';

    // Generate password with at least 1 uppercase, 1 number, and 1 special character
    password += getRandomCharFrom(uppercaseChars);
    password += getRandomCharFrom(numberChars);
    password += getRandomCharFrom(specialChars);

    // Fill remaining password with random characters
    for (let i = 0; i < passwordLength - 3; i++) {
        password += getRandomCharFrom(allChars);
    }

    // Shuffle the password to make it more random
    return shuffleString(password);
};
