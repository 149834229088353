var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoIcon } from '@localina/icons';
import { Typography } from '@mui/material';
import { StringUtils } from '../../utils';
import { Tooltip } from '../Tooltip';
export var Label = function (props) {
    var hasIcon = props.icon && typeof props.icon !== 'boolean';
    var useIcon = props.icon && typeof props.icon === 'boolean';
    var isButton = props.onClick !== undefined;
    var classNames = StringUtils.combineStrings([
        'localina-label',
        props.extraClassName && props.extraClassName,
        props.type,
        props.align,
        props.error && 'error',
        !!hasIcon && 'has-icon',
        !!useIcon && 'use-icon',
        isButton && 'is-button',
        props.required && 'is-required',
    ]);
    return (_jsxs("div", __assign({ className: classNames, onClick: props.onClick }, { children: [props.icon, props.tooltip && (_jsx(Tooltip, __assign({ content: props.tooltip, disablePortal: true }, { children: _jsx(InfoIcon, {}) }))), _jsx(Typography, __assign({ variant: props.variant }, { children: props.value }))] })));
};
