import { IAuthUser } from '../interfaces';
import { RoleUtils } from './RoleUtils';

const ACCOUNT_ID = 'accountId';
const initialize = (search: string) => {
    const searchParams = new URLSearchParams(search);
    const accountId = searchParams.get(ACCOUNT_ID);
    if (accountId) {
        window.sessionStorage.setItem(ACCOUNT_ID, accountId);
    }
};

const getAccountId = () => window.sessionStorage.getItem(ACCOUNT_ID);

// returns id of impersonated account as a support
const getSupportAccountId = (user?: IAuthUser) => {
    if (!user || !(RoleUtils.hasSupportRole(user) || RoleUtils.hasSalesRole(user))) {
        return null;
    }

    return getAccountId() || user.localina_account;
};

const isSupportPanel = (user: IAuthUser) =>
    (RoleUtils.hasSupportRole(user) || RoleUtils.hasSalesRole(user)) && getSupportAccountId(user) === null;

const resetSession = () => window.sessionStorage.removeItem(ACCOUNT_ID);

export const SupportUtils = {
    initialize,
    getSupportAccountId,
    isSupportPanel,
    resetSession,
};

export { ACCOUNT_ID };
