import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface IDraggableItemWrapperProps {
    children: React.ReactNode;
    id: string;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    findItemIndex: (id: string) => number;
    index: number;
    itemType?: string;
    onDrop?: () => void;
}

interface IDragItem {
    index: number;
    id: string;
}

type TIdentifier = string | symbol;

const DraggableItemWrapper = ({
    id,
    children,
    index,
    moveItem,
    findItemIndex,
    onDrop,
    itemType = 'item',
}: IDraggableItemWrapperProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<IDragItem, void, { handlerId: TIdentifier | null }>(
        () => ({
            accept: itemType,
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
            hover: (item) => {
                const dragIndex = findItemIndex(item.id);
                if (dragIndex !== index) {
                    moveItem(dragIndex, index);
                }
            },
        }),
        [findItemIndex, moveItem],
    );

    const [{ dragging }, drag] = useDrag(
        () => ({
            collect: (monitor) => ({ dragging: monitor.isDragging() }),
            type: itemType,
            item: { id, index },
            end: () => {
                if (onDrop) {
                    onDrop();
                }
            },
        }),
        [id, index],
    );
    drag(drop(ref));
    return (
        <div ref={ref} style={{ opacity: dragging ? 0.5 : 1 }} data-handler-id={handlerId}>
            {children}
        </div>
    );
};

function splice<TItem>(arr: TItem[], start: number, deleteCount: number, ...addItem: TItem[]) {
    const result = [];
    if (start > 0) {
        result.push(...arr.slice(0, start));
    }
    result.push(...addItem);
    const len = result.length - addItem.length;
    const count = deleteCount <= 0 ? len : len + deleteCount;
    if (arr[count]) {
        result.push(...arr.slice(count));
    }
    return result;
}

interface ISortableItemRequiredProps {
    id: string;
}

export function sortItemsBySortedIdsArray<T, K extends keyof T>(
    listOfItems: T[],
    sortedIdsArray: string[],
    idProp: K = 'id' as K,
): T[] {
    return listOfItems.sort(
        (a, b) => sortedIdsArray.indexOf(a[idProp] as string) - sortedIdsArray.indexOf(b[idProp] as string),
    );
}

function useSortableItemsWrapperUtils<TItem extends ISortableItemRequiredProps>(
    itemsState: TItem[],
    setItemsState: React.Dispatch<React.SetStateAction<TItem[]>>,
) {
    const findItemIndex = useCallback(
        (id: string) => {
            const item = itemsState.filter(({ id: itemId }) => itemId === id)[0];
            return itemsState.indexOf(item);
        },
        [itemsState],
    );

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setItemsState((prevState) => {
            // Remove dragging item from array
            const arrayWithoutDragItem = splice(prevState, dragIndex, 1);
            // Add drag item to hoverIndex
            return splice(arrayWithoutDragItem, hoverIndex, 0, prevState[dragIndex]);
        });
    }, []);

    return { moveItem, findItemIndex };
}

export { DraggableItemWrapper, useSortableItemsWrapperUtils };
