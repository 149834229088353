import { FabButton, IRestaurantArea, ISaveAreaRequest, useConfirmContext } from '@localina/core';
import { AddIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useDeleteAreaMutation, useSaveAreaMutation } from '../../api/queries/restaurantAreas';
import { AreaCreationView, Page } from '../../components';
import Areas from './Areas/Areas';
import VirtualAreas from './VirtualAreas/VirtualAreas';

const Sectors: React.FC = () => {
    const { t } = useTranslation();
    const [restaurantAreaDetails, setRestaurantAreaDetails] = React.useState<ISaveAreaRequest>();
    const [openRestaurantAreaDrawer, setOpenRestaurantAreaDrawer] = React.useState(false);
    const { confirm, alert } = useConfirmContext();

    const updateAreaMutation = useSaveAreaMutation();
    const deleteAreaMutation = useDeleteAreaMutation();

    const [canEditAreas] = useHaveAccountFeatures(['areasEdit']);

    const handleRestaurantAreaChange = (areaDetails: ISaveAreaRequest) => {
        setRestaurantAreaDetails(areaDetails);
    };

    const handleRestaurantAreaCreationSave = (areaDetails?: ISaveAreaRequest) => {
        if (areaDetails) {
            updateAreaMutation.mutate(areaDetails, {
                onSuccess: closeRestaurantAreaDetails,
            });
        }
    };

    const openRestaurantAreaDetails = () => {
        if (canEditAreas) {
            setOpenRestaurantAreaDrawer(true);
        } else {
            alert({
                msg: t('common.features.areas'),
                title: t('common.features.missingFeatureTitle'),
            });
        }
    };

    const closeRestaurantAreaDetails = () => {
        setOpenRestaurantAreaDrawer(false);
        setRestaurantAreaDetails(undefined);
    };

    const editRestaurantArea = (areaDetails: IRestaurantArea, virtualAreaId: string) => {
        const saveAreaRequest: ISaveAreaRequest = {
            id: areaDetails.id,
            name: areaDetails.name,
            code: areaDetails.code,
            capacity: areaDetails.capacity,
            virtualAreaId: virtualAreaId,
        };
        setOpenRestaurantAreaDrawer(true);
        setRestaurantAreaDetails(saveAreaRequest);
    };

    const onDelete = async () => {
        if (
            (await confirm({
                msg: t('sectors.deleteConfirmationMessage'),
            })) === 'yes'
        ) {
            if (restaurantAreaDetails?.id) {
                deleteAreaMutation.mutate(
                    { areaId: restaurantAreaDetails.id },
                    { onSuccess: closeRestaurantAreaDetails },
                );
            }
        }
    };

    return (
        <Page
            name="sectors"
            title={t('common.menu.categories.settings.menu.sectors')}
            isLoading={updateAreaMutation.isLoading || deleteAreaMutation.isLoading}
        >
            <VirtualAreas />
            <Areas editRestaurantArea={editRestaurantArea} />
            <FabButton icon={<AddIcon />} onClick={openRestaurantAreaDetails} className="sectors-fab-button" />
            <AreaCreationView
                restaurantArea={restaurantAreaDetails}
                hasError={updateAreaMutation.isError || deleteAreaMutation.isError}
                openDialog={openRestaurantAreaDrawer}
                onSave={handleRestaurantAreaCreationSave}
                onClose={closeRestaurantAreaDetails}
                onChange={handleRestaurantAreaChange}
                onDelete={canEditAreas && restaurantAreaDetails?.id ? onDelete : undefined}
            />
        </Page>
    );
};

export default Sectors;
