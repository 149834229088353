export interface ITablePlanDesignTableObjDetailsParsed {
    id: string;
    name: string;
    numberOfSeats: number;
    description?: string;
}

export const TablePlanObjTypes = {
    smallTable: 'small-table',
    bigTable: 'big-table',
    smallTableRounded: 'small-table-rounded',
    bigTableRounded: 'big-table-rounded',
    floor: 'floor',
    wall: 'wall',
    plant1: 'plant-1',
    plant2: 'plant-2',
    plant3: 'plant-3',
};

export const PlantsObjs = [TablePlanObjTypes.plant1, TablePlanObjTypes.plant2, TablePlanObjTypes.plant3];

export const NotTableObjs = [TablePlanObjTypes.floor, TablePlanObjTypes.wall, ...PlantsObjs];

export const TableObjs = [
    TablePlanObjTypes.smallTable,
    TablePlanObjTypes.bigTable,
    TablePlanObjTypes.smallTableRounded,
    TablePlanObjTypes.bigTableRounded,
];

export const TablePlanPlantImageSources = {
    plant1: '/images/plant1.svg',
    plant2: '/images/plant2.svg',
    plant3: '/images/plant3.svg',
};
