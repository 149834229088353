import { GuestStatus, IGuestInfo } from '@localina/core';
import { Block, Star } from '@mui/icons-material';
import React from 'react';

interface IGuestStatusIcon {
    guestStatus?: IGuestInfo['guestStatus'];
}

const GuestStatusIcon = ({ guestStatus }: IGuestStatusIcon) => {
    return (
        <>
            {guestStatus === GuestStatus.VIP && <GuestStatusVipIcon />}
            {guestStatus === GuestStatus.BLACKLIST && <GuestStatusBlacklistIcon />}
        </>
    );
};

const GuestStatusVipIcon = () => (
    <span className="guest-status-vip">
        <Star />
    </span>
);

const GuestStatusBlacklistIcon = () => (
    <span className="guest-status-blacklist">
        <Block />
    </span>
);

export { GuestStatusIcon };
