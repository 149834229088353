import {
    IconButton,
    IReservation,
    IRestaurant,
    Label,
    RESERVATION_CHANGE_TABLE_DROP_TYPE,
    SERVER_DATE_FORMAT,
    TIME_FORMAT,
} from '@localina/core';
import { BookNewIcon, RemoveMinusIcon, WalkinIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../../api/queries/restaurants';
import { useReservationFormContext } from '../../../contexts/ReservationsContext';
import { ReservationDetailsType } from '../../../enums';
import { ITablePlanDesignTableObjDetailsParsed } from '../../../interfaces/entities/ITablePlan';
import { GuestUtils } from '../../../utils';
import ReservationStatusActionIcon from '../../ReservationStatus/ReservationStatusActionIcon';

interface IProps {
    tableObj: ITablePlanDesignTableObjDetailsParsed;
    reservations: IReservation[];
    onStartDrag: () => void;
    onEndDrag: () => void;
    reservationEnabled: boolean;
    walkinEnabled: boolean;
    onReserve: (isWalkin: boolean) => void;
    onRemoveReservation: (reservation: IReservation) => void;
}

const TableDetails: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const restaurantQuery = useRestaurant();

    return (
        <div className="table-details">
            <div className="header">
                <div className="header-row">
                    <div>
                        <Label type="title" value={props.tableObj.name} />
                        <Label type="info" value={`${t('table.max.people')}: ${props.tableObj.numberOfSeats}`} />
                    </div>
                    <div className="icons">
                        {props.walkinEnabled && (
                            <IconButton icon={<WalkinIcon />} onClick={() => props.onReserve(true)} />
                        )}
                        {props.reservationEnabled && (
                            <IconButton icon={<BookNewIcon />} onClick={() => props.onReserve(false)} />
                        )}
                    </div>
                </div>
            </div>
            <div className="main">
                {props.reservations.length === 0 && <Label type="text" value={t('table.no.reservations')} />}
                {props.reservations.length > 0 &&
                    props.reservations.map((reservation) => {
                        return (
                            <ReservationDragItem
                                reservation={reservation}
                                restaurant={restaurantQuery.data}
                                onStartDrag={props.onStartDrag}
                                onEndDrag={props.onEndDrag}
                                tableId={props.tableObj.id}
                                onRemove={() => {
                                    props.onRemoveReservation(reservation);
                                }}
                                key={reservation.id}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

interface IItemProps {
    reservation: IReservation;
    restaurant?: IRestaurant;
    tableId: string;
    onRemove: () => void;
    onStartDrag: () => void;
    onEndDrag: () => void;
}

function ReservationDragItem(props: IItemProps) {
    const { t } = useTranslation();
    const { openReservationForm } = useReservationFormContext();
    const areas = props.restaurant?.configuration.virtualAreas.flatMap((va) => va.areas) || [];
    const areaCode = areas
        .filter((area) => props.reservation.areaIds.includes(area.id))
        .map((a) => a.code)
        .join(', ');
    const tableNumbers = areas
        .flatMap((a) => a.tablePlans)
        .flatMap((tp) => tp.tables)
        .filter((table) => props.reservation.tableIds?.includes(table.id))
        .map((table) => table.name);
    const [{ isDragging }, dragRef] = useDrag(() => {
        return {
            type: RESERVATION_CHANGE_TABLE_DROP_TYPE,
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
            item: {
                id: props.reservation.id,
                tableIds: props.reservation.tableIds,
                tableNumbers,
                currentTableId: props.tableId,
                areaCode,
                isVirtualArea: false,
                restaurantId: props.restaurant?.id,
                reservationDateServerFormat: DateTime.fromISO(props.reservation.reservationDateTime).toFormat(
                    SERVER_DATE_FORMAT,
                ),
            },
        };
    });

    const prevIsDragging = useRef<boolean | undefined>(undefined);

    useEffect(() => {
        if (isDragging) {
            props.onStartDrag();
        } else if (prevIsDragging.current) {
            props.onEndDrag();
        }
        prevIsDragging.current = isDragging;

        return () => {
            if (prevIsDragging.current) {
                props.onEndDrag();
            }
        };
    }, [isDragging]);

    const reservationRowInfo = `${props.reservation.participants} ${t('table.people')}, ${GuestUtils.formatGuestName(
        props.reservation.guestInfo,
        t('reservations.view.fields.walkin'),
    )}`;

    const startTime = DateTime.fromISO(props.reservation.reservationDateTime);
    const timeInfoLabel = `${startTime.toFormat(TIME_FORMAT)} - ${startTime
        .plus({ second: props.reservation.occupancyTime })
        .toFormat(TIME_FORMAT)}`;

    return (
        <div
            onClick={() => {
                openReservationForm(
                    props.reservation,
                    props.reservation.guestInfo ? ReservationDetailsType.EDIT : ReservationDetailsType.WALKIN,
                );
            }}
            ref={dragRef}
            key={props.reservation.id}
            className="table-reservation-description-row row"
        >
            <div className="table-reservation-description-row__info">
                <div>
                    <Label type="text" value={timeInfoLabel} />
                </div>
                <div>
                    <Label extraClassName="no-wrap" type="info" value={reservationRowInfo} />
                </div>
            </div>

            <div className="action-buttons">
                <ReservationStatusActionIcon reservation={props.reservation} />
                <RemoveMinusIcon
                    onClick={(e) => {
                        props.onRemove();
                        e.stopPropagation();
                    }}
                    size="small"
                />
            </div>
        </div>
    );
}

export default TableDetails;
