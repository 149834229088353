var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as MuiButton, ButtonGroup as MuiButtonGroup } from '@mui/material';
import React, { useMemo } from 'react';
import { StringUtils } from '../../utils';
import { Tooltip } from '../Tooltip';
var defaultEqualityFn = function (element, value) { return element === value; };
export var ButtonGroup = function (props) {
    var isEqual = useMemo(function () { return props.equalityFn || defaultEqualityFn; }, [props.equalityFn]);
    var handleClick = function (val) { return function () {
        if (props.onChange) {
            if (Array.isArray(props.value)) {
                if (props.value.some(function (element) { return isEqual(element, val); })) {
                    props.onChange(props.value.filter(function (el) { return !isEqual(el, val); }));
                }
                else {
                    props.onChange(__spreadArray(__spreadArray([], props.value, true), [val], false));
                }
            }
            else {
                if (isEqual(props.value, val) && props.enableDeselect) {
                    props.onChange(null);
                }
                else {
                    props.onChange(val);
                }
            }
        }
    }; };
    return (_jsx("div", __assign({ className: StringUtils.combineStrings(['localina-buttongroup', Array.isArray(props.value) && 'multiple']) }, { children: _jsx("div", __assign({ className: "localina-buttongroup__container" }, { children: _jsx(MuiButtonGroup, __assign({ disabled: props.disabled, disableRipple: true, fullWidth: props.fullWidth }, { children: props.options.length === 0 ? (_jsx(MuiButton, { disabled: true })) : (props.options.map(function (option, index) {
                    var _a = typeof option === 'string'
                        ? {
                            label: option,
                            value: option,
                            className: undefined,
                            disabled: undefined,
                            tooltip: undefined,
                        }
                        : option, label = _a.label, value = _a.value, className = _a.className, disabled = _a.disabled, tooltip = _a.tooltip;
                    var isSelected = (Array.isArray(props.value) && props.value.some(function (el) { return isEqual(el, value); })) ||
                        isEqual(props.value, value)
                        ? 'selected'
                        : undefined;
                    return (_jsx(TooltipWrapper, __assign({ tooltip: tooltip }, { children: _jsx(MuiButton, __assign({ className: StringUtils.combineStrings([isSelected, className]), disabled: disabled, onClick: handleClick(value) }, { children: label })) }), "".concat(value, "-").concat(index)));
                })) })) })) })));
};
var TooltipWrapper = function (props) {
    return (_jsx(React.Fragment, { children: Boolean(props.tooltip) ? (_jsx(Tooltip, __assign({ content: props.tooltip, className: 'localina-buttongroup__tooltip' }, { children: props.children }))) : (_jsx(React.Fragment, { children: props.children })) }));
};
