import {
    DATE_FORMAT,
    DateField,
    InfiniteTable,
    IPlatform,
    ISalesStatistics,
    Label,
    PlatformUtils,
    RestaurantStatus,
    RestaurantSubscription,
    RestaurantUtils,
    SearchField,
    SERVER_DATE_FORMAT,
    StringUtils,
} from '@localina/core';
import { TableCell, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useActivePlatforms } from '../../../api/queries/platforms';
import { ISalesStatisticsFilters, useSalesStatistics } from '../../../api/queries/salesStatistics';
import { Page } from '../../../components';

interface IRowContent {
    restaurantName: string;
    restaurantId: string;
    restaurantAddress: string;
    subscription: RestaurantSubscription;
    status: RestaurantStatus;
    reservationsCount: ISalesStatistics['reservationsCount'];
}

const SalesStatistics = () => {
    const { t } = useTranslation();
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [filter, setFilter] = useState<ISalesStatisticsFilters>({});

    const isDateFieldsError = Boolean(filter.dateFrom && filter.dateTo && filter.dateFrom > filter.dateTo);
    const onFilterFieldChange =
        (name: keyof ISalesStatisticsFilters) => (value: ISalesStatisticsFilters[typeof name]) => {
            setFilter((prevState) => ({ ...prevState, [name]: value }));
        };

    const onFilterDateChange =
        (name: keyof Pick<ISalesStatisticsFilters, 'dateTo' | 'dateFrom'>) => (value: string) => {
            const date = value ? DateTime.fromFormat(value, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT) : '';
            setFilter((prevState) => ({ ...prevState, [name]: date }));
        };

    const salesStatisticsQuery = useSalesStatistics(
        {
            ...filter,
            query: debouncedQuery,
        },
        { enabled: !isDateFieldsError },
    );
    const restaurantsWithStats = salesStatisticsQuery.data?.pages.flatMap((page) => page.items) || [];

    const activePlatformsQuery = useActivePlatforms();
    const platforms = activePlatformsQuery.data || [];

    const isLoading = activePlatformsQuery.isInitialLoading || salesStatisticsQuery.isInitialLoading;

    const loadNextPage = useCallback(() => {
        if (salesStatisticsQuery.hasNextPage && !salesStatisticsQuery.isFetchingNextPage) {
            void salesStatisticsQuery.fetchNextPage();
        }
    }, [salesStatisticsQuery.hasNextPage, salesStatisticsQuery.isFetchingNextPage]);

    return (
        <Page name="sales-statistics" title={t('salesStatistics.title')} isLoading={isLoading}>
            <div className={'filters-wrapper'}>
                <SearchField
                    label={t('salesStatistics.filters.search')}
                    value={filter.query || ''}
                    onChange={onFilterFieldChange('query')}
                    onSearch={setDebouncedQuery}
                />
                <div className="spacer" />
                <div className={StringUtils.combineStrings(['datefields-wrapper', isDateFieldsError && 'error'])}>
                    <div className={'datefields-container'}>
                        <DateField
                            label={t('salesStatistics.filters.dateFrom')}
                            name={'dateFrom'}
                            value={
                                filter.dateFrom
                                    ? DateTime.fromFormat(filter.dateFrom, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                                    : undefined
                            }
                            onChange={onFilterDateChange('dateFrom')}
                            allowClear
                        />
                        <DateField
                            label={t('salesStatistics.filters.dateTo')}
                            name={'dateTo'}
                            value={
                                filter.dateTo
                                    ? DateTime.fromFormat(filter.dateTo, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                                    : undefined
                            }
                            onChange={onFilterDateChange('dateTo')}
                            allowClear
                        />
                    </div>
                    {isDateFieldsError && (
                        <Label
                            type={'info'}
                            error
                            value={t('salesStatistics.filters.datesError')}
                            extraClassName="date-fields-error"
                        />
                    )}
                </div>
            </div>
            <div className="table-container">
                <InfiniteTable<IRowContent>
                    headerContent={() => <HeaderContent platforms={platforms} />}
                    items={restaurantsWithStats.map((row) => ({
                        restaurantName: row.restaurantInfo.name,
                        restaurantId: row.restaurantId,
                        restaurantAddress: RestaurantUtils.formatRestaurantAddress(row.restaurantInfo),
                        reservationsCount: row.reservationsCount,
                        status: row.status,
                        subscription: row.subscription,
                    }))}
                    hasMore={Boolean(salesStatisticsQuery.hasNextPage)}
                    loadNext={loadNextPage}
                    isFetchingNextPage={salesStatisticsQuery.isFetchingNextPage}
                    itemContent={(_index: number, row: IRowContent) => <ItemContent platforms={platforms} row={row} />}
                />
            </div>
        </Page>
    );
};

const reservationsCountCellWidth = 160;

interface IItemContentProps {
    platforms: IPlatform[];
    row: IRowContent;
}

const ItemContent = (props: IItemContentProps) => {
    return (
        <>
            <TableCell component="th" scope="row" align="left">
                <Label type="text" value={props.row.restaurantName} />
                <Label type="info" value={props.row.restaurantAddress} />
            </TableCell>
            <TableCell align="center">{props.row.subscription}</TableCell>
            <TableCell align="center" className="border-right">
                {props.row.status}
            </TableCell>
            <TableCell align="center" width={reservationsCountCellWidth} className="border-right">
                {props.row.reservationsCount.total}
            </TableCell>
            <TableCell align="center" width={reservationsCountCellWidth}>
                {props.row.reservationsCount.online}
            </TableCell>
            <TableCell align="center" width={reservationsCountCellWidth}>
                {props.row.reservationsCount.mylocalina}
            </TableCell>
            {props.platforms.map((platform) => (
                <TableCell
                    align="center"
                    key={`${props.row.restaurantId}-${platform.platformId}`}
                    width={reservationsCountCellWidth}
                >
                    {props.row.reservationsCount.platforms.find(
                        (platformReservations) => platformReservations.platformId === platform.platformId,
                    )?.reservationsCount || 'N/A'}
                </TableCell>
            ))}
            <TableCell align="center" width={reservationsCountCellWidth} className="border-left">
                {props.row.reservationsCount.walkin}
            </TableCell>
            <TableCell align="center" width={reservationsCountCellWidth}>
                {props.row.reservationsCount.manual}
            </TableCell>
        </>
    );
};

interface IHeaderContent {
    platforms: IPlatform[];
}

const HeaderContent = (props: IHeaderContent) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <TableRow className="main-row">
                <TableCell align={'center'} colSpan={3} className="border-bottom-and-right">
                    <Label type={'text'} align={'center'} value={t('salesStatistics.table.preHeaders.customer')} />
                </TableCell>
                <TableCell align={'center'} colSpan={1} className="border-bottom-and-right">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.preHeaders.allReservations')}
                    />
                </TableCell>
                <TableCell align={'center'} colSpan={props.platforms.length + 2} className="border-bottom">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.preHeaders.onlineReservations')}
                    />
                </TableCell>
                <TableCell align={'center'} colSpan={2} className="border-bottom-and-left">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.preHeaders.manualReservations')}
                    />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell align="left">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.name')}
                        key="header-name"
                    />
                </TableCell>
                <TableCell align="center">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.type')}
                        key="header-type"
                    />
                </TableCell>
                <TableCell align="center" className="border-right">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.status')}
                        key="header-status"
                    />
                </TableCell>
                <TableCell align="center" className="border-right">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.totalReservations')}
                        key="header-totalReservations"
                    />
                </TableCell>
                <TableCell align="center">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.onlineReservations')}
                        key="header-onlineReservations"
                    />
                </TableCell>
                <TableCell align="center">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.myLocalinaWidgetReservations')}
                        key="header-myLocalinaWidgetReservations"
                    />
                </TableCell>
                {props.platforms.map((platform) => (
                    <TableCell align="center" key={platform.platformId} width={reservationsCountCellWidth}>
                        <Label
                            key={platform.platformId}
                            type={'text'}
                            align={'center'}
                            value={
                                <Trans
                                    i18n={i18n}
                                    components={[<span key="platform" />]}
                                    i18nKey={'salesStatistics.table.headers.platformReservations'}
                                    values={{ platform: PlatformUtils.getLocalizedPlatformName(platform.name) }}
                                />
                            }
                        />
                    </TableCell>
                ))}
                <TableCell align="center" key="header-walkinReservations" className="border-left">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.walkinReservations')}
                    />
                </TableCell>
                <TableCell align="center" key="header-manualReservations">
                    <Label
                        type={'text'}
                        align={'center'}
                        value={t('salesStatistics.table.headers.manualReservations')}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default SalesStatistics;
