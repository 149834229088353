import { IRestaurant, ValidationUtils } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '../Drawer';
import { RestaurantInfo } from '../Restaurant';

interface IProps {
    restaurant?: IRestaurant;
    hasError: boolean;
    onSave: (restaurant: IRestaurant) => void;
    onClose: () => void;
}

export const RestaurantView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [restaurant, setRestaurant] = React.useState(props.restaurant);
    const [isFormValid, setFormValid] = React.useState(false);
    const [actionRequested, setActionRequested] = React.useState(false);

    React.useEffect(() => {
        setRestaurant(props.restaurant);
        setActionRequested(false);
    }, [props.restaurant]);

    React.useEffect(() => {
        if (restaurant) {
            setFormValid(ValidationUtils.validateObject(restaurant.info));
        }
    }, [restaurant]);

    const handleClose = () => {
        props.onClose();
    };

    const handleSave = () => {
        if (restaurant) {
            setActionRequested(true);
            props.onSave(restaurant);
        }
    };

    return (
        <Drawer
            className="restaurant-view"
            open={!!props.restaurant}
            title={t('restaurants.view.title')}
            disabled={!isFormValid}
            error={{
                isVisible: actionRequested && props.hasError,
                message: t('restaurants.view.error'),
            }}
            onClose={handleClose}
            onSave={handleSave}
        >
            {restaurant && <RestaurantInfo restaurant={restaurant} onChange={setRestaurant} />}
        </Drawer>
    );
};
