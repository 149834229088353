import React from 'react';
import { ListItem, ITablePlan, IRestaurant } from '@localina/core';
import { useNavigate } from 'react-router-dom';
import { PathUtils } from '../../utils';
import { Path } from '../../enums';

type IProps = { restaurant?: IRestaurant; tablePlan: ITablePlan };

const TablePlan: React.FC<IProps> = (props) => {
    const navigate = useNavigate();

    const handleClickTablePlan = () => {
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_TABLE_PLAN_DETAILS, {
                restaurantId: props.restaurant?.id,
                tablePlanId: props.tablePlan.id,
            }),
        );
    };

    return <ListItem title={props.tablePlan.name} onClick={handleClickTablePlan} />;
};

export default TablePlan;
