import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { InputFieldUtils } from './InputFieldUtils';
var validateField = function (name, value, optional) {
    var output = {
        isValid: true,
    };
    if (optional && value === '') {
        return output;
    }
    if (name === 'phoneNumber') {
        // only validates the length of the phone number
        output.isValid = isPossiblePhoneNumber(value);
    }
    else {
        var length_1 = InputFieldUtils.getLengthForField(name);
        if (length_1) {
            if (typeof value === 'string') {
                output.isValid = value.length >= length_1.minLength && value.length <= length_1.maxLength;
            }
            else if (typeof value === 'number') {
                output.isValid = value >= length_1.minLength && value <= length_1.maxLength;
            }
        }
    }
    if (!output.isValid) {
        output.invalidMessage = 'core:errors.invalidLength';
    }
    if (name === 'phoneNumber' && output.isValid) {
        // validates both phone number length and the actual phone number digits
        output.isValid = isValidPhoneNumber(value);
        if (!output.isValid) {
            output.invalidMessage = InputFieldUtils.getInvalidFormatFieldErrorMessage(name);
        }
    }
    else {
        var format = InputFieldUtils.getFormatForField(name);
        if (format) {
            var isValidFormat = ValidationUtils.validateStringFormat(name, value);
            output.isValid = output.isValid && isValidFormat;
            if (!isValidFormat) {
                output.invalidMessage = InputFieldUtils.getInvalidFormatFieldErrorMessage(name);
            }
        }
    }
    return output;
};
var validateObject = function (object, more) {
    return Object.keys(object).every(function (key) {
        var value = object[key];
        if (more && more.optionalFields.includes(key) && value === '') {
            return true;
        }
        else if (typeof value === 'string' && value.trim().length === 0) {
            return false;
        }
        else if (Array.isArray(value) && key === 'phoneNumbers') {
            return validateArray('phoneNumber', value);
        }
        return validateField(key, value, false).isValid;
    });
};
var validateArray = function (fieldName, array) {
    return array.every(function (value) {
        if (typeof value === 'string' && value.trim().length === 0) {
            return false;
        }
        return validateField(fieldName, value, false).isValid;
    });
};
var validateStringFormat = function (name, value) {
    if (typeof value === 'string') {
        var formatRegex = InputFieldUtils.getFormatForField(name);
        if (formatRegex) {
            return formatRegex.test(value);
        }
    }
    return true;
};
export var ValidationUtils = {
    validateField: validateField,
    validateObject: validateObject,
    validateStringFormat: validateStringFormat,
};
