import { ExportFormat } from '../enums';

function relativeUrl(relative: string): URL {
    return new URL(`/api/v1${relative}`, window.location.origin);
}

export const TracingApiConstants = {
    getTracingsExport(restaurantId: string, format: ExportFormat): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/tracing/exports`);
        const queryParams = new URLSearchParams();
        queryParams.append('format', format);
        url.search = queryParams.toString();
        return url;
    },
};
