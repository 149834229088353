import { ILocalizedError, IReservation, IReservations, SERVER_DATE_FORMAT } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { useReservation, useReservations } from './reservations';
import { usePreservedRestaurant } from './restaurants';
import { getOptionsEnabled } from './utils';

const useUnreadReservations = (options?: UseQueryOptions<IReservations, ILocalizedError>) => {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantQuery = usePreservedRestaurant(false);

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getUnreadReservations(restaurantQuery.data?.id || '');
        },
        queryKey: queryKeys.restaurants.single.notifications.unreadReservations(restaurantQuery.data?.id || ''),
        ...options,
        enabled: Boolean(optionsEnabled && restaurantQuery.data?.id),
    });
};

interface IUseUpdateReservationReadStatusVariables {
    reservations: IReservation[];
}

const useUpdateReservationReadStatus = (
    restaurantId?: string,
    options?: UseMutationOptions<string[], ILocalizedError, IUseUpdateReservationReadStatusVariables>,
) => {
    const queryClient = useQueryClient();
    const restaurantIdFromUrl = useRestaurantId();
    const restaurantIdToUse = restaurantId || restaurantIdFromUrl;

    return useMutation({
        mutationFn: (variables) => {
            const reservationIds = variables.reservations.map(({ id }) => id);
            return LocalinaApiContext.serviceApi.markReservationAsRead(restaurantIdToUse, { reservationIds });
        },
        onSuccess: (_data, variables) => {
            variables.reservations.forEach((reservation) => {
                if (reservation.reservationDateTime) {
                    queryClient.setQueryData<ReturnType<typeof useReservations>['data']>(
                        queryKeys.restaurants.single.reservations.onDate(
                            restaurantIdToUse,
                            DateTime.fromISO(reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT),
                        ),
                        (prevData) => ({
                            ...prevData,
                            reservations: prevData
                                ? prevData.reservations.map((res) =>
                                      res.id === reservation.id ? { ...res, read: true } : res,
                                  )
                                : [{ ...reservation, read: true }],
                        }),
                    );
                }
                queryClient.setQueryData<ReturnType<typeof useReservation>['data']>(
                    queryKeys.restaurants.single.reservations.single(restaurantIdToUse, reservation.id),
                    (prevData) => ({ ...(prevData ? prevData : reservation), read: true }),
                );
            });
        },
        ...options,
    });
};

const useRemoveReservationFromUnreadQueryCache = () => {
    const queryClient = useQueryClient();
    const restaurantQuery = usePreservedRestaurant(false);
    return useCallback(
        (reservationId: string) => {
            if (restaurantQuery.data?.id) {
                queryClient.setQueryData<ReturnType<typeof useUnreadReservations>['data']>(
                    queryKeys.restaurants.single.notifications.unreadReservations(restaurantQuery.data.id),
                    (prevData) =>
                        prevData
                            ? {
                                  ...prevData,
                                  reservations: prevData.reservations.filter((res) => res.id !== reservationId),
                              }
                            : undefined,
                );
            }
        },
        [restaurantQuery.data?.id],
    );
};

export { useUnreadReservations, useUpdateReservationReadStatus, useRemoveReservationFromUnreadQueryCache };
