import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IPageBanner, PlatformName, useConfirmContext } from '@localina/core';
import { GoogleIcon, LogoLocalsearchIcon } from '@localina/icons';
import { Public } from '@mui/icons-material';
import { AlertProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useActivePlatforms } from '../api/queries/platforms';
import { useRestaurantById, useUpdateSpecificRestaurantMutation } from '../api/queries/restaurants';
import { useRestaurantId } from './RestaurantUtils';

const getPlatformIcon = (platformName?: string): ReactJSXElement => {
    switch (platformName) {
        case PlatformName.GOOGLE:
            return <GoogleIcon />;
        case PlatformName.LOCALCH:
            return <LogoLocalsearchIcon />;
        default:
            return <Public />;
    }
};

const useActivateLocalChBanner = (restaurantId?: string) => {
    const { t } = useTranslation();

    const restaurantIdToUse = useRestaurantId(restaurantId);
    const restaurantQuery = useRestaurantById(restaurantIdToUse, { enabled: false });

    const platformsQuery = useActivePlatforms({
        enabled: Boolean(restaurantQuery.data),
    });
    const localChPlatform = platformsQuery.data?.find(
        (platform) => platform.name === PlatformName.LOCALCH && platform.active,
    );

    const updateRestaurantMutation = useUpdateSpecificRestaurantMutation();

    const { snackbar } = useConfirmContext();

    const activateLocalCh = () => {
        if (restaurantQuery.data && localChPlatform) {
            updateRestaurantMutation.mutate(
                {
                    restaurantId: restaurantIdToUse,
                    restaurant: {
                        platformIds: [...restaurantQuery.data.configuration.platforms, localChPlatform.platformId],
                    },
                },
                {
                    onSuccess: () => {
                        snackbar({
                            msg: t('common.banner.platforms.localch.successMessage'),
                            severity: 'success',
                        });
                    },
                },
            );
        }
    };

    const isLocalChActivated =
        localChPlatform && restaurantQuery.data?.configuration.platforms.includes(localChPlatform.platformId);

    const [bannerVisible, setBannerVisible] = useState(true);
    const onBannerClose = () => {
        setBannerVisible(false);
    };

    const banner: IPageBanner | undefined =
        isLocalChActivated === false
            ? {
                  text: (
                      <Trans
                          i18nKey={'common.banner.platforms.localch.bannerText'}
                          components={[
                              <span className={'highlight'} key={'banner-highlight-text'} onClick={activateLocalCh} />,
                          ]}
                      />
                  ),
                  severity: 'error' as AlertProps['severity'],
                  open: bannerVisible,
                  onClose: onBannerClose,
              }
            : undefined;

    useEffect(() => {
        setBannerVisible(true);
    }, [restaurantIdToUse]);

    return [banner, updateRestaurantMutation.isLoading] as const;
};

export { useActivateLocalChBanner, getPlatformIcon };
