import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextField, IRestaurantVirtualArea, Spinner, useConfirmContext } from '@localina/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Schema } from 'yup';
import { useUpdateVirtualArea } from '../../../api/queries/restaurantAreas';
import { Drawer } from '../../../components';

interface IVirtualAreaViewProps {
    onClose: () => void;
    virtualArea?: IRestaurantVirtualArea;
}

type TVirtualAreaForm = Pick<IRestaurantVirtualArea, 'name' | 'code'>;
const VirtualAreaView = (props: IVirtualAreaViewProps) => {
    const { t } = useTranslation();
    const { confirm, snackbar } = useConfirmContext();

    const updateVirtualArea = useUpdateVirtualArea();

    const virtualAreaSchema: Record<keyof TVirtualAreaForm, Schema> = {
        name: yup.string().required().min(2).max(30),
        code: yup.string().required().min(1).max(8),
    };

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(virtualAreaSchema)),
        defaultValues: {
            name: props.virtualArea?.name || '',
            code: props.virtualArea?.code || '',
        },
    });
    const { isDirty, isValid } = methods.formState;

    const confirmCloseDrawer = async () => {
        if (
            !isDirty ||
            (await confirm({ title: t('sectors.mainAreaView.form.confirmDiscardChanges.title') })) === 'yes'
        ) {
            props.onClose();
        }
    };

    const handleSubmit = () => {
        if (isValid && props.virtualArea) {
            updateVirtualArea.mutate(
                { ...props.virtualArea, ...methods.getValues() },
                {
                    onError: () => {
                        snackbar({
                            msg: t('sectors.mainAreaView.form.errorMessage'),
                            severity: 'error',
                        });
                    },
                    onSuccess: () => {
                        snackbar({
                            msg: t('sectors.mainAreaView.form.successMessage'),
                            severity: 'success',
                        });
                        props.onClose();
                    },
                },
            );
        }
    };
    const isLoading = updateVirtualArea.isLoading;

    useEffect(() => {
        methods.reset({
            name: props.virtualArea?.name || '',
            code: props.virtualArea?.code || '',
        });
    }, [props.virtualArea]);

    return (
        <Drawer
            open={Boolean(props.virtualArea)}
            title={t('sectors.mainAreaView.form.title')}
            disabled={!(isDirty && isValid)}
            onClose={confirmCloseDrawer}
            onSave={handleSubmit}
        >
            <FormProvider {...methods}>
                <form>
                    <FormTextField name={'name'} label={t('sectors.mainAreaView.form.fields.name')} />
                    <FormTextField name={'code'} label={t('sectors.mainAreaView.form.fields.code')} uppercase />
                </form>
            </FormProvider>
            {isLoading && <Spinner />}
        </Drawer>
    );
};

export default VirtualAreaView;
