import {
    CANVAS_TABLE_DROP_TYPE,
    IObjTypes,
    ITablePlanCanvas,
    ITablePlanObj,
    ITablePlanObjMetadata,
} from '@localina/core';
import { fabric } from 'fabric';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { TablePlanContext } from '../../../containers/Tableplans/context';
import { useMove } from '../../../hooks/useMove';
import { useZoom } from '../../../hooks/useZoom';
import { NotTableObjs, TableObjs } from '../../../interfaces/entities/ITablePlan';
import { getIntersectingObjects, onObjectScaling, onObjMoving, validatePositions } from '../CanvasOnEvents';
import { CustomTableControls } from '../CustomTableControls';
import { createFabricObject, createTablePlanMetadataObj, getTableCanvasFromFabricCanvas } from '../FabricHelper';

interface IProps {
    onChange: (canvasState: ITablePlanCanvas) => void;
    canvasState: ITablePlanCanvas | undefined;
    onElementClick: (id: string) => void;
    selectedCanvasElement?: ITablePlanObjMetadata;
    getCanvasIcons: () => React.ReactNode[];
    tables: ITablePlanObj[];
    onDrop: (data: ITablePlanObjMetadata, canvasWidth: number, canvasHeight: number) => void;
}

const getCanvasSize = (canvasState?: ITablePlanCanvas) => {
    if (!canvasState) {
        return [0, 0] as const;
    }
    const width = canvasState.elements.reduce((acc, element) => {
        const val = element.left + (element.type === 'circle' ? 2 * element.radius : element.width);
        return Math.max(val, acc);
    }, 0);
    const height = canvasState.elements.reduce((acc, element) => {
        const val = element.top + (element.type === 'circle' ? 2 * element.radius : element.height);
        return Math.max(val, acc);
    }, 0);
    return [width, height] as const;
};

export const TablePlanCanvas: React.FC<IProps> = (props) => {
    const { zoomCanvas: zoom, onZoomChange } = useContext(TablePlanContext);
    const zoomCanvas = 0.4 ** (1 - zoom);
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const parentCanvasRef = useRef<HTMLDivElement>(null);
    const [canvasWidth, canvasHeight] = getCanvasSize(props.canvasState);
    const selectedFabricObject = canvasRef.current?.getObjects().find((o) => {
        return o.data?.id === props.selectedCanvasElement?.id;
    });

    const [, drop] = useDrop(
        () => ({
            accept: CANVAS_TABLE_DROP_TYPE,
            drop: (item: { objType: IObjTypes; imageUrl?: string }, monitor) => {
                if (!canvasRef.current || !parentCanvasRef.current) {
                    return;
                }
                const offset = monitor.getClientOffset();
                const dropTargetXy = parentCanvasRef.current.getBoundingClientRect();
                const objType = item.objType;
                const left = ((offset?.x || 0) + parentCanvasRef.current.scrollLeft - dropTargetXy.left) / zoomCanvas;
                const top = ((offset?.y || 0) + parentCanvasRef.current.scrollTop - dropTargetXy.top) / zoomCanvas;
                const tableObj = createTablePlanMetadataObj(objType, left, top, item, true);

                createFabricObject(tableObj, '')
                    .then((val) => {
                        if (!canvasRef.current || !val || getIntersectingObjects(val).length > 0) {
                            return;
                        }
                        props.onDrop(tableObj, canvasRef.current.getWidth(), canvasRef.current.getHeight());
                    })
                    .catch(() => {
                        //do nothing
                    });
            },
        }),
        [props.onDrop, canvasRef.current, parentCanvasRef.current, zoomCanvas],
    );
    const [objectMoving, setObjectMoving] = useState(false);

    useLayoutEffect(() => {
        if (!parentCanvasRef.current) {
            return;
        }
        const handler = () => {
            props.onElementClick('');
        };
        parentCanvasRef.current.addEventListener('scroll', handler);
        return () => {
            parentCanvasRef.current?.removeEventListener('scroll', handler);
        };
    }, [parentCanvasRef.current]);

    useEffect(() => {
        const _canvasFabric = new fabric.Canvas('canvas', {
            skipOffscreen: true,
            renderOnAddRemove: false,
            selection: false,
            enableRetinaScaling: false,
        });
        _canvasFabric.toDataURL({});
        let parentCanvasWidth = (parentCanvasRef.current?.offsetWidth || 0) * 4;
        let parentCanvasHeight = (parentCanvasRef.current?.offsetHeight || 0) * 4;
        const setDimensions = () => {
            parentCanvasWidth = Math.max((parentCanvasRef.current?.offsetWidth || 0) * 4, 1.4 * canvasWidth);
            parentCanvasHeight = Math.max((parentCanvasRef.current?.offsetHeight || 0) * 4, 1.4 * canvasHeight);
            _canvasFabric.setWidth(parentCanvasWidth);
            _canvasFabric.setHeight(parentCanvasHeight);
        };

        _canvasFabric.on('object:moving', function (e) {
            props.onElementClick('');
            e.e.stopPropagation();
            e.e.preventDefault();
            onObjMoving(e.target);
        });
        _canvasFabric.on('object:modified', (e) => {
            validatePositions(e.target);
            const val = getTableCanvasFromFabricCanvas(_canvasFabric);

            props.onChange({
                ...val,
                elements: val.elements.map((element) => {
                    if (element.id === e.target?.data.id) {
                        if (element.type === 'circle') {
                            return {
                                ...element,
                                radius: element.radius * (e.target?.scaleX || 1),
                            };
                        }

                        return {
                            ...element,
                            borderRadius: element.borderRadius * (e.target?.scaleY || 1),
                            width: e.target?.getScaledWidth() || 1,
                            height: e.target?.getScaledHeight() || 1,
                        };
                    }
                    return element;
                }),
            });
        });
        _canvasFabric.on('mouse:up', function () {
            setObjectMoving(false);
        });
        _canvasFabric.on('mouse:down', function (e) {
            const targetObjType = (e.target?.data?.objType || '') as string;
            if (TableObjs.includes(targetObjType) || NotTableObjs.includes(targetObjType)) {
                props.onElementClick((e.target?.data.id || '') as string);
                e.e.stopPropagation();
                setObjectMoving(true);
                return;
            }
            props.onElementClick('');
        });
        _canvasFabric.on('object:scaling', function (e) {
            if (e.target) {
                onObjectScaling(e.target);
            }
        });
        setDimensions();
        window.addEventListener('resize', setDimensions, false);
        canvasRef.current = _canvasFabric;
        return () => {
            _canvasFabric.dispose();
            window.removeEventListener('resize', setDimensions);
        };
    }, []);

    useEffect(() => {
        if (!props.canvasState || !canvasRef.current) {
            return;
        }
        Promise.all(
            props.canvasState.elements.map((element) => {
                let text = '';
                if (element.objType === 'floor') {
                    text = element.label || '';
                }
                if (TableObjs.includes(element.objType)) {
                    const table = props.tables.find((e) => e.id === element.tableId);
                    if (table) {
                        text = `${table.name} (${table.numberOfSeats})`;
                    }
                }
                return createFabricObject(element, text);
            }),
        )
            .then((val) => {
                const filtered = val.filter((e) => e !== undefined) as (fabric.Rect | fabric.Circle | fabric.Image)[];
                if (canvasRef.current) {
                    canvasRef.current.renderOnAddRemove = false;
                    const prev = canvasRef.current.getObjects().filter((o) => !!o.data?.id);
                    canvasRef.current.remove(...prev);
                    canvasRef.current.add(
                        ...filtered.sort((a, b) => {
                            return (a.data?.zIndex || 0) - (b.data?.zIndex || 0);
                        }),
                    );
                    canvasRef.current.renderOnAddRemove = true;
                    canvasRef.current.renderAll();
                }
            })
            .catch(() => {
                //do nothing
            });
    }, [props.canvasState, canvasRef, props.tables]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const ratio = Math.max(1, canvasRef.current.getZoom() / zoomCanvas);

        canvasRef.current.width = canvasRef.current.getWidth() * ratio;
        canvasRef.current.height = canvasRef.current.getHeight() * ratio;
        canvasRef.current.setZoom(zoomCanvas);
    }, [zoomCanvas, canvasRef.current]);

    const zoomHandler = useCallback(
        (val: number) => {
            if (onZoomChange) {
                onZoomChange((prev) => {
                    const ratio = prev * val;
                    return Math.max(0.1, Math.min(5, ratio));
                });
            }
        },
        [onZoomChange],
    );
    useZoom(parentCanvasRef, zoomHandler);

    useMove(parentCanvasRef, objectMoving);

    return (
        <div ref={parentCanvasRef} className="table-plan-editor-canvas-container" id="parent-canvas">
            <div
                style={{
                    width: `${(parentCanvasRef.current?.offsetWidth || 1) * 2}px`,
                    height: `${(parentCanvasRef.current?.offsetHeight || 1) * 2}px`,
                }}
                ref={drop}
            >
                <canvas id="canvas" className="canvas" />
            </div>
            {selectedFabricObject && (
                <CustomTableControls
                    zoomCanvas={zoomCanvas}
                    top={selectedFabricObject.top || 0}
                    left={(selectedFabricObject.left || 0) + (selectedFabricObject.width || 0)}
                    icons={props.getCanvasIcons()}
                ></CustomTableControls>
            )}
        </div>
    );
};
