import { TextField, ValidationUtils } from '@localina/core';
import { EmailIcon, PersonIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '../../../components';
import { ICreateAuthUser } from '../../../interfaces';
import { createRandomPassword } from '../../../utils';

interface IRegisterRestaurantAccountDrawerProps {
    onClose: () => void;
    onSave: (arg: any) => void;
    open: boolean;
    hasError?: boolean;
}

const generateInitialUser = (): ICreateAuthUser => ({
    firstName: '',
    lastName: '',
    email: '',
    password: createRandomPassword(),
});
const RegisterRestaurantAccountDrawer = (props: IRegisterRestaurantAccountDrawerProps) => {
    const { t } = useTranslation();

    const [user, setUser] = React.useState<ICreateAuthUser>(generateInitialUser);
    const [registerRequested, setRegisterRequested] = React.useState(false);

    const isFormValid = ValidationUtils.validateObject(user);
    const handleChanges = (field: string) => (value: string) => {
        setUser((prevState) => ({ ...prevState, [field]: value }));
    };

    const onSave = () => {
        setRegisterRequested(true);
        props.onSave(user);
    };

    const onClose = () => {
        setRegisterRequested(false);
        setUser(generateInitialUser());
        props.onClose();
    };

    return (
        <Drawer
            className="register-restaurant-account-drawer"
            open={props.open}
            title={t('supportAccounts.registerRestaurantAccountDrawer.title')}
            error={{
                isVisible: Boolean(registerRequested && props.hasError),
                message: t('supportAccounts.registerRestaurantAccountDrawer.error'),
            }}
            onClose={onClose}
            onSave={onSave}
            disabled={!isFormValid}
        >
            <div className="register-restaurant-account">
                <TextField
                    label={t('register.fields.firstName')}
                    name="firstName"
                    autoComplete={false}
                    icon={<PersonIcon />}
                    required={true}
                    value={user.firstName}
                    onChange={handleChanges('firstName')}
                />
                <TextField
                    label={t('register.fields.lastName')}
                    name="lastName"
                    autoComplete={false}
                    icon={true}
                    required={true}
                    value={user.lastName}
                    onChange={handleChanges('lastName')}
                />
                <TextField
                    label={t('register.fields.email')}
                    name="email"
                    autoComplete={false}
                    type="email"
                    icon={<EmailIcon />}
                    required={true}
                    value={user.email}
                    onChange={handleChanges('email')}
                />
            </div>
        </Drawer>
    );
};
export default RegisterRestaurantAccountDrawer;
