import React from 'react';
import { useRegisterMutation } from '../../api/queries/auth';
import { Page } from '../../components';
import { Done, UserInfo } from './Steps';

const Register: React.FC = () => {
    const [step, setStep] = React.useState(1);

    const registerMutation = useRegisterMutation();

    React.useEffect(() => {
        setStep(1);
    }, []);

    const scrollToTop = () => {
        const main = document.getElementsByTagName('main')[0];
        main.scrollTop = 0;
    };
    const handleNext = () => {
        setStep(step + 1);
        scrollToTop();
    };

    const getPage = () => {
        switch (step) {
            case 1:
                return <UserInfo onNext={handleNext} registerMutation={registerMutation} />;
            case 2:
                return <Done />;
            default:
                return <></>;
        }
    };

    const getClassName = () => {
        switch (step) {
            case 1:
                return 'userinfo';
            case 2:
                return 'done';
        }
    };

    return (
        <Page name={`register ${getClassName()}`} isLoading={registerMutation.isLoading}>
            <div className="register__content">{getPage()}</div>
        </Page>
    );
};
export default Register;
