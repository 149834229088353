import { DATE_FORMAT, MessageLanguage, MessageType, StringUtils } from '@localina/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const CONFIRMATION_MAIL_TEXT_MAX_CHAR_LIMIT = 600;
const CONFIRMATION_MAIL_LINK_NAME_MAX_CHAR_LIMIT = 50;
const useMessageSchema = (type?: MessageType | string) => {
    const { t } = useTranslation();
    const displayTextSchema = useDisplayTextSchema(type);

    return {
        name: yup.string().required(),
        link: yup
            .string()
            .test('validate-url', t('errors.invalidUrl', { ns: 'core' }), (value) => {
                return !value || StringUtils.isValidUrl(value);
            })
            .matches(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                { message: t('errors.invalidUrl', { ns: 'core' }), excludeEmptyString: true },
            )
            .nullable(),
        from: yup
            .string()
            .nullable()
            .test('lessOrEqualThanTo', t('errors.invalidDate', { ns: 'core' }), (value, context) => {
                if (value) {
                    const dateTo = context.parent['to'];
                    if (dateTo) {
                        return (
                            DateTime.fromFormat(value, DATE_FORMAT).diff(
                                DateTime.fromFormat(dateTo as string, DATE_FORMAT),
                            ).milliseconds <= 0
                        );
                    }
                }
                return true;
            }),
        to: yup
            .string()
            .nullable()
            .test('greaterOrEqualThanFrom', t('errors.invalidDate', { ns: 'core' }), (value, context) => {
                if (value) {
                    const dateFrom = context.parent['from'];
                    if (dateFrom) {
                        return (
                            DateTime.fromFormat(value, DATE_FORMAT).diff(
                                DateTime.fromFormat(dateFrom as string, DATE_FORMAT),
                            ).milliseconds >= 0
                        );
                    }
                }
                return true;
            }),

        displayTexts: yup.array().of(yup.object(displayTextSchema)).required(),
    };
};

const useDisplayTextSchema = (type?: MessageType | string) => {
    const { t } = useTranslation();
    const isConfirmationMail = type === MessageType.CONFIRMATION_MAIL;
    return {
        language: yup.string<MessageLanguage>().oneOf(Object.values(MessageLanguage)).required(),
        link: yup
            .string()
            .notRequired()
            .when((_value, schema) => {
                return isConfirmationMail
                    ? schema.max(
                          CONFIRMATION_MAIL_LINK_NAME_MAX_CHAR_LIMIT,
                          t('validation.string_too_long', {
                              ns: 'core',
                              max: CONFIRMATION_MAIL_LINK_NAME_MAX_CHAR_LIMIT,
                          }),
                      )
                    : schema;
            }),
        text: yup
            .string()
            .required()
            .when((_value, schema) => {
                return isConfirmationMail
                    ? schema.max(
                          CONFIRMATION_MAIL_TEXT_MAX_CHAR_LIMIT,
                          t('validation.string_too_long', {
                              ns: 'core',
                              max: CONFIRMATION_MAIL_TEXT_MAX_CHAR_LIMIT,
                          }),
                      )
                    : schema;
            }),
    };
};

export { useMessageSchema, useDisplayTextSchema };
