import { IReservation, Label } from '@localina/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GuestUtils } from '../../utils';

interface IReservationTouchDndPreview {
    reservation: IReservation;
}

const ReservationTouchDndPreview = (props: IReservationTouchDndPreview) => {
    const { t } = useTranslation();

    const reservationRowInfo = `${props.reservation.participants} ${t('table.people')}, ${GuestUtils.formatGuestName(
        props.reservation.guestInfo,
        t('reservations.view.fields.walkin'),
    )}`;

    return (
        <div className="reservation-touch-dnd-preview">
            <div className="reservation-touch-dnd-preview-content">
                <div>
                    <Label
                        type="text"
                        value={DateTime.fromISO(props.reservation.reservationDateTime).toFormat('HH:mm')}
                    />
                </div>
                <div>
                    <Label extraClassName="no-wrap" type="info" value={reservationRowInfo} />
                </div>
            </div>
        </div>
    );
};

export default ReservationTouchDndPreview;
