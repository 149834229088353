import { ISaveTablePlanRequest, SelectField, TextField } from '@localina/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAreas } from '../../api/queries/restaurantAreas';

interface IProps {
    tablePlanDetails: ISaveTablePlanRequest;
    onChange: (tablePlan: ISaveTablePlanRequest) => void;
}

const TablePlanCreationDetails: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const areasQuery = useAreas();

    const availableAreas = useMemo(
        () => areasQuery.data?.areas.map((area) => ({ label: area.name, value: area.id })) || [],
        [areasQuery.data],
    );

    const handleChange = (field: string) => (value: string | File | undefined) => {
        if (props.tablePlanDetails) {
            const updatedTablePlan = {
                ...props.tablePlanDetails,
                [field]: value,
            };

            props.onChange(updatedTablePlan);
        }
    };

    return (
        <div className="table-plan-creation-details">
            <TextField
                name="name"
                label={t('table.plan.create.table.plan.name')}
                value={props.tablePlanDetails?.name}
                type="text"
                required={true}
                onChange={handleChange('name')}
            />
            <SelectField
                label={t('table.plan.create.areas')}
                options={availableAreas}
                value={props.tablePlanDetails?.areaId}
                required={true}
                onChange={handleChange('areaId')}
            />
        </div>
    );
};

export default TablePlanCreationDetails;
