import { ILocalizedError, IRestaurant, IRestaurants, IUpdateRestaurant, StringUtils } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useRef } from 'react';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { useGetAuthUser } from './auth';
import { queryKeys } from './query-keys';
import { getOptionsEnabled } from './utils';

type TGetRestaurantsQueryData = IRestaurants;

function useFetchRestaurants<TQueryData>(
    options?: UseQueryOptions<IRestaurants, ILocalizedError, TQueryData, typeof queryKeys.restaurants.all>,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const authUserQuery = useGetAuthUser({
        enabled: optionsEnabled,
    });

    return useQuery(
        queryKeys.restaurants.all,
        () =>
            authUserQuery.data?.accountId
                ? LocalinaApiContext.supportApi.getAccountRestaurants(authUserQuery.data?.accountId)
                : LocalinaApiContext.serviceApi.getRestaurants(),
        {
            staleTime: 60 * 1000,
            ...options,
            enabled: authUserQuery.isSuccess && optionsEnabled,
        },
    );
}

const useGetRestaurants = (
    options?: Omit<
        UseQueryOptions<IRestaurants, ILocalizedError, TGetRestaurantsQueryData, typeof queryKeys.restaurants.all>,
        'select'
    >,
) => {
    return useFetchRestaurants<TGetRestaurantsQueryData>({
        ...options,
        select: (data) => ({
            ...data,
            restaurants: data.restaurants.sort((r1, r2) =>
                r1.info.name.toLowerCase().localeCompare(r2.info.name.toLowerCase()),
            ),
        }),
    });
};

interface IUseRestaurantOptions<K> extends UseQueryOptions<any, ILocalizedError, K, [string]> {
    select?: (data: TGetRestaurantReturn) => K;
}

function useRestaurant<K = TGetRestaurantReturn>(options?: IUseRestaurantOptions<K>) {
    const restaurantId = useRestaurantId();

    return useFetchRestaurants({
        ...options,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        select: (data) => {
            const returnData = getRestaurantFromRestaurants(data.restaurants, restaurantId);
            if (options?.select) {
                return options.select(returnData);
            }
            return returnData;
        },
    });
}

function useRestaurantById<K = TGetRestaurantReturn>(restaurantId: string, options?: IUseRestaurantOptions<K>) {
    return useFetchRestaurants({
        ...options,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        select: (data) => {
            const returnData = data.restaurants.find((it) => it.id === restaurantId);
            if (options?.select) {
                return options.select(returnData);
            }
            return returnData;
        },
    });
}

// Needed for usage of last restaurant data when route does not contain restaurantId param
const usePreservedRestaurant = (enabled?: boolean) => {
    const lastValidRestaurantId = useRef('');
    const restaurantId = useRestaurantId();

    return useFetchRestaurants({
        enabled,
        select: (data) => {
            const targetRestaurant = getRestaurantFromRestaurants(
                data.restaurants,
                restaurantId,
                lastValidRestaurantId.current,
            );
            if (targetRestaurant) {
                lastValidRestaurantId.current = targetRestaurant.id;
            }
            return targetRestaurant;
        },
    });
};

const useUpdateRestaurantMutation = (
    restaurantId?: string,
    options?: UseMutationOptions<string, ILocalizedError, Partial<IUpdateRestaurant>>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const queryClient = useQueryClient();

    const restaurantIdToUse = restaurantId || restaurantIdFromUrl;

    return useMutation({
        mutationFn: updateRestaurantMutationFunction(restaurantIdToUse),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};
const useUpdateSpecificRestaurantMutation = (
    options?: UseMutationOptions<
        string,
        ILocalizedError,
        {
            restaurantId: string;
            restaurant: Partial<IUpdateRestaurant>;
        }
    >,
) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (variables) =>
            LocalinaApiContext.serviceApi.updateRestaurant(variables.restaurantId, variables.restaurant),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};

const useCreateRestaurantMutation = (
    options?: UseMutationOptions<IRestaurant, ILocalizedError, { restaurant: IRestaurant }>,
) => {
    return useMutation({
        mutationFn: (variables) => {
            const request = prepareRestaurantObject(variables.restaurant);

            return LocalinaApiContext.serviceApi.createRestaurant({
                ...request,
                capacity: variables.restaurant.configuration.capacity || 20,
            });
        },
        onSuccess: () => {
            return LocalinaApiContext.authApi.getAccessToken(true);
        },
        ...options,
    });
};

const updateRestaurantMutationFunction = (restaurantId: string) => (restaurant: Partial<IUpdateRestaurant>) =>
    LocalinaApiContext.serviceApi.updateRestaurant(restaurantId, restaurant);

type TGetRestaurantReturn = IRestaurant | undefined;
const getRestaurantFromRestaurants = (
    restaurants: IRestaurant[],
    restaurantId: string,
    fallbackRestaurantId?: string,
): IRestaurant | undefined => {
    let targetRestaurant = restaurants.find((it) => it.id === restaurantId);
    if (!targetRestaurant && fallbackRestaurantId) {
        targetRestaurant = restaurants.find((it) => it.id === fallbackRestaurantId);
    }
    if (!targetRestaurant) {
        targetRestaurant = restaurants[0];
    }
    return targetRestaurant;
};

function prepareRestaurantObject(restaurant: IRestaurant): IUpdateRestaurant {
    const closingDays = restaurant.configuration.closingDays.map((closingDay) => {
        return {
            ...closingDay,
            areas: [restaurant.configuration.defaultAreaId],
        };
    });
    const defaultShiftAreas = [
        {
            areaId: restaurant.configuration.defaultAreaId,
            capacityPercentage: 100,
            directlyBookable: true,
            tablePlanId: '',
        },
    ];
    const shifts = restaurant.configuration.shifts.map((shift) => {
        return {
            ...shift,
            shiftAreas: defaultShiftAreas,
        };
    });
    return {
        tracingEnabled: restaurant.configuration.tracingEnabled,
        info: {
            ...restaurant.info,
            phoneNumber: StringUtils.removeSpace(restaurant.info.phoneNumber),
        },
        receiveReservationsEmails: Boolean(restaurant.configuration.receiveReservationsEmails),
        closingDays,
        shifts,
        platformIds: restaurant.configuration.platforms || undefined,
        cancelReservationNotice: restaurant.configuration.cancelReservationNotice,
        orderings: restaurant.configuration.orderings,
    };
}

export {
    useGetRestaurants,
    useRestaurant,
    useRestaurantById,
    usePreservedRestaurant,
    useUpdateRestaurantMutation,
    useUpdateSpecificRestaurantMutation,
    updateRestaurantMutationFunction,
    useCreateRestaurantMutation,
};
