import { Button, Label, useUrlParams } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from '../../api/queries/auth';
import { Page } from '../../components';
import { Path } from '../../enums';

const VerifyEmail: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [queryParams] = useUrlParams();

    const verifyEmailMutation = useVerifyEmailMutation();

    React.useEffect(() => {
        const code = queryParams.get('code');

        if (code) {
            verifyEmailMutation.mutate(code);
        } else {
            navigate(Path.LOGIN);
        }
    }, []);

    const getMessage = () => {
        if (verifyEmailMutation.isError) {
            return <Label type="text" value={t('verifyEmail.error')} error={true} />;
        } else if (verifyEmailMutation.isSuccess) {
            return <Label type="text" value={t('verifyEmail.confirmation')} />;
        } else {
            return <></>;
        }
    };

    const handleClick = () => {
        navigate(Path.LOGIN);
    };

    return (
        <Page name="verify-email" isLoading={verifyEmailMutation.isLoading}>
            <div className="verify-email__content">
                <Label type="title" value={t('verifyEmail.title')} variant="h3" />
                {getMessage()}
                <div className="grow" />
                <div className="buttons">
                    <Button label={t('verifyEmail.buttons.login')} onClick={handleClick} />
                </div>
            </div>
        </Page>
    );
};

export default VerifyEmail;
