import { default as i18next } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de, en, fr, it } from '../messages';
var init = function (namespace) {
    var lng = navigator.language.length > 2 ? navigator.language.substr(0, 2) : navigator.language;
    var fallbackLng = 'en';
    var supportedLngs = ['de', 'fr', 'it', 'en'];
    var isSupportedLanguage = function (x) { return supportedLngs.includes(x); };
    var locale = isSupportedLanguage(lng) ? lng : fallbackLng;
    var i18n = i18next.use(initReactI18next).createInstance({
        defaultNS: namespace,
        lng: locale,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        supportedLngs: supportedLngs,
        ns: [namespace, 'core'],
        resources: {
            de: de,
            fr: fr,
            it: it,
            en: en,
        },
    });
    // eslint-disable-next-line
    i18n.init();
    return i18n;
};
export var Localization = {
    init: init,
};
