import { IAccount, IAccountRestaurant } from '@localina/core';
import { EditIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRestaurantTestPlanIcon } from '../../utils';
import { Drawer } from '../index';
import EditRestaurantPlanDrawersRow from '../Row/EditRestaurantPlanDrawersRow';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    account: IAccount | undefined;
    onEditClick: (restaurantInfo: IAccountRestaurant) => void;
}

const EditRestaurantAccountsDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Drawer
            className="edit-restaurant-drawer"
            open={props.isOpen}
            title={t('supportAccounts.listOfRestaurants.title')}
            onClose={() => props.onClose()}
        >
            <div className="edit-restaurant-account">
                <div className="account-number">{props.account && props.account.accountNumber}</div>
                {props.account &&
                    props.account.restaurants.map((restaurant) => (
                        <EditRestaurantPlanDrawersRow
                            key={restaurant.id}
                            restaurantInfo={restaurant}
                            leftIcon={getRestaurantTestPlanIcon(restaurant.subscription, restaurant.restaurantStatus)}
                            rightIcon={<EditIcon />}
                            onEditClick={() => props.onEditClick(restaurant)}
                        />
                    ))}
            </div>
        </Drawer>
    );
};

export default EditRestaurantAccountsDrawer;
