import {
    FabButton,
    IStaffTag,
    Label,
    List,
    ListItem,
    STAFF_TAG_LIST_ITEM_DROP_TYPE,
    StringUtils,
    Switch,
    useConfirmContext,
} from '@localina/core';
import { AddIcon } from '@localina/icons';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurant, useUpdateRestaurantMutation } from '../../api/queries/restaurants';
import { useStaffTags, useUpdateStaffTagOrderingMutation } from '../../api/queries/staffTags';
import { Page } from '../../components';
import { DraggableItemWrapper, useSortableItemsWrapperUtils } from '../../utils/SortableItemsUtils';
import StaffTagView from './StaffTagView';

type TDrawerState = {
    open: boolean;
    selectedStaffTag?: IStaffTag;
};

const defaultDrawerState = {
    open: false,
    selectedStaffTag: undefined,
};
const StaffTags: React.FC = () => {
    const { t } = useTranslation();
    const { snackbar } = useConfirmContext();

    const staffTagsQuery = useStaffTags();
    const restaurantQuery = useRestaurant();
    const updateRestaurantMutation = useUpdateRestaurantMutation();
    const updateStaffTagOrderingMutation = useUpdateStaffTagOrderingMutation();

    const [drawerState, setDrawerState] = useState<TDrawerState>(defaultDrawerState);

    const { alert } = useConfirmContext();
    const [staffTagList, setStaffTagList] = useState(staffTagsQuery.data?.staffTags || []);
    const { moveItem, findItemIndex } = useSortableItemsWrapperUtils<IStaffTag>(staffTagList, setStaffTagList);
    const handleDrop = () => {
        updateStaffTagOrderingMutation.mutate(
            staffTagList.map(({ id }) => id),
            {
                onError: () => {
                    snackbar({ severity: 'error', msg: t('staffTags.configuration.updateStaffTagOrderError') });
                    if (staffTagsQuery.data) {
                        setStaffTagList(staffTagsQuery.data.staffTags);
                    }
                },
            },
        );
    };

    const handleItemClick = (item: IStaffTag) => () => {
        setDrawerState({
            open: true,
            selectedStaffTag: item,
        });
    };

    const handleCreateStaffTag = () => {
        setDrawerState({ open: true, selectedStaffTag: undefined });
    };

    const onCloseDialog = () => {
        setDrawerState(defaultDrawerState);
    };

    const toggleStaffTagMandatorySwitch = (value: boolean) => {
        if (value && staffTagsQuery.isSuccess && !staffTagsQuery.data.staffTags.length) {
            alert({ msg: t('staffTags.configuration.unableToSetStaffTagMandatory') });
        } else {
            updateRestaurantMutation.mutate({ staffTagMandatory: value });
        }
    };

    const toggleStaffTagEnabledSwitch = (value: boolean) => {
        if (value && staffTagsQuery.isSuccess && !staffTagsQuery.data.staffTags.length) {
            alert({ msg: t('staffTags.configuration.unableToSetStaffTagEnabled') });
        } else {
            updateRestaurantMutation.mutate({ staffTagEnabled: value });
        }
    };
    useEffect(() => {
        if (staffTagsQuery.data) {
            setStaffTagList(staffTagsQuery.data.staffTags);
        }
    }, [staffTagsQuery.data]);

    useEffect(() => {
        // Setup initial staff tag ordering list for restaurants that didn't use staff tag orderings
        if (
            restaurantQuery.data &&
            staffTagsQuery.data &&
            !restaurantQuery.data.configuration.orderings.staffTags &&
            updateStaffTagOrderingMutation.isIdle
        ) {
            updateStaffTagOrderingMutation.mutate(staffTagsQuery.data.staffTags.map(({ id }) => id));
        }
    }, [Boolean(restaurantQuery.data), Boolean(restaurantQuery.data?.configuration.orderings?.staffTags)]);

    useEffect(() => {
        if (
            staffTagsQuery.isSuccess &&
            !staffTagsQuery.data.staffTags.length &&
            staffTagsQuery.data.staffTagMandatory
        ) {
            updateRestaurantMutation.mutate({ staffTagMandatory: false, staffTagEnabled: false });
        }
    }, [staffTagsQuery.data?.staffTags.length, staffTagsQuery.data?.staffTagMandatory]);

    return (
        <Page
            name="staff-tags"
            title={t('staffTags.pageTitle')}
            isLoading={staffTagsQuery.isLoading || updateRestaurantMutation.isLoading}
        >
            <div className="staff-tags-configuration-wrapper">
                <Switch
                    label={t('staffTags.configuration.staffTagIsEnabled')}
                    checked={staffTagsQuery.isSuccess && staffTagsQuery.data.staffTagEnabled}
                    onChange={toggleStaffTagEnabledSwitch}
                />
                <Switch
                    label={t('staffTags.configuration.staffTagIsMandatory')}
                    disabled={!staffTagsQuery.data?.staffTagEnabled}
                    checked={staffTagsQuery.isSuccess && staffTagsQuery.data.staffTagMandatory}
                    onChange={toggleStaffTagMandatorySwitch}
                />
            </div>
            <List>
                {!staffTagsQuery.isLoading && staffTagsQuery.isSuccess && (
                    <>
                        {staffTagsQuery.data.staffTags.length === 0 && (
                            <div className={'staff-tags-empty-label-wrapper'}>
                                <Label type={'text'} value={t('staffTags.noStaffTagsCreated')} />
                            </div>
                        )}

                        {staffTagList.map((item, index) => (
                            <DraggableItemWrapper
                                key={item.id}
                                id={item.id}
                                moveItem={moveItem}
                                index={index}
                                findItemIndex={findItemIndex}
                                itemType={STAFF_TAG_LIST_ITEM_DROP_TYPE}
                                onDrop={handleDrop}
                            >
                                <ListItem
                                    title={<StaffTagListItemTitle item={item} />}
                                    onClick={handleItemClick(item)}
                                    leftIcon={<DragIndicatorIcon />}
                                    leftLabel={`${+index + 1}.`}
                                    disableRipple
                                />
                            </DraggableItemWrapper>
                        ))}
                    </>
                )}
            </List>
            <FabButton icon={<AddIcon />} onClick={handleCreateStaffTag} className="staff-tags-fab-button" fixed />
            <StaffTagView open={drawerState.open} onClose={onCloseDialog} selectedTag={drawerState.selectedStaffTag} />
        </Page>
    );
};

interface IStaffTagListItemTitleProps {
    item: IStaffTag;
}

const StaffTagListItemTitle = ({ item }: IStaffTagListItemTitleProps) => (
    <div className={'staff-tag-title'}>
        <Label type={'text'} value={StringUtils.combineStrings([item.firstName, item.lastName], ', ')} />
        <Label type={'text'} value={item.staffTag} />
    </div>
);

export default StaffTags;
