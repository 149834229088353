import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Div100vh from 'react-div-100vh';
export var Container = function (_a) {
    var className = _a.className, children = _a.children;
    // Workaround for bug in 'react-div-100h' library not getting updated after orientation change
    React.useEffect(function () {
        var resize = function () {
            [250, 500, 750].forEach(function (time) {
                return setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, time);
            });
        };
        window.addEventListener('orientationchange', resize, false);
        return function () {
            window.removeEventListener('orientationchange', resize, false);
        };
    }, []);
    return _jsx(Div100vh, { className: className, children: children });
};
