import { AREA_LIST_ITEM_DROP_TYPE, IRestaurantArea, ListItem } from '@localina/core';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DraggableItemWrapper, IDraggableItemWrapperProps } from '../../../utils/SortableItemsUtils';

interface IProps extends TDraggableProps {
    area: IRestaurantArea;
    onClick: (areaDetails: IRestaurantArea, virtualAreaId: string) => void;
    virtualAreaId: string;
}

type TDraggableProps = Pick<IDraggableItemWrapperProps, 'findItemIndex' | 'moveItem' | 'onDrop' | 'index'>;
export const AreaListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (props.area) {
            props.onClick(props.area, props.virtualAreaId);
        }
    };

    return (
        <DraggableItemWrapper
            id={props.area.id}
            moveItem={props.moveItem}
            index={props.index}
            findItemIndex={props.findItemIndex}
            itemType={AREA_LIST_ITEM_DROP_TYPE}
            onDrop={props.onDrop}
        >
            <ListItem
                title={props.area?.name}
                subtitle={`${t('sectors.capacity')}: ${props.area?.capacity}`}
                leftIcon={<DragIndicatorIcon />}
                leftLabel={`${+props.index + 1}.`}
                onClick={handleClick}
                disableRipple
            />
        </DraggableItemWrapper>
    );
};
