import {
    IReservation,
    IRestaurantVirtualArea,
    ITablePlan,
    ITablePlanCanvas,
    ITablePlanObj,
    ITablePlanObjMetadata,
} from '@localina/core';
import { CSSProperties } from 'react';
import { getObjDetails } from '../components/TablePlan/FabricHelper';
import { IPoint } from '../hooks/useZoom';
import { ITablePlanDesignTableObjDetailsParsed, NotTableObjs, TableObjs } from '../interfaces/entities/ITablePlan';

export const getCanvasNonTableObjs = (canvasObjs: ITablePlanObjMetadata[]) => {
    return canvasObjs.filter((canvasObj) => NotTableObjs.includes(canvasObj.objType));
};

export const getCanvasTableObjs = (canvasObjs: ITablePlanObjMetadata[]) => {
    return canvasObjs.filter((canvasObj) => TableObjs.includes(canvasObj.objType));
};

export const getCanvasTableParsedDetails = (
    parsedTables: ITablePlanDesignTableObjDetailsParsed[],
    tableId: string,
): ITablePlanDesignTableObjDetailsParsed | undefined => {
    return parsedTables.find((table) => table.id === tableId);
};

export const getTablePlan = (virtualAreas: IRestaurantVirtualArea[], tablePlanId?: string): ITablePlan | undefined => {
    return virtualAreas
        .flatMap((virtualArea) => virtualArea.areas)
        .flatMap((area) => area.tablePlans)
        .find((tablePlan) => tablePlan.id === tablePlanId);
};

export const getTableToEditDetails = (tablePlan: ITablePlan, tableId: string): ITablePlanObj | undefined => {
    return tablePlan.tables.find((table) => table.id === tableId);
};

export const buildCanvasTablesObjsToStore = (
    tablePlan: ITablePlan,
    tableObjs: ITablePlanObjMetadata[],
): ITablePlanObj[] => {
    const _tables: ITablePlanObj[] = [];
    tableObjs.forEach((tableObj) => {
        const _storedTable = tablePlan.tables.find((table) => table.id === tableObj.id);
        const { id } = tableObj;
        if (_storedTable && id) {
            _tables.push({
                id,
                name: _storedTable.name,
                numberOfSeats: _storedTable.numberOfSeats,
                description: _storedTable.description,
            });
        }
    });
    return _tables;
};

export const generateTableName = (tablePlan?: ITablePlan): string => {
    if (!tablePlan) return '1';
    const allTableNames = tablePlan.tables.flatMap((table) => table.name);
    const allTableNamesAsNumber: number[] = allTableNames
        .filter((tableName) => !isNaN(+tableName))
        .map((tableNumber) => +tableNumber);
    const highestNumber = allTableNamesAsNumber.length > 0 ? Math.max(...allTableNamesAsNumber) : 0;
    return `${highestNumber + 1}` || '1';
};

export const getTablesNamesByIds = (tableIds: IReservation['tableIds'], virtualAreas?: IRestaurantVirtualArea[]) => {
    return !tableIds || !virtualAreas
        ? []
        : virtualAreas
              .flatMap((v) => v.areas)
              .flatMap((area) => area.tablePlans)
              .flatMap((tp) => tp.tables)
              .filter((tb) => tableIds.includes(tb.id))
              .map((tb) => tb.name);
};

export const getBoundingRect = (tablePlanCanvas: ITablePlanCanvas): [IPoint, IPoint] => {
    let top = -1;
    let left = -1;
    let bottom = 0;
    let right = 0;

    tablePlanCanvas.elements.forEach((element) => {
        if (element.left < left || left < 0) {
            left = element.left;
        }
        if (element.top < top || top < 0) {
            top = element.top;
        }
        right = Math.max(right, element.left + getElementWidth(element));
        bottom = Math.max(bottom, element.top + getElementHeight(element));
    });

    return [
        { x: left, y: top },
        { x: right, y: bottom },
    ];
};

const getElementWidth = (element: ITablePlanObjMetadata) => {
    if (element.type === 'circle') {
        return element.radius * 2;
    }
    return element.width;
};
const getElementHeight = (element: ITablePlanObjMetadata) => {
    if (element.type === 'circle') {
        return element.radius * 2;
    }
    return element.height;
};

export function getCanvasElementStyles(
    canvasElement: ITablePlanObjMetadata,
    scaleX: number,
    scaleY: number,
    offset: IPoint,
): CSSProperties | undefined {
    const objDetails = getObjDetails(canvasElement);
    if (!objDetails) {
        return undefined;
    }
    const scale = `scale(${scaleX},${scaleY})`;
    const transformOrigin = `top left`;

    return {
        transformOrigin: transformOrigin,
        msTransformOrigin: transformOrigin,
        MozTransformOrigin: transformOrigin,
        WebkitTransformOrigin: transformOrigin,
        transform: scale,
        WebkitTransform: scale,
        position: 'absolute',
        backgroundColor: objDetails.color,
        msTransform: scale,
        zIndex: objDetails.zIndex,
        padding: `${objDetails.paddingY}px ${objDetails.paddingX}px`,
        top: `${(canvasElement.top - offset.y) * scaleY + 50}px`,
        left: `${(canvasElement.left - offset.x) * scaleX + 50}px`,
        width: `${objDetails.width - 2 * objDetails.paddingX}px`,
        height: `${objDetails.height - 2 * objDetails.paddingY}px`,
        borderRadius: `${(objDetails.radius || 0) + objDetails.paddingY}px`,
    };
}

export function filterTableIdsByShiftAndArea(
    virtualAreas: IRestaurantVirtualArea[],
    areaId: string,
    shiftId: string,
    tableIds: string[],
) {
    const area = virtualAreas.flatMap((v) => v.areas).find((a) => a.id === areaId);
    if (!area) {
        return [];
    }
    const tablePlanId = area.areaShifts.find((as) => as.shiftId === shiftId)?.tablePlanId;
    if (!tablePlanId) {
        return [];
    }
    const tablePlan = area.tablePlans.find((t) => t.id === tablePlanId);
    if (!tablePlan) {
        return [];
    }
    return tableIds.filter((tableId) => tablePlan.tables.find((t) => t.id === tableId) !== undefined);
}
