var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { DatePickerToolbar, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DAY_DATE_FORMAT } from '../../config';
import { InputFieldUtils, StringUtils } from '../../utils';
import { InputField } from '../InputField';
import { Switch } from '../Switch';
export var DatesField = function (props) {
    var _a, _b, _c;
    var _d = useTranslation('core'), i18n = _d.i18n, t = _d.t;
    var ref = React.createRef();
    var _e = useState(false), multiselectEnabled = _e[0], setMultiselectEnabled = _e[1];
    var _f = React.useState(null), date = _f[0], setDate = _f[1];
    var _g = React.useState((_a = props.isOpen) !== null && _a !== void 0 ? _a : false), isOpen = _g[0], setOpen = _g[1];
    var _h = React.useState((_c = (_b = props.value) === null || _b === void 0 ? void 0 : _b.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _c !== void 0 ? _c : []), dateArray = _h[0], setDateArray = _h[1];
    var singleDate = props.singleDate || !multiselectEnabled;
    var handleMultiselectSwitch = function (value) {
        setDate(null);
        setDateArray([]);
        setMultiselectEnabled(value);
    };
    React.useEffect(function () {
        var _a;
        if (props.value) {
            var newDateArray = (_a = props.value.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _a !== void 0 ? _a : [];
            setDateArray(newDateArray);
            setDate(newDateArray.reduce(function (a, b) { return (a < b ? a : b); }, newDateArray[0]));
        }
        else {
            setDate(null);
        }
    }, [props.value]);
    React.useEffect(function () {
        if (props.isOpen !== undefined) {
            setOpen(props.isOpen);
        }
    }, [props.isOpen]);
    var handleOpen = function () {
        if (!singleDate) {
            setDate(null);
        }
        setOpen(true);
    };
    var handleDisableDate = function (value) {
        if (!value || !props.shouldDisableDate) {
            return false;
        }
        return props.shouldDisableDate(value);
    };
    var handleSingleDateChange = function (value) {
        setDateArray([value]);
        setDate(value);
        if (props.onChange) {
            props.onChange([(value === null || value === void 0 ? void 0 : value.isValid) ? value.toFormat(DATE_FORMAT) : '']);
        }
    };
    var handleChange = function (val) {
        var value = val ? val : null;
        if (value) {
            if (singleDate) {
                handleSingleDateChange(value);
                setOpen(false);
            }
            else {
                setDateArray(function (array) {
                    return array.some(function (date) { return date.hasSame(value, 'day'); })
                        ? array.filter(function (date) { return !date.hasSame(value, 'day'); })
                        : __spreadArray(__spreadArray([], array, true), [value], false);
                });
            }
        }
    };
    var handleOk = function () {
        if (props.onChange && !singleDate) {
            if (dateArray === null || dateArray === void 0 ? void 0 : dateArray.length) {
                props.onChange(dateArray.map(function (value) { return ((value === null || value === void 0 ? void 0 : value.isValid) ? value.toFormat(DATE_FORMAT) : ''); }));
            }
            else {
                resetDatePickerValue();
            }
        }
        handleClose(true);
    };
    var handleCancel = function () {
        handleClose(false);
    };
    var resetDatePickerValue = function () {
        var _a, _b;
        setDateArray((_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _b !== void 0 ? _b : []);
    };
    var handleClose = function (valueAccepted) {
        if (valueAccepted === void 0) { valueAccepted = false; }
        if (!valueAccepted) {
            resetDatePickerValue();
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    useEffect(function () {
        if (!isOpen) {
            setDate(getMinimumDateFromDatesArray(dateArray));
        }
    }, [isOpen, dateArray]);
    useEffect(function () {
        if (isOpen && dateArray.length === 1) {
            setMultiselectEnabled(false);
        }
    }, [isOpen]);
    var getPickersDayClassNames = function (_a) {
        var day = _a.day;
        var shouldDisableDate = props.shouldDisableDate && props.shouldDisableDate(day);
        var shouldHighlightDate = props.shouldHighlightDate && props.shouldHighlightDate(day);
        var shouldSelectDate = dateArray.some(function (date) { return date.hasSame(day, 'day'); });
        return StringUtils.combineStrings([
            !shouldDisableDate && shouldHighlightDate
                ? typeof shouldHighlightDate === 'string'
                    ? shouldHighlightDate
                    : 'highlight'
                : '',
            !shouldDisableDate && shouldSelectDate && 'Mui-selected',
        ]);
    };
    return (_jsx(React.Fragment, { children: _jsx(InputField, __assign({ name: "localina-datefield", inputRef: ref, icon: props.icon, helperText: props.helperText, onEnter: props.onEnter }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterLuxon, adapterLocale: i18n.language }, { children: _jsx(MobileDatePicker, { inputRef: ref, open: isOpen, format: DAY_DATE_FORMAT, label: props.label, value: date, minDate: props.minDate, disabled: props.disabled, shouldDisableDate: handleDisableDate, onOpen: handleOpen, onChange: handleChange, closeOnSelect: false, onClose: handleClose, slots: {
                        actionBar: function () { return (_jsx(_Fragment, { children: singleDate ? null : (_jsxs("div", __assign({ className: 'date-picker-action-bar' }, { children: [_jsx(Button, __assign({ onClick: handleCancel }, { children: t('buttons.cancel') })), _jsx(Button, __assign({ onClick: handleOk, disabled: dateArray.length === 0 }, { children: t('buttons.ok') }))] }))) })); },
                        toolbar: props.singleDate
                            ? DatePickerToolbar
                            : function () { return (_jsx(ToolbarMultipleDatesSwitch, { value: multiselectEnabled, onChange: handleMultiselectSwitch })); },
                    }, slotProps: {
                        toolbar: {
                            hidden: false,
                        },
                        day: function (ownerState) { return ({
                            className: getPickersDayClassNames(ownerState),
                            onDaySelect: handleChange,
                        }); },
                        dialog: {
                            className: 'date-picker-dialog',
                        },
                        textField: {
                            variant: 'outlined',
                            autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete),
                            required: props.required,
                            fullWidth: true,
                            inputProps: {
                                placeholder: '',
                            },
                            placeholder: '',
                        },
                    } }) })) })) }));
};
var getMinimumDateFromDatesArray = function (dates) { return dates.reduce(function (a, b) { return (a < b ? a : b); }, dates[0]); };
var ToolbarMultipleDatesSwitch = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    var t = useTranslation('core').t;
    return (_jsx("div", __assign({ className: "date-picker-toolbar" }, { children: _jsx(Switch, { label: t('dateField.buttons.multipleDatesSwitchLabel'), value: value, onChange: onChange }) })));
};
