var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icons } from '../../icons';
export var Icon = function (_a) {
    var name = _a.name, className = _a.className, size = _a.size, onClick = _a.onClick, _b = _a.fillDefault, fillDefault = _b === void 0 ? true : _b;
    var IconComponent = Icons[name];
    var classNames = ['icon', className, size, onClick && 'is-button', fillDefault && 'use-default']
        .filter(function (s) { return !!s; })
        .join(' ');
    return (_jsx("div", __assign({ className: classNames, onClick: onClick }, { children: _jsx(IconComponent, {}) })));
};
