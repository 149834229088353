import {
    DateField,
    DateTimeUtils,
    FormField,
    FormTextField,
    IMessage,
    IMessageDisplayText,
    ISpeedDialActionProps,
    Label,
    List,
    MessageLanguage,
    MessageType,
    SERVER_DATE_FORMAT,
    SpeedDial,
    StringUtils,
    Switch,
    ValidationUtils,
} from '@localina/core';
import { ArticleIcon, DateIcon, LanguageIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';
import { MessageDisplayTextListItem } from '../../../components';
import { useMessageSchema } from '../message-utils';

interface IProps {
    type: MessageType;
    onCreateDisplayText: (language: MessageLanguage) => void;
    onEditDisplayText: (messageDisplayText: IMessageDisplayText & { index: number }) => void;
    isDefaultMessage: boolean;
    setIsDefaultMessage: Dispatch<SetStateAction<boolean>>;
}

export const Message: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const messageSchema = yup.object(useMessageSchema(props.type));
    type TFormValues = InferType<typeof messageSchema>;

    const methods = useFormContext<TFormValues>();
    const [from, displayTexts] = methods.watch(['from', 'displayTexts']);

    useEffect(() => {
        if (props.isDefaultMessage) {
            methods.setValue('from', '', { shouldDirty: true, shouldValidate: true });
            methods.setValue('to', '', { shouldDirty: true, shouldValidate: true });
        }
    }, [props.isDefaultMessage]);

    const createActions: ISpeedDialActionProps[] = Object.values(MessageLanguage).map((lng) => ({
        label: t(`core:languages.${lng.toLowerCase()}`),
        icon: <LanguageIcon />,
        disabled: displayTexts?.some((it) => it.language.toUpperCase() === lng),
        onClick: () => props.onCreateDisplayText(lng),
    }));

    return (
        <>
            <div className="form">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormTextField name="name" label={t('message.fields.name')} icon={<ArticleIcon />} required />
                    </Grid>
                    <Grid item xs={12}>
                        {props.type !== MessageType.EMAIL && (
                            <FormTextField
                                name="link"
                                label={t('message.fields.link')}
                                icon
                                helperText={t('message.helperTexts.link')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Switch
                            label={t('message.fields.validity')}
                            tooltip={t('message.infoText.basicInformation')}
                            value={props.isDefaultMessage}
                            onChange={props.setIsDefaultMessage}
                        />
                    </Grid>
                    <div className="row">
                        <Grid item xs={6} md={4}>
                            <FormField
                                name="from"
                                label={t('message.fields.from')}
                                disabled={Boolean(props.isDefaultMessage)}
                                accepter={DateField}
                                icon={<DateIcon />}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormField
                                name="to"
                                label={t('message.fields.to')}
                                disabled={Boolean(props.isDefaultMessage)}
                                accepter={DateField}
                                icon={<DateIcon />}
                                minDate={from ? DateTime.fromFormat(from, SERVER_DATE_FORMAT) : undefined}
                            />
                        </Grid>
                    </div>
                </Grid>
            </div>
            <div className="message__subheader">
                <Label
                    type="title"
                    variant="h6"
                    value={t('message.languages')}
                    tooltip={t('message.infoText.languages')}
                />
            </div>
            <List>
                {displayTexts?.map((displayText, i) => (
                    <MessageDisplayTextListItem
                        key={i}
                        displayText={displayText}
                        onClick={() => {
                            props.onEditDisplayText({
                                ...displayText,
                                index: i,
                            });
                        }}
                    />
                ))}
            </List>
            <SpeedDial label="create" actions={createActions} />
        </>
    );
};

export const validateMessage = (message: IMessage) => {
    const hasName = message.name !== '';
    if (!hasName) {
        return false;
    }

    const hasValidUrl = !message.link || message.link === '' || StringUtils.isValidUrl(message.link);
    if (!hasValidUrl) {
        return false;
    }

    const { from, to } = message;
    const hasNone = !from && from !== '' && !to && to !== '';
    const hasOnlyFrom = !!from && from !== '' && (!to || to === '');
    const hasOnlyTo = !!to && to !== '' && (!from || from === '');
    const hasBoth = !!from && from !== '' && !!to && to !== '';
    const hasValidDateRange =
        hasNone || hasOnlyFrom || hasOnlyTo || (hasBoth && DateTimeUtils.hasValidDateRange(from, to));
    if (!hasValidDateRange) {
        return false;
    }

    const hasDisplayTexts = message.displayTexts.length !== 0;
    if (!hasDisplayTexts) {
        return false;
    }

    return ValidationUtils.validateObject(message, { optionalFields: ['id', 'link', 'from', 'to'] });
};
