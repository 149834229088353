var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon } from '@localina/icons';
import { ClearIcon, LocalizationProvider, renderMultiSectionDigitalClockTimeView, TimePicker, } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TIME_FORMAT } from '../../config';
import { InputFieldUtils } from '../../utils';
import { InputField } from '../InputField';
export var TimeField = function (props) {
    var _a;
    var _b;
    var i18n = useTranslation().i18n;
    var ref = React.createRef();
    var _c = React.useState(null), time = _c[0], setTime = _c[1];
    var _d = React.useState((_b = props.isOpen) !== null && _b !== void 0 ? _b : false), isOpen = _d[0], setOpen = _d[1];
    React.useEffect(function () {
        var _a;
        if (props.value) {
            var value = DateTime.fromFormat((_a = props.value) !== null && _a !== void 0 ? _a : '', TIME_FORMAT);
            if (value.isValid) {
                setTime(value);
            }
        }
        else {
            setTime(null);
        }
    }, [props.value]);
    React.useEffect(function () {
        if (props.isOpen !== undefined) {
            setOpen(props.isOpen);
        }
    }, [props.isOpen]);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleChange = function (value) {
        setTime(value);
        if (props.onChange) {
            if (value) {
                props.onChange(value.toFormat(TIME_FORMAT));
            }
            else {
                props.onChange('');
            }
        }
    };
    var handleClose = function () {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    return (_jsx(InputField, __assign({ name: "localina-timefield", inputRef: ref, icon: props.icon, helperText: props.helperText, onEnter: props.onEnter }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterLuxon, adapterLocale: i18n.language }, { children: [_jsx(TimePicker, __assign({ inputRef: ref, viewRenderers: {
                        hours: function (props) {
                            return renderMultiSectionDigitalClockTimeView(props);
                        },
                        minutes: function (props) {
                            return renderMultiSectionDigitalClockTimeView(props);
                        },
                    }, open: isOpen, format: TIME_FORMAT, label: props.label, value: time, ampm: false, disabled: props.disabled, views: ['hours', 'minutes'], slotProps: {
                        actionBar: { actions: props.allowClear ? ['clear', 'accept'] : ['accept'] },
                        toolbar: { hidden: false },
                        dialog: {
                            className: 'localina-timefield__timepicker',
                        },
                        popper: {
                            className: 'localina-timefield__timepicker__popper',
                            disablePortal: true,
                        },
                        textField: {
                            required: props.required,
                            autoFocus: isOpen,
                            error: props.error,
                            onBlur: props.onBlur,
                            autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete),
                            variant: 'outlined',
                            fullWidth: true,
                            placeholder: '',
                            inputProps: (_a = {
                                    placeholder: ''
                                },
                                _a['aria-placeholder'] = '',
                                _a),
                        },
                    }, slots: {
                        openPickerIcon: ClockIcon,
                        clearIcon: ClearIcon,
                    }, onOpen: handleOpen, onChange: handleChange, onClose: handleClose }, props.TimePickerProps)), props.disabled && _jsx(ClockIcon, { className: "disabled-clock-icon" })] })) })));
};
