import { IShiftOccupancyStats, Label, TLanguageKey } from '@localina/core';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../../../enums';
import { PathUtils } from '../../../../utils';
import { useRestaurantId } from '../../../../utils/RestaurantUtils';
import { RestaurantStatsChart } from './RestaurantStatsChart';

interface IProps {
    stats?: IShiftOccupancyStats;
    date: string;
    area: string;
}

const RestaurantStatsAreaTile: React.FC<IProps> = (props) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const restaurantId = useRestaurantId();

    const openReservations = () => {
        const date = props.date;
        const areaId = props.area;
        const shiftId = props.stats?.shift.id || '';
        navigate(PathUtils.generateUrl(Path.RESTAURANT_RESERVATIONS, { restaurantId }, { date, areaId, shiftId }));
    };

    return (
        <div className="restaurant-stats__area-tile" onClick={openReservations}>
            <Grid container={true} alignItems={'flex-end'}>
                <Grid item={true} xs={12}>
                    <div className="restaurant-stats__area-tile__charts">
                        {props.stats && (
                            <RestaurantStatsChart {...props.stats} showMiddleTimeIndex={true} showEndTimeIndex={true} />
                        )}
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="restaurant-stats__area-tile__title">
                        <Label type="label" value={props.stats?.shift.name[i18n.language as TLanguageKey]} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default RestaurantStatsAreaTile;
