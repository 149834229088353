import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label } from '@localina/core';

interface IProps {
    onLogin: () => void;
}

export const UserVerified: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const handleClick = () => {
        props.onLogin();
    };

    return (
        <>
            <Label type="title" value={t('resetPassword.title')} variant="h3" />
            <Label type="text" value={t('resetPassword.userVerified')} />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('resetPassword.buttons.login')} onClick={handleClick} />
            </div>
        </>
    );
};
