import { FabButton, IAccount, IAccountRestaurant, InfiniteList, SearchField, StringUtils } from '@localina/core';
import { AddIcon } from '@localina/icons';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAuthUser } from '../../../api/queries/auth';
import { queryKeys } from '../../../api/queries/query-keys';
import {
    useGrantAccountAccess,
    useRegisterAccountFromSupportUser,
    useSupportAccounts,
} from '../../../api/queries/supportAccounts';
import { Page, SupportAccountListItem } from '../../../components';
import EditRestaurantAccountsDrawer from '../../../components/Drawer/EditRestaurantAccountsDrawer';
import EditSingleRestaurantPlanDrawer from '../../../components/Drawer/EditSingleRestaurantPlanDrawer';
import { Path } from '../../../enums';
import { ICreateAuthUser } from '../../../interfaces';
import { ACCOUNT_ID, PathUtils } from '../../../utils';
import RegisterRestaurantAccountDrawer from './RegisterRestaurantAccountDrawer';

const SupportAccounts: React.FC = () => {
    const { t } = useTranslation();
    const [accountsSearch, setAccountsSearch] = React.useState<string>('');
    const [debouncedAccountsSearch, setDebouncedAccountsSearch] = React.useState<string>(accountsSearch);

    const authUserQuery = useGetAuthUser();

    const [isCreateRestaurantAccountDrawerOpen, setIsCreateRestaurantAccountDrawerOpen] = useState(false);

    const grantAccountAccessMutation = useGrantAccountAccess();

    const [selectedAccountId, setSelectedAccountId] = React.useState<string | undefined>(undefined);
    const [selectedRestaurantId, setSelectedRestaurantId] = React.useState<string | undefined>(undefined);

    const queryClient = useQueryClient();

    const accountsQuery = useSupportAccounts({ query: debouncedAccountsSearch });
    const cachedAccountsList = queryClient.getQueryData<ReturnType<typeof useSupportAccounts>['data']>(
        queryKeys.support.accounts(accountsSearch),
    );

    const registerAccount = useRegisterAccountFromSupportUser();

    const selectedAccount = React.useMemo(
        () =>
            selectedAccountId
                ? cachedAccountsList?.pages.flatMap((page) => page.items).find((item) => item.id === selectedAccountId)
                : undefined,
        [selectedAccountId, cachedAccountsList],
    );

    const selectedRestaurant = React.useMemo(
        () =>
            selectedAccount
                ? selectedAccount.restaurants.find((restaurant) => restaurant.id === selectedRestaurantId)
                : undefined,
        [selectedRestaurantId, selectedAccount],
    );

    const isLoading =
        registerAccount.isLoading ||
        grantAccountAccessMutation.isLoading ||
        accountsQuery.isInitialLoading ||
        cachedAccountsList === undefined;

    React.useEffect(() => {
        if (registerAccount.data) {
            closeCreateRestaurantUserDrawer();
            goToAccount(registerAccount.data);
        }
    }, [registerAccount.data]);

    const goToAccount = (item: IAccount, restaurantId?: string) => {
        if (item.restaurants.length !== 0) {
            const accountId = item.id;
            grantAccountAccessMutation.mutate(accountId, {
                onSuccess: () => {
                    if (restaurantId === undefined) {
                        restaurantId = item.restaurants[0].id;
                    }
                    const restaurantUrl = StringUtils.combineStrings(
                        [
                            window.location.origin,
                            PathUtils.generateUrl(
                                Path.RESTAURANT_DASHBOARD,
                                { restaurantId },
                                { [ACCOUNT_ID]: accountId },
                            ),
                        ],
                        '/#',
                    );
                    window.location.replace(restaurantUrl);
                    window.location.reload();
                },
            });
        }
    };

    const handleCloseEditRestaurantDrawer = () => {
        setSelectedRestaurantId(undefined);
    };

    const handleEditRestaurantClick = (restaurantInfo: IAccountRestaurant) => {
        setSelectedRestaurantId(restaurantInfo.id);
    };

    const openCreateRestaurantUserDrawer = () => {
        setIsCreateRestaurantAccountDrawerOpen(true);
    };
    const closeCreateRestaurantUserDrawer = () => {
        setIsCreateRestaurantAccountDrawerOpen(false);
    };

    const onSaveRestaurantAccount = (user: ICreateAuthUser) => {
        if (user) {
            registerAccount.mutate(user);
        }
    };

    const onSaveRestaurantSuccess = () => {
        handleCloseEditRestaurantDrawer();
        return accountsQuery.refetch();
    };

    return (
        <Page
            name="support-accounts"
            title={
                authUserQuery.data?.hasSalesRole
                    ? t('common.menu.categories.support.menu.salesAccounts')
                    : t('supportAccounts.title')
            }
            isLoading={isLoading}
        >
            <div id="accounts">
                <div className="accounts-filters">
                    <SearchField
                        label={t('supportAccounts.filter.search')}
                        value={accountsSearch}
                        onChange={setAccountsSearch}
                        onSearch={setDebouncedAccountsSearch}
                    />
                </div>
                <InfiniteList
                    scrollableTarget="accounts"
                    hasMore={Boolean(accountsQuery.hasNextPage)}
                    items={(cachedAccountsList?.pages || accountsQuery.data?.pages || []).flatMap((page) =>
                        page.items.map((item) => (
                            <SupportAccountListItem
                                key={item.id}
                                account={item}
                                onGoToAccountClick={goToAccount}
                                onClick={() => {
                                    setSelectedAccountId(item.id);
                                }}
                            />
                        )),
                    )}
                    emptyMessage={accountsQuery.isLoading ? undefined : t('supportAccounts.list.empty')}
                    loadNext={accountsQuery.fetchNextPage}
                />
            </div>
            <EditRestaurantAccountsDrawer
                isOpen={selectedAccount !== undefined}
                onClose={() => {
                    setSelectedAccountId(undefined);
                }}
                account={selectedAccount}
                onEditClick={handleEditRestaurantClick}
            />
            <EditSingleRestaurantPlanDrawer
                isOpen={selectedRestaurant !== undefined}
                onClose={handleCloseEditRestaurantDrawer}
                onSave={onSaveRestaurantSuccess}
                restaurant={selectedRestaurant}
            />
            <RegisterRestaurantAccountDrawer
                open={isCreateRestaurantAccountDrawerOpen}
                onClose={closeCreateRestaurantUserDrawer}
                onSave={onSaveRestaurantAccount}
                hasError={registerAccount.isError}
            />
            {authUserQuery.data?.hasSupportRole && (
                <FabButton icon={<AddIcon />} onClick={openCreateRestaurantUserDrawer} fixed />
            )}
        </Page>
    );
};

export default SupportAccounts;
