import { DATE_FORMAT, IGuestListItem, ISort, Label, ListItem, StringUtils } from '@localina/core';
import { Email, PersonOutline, Phone } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortBy } from '../../enums';
import { GuestStatusIcon } from '../GuestStatusIcon';

interface IProps {
    guest: IGuestListItem;
    sort: ISort;
    onClick: (guest: IGuestListItem) => void;
}

export const GuestListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { guest, numReservations, lastReservationDate: reservationDate } = props.guest;
    const { firstName, lastName, email, guestStatus, phoneNumbers } = guest;
    const titleText = StringUtils.combineStrings(
        props.sort.by === SortBy.FIRST_NAME_LAST_NAME ? [firstName, lastName] : [lastName, firstName],
        ' ',
    );

    const lastReservationDate = reservationDate ? DateTime.fromISO(reservationDate).toFormat(DATE_FORMAT) : null;

    const title = (
        <Grid container={true} className={'title-container'}>
            <Label type="title" value={titleText} />
            <div className="grow" />
            <Label type="info" value={phoneNumbers?.[0]} extraClassName={'phone-number-label'} icon={<Phone />} />
        </Grid>
    );
    const subtitle = (
        <Grid container={true} className={'subtitle-container'}>
            {numReservations > 0 && lastReservationDate && (
                <Label type="info" value={t('guests.list.reservations', { numReservations, lastReservationDate })} />
            )}
            <div className="grow" />
            <Label type="info" value={email} icon={<Email />} />
        </Grid>
    );

    const handleClick = () => {
        props.onClick(props.guest);
    };

    const leftIcon =
        guestStatus === null ? (
            <PersonOutline className="regular-guest" />
        ) : (
            <GuestStatusIcon guestStatus={guestStatus} />
        );

    return <ListItem title={title} subtitle={subtitle} onClick={handleClick} leftIcon={leftIcon} />;
};
