import { IRestaurant } from '@localina/core';
import { DoneIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';
import { Category, ICategoryItem } from './Category';

interface IProps {
    restaurantId: string;
    restaurants: IRestaurant[];
}

export const Restaurants: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const categoryItems: ICategoryItem[] = props.restaurants.map((restaurant) => ({
        title: restaurant.info.name,
        icon: props.restaurantId === restaurant.id ? <DoneIcon /> : undefined,
        url: PathUtils.generateUrl(Path.RESTAURANT_DASHBOARD, { restaurantId: restaurant.id }),
        onClick: () => {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_DASHBOARD, { restaurantId: restaurant.id }));
        },
    }));

    return <Category name="restaurants" title={t('common.menu.categories.restaurants.title')} items={categoryItems} />;
};
