import {
    createEmptyRestaurant,
    Drawer,
    IRestaurant,
    Label,
    Page,
    PlatformName,
    StringUtils,
    useConfirmContext,
} from '@localina/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAuthUser } from '../../api/queries/auth';
import { useActivePlatforms } from '../../api/queries/platforms';
import {
    useCreateRestaurantMutation,
    useGetRestaurants,
    useRestaurantById,
    useUpdateRestaurantMutation,
} from '../../api/queries/restaurants';
import { PlatformsIntegration } from '../IntegrationViews';
import { Availability, Configuration, Done, Info, Start } from './Steps';

interface IProps {
    restaurant?: IRestaurant;
    onClose: () => void;
}

export const RestaurantWizard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [step, setStep] = React.useState(props.restaurant?.info.name ? 2 : 0);
    const [restaurant, setRestaurant] = React.useState(createEmptyRestaurant);

    const authUserQuery = useGetAuthUser();
    const platformsQuery = useActivePlatforms();
    const createRestaurantMutation = useCreateRestaurantMutation();
    const updateRestaurantMutation = useUpdateRestaurantMutation(createRestaurantMutation.data?.id);

    const restaurantsQuery = useGetRestaurants();
    const isInitial =
        restaurantsQuery.data?.restaurants.length === 1 && !restaurantsQuery.data.restaurants[0].info.name;

    const createdRestaurantQuery = useRestaurantById(createRestaurantMutation.data?.id || '', {
        enabled: Boolean(createRestaurantMutation.data?.id),
    });

    const { snackbar } = useConfirmContext();
    const scrollToTop = () => {
        const wizard = document.getElementsByClassName('restaurant-wizard')[0];
        const main = wizard.getElementsByTagName('main')[0];
        main.scrollTop = 0;
    };
    const handleNext = () => {
        setStep((prev) => prev + 1);
        scrollToTop();
    };
    const handleBack = () => {
        setStep(step - 1);
        scrollToTop();
    };

    const isSupportUser = authUserQuery.data?.hasSupportRole;
    const handleClose = () => {
        if (!isInitial || isSupportUser) {
            props.onClose();
        }
    };

    const updatePlatforms = () => {
        updateRestaurantMutation.mutate(
            { platformIds: restaurant.configuration.platforms },
            {
                onSuccess: handleNext,
                onError: () => {
                    snackbar({ msg: t('integrations.items.platforms.error'), severity: 'error' });
                },
            },
        );
    };

    const onChangePlatforms = (newPlatforms: string[]) => {
        setRestaurant((prevState) => ({
            ...prevState,
            configuration: {
                ...prevState.configuration,
                platforms: newPlatforms,
            },
        }));
    };
    const updateRestaurantInfo = () => {
        updateRestaurantMutation.mutate(
            { info: restaurant.info },
            {
                onSuccess: handleNext,
                onError: () => {
                    snackbar({
                        msg: t('wizard.error'),
                        severity: 'error',
                    });
                },
            },
        );
    };

    const createRestaurant = () => {
        createRestaurantMutation.mutate(
            { restaurant },
            {
                onSuccess: handleNext,
                onError: () => {
                    snackbar({
                        msg: t('wizard.error'),
                        severity: 'error',
                    });
                },
            },
        );
    };
    const handleSaveInfo = () => {
        if (isInitial || createRestaurantMutation.isSuccess || restaurant.id) {
            updateRestaurantInfo();
        } else {
            createRestaurant();
        }
    };

    const getPageContent = () => {
        switch (step) {
            case 0:
                return {
                    page: (
                        <Start
                            onNext={handleNext}
                            onClose={props.onClose}
                            message={
                                isInitial
                                    ? 'wizard.messages.start'
                                    : 'wizard.messages.welcomeMessageMultipleRestaurants'
                            }
                        />
                    ),
                    className: 'start',
                    title: t('wizard.titles.start'),
                };
            case 1:
                return {
                    page: <Info restaurant={restaurant} onChange={setRestaurant} onNext={handleSaveInfo} />,
                    className: 'info',
                    title: t('wizard.titles.info'),
                };
            case 2:
                return {
                    page: <Configuration restaurant={restaurant} onBack={handleBack} onNext={handleNext} />,
                    className: 'configuration',
                    title: t('wizard.titles.configuration'),
                };
            case 3:
                return {
                    page: <Availability restaurant={restaurant} onBack={handleBack} onSave={handleNext} />,
                    className: 'availability',
                    title: t('wizard.titles.availability'),
                };
            case 4:
                return {
                    page: (
                        <PlatformsIntegration
                            restaurant={restaurant}
                            setActivatedPlatforms={onChangePlatforms}
                            activatedPlatforms={restaurant.configuration.platforms}
                            wizard={true}
                            buttons={[
                                {
                                    label: t('wizard.buttons.back'),
                                    variant: 'secondary',
                                    onClick: handleBack,
                                },
                                {
                                    label: t('wizard.buttons.next'),
                                    variant: 'primary',
                                    onClick: updatePlatforms,
                                },
                            ]}
                        />
                    ),
                    className: 'platforms',
                    title: t('wizard.titles.platforms'),
                };
            case 5:
                return {
                    page: <Done restaurant={restaurant} onClose={props.onClose} id={restaurant.id || ''} />,
                    className: 'done',
                    title: t('wizard.titles.done'),
                };
            default:
                return { page: undefined, className: undefined };
        }
    };

    const isLoading = createRestaurantMutation.isLoading || updateRestaurantMutation.isLoading;

    React.useEffect(() => {
        const createInitialRestaurant = (): IRestaurant => {
            const emptyRestaurant = createEmptyRestaurant();

            // enable localch platform when creating new restaurant
            const initialPlatformsArray = platformsQuery.data
                ?.filter(({ name }) => name === PlatformName.LOCALCH)
                .map(({ platformId }) => platformId);

            return {
                ...emptyRestaurant,
                configuration: {
                    ...emptyRestaurant.configuration,
                    platforms: initialPlatformsArray || [],
                },
            };
        };

        setRestaurant(props.restaurant ? props.restaurant : createInitialRestaurant());
    }, [props.restaurant, platformsQuery.data]);

    useEffect(() => {
        if (createdRestaurantQuery.data) {
            setRestaurant(createdRestaurantQuery.data);
        }
    }, [createdRestaurantQuery.data]);

    const pageContent = getPageContent();

    return (
        <Drawer
            open
            className={StringUtils.combineStrings(['restaurant-wizard', pageContent.className])}
            position="right"
            onClose={handleClose}
        >
            <Page
                isLoading={isLoading}
                name="restaurant-wizard__page"
                stepperProps={step ? { step, stepsCount: 5 } : undefined}
                title={step ? restaurant.info.name : undefined}
                subtitle={
                    step !== undefined ? (
                        <div className="title-wrapper">
                            <Label type="title" value={pageContent.title} variant={'h5'} />
                        </div>
                    ) : undefined
                }
            >
                {pageContent.page}
            </Page>
        </Drawer>
    );
};

export default RestaurantWizard;
