import { Label, useUrlParams } from '@localina/core';
import { SwitchReservationsTablePlanIcon, SwitchTablePlanReservationsIcon } from '@localina/icons';
import { Button as MuiButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';
import { useRestaurantId } from '../../utils/RestaurantUtils';

interface ITablePlanReservationsSwitchButtonProps {
    switchTo: 'reservations' | 'tableplan';
}

const TablePlanReservationsSwitchButton = (props: ITablePlanReservationsSwitchButtonProps) => {
    const { t } = useTranslation();
    const [params] = useUrlParams();
    const restaurantId = useRestaurantId();

    const date = params.get('date') || '';
    const shiftId = params.get('shiftId') || '';
    const areaId = params.get('areaId') || '';
    const search = params.get('search') || '';

    const toTablePlan = props.switchTo === 'tableplan';

    const linkTo = PathUtils.generateUrl(
        toTablePlan ? Path.RESTAURANT_TABLE_PLAN_ASSIGN_RESERVATIONS : Path.RESTAURANT_RESERVATIONS,
        { restaurantId },
        { date, shiftId, areaId, search },
    );

    return (
        <MuiButton className="table-plan-reservations-switch-button">
            <Link to={linkTo}>
                <div className="wrapper">
                    {toTablePlan ? <SwitchReservationsTablePlanIcon /> : <SwitchTablePlanReservationsIcon />}
                    <Label
                        type={'title'}
                        value={
                            toTablePlan
                                ? t('reservations.switchToTablePlanLabel')
                                : t('reservations.switchToReservationsLabel')
                        }
                        extraClassName={'button-label'}
                    />
                </div>
            </Link>
        </MuiButton>
    );
};

export default TablePlanReservationsSwitchButton;
