import { Button, IRestaurant, Label } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurantById } from '../../../api/queries/restaurants';
import AvailabilityContent from '../../../containers/Availability/AvailabilityContent';

interface IProps {
    restaurant: IRestaurant;
    onSave: () => void;
    onBack: () => void;
}

export const Availability: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const restaurantQuery = useRestaurantById(props.restaurant.id, {
        enabled: Boolean(props.restaurant.id),
    });

    return (
        <>
            {restaurantQuery.data?.configuration.shifts.length === 0 &&
                restaurantQuery.data.configuration.closingDays.length === 0 && (
                    <Label type="text" value={t('wizard.messages.configure')} />
                )}

            <div className={'availability-content-container'}>
                <AvailabilityContent restaurantId={props.restaurant.id} />
            </div>
            <div className="buttons">
                <Button label={t('wizard.buttons.back')} secondary={true} onClick={props.onBack} />
                <Button
                    label={t('wizard.buttons.next')}
                    disabled={props.restaurant.configuration.shifts.length === 0}
                    onClick={props.onSave}
                />
            </div>
        </>
    );
};
