import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '../Drawer';
import { ISaveTablePlanRequest, ITablePlan } from '@localina/core';
import TablePlanCreationDetails from '../TablePlanCreationDetails';

interface IProps {
    tablePlan?: ITablePlan;
    openDialog: boolean | undefined;
    areaId?: string;
    hasError: boolean;
    onSave: (tablePlan?: ISaveTablePlanRequest) => void;
    onClose: () => void;
    onDelete?: () => void;
}

export const TablePlanCreationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [actionRequested, setActionRequested] = React.useState(false);
    const [isFormValid, setFormValid] = React.useState(false);
    const [tablePlanDetails, setTablePlanDetails] = useState<ISaveTablePlanRequest>({
        areaId: props.areaId || '',
        id: props.tablePlan?.id || '',
        name: props.tablePlan?.name || '',
        graphic: props.tablePlan ? new File([], props.tablePlan.graphicFilePath) : undefined,
    });
    useEffect(() => {
        if (!props.openDialog) {
            return;
        }
        setTablePlanDetails({
            areaId: props.areaId || '',
            id: props.tablePlan?.id || '',
            name: props.tablePlan?.name || '',
            graphic: props.tablePlan?.graphicFilePath ? new File([], props.tablePlan.graphicFilePath) : undefined,
        });
    }, [props.tablePlan, props.areaId, props.openDialog]);
    React.useEffect(() => {
        setActionRequested(false);
        validateAreaFields();
    }, [props.tablePlan]);

    const handleClose = () => {
        props.onClose();
    };

    const handleSave = () => {
        setActionRequested(true);
        props.onSave(tablePlanDetails);
    };

    const handleDelete = () => {
        if (props.tablePlan && props.onDelete) {
            setActionRequested(true);
            props.onDelete();
        }
    };

    const validateAreaFields = () => {
        return props.tablePlan?.name !== '' && props.areaId !== '' ? setFormValid(true) : setFormValid(false);
    };

    return (
        <Drawer
            open={!!props.openDialog}
            title={t('table.plans.create.table.plan')}
            error={{
                isVisible: actionRequested && props.hasError,
                message: t('table.plans.create.error'),
            }}
            disabled={!isFormValid}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={props.tablePlan?.id ? handleDelete : undefined}
        >
            <TablePlanCreationDetails tablePlanDetails={tablePlanDetails} onChange={setTablePlanDetails} />
        </Drawer>
    );
};
