export var MessageType;
(function (MessageType) {
    MessageType["WELCOME"] = "WELCOME";
    MessageType["GOODBYE"] = "GOODBYE";
    MessageType["EMAIL"] = "EMAIL";
    MessageType["CONFIRMATION_MAIL"] = "CONFIRMATION_MAIL";
})(MessageType || (MessageType = {}));
export var MessageLanguage;
(function (MessageLanguage) {
    MessageLanguage["EN"] = "EN";
    MessageLanguage["DE"] = "DE";
    MessageLanguage["IT"] = "IT";
    MessageLanguage["FR"] = "FR";
})(MessageLanguage || (MessageLanguage = {}));
export var MessageLanguageLowercase;
(function (MessageLanguageLowercase) {
    MessageLanguageLowercase["EN"] = "en";
    MessageLanguageLowercase["DE"] = "de";
    MessageLanguageLowercase["IT"] = "it";
    MessageLanguageLowercase["FR"] = "fr";
})(MessageLanguageLowercase || (MessageLanguageLowercase = {}));
