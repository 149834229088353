import { IconButton, Label, Spinner } from '@localina/core';
import { UploadIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useGuestsImport, useGuestsImportPreview } from '../../api/queries/guests';
import { queryKeys } from '../../api/queries/query-keys';
import { Drawer } from '../Drawer';

interface IProps {
    restaurantId: string;
    onStart: () => void;
    onClose: () => void;
}

export const GuestsImportView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1,
        multiple: false,
        noClick: true,
        onDropAccepted: (acceptedFiles: File[]) => {
            if (acceptedFiles.length) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                importGuestsPreviewMutation.mutate(selectedFile);
            }
        },
    });

    const queryClient = useQueryClient();

    const importGuestsPreviewMutation = useGuestsImportPreview();
    const importGuestsMutation = useGuestsImport();

    const [file, setFile] = React.useState<File | undefined>(undefined);

    const handleClose = () => {
        importGuestsPreviewMutation.reset();
        importGuestsMutation.reset();
        props.onClose();
    };

    const handleSave = () => {
        if (file && importGuestsPreviewMutation.data && importGuestsPreviewMutation.data.numberOfValidImports) {
            importGuestsMutation.mutate(file, {
                onSuccess: () => {
                    return queryClient.invalidateQueries(queryKeys.restaurants.single.guests.all(props.restaurantId));
                },
            });
            props.onStart();
        }
    };

    const isLoading = importGuestsPreviewMutation.isLoading || importGuestsMutation.isLoading;
    const isError = importGuestsPreviewMutation.isError || importGuestsMutation.isError;

    return (
        <Drawer
            className="guestsimport-view"
            open={true}
            title={t('guests.import.title')}
            disabled={!importGuestsPreviewMutation.data || !importGuestsPreviewMutation.data.numberOfValidImports}
            error={{
                isVisible: isError,
                message: t('guests.import.error'),
            }}
            onClose={handleClose}
            onSave={importGuestsMutation.data === undefined ? handleSave : undefined}
        >
            <div className="preview-container">
                <div className="upload-container" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-container__content">
                        <Label type="text" value={t('guests.import.fields.upload')} />
                        <Label type="text" value={t('guests.import.fields.accept')} />
                        <IconButton icon={<UploadIcon />} onClick={open} />
                    </div>
                </div>
                {importGuestsPreviewMutation.data && (
                    <div className="preview-info">
                        <Label type="text" value={t('guests.import.fields.info')} />
                        <Grid container={true}>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={t('guests.import.fields.entries')} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={importGuestsPreviewMutation.data.numberOfValidImports} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={t('guests.import.fields.duplicates')} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={importGuestsPreviewMutation.data.numberOfInvalidImports} />
                            </Grid>
                        </Grid>
                        <Label type="text" value={t('guests.import.fields.waiver')} />
                    </div>
                )}
            </div>
            {isLoading && <Spinner />}
        </Drawer>
    );
};
