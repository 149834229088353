import { ILocalizedError } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { LocalinaApiContext } from '../../../index';
import { Path } from '../../enums';
import { IAuthCredentials, IAuthUser, ICreateAuthUser } from '../../interfaces';
import { RoleUtils, SupportUtils } from '../../utils';
import { queryKeys } from './query-keys';

const useGetAuthUser = (options?: TUseGetAuthUserQueryOptions) => {
    return useQuery(
        queryKeys.auth,
        () => {
            return LocalinaApiContext.authApi.getUserInfo();
        },
        { ...options, select: transformAuthUserData },
    );
};

const useLogoutMutation = (options?: UseMutationOptions<void, ILocalizedError>) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: () => {
            return LocalinaApiContext.authApi.logout();
        },
        onSuccess: () => {
            navigate(Path.LOGIN);
            return queryClient.clear();
        },
        ...options,
    });
};
const useLoginMutation = (options?: UseMutationOptions<IAuthUser, ILocalizedError, IAuthCredentials>) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: ({ email, password }) => {
            return LocalinaApiContext.authApi.login(email, password);
        },
        onSuccess: (data) => {
            return queryClient.setQueryData(queryKeys.auth, data);
        },
    });
};
const useRegisterMutation = (options?: UseMutationOptions<void, ILocalizedError, ICreateAuthUser>) => {
    return useMutation({
        ...options,
        mutationFn: (user) => LocalinaApiContext.authApi.register(user),
    });
};
const useSendEmailVerificationMutation = (options?: UseMutationOptions<void, ILocalizedError>) => {
    return useMutation({
        ...options,
        mutationFn: () => {
            return LocalinaApiContext.authApi.sendEmailVerification();
        },
    });
};
const useResetPasswordMutation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            code: string;
            password: string;
        }
    >,
) => {
    return useMutation({
        ...options,
        mutationFn: ({ code, password }) => {
            return LocalinaApiContext.authApi.resetPassword(code, password);
        },
    });
};
const useSendResetPasswordEmailMutation = (options?: UseMutationOptions<void, ILocalizedError, string>) => {
    return useMutation({
        ...options,
        mutationFn: (email) => {
            return LocalinaApiContext.authApi.sendPasswordResetEmail(email);
        },
    });
};
const useVerifyEmailMutation = (options?: UseMutationOptions<void, ILocalizedError, string>) => {
    return useMutation({
        ...options,
        mutationFn: (code) => {
            return LocalinaApiContext.authApi.verifyEmail(code);
        },
    });
};

type TUseGetAuthUserQueryOptions = UseQueryOptions<
    IAuthUser,
    ILocalizedError,
    ReturnType<typeof transformAuthUserData>,
    typeof queryKeys.auth
>;

const transformAuthUserData = (data: IAuthUser) => ({
    user: data,
    isLoggedIn: data.verified,
    hasSupportRole: RoleUtils.hasSupportRole(data),
    hasSalesRole: RoleUtils.hasSalesRole(data),
    canViewRestaurants: RoleUtils.canViewRestaurants(data),
    canViewUsers: RoleUtils.canViewUsers(data),
    accountId: SupportUtils.getSupportAccountId(data),
});

export {
    useGetAuthUser,
    useLogoutMutation,
    useLoginMutation,
    useRegisterMutation,
    useSendEmailVerificationMutation,
    useResetPasswordMutation,
    useSendResetPasswordEmailMutation,
    useVerifyEmailMutation,
};
