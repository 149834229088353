import { Button, Label, TextField } from '@localina/core';
import { LockIcon, PersonIcon } from '@localina/icons';
import { Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    useGetAuthUser,
    useLoginMutation,
    useLogoutMutation,
    useSendEmailVerificationMutation,
} from '../../api/queries/auth';
import { useGetRestaurants } from '../../api/queries/restaurants';
import { useRevokeAccountAccess } from '../../api/queries/supportAccounts';
import { Page } from '../../components';
import { Path } from '../../enums';
import { IAuthCredentials } from '../../interfaces';
import { PathUtils, RoleUtils, SupportUtils } from '../../utils';
import { queryClient } from '../App';

const Login: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const revokeAccountAccessMutation = useRevokeAccountAccess();
    const loginMutation = useLoginMutation();
    const logoutMutation = useLogoutMutation({
        onSuccess: () => {
            return authUserQuery.refetch();
        },
    });

    const [credentials, setCredentials] = React.useState<IAuthCredentials>({
        email: '',
        password: '',
    });
    const authUserQuery = useGetAuthUser({
        enabled: loginMutation.isSuccess,
    });
    const restaurantsQuery = useGetRestaurants({
        enabled: authUserQuery.isSuccess && !authUserQuery.data.user,
    });

    const isFormValid = credentials.email !== '' && credentials.password !== '';

    const hasNoRestaurants =
        authUserQuery.isSuccess && restaurantsQuery.isSuccess && restaurantsQuery.data.restaurants.length === 0;

    const handleRevoke = () => {
        revokeAccountAccessMutation.mutate(undefined, {
            onSuccess: () => {
                navigate(Path.SUPPORT_ACCOUNTS);
                SupportUtils.resetSession();
                window.location.reload();
            },
        });
    };

    React.useEffect(() => {
        if (authUserQuery.isSuccess && authUserQuery.data.isLoggedIn) {
            if (authUserQuery.data.user && (authUserQuery.data.hasSupportRole || authUserQuery.data.hasSalesRole)) {
                if (!authUserQuery.data.accountId) {
                    navigate(Path.SUPPORT_ACCOUNTS);
                }
            }
        }
    }, [authUserQuery.isSuccess]);

    React.useEffect(() => {
        if (authUserQuery.isSuccess && authUserQuery.data.isLoggedIn) {
            if (restaurantsQuery.isSuccess && restaurantsQuery.data.restaurants.length) {
                const restaurantId = restaurantsQuery.data.restaurants[0].id;
                navigate(PathUtils.generateUrl(Path.RESTAURANT_DASHBOARD, { restaurantId }));
            } else if (
                restaurantsQuery.isSuccess &&
                !restaurantsQuery.data.restaurants.length &&
                (authUserQuery.data.hasSupportRole || authUserQuery.data.hasSalesRole)
            ) {
                handleRevoke();
            } else if (restaurantsQuery.isError) {
                logoutMutation.mutate();
            }
        }
    }, [authUserQuery.status, restaurantsQuery.status]);

    const handleEmailChange = (value: string) => {
        setCredentials({ ...credentials, email: value });
    };

    const handlePasswordChange = (value: string) => {
        setCredentials({ ...credentials, password: value });
    };

    const handleLoginClick = async () => {
        if (isFormValid) {
            await queryClient.resetQueries();

            loginMutation.mutate(credentials, {
                onSuccess: (user) => {
                    if (!(RoleUtils.hasSupportRole(user) || RoleUtils.hasSalesRole(user))) {
                        void restaurantsQuery.refetch();
                    }
                },
            });
        }
    };

    const sendEmailVerificationMutation = useSendEmailVerificationMutation();

    const isLoading = loginMutation.isLoading || logoutMutation.isLoading || revokeAccountAccessMutation.isLoading;

    const ErrorMessage = () => {
        if (loginMutation.isError) {
            return <Label type="text" value={t('login.errors.invalidCredentials')} error />;
        }
        if (hasNoRestaurants || (restaurantsQuery.isError && restaurantsQuery.error?.status === 403)) {
            return <Label type="text" value={t('login.errors.noRestaurants')} error />;
        }
        if (restaurantsQuery.isError) {
            return <Label type="text" value={t('login.errors.somethingWentWrong')} error />;
        }
        if (authUserQuery.isSuccess && !authUserQuery.data.isLoggedIn) {
            return (
                <Label
                    type="text"
                    value={t('login.errors.pendingVerification')}
                    error
                    onClick={sendEmailVerificationMutation.mutate}
                />
            );
        }
        return null;
    };

    return (
        <Page name="login" isLoading={isLoading}>
            <div className="login__content">
                <Typography className="login__title">{t('login.title')}</Typography>
                <TextField
                    label={t('login.fields.email')}
                    name="email"
                    autoComplete="username"
                    type="email"
                    shrink
                    validate={false}
                    icon={<PersonIcon />}
                    value={credentials.email}
                    onChange={handleEmailChange}
                    onEnter={handleLoginClick}
                />
                <TextField
                    label={t('login.fields.password')}
                    name="password"
                    autoComplete="current-password"
                    type="password"
                    shrink
                    validate={false}
                    icon={<LockIcon />}
                    value={credentials.password}
                    onChange={handlePasswordChange}
                    onEnter={handleLoginClick}
                />
                <Label
                    type="label"
                    icon
                    value={
                        <Trans i18nKey="login.buttons.resetPassword">
                            Forgotten your password? Request a new password <Link to={Path.RESET_PASSWORD}>here</Link> .
                        </Trans>
                    }
                />
                <ErrorMessage />
                <div className="buttons">
                    <Button label={t('login.buttons.login')} disabled={!isFormValid} onClick={handleLoginClick} />
                </div>
                <Label
                    type="text"
                    value={
                        <Trans i18nKey="login.buttons.register">
                            New here? <Link to={Path.REGISTER}>Create a new user account</Link> and enter your
                            restaurant.
                        </Trans>
                    }
                />
            </div>
        </Page>
    );
};

export default Login;
