export var defaultShiftNames = {
    breakfast: {
        de: 'Morgen',
        en: 'Morning',
        fr: 'Matin',
        it: 'Mattina',
    },
    brunch: {
        de: 'Brunch',
        en: 'Brunch',
        fr: 'Brunch',
        it: 'Brunch',
    },
    lunch: {
        de: 'Mittag',
        en: 'Noon',
        fr: 'Midi',
        it: 'Mezzogiorno',
    },
    afternoon: {
        de: 'Nachmittag',
        en: 'Afternoon',
        fr: 'Après-midi',
        it: 'Pomeriggio',
    },
    dinner: {
        de: 'Abend',
        en: 'Evening',
        fr: 'Soir',
        it: 'Sera',
    },
    wholeDay: {
        de: 'Ganzer Tag',
        en: 'Whole day',
        fr: 'Toute la journée',
        it: 'Giornata intera',
    },
};
export var defaultShiftTimes = {
    breakfast: {
        from: '06:00',
        to: '10:00',
    },
    brunch: {
        from: '09:00',
        to: '13:00',
    },
    lunch: {
        from: '11:00',
        to: '14:00',
    },
    afternoon: {
        from: '14:00',
        to: '17:00',
    },
    dinner: {
        from: '17:00',
        to: '22:00',
    },
    wholeDay: {
        from: '09:00',
        to: '23:00',
    },
};
