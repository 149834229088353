import { Button, Label } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    onNext: () => void;
    onClose: () => void;
    message: string;
}

export const Start: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Label type="text" value={t(props.message)} align="center" />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('wizard.buttons.start')} onClick={props.onNext} />
            </div>
        </>
    );
};
