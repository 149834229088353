import {
    ButtonGroup,
    DATE_FORMAT,
    FormAutocomplete,
    FormField,
    FormTextField,
    GuestStatus,
    IGuestInfo,
    IGuestListItem,
    IGuestSearchFields,
    Label,
    StringUtils,
    Switch,
    TIME_FORMAT,
    useConfirmContext,
} from '@localina/core';
import { CookieIcon } from '@localina/icons';
import { CommentOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGuest, useGuestList } from '../../api/queries/guests';
import { queryKeys } from '../../api/queries/query-keys';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { Path, SortBy } from '../../enums';
import { PathUtils } from '../../utils';
import { UserFeature } from '../UserFeature';
import { GuestOption } from './Fragments';

interface IProps {
    onSelectGuest: (guest: IGuestInfo, phoneNumber: string) => void;
    restaurantId: string;
    isEditing?: boolean;
    guestId?: string;
}

function GuestInfoFormFields(props: IProps) {
    const { t } = useTranslation();
    const [autocompleteFieldName, setAutocompleteFieldName] = useState('');
    const [filter, setFilter] = useState({ query: '', field: SortBy.FIRST_NAME_LAST_NAME as IGuestSearchFields });
    const [guestId, setGuestId] = useState<string>(props.guestId || '');
    const navigate = useNavigate();

    const { getValues, watch, setValue, trigger, formState } = useFormContext();
    const { dirtyFields, touchedFields } = formState;
    const { confirm } = useConfirmContext();
    const { closeReservationForm, closeNotifications } = useReservationFormContext();

    const [sendEmailReminder, sendSmsReminder, reminderDate, email, phone] = watch([
        'sendEmailReminder',
        'sendSmsReminder',
        'reminderDate',
        'email',
        'phoneNumber',
    ]);

    const queryClient = useQueryClient();

    const guestQuery = useGuest(guestId, props.restaurantId, {
        enabled: Boolean(props.guestId),
    });

    const displayGuestsAutocomplete = filter.query?.length >= 3;
    const guestsListQuery = useGuestList(filter, props.restaurantId, {
        enabled: displayGuestsAutocomplete,
    });
    const onAutocompleteFieldFocus = (name: string, value: string) => {
        if (autocompleteFieldName !== name) {
            setFilter({
                query: value,
                field: name === 'lastName' ? SortBy.FIRST_NAME_LAST_NAME : SortBy.PHONE,
            });
            setAutocompleteFieldName(name);
        }
    };

    const salutationOptions = [
        {
            value: 'MALE',
            label: t('guest.details.view.fields.salutation.male'),
        },
        {
            value: 'FEMALE',
            label: t('guest.details.view.fields.salutation.female'),
        },
        {
            value: 'OTHER',
            label: t('guest.details.view.fields.salutation.other'),
        },
    ];
    const guestStatusOptions = [
        {
            value: GuestStatus.VIP,
            label: t('guest.details.view.fields.guestStatus.vip'),
        },
        {
            value: GuestStatus.BLACKLIST,
            label: t('guest.details.view.fields.guestStatus.blacklist'),
        },
    ];

    const confirmNavigationToGuest = async () => {
        if (
            (await confirm({
                title: t('reservations.view.fields.confirmRedirectingToGuestMessage'),
            })) === 'yes'
        ) {
            navigate(
                PathUtils.generateUrl(Path.RESTAURANT_GUEST, {
                    restaurantId: props.restaurantId,
                    guestId,
                }),
            );
            closeReservationForm();
            closeNotifications();
        }
    };

    const phoneNumberGetOptionLabel = (val: IGuestListItem) => {
        const phoneQuery = clearSpacesFromText(filter.query);
        return val.guest.phoneNumbers.find((pn) => pn.startsWith(phoneQuery)) || '';
    };

    const handleSelectGuest = (value: IGuestListItem, phoneNumber: string) => {
        queryClient.setQueryData(queryKeys.restaurants.single.guests.single(props.restaurantId, value.guest.id), value);
        props.onSelectGuest(value.guest, phoneNumber);
        setGuestId(value.guest.id);
    };

    const formValue = getValues();

    const isGuestDataChanged = useMemo(() => {
        const guest = guestQuery.data?.guest;
        if (guest && formValue) {
            return !(
                formValue.email === guest.email &&
                formValue.firstName === guest.firstName &&
                formValue.lastName === guest.lastName &&
                ((!formValue.phoneNumber && !guest.phoneNumbers.length) ||
                    (formValue.phoneNumber &&
                        guest.phoneNumbers.includes(clearSpacesFromText(formValue.phoneNumber as string))))
            );
        }
        return true;
    }, [formValue, guestQuery.data]);

    useEffect(() => {
        if (props.guestId) {
            setGuestId(props.guestId);
        }
    }, [props.guestId]);

    useEffect(() => {
        void trigger('sendConfirmationMail');
        void trigger('sendEmailReminder');
    }, [Boolean(email)]);

    useEffect(() => {
        if (dirtyFields.email && email && !touchedFields.sendEmailReminder) {
            setValue('sendEmailReminder', true);
        }
    }, [Boolean(dirtyFields.email)]);

    useEffect(() => {
        void trigger('sendSmsReminder');
    }, [Boolean(phone)]);

    return (
        <>
            <UserFeature filter="guestStatus">
                <FormField
                    name="guestStatus"
                    className="guest-info-guest-status"
                    accepter={ButtonGroup}
                    fullWidth
                    options={guestStatusOptions}
                    enableDeselect
                />
            </UserFeature>
            <FormField
                name="salutation"
                className="guest-info-salutation"
                accepter={ButtonGroup}
                fullWidth
                options={salutationOptions}
            />
            {Boolean(guestId) && (
                <div
                    className={StringUtils.combineStrings([
                        'existing-guest-info',
                        isGuestDataChanged && !guestQuery.isInitialLoading && 'hidden',
                    ])}
                >
                    <Label
                        type="text"
                        extraClassName="total-visits-count"
                        value={
                            <>
                                {t('reservations.view.fields.totalVisitsCount', {
                                    count: guestQuery.data?.numReservations,
                                })}
                                {guestQuery.isLoading && <CircularProgress size={12} />}
                            </>
                        }
                    />
                    <Label
                        type="link"
                        extraClassName="open-guest-info-link"
                        onClick={confirmNavigationToGuest}
                        value={t('reservations.view.fields.openGuestInDatabase')}
                    />
                </div>
            )}
            <FormAutocomplete
                loading={guestsListQuery.isInitialLoading}
                getOptionLabel={(option) => option.guest.lastName}
                options={
                    displayGuestsAutocomplete && guestsListQuery.isSuccess
                        ? guestsListQuery.data.pages.flatMap((page) => page.items)
                        : []
                }
                name="lastName"
                required
                label={t('guest.details.view.fields.lastName')}
                onSearch={(q) => {
                    setFilter({
                        query: q,
                        field: 'FIRST_NAME_LAST_NAME',
                    });
                }}
                onSelect={(val) => {
                    if (val) {
                        const phoneNumber = val.guest.phoneNumbers.length ? val.guest.phoneNumbers[0] : '';
                        handleSelectGuest(val, phoneNumber);
                    }
                }}
                renderOption={(option) => {
                    return (
                        <GuestOption
                            email={option.guest.email || ''}
                            phone={option.guest.phoneNumbers.length === 0 ? '' : option.guest.phoneNumbers[0]}
                            name={StringUtils.combineStrings([option.guest.lastName, option.guest.firstName], ', ')}
                            guestStatus={option.guest.guestStatus}
                            reservationsCount={option.numReservations}
                        />
                    );
                }}
                type="text"
                onFocus={onAutocompleteFieldFocus}
            />
            <FormTextField name="firstName" label={t('guest.details.view.fields.firstName')} />
            <FormTextField name="company" label={t('guest.details.view.fields.company')} />
            <FormTextField name="email" label={t('guest.details.view.fields.email')} disabled={props.isEditing} />
            {Boolean(!reminderDate) && (
                <FormField
                    accepter={Switch}
                    className="reservation-view-switch"
                    label={t('guest.details.view.fields.sendEmailReminder')}
                    name="sendEmailReminder"
                    type="boolean"
                    injectErrorMessage
                />
            )}
            {Boolean(reminderDate) && typeof reminderDate === 'string' && sendEmailReminder && (
                <Label
                    type={'text'}
                    value={t('guest.details.view.fields.emailReminderSent', {
                        date: DateTime.fromISO(reminderDate).toFormat(DATE_FORMAT),
                        time: DateTime.fromISO(reminderDate).toFormat(TIME_FORMAT),
                    })}
                />
            )}
            <FormAutocomplete
                loading={guestsListQuery.isLoading}
                getOptionLabel={phoneNumberGetOptionLabel}
                options={
                    displayGuestsAutocomplete && guestsListQuery.isSuccess
                        ? guestsListQuery.data.pages.flatMap((page) => page.items)
                        : []
                }
                name="phoneNumber"
                type="phone"
                label={t('guest.details.view.fields.phoneNumber')}
                onSearch={(q) => {
                    setFilter({
                        query: q,
                        field: 'PHONE',
                    });
                }}
                onSelect={(val) => {
                    if (val) {
                        const targetPhoneNumber = phoneNumberGetOptionLabel(val);
                        handleSelectGuest(val, targetPhoneNumber);
                    }
                }}
                renderOption={(option) => {
                    return (
                        <GuestOption
                            email={option.guest.email || ''}
                            phone={
                                option.guest.phoneNumbers.find((pn) => pn.startsWith(filter.query)) ||
                                option.guest.phoneNumbers[0] ||
                                ''
                            }
                            guestStatus={option.guest.guestStatus}
                            name={StringUtils.combineStrings([option.guest.firstName, option.guest.lastName], ', ')}
                            reservationsCount={option.numReservations}
                        />
                    );
                }}
                onFocus={onAutocompleteFieldFocus}
            />
            <UserFeature filter="smsReminder">
                {Boolean(!reminderDate) && (
                    <FormField
                        accepter={Switch}
                        className="reservation-view-switch"
                        label={t('guest.details.view.fields.sendSmsReminder')}
                        name="sendSmsReminder"
                        type="boolean"
                        injectErrorMessage
                    />
                )}
                {Boolean(reminderDate) && typeof reminderDate === 'string' && sendSmsReminder && (
                    <Label
                        type={'text'}
                        value={t('guest.details.view.fields.smsReminderSent', {
                            date: DateTime.fromISO(reminderDate).toFormat(DATE_FORMAT),
                            time: DateTime.fromISO(reminderDate).toFormat(TIME_FORMAT),
                        })}
                    />
                )}
            </UserFeature>
            <FormTextField
                name="allergies"
                label={t('guest.details.view.fields.allergies')}
                multiline
                icon={<CookieIcon />}
            />
            <FormTextField
                name="restaurantComment"
                label={t('guest.details.view.fields.restaurantComment')}
                multiline
                icon={<CommentOutlined />}
            />
        </>
    );
}

const clearSpacesFromText = (text: string) => text.replaceAll(' ', '');

export default GuestInfoFormFields;
