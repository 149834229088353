import { ILocalizedError, ISalesStatisticsList } from '@localina/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { GenericAbortSignal } from 'axios';
import { LocalinaApiContext } from '../../../index';
import { queryKeys } from './query-keys';
import { getSlicedListPageParam } from './utils';

interface ISalesStatisticsFilters {
    query?: string;
    dateFrom?: string;
    dateTo?: string;
}

type TPageParam = {
    page: number;
    limit: number;
};

function useSalesStatistics(
    filter: ISalesStatisticsFilters = {},
    options?: UseInfiniteQueryOptions<
        ISalesStatisticsList,
        ILocalizedError,
        ISalesStatisticsList,
        ISalesStatisticsList,
        ReturnType<typeof queryKeys.support.statistics>
    >,
) {
    return useInfiniteQuery({
        queryFn: ({
            pageParam = { page: 0, limit: 20 },
            signal,
        }: {
            pageParam?: TPageParam;
            signal?: GenericAbortSignal;
        }) => {
            return LocalinaApiContext.supportApi.getStatisticsList(pageParam.page, pageParam.limit, filter, signal);
        },
        queryKey: queryKeys.support.statistics(filter),
        ...options,
        getNextPageParam: getSlicedListPageParam.next,
        getPreviousPageParam: getSlicedListPageParam.prev,
        staleTime: 5 * 60 * 1000,
    });
}

export { useSalesStatistics };

export type { ISalesStatisticsFilters };
