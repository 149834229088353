var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@localina/icons';
import { Alert, Dialog as MuiDialog, DialogContent, DialogTitle, IconButton, Snackbar, } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Label } from '../Label';
import { ConfirmWithTextCopyDialog } from './ConfirmWIthTextCopyDialog';
import { ConfirmContext } from './context';
export function ConfirmDialogProvider(props) {
    var _this = this;
    var t = useTranslation('core').t;
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    var _b = useState(''), message = _b[0], setMessage = _b[1];
    var _c = useState(''), title = _c[0], setTitle = _c[1];
    var _d = useState(''), dialogClassName = _d[0], setDialogClassName = _d[1];
    var _f = useState(t('prompt.no')), modalCancelLabel = _f[0], setModalCancelLabel = _f[1];
    var _g = useState(t('prompt.yes')), modalConfirmLabel = _g[0], setModalConfirmLabel = _g[1];
    var _h = useState(false), openSnackbar = _h[0], setOpenSnackbar = _h[1];
    var _j = useState(''), snackbarMessage = _j[0], setSnackbarMessage = _j[1];
    var _k = useState('info'), snackbarSeverity = _k[0], setSnackbarSeverity = _k[1];
    var _l = useState(2000), autohideDuration = _l[0], setAutohideDuration = _l[1];
    var _m = useState(false), openAlert = _m[0], setOpenAlert = _m[1];
    var _o = useState(''), alertMessage = _o[0], setAlertMessage = _o[1];
    var _p = useState(''), alertTitle = _p[0], setAlertTitle = _p[1];
    var _q = useState({
        open: false,
        value: '',
        type: 'email',
    }), copyOrLinkDialog = _q[0], setCopyOrLinkDialog = _q[1];
    var _r = useState({
        open: false,
        textToCopy: '',
    }), confirmWithTextCopyDialog = _r[0], setConfirmWithTextCopyDialog = _r[1];
    var resolver = useRef();
    var closeAlert = useCallback(function () {
        setOpenAlert(false);
    }, []);
    var closeSnackbar = useCallback(function () {
        setOpenSnackbar(false);
    }, []);
    var closeCopyOrLinkDialog = function () {
        setCopyOrLinkDialog(function (prevState) { return (__assign(__assign({}, prevState), { open: false })); });
    };
    var handleOpenSnackbar = useCallback(function (params) {
        setOpenSnackbar(true);
        setAutohideDuration(params.autoHideDuration || 2000);
        setSnackbarMessage(params.msg || '');
        setSnackbarSeverity(params.severity || 'info');
    }, []);
    var onConfirm = function () {
        if (resolver.current) {
            resolver.current('yes');
        }
        setConfirmWithTextCopyDialog(function (prevState) { return (__assign(__assign({}, prevState), { open: false })); });
    };
    var onClose = function () {
        if (resolver.current) {
            resolver.current('cancel');
        }
        setConfirmWithTextCopyDialog(function (prevState) { return (__assign(__assign({}, prevState), { open: false })); });
    };
    var onCancel = function () {
        if (resolver.current) {
            resolver.current('no');
        }
        setConfirmWithTextCopyDialog(function (prevState) { return (__assign(__assign({}, prevState), { open: false })); });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, { message: message, open: openModal, cancelLabel: modalCancelLabel, confirmLabel: modalConfirmLabel, title: title || '', className: dialogClassName, onConfirm: function () {
                    if (resolver.current) {
                        resolver.current('yes');
                    }
                    setOpenModal(false);
                }, onClose: function () {
                    if (resolver.current) {
                        resolver.current('cancel');
                    }
                    setOpenModal(false);
                }, onCancel: function () {
                    if (resolver.current) {
                        resolver.current('no');
                    }
                    setOpenModal(false);
                } }), _jsx(ConfirmWithTextCopyDialog, __assign({}, confirmWithTextCopyDialog, { onConfirm: onConfirm, onClose: onClose, onCancel: onCancel })), _jsx(CopyOrLinkDialog, { open: copyOrLinkDialog.open, value: copyOrLinkDialog.value, type: copyOrLinkDialog.type, onClose: closeCopyOrLinkDialog, openSnackbar: handleOpenSnackbar }), _jsx(SnackbarForm, { open: openSnackbar, autohideDuration: autohideDuration, message: snackbarMessage, severity: snackbarSeverity, onClose: closeSnackbar }), _jsx(AlertForm, { onClose: closeAlert, open: openAlert, title: alertTitle, message: alertMessage }), _jsx(ConfirmContext.Provider, __assign({ value: {
                    confirm: function (confirmObj) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setMessage(confirmObj.msg || '');
                            setTitle(confirmObj.title || '');
                            setDialogClassName(confirmObj.dialogClassName || '');
                            setModalCancelLabel(confirmObj.cancelLabel || t('prompt.no'));
                            setModalConfirmLabel(confirmObj.confirmLabel || t('prompt.yes'));
                            setOpenModal(true);
                            return [2 /*return*/, new Promise(function (resolve) {
                                    resolver.current = resolve;
                                })];
                        });
                    }); },
                    confirmWithTextCopy: function (params) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setConfirmWithTextCopyDialog(__assign(__assign({}, params), { open: true }));
                            return [2 /*return*/, new Promise(function (resolve) {
                                    resolver.current = resolve;
                                })];
                        });
                    }); },
                    snackbar: handleOpenSnackbar,
                    alert: function (params) {
                        setOpenAlert(true);
                        setAlertMessage(params.msg || '');
                        setAlertTitle(params.title || '');
                    },
                    copyOrLinkDialog: function (params) {
                        setCopyOrLinkDialog(__assign(__assign({}, params), { open: true }));
                    },
                } }, { children: props.children }))] }));
}
function SnackbarForm(props) {
    return (_jsx(Snackbar, __assign({ open: props.open, autoHideDuration: props.autohideDuration, onClose: function (_e, reason) {
            if (reason === 'clickaway') {
                return;
            }
            props.onClose();
        }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }, { children: _jsx(Alert, __assign({ onClose: props.onClose, elevation: 6, variant: "filled", severity: props.severity, sx: { width: '100%' } }, { children: _jsx(Label, { type: "text", value: props.message }) })) })));
}
function AlertForm(props) {
    return (_jsxs(MuiDialog, __assign({ open: props.open, onClose: props.onClose }, { children: [_jsxs(DialogTitle, __assign({ sx: {
                    minHeight: '20px',
                } }, { children: [_jsx(Label, { type: "title", value: props.title, variant: "h6" }), _jsx(IconButton, __assign({ "aria-label": "close", onClick: props.onClose, sx: {
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, __assign({ dividers: true }, { children: _jsx(Label, { type: "text", value: props.message }) }))] })));
}
function CopyOrLinkDialog(props) {
    var _this = this;
    var t = useTranslation('core').t;
    var handleCopy = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, navigator.clipboard.writeText(props.value).then(function () {
                        props.openSnackbar({
                            msg: t('prompt.copyOrLinkDialog.message.copySuccess'),
                            severity: 'success',
                        });
                    })];
                case 1:
                    _a.sent();
                    props.onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var getLink = function () {
        switch (props.type) {
            case 'email':
                return "mailto:".concat(props.value);
            case 'phoneNumber':
                return "tel:".concat(props.value);
        }
    };
    return (_jsxs(MuiDialog, __assign({ className: "localina-copyOrLinkDialog", open: props.open, onClose: props.onClose }, { children: [_jsx(Label, { type: "text", value: t("prompt.copyOrLinkDialog.title.".concat(props.type)) }), _jsx(Label, { type: "text", value: t("prompt.copyOrLinkDialog.message.".concat(props.type), { value: props.value }) }), _jsxs("div", __assign({ className: 'buttons' }, { children: [_jsx(Button, { label: t('prompt.copyOrLinkDialog.buttons.close'), secondary: true, onClick: props.onClose }), _jsx(Button, { label: t("prompt.copyOrLinkDialog.buttons.copy.".concat(props.type)), onClick: handleCopy }), _jsx("a", __assign({ href: getLink() }, { children: _jsx(Button, { label: t("prompt.copyOrLinkDialog.buttons.link.".concat(props.type)), onClick: props.onClose }) }))] }))] })));
}
