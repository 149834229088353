import { Checkbox, IReservation, ReservationStatus, SERVER_DATE_FORMAT, Spinner, StringUtils } from '@localina/core';
import { CancelIcon, DisabledIcon, DoneIcon, QuestionIcon, RingBellIcon, UnpublishedIcon } from '@localina/icons';
import { Check } from '@mui/icons-material';
import { DateTime } from 'luxon';
import React from 'react';
import { useUpdateReservationStatus } from '../../api/queries/reservations';

interface IReservationStatusActionIconProps {
    reservation: IReservation;
    className?: string;
}

const ReservationStatusActionIcon = ({ reservation, className }: IReservationStatusActionIconProps) => {
    const updateReservationStatusMutation = useUpdateReservationStatus();

    const handleStatusChange = (status: ReservationStatus) => () => {
        updateReservationStatusMutation.mutate({
            reservationDate: DateTime.fromISO(reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT),
            status,
            reservationId: reservation.id,
        });
    };

    const setReservationStatusFulfilled = handleStatusChange(ReservationStatus.FULFILLED);
    const setReservationStatusApproved = handleStatusChange(ReservationStatus.APPROVED);

    const iconIsActionButton = [ReservationStatus.APPROVED, ReservationStatus.APPROVAL_PENDING].includes(
        reservation.status,
    );

    return (
        <>
            <div
                className={StringUtils.combineStrings(['reservation__status', className])}
                onClick={(e) => {
                    if (iconIsActionButton) {
                        e.stopPropagation();
                    }
                }}
            >
                {reservation.status === ReservationStatus.APPROVED && (
                    <Checkbox
                        uncheckedIcon={<RingBellIcon size="big" />}
                        checkedIcon={<Check />}
                        onChange={setReservationStatusFulfilled}
                    />
                )}
                {reservation.status === ReservationStatus.APPROVAL_PENDING && (
                    <Checkbox
                        uncheckedIcon={<DoneIcon size={'big'} />}
                        checkedIcon={<RingBellIcon size="big" />}
                        onChange={setReservationStatusApproved}
                    />
                )}
                {!iconIsActionButton && (
                    <ReservationStatusIcon status={reservation.status} isWalkin={!reservation.guestInfo} />
                )}
            </div>
            {updateReservationStatusMutation.isLoading && <Spinner />}
        </>
    );
};

interface IReservationStatusIconProps {
    status: ReservationStatus;
    isWalkin?: boolean;
}

const ReservationStatusIcon = ({ status, isWalkin }: IReservationStatusIconProps) => {
    if (isWalkin) {
        return <DoneIcon />;
    }
    switch (status) {
        case ReservationStatus.APPROVAL_PENDING:
            return <QuestionIcon />;
        case ReservationStatus.APPROVED:
            return <RingBellIcon />;
        case ReservationStatus.FULFILLED:
            return <DoneIcon />;
        case ReservationStatus.NO_SHOW:
            return <UnpublishedIcon />;
        case ReservationStatus.GUEST_CANCELLED:
            return <CancelIcon />;
        case ReservationStatus.RESTAURANT_CANCELLED:
            return <DisabledIcon />;
        default:
            return <></>;
    }
};

export { ReservationStatusIcon };

export default ReservationStatusActionIcon;
