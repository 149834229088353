import { Label } from '@localina/core';
import { ArrowLeftIcon } from '@localina/icons';
import React from 'react';

export interface ITitleProps {
    value: string;
    icon?: React.ReactNode;
    onBack?: () => void;
    tooltip?: string;
}

export const PageTitle: React.FC<ITitleProps> = (props) => (
    <div className="page-title">
        <div className="page-title__content">
            <div className="page-title__content__button">
                {props.onBack && <ArrowLeftIcon onClick={props.onBack} />}
            </div>
            <div className="page-title__content__title">
                {props.icon}
                <Label type="title" value={props.value} variant="h5" tooltip={props.tooltip} />
            </div>
        </div>
    </div>
);
