import { IGuestInfo, IGuestListItem, Label, StringUtils } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GuestStatusIcon } from '../../GuestStatusIcon';

interface IProps {
    name: string;
    phone: string;
    email: string;
    guestStatus?: IGuestInfo['guestStatus'];
    reservationsCount?: IGuestListItem['numReservations'];
}

export function GuestOption(props: IProps) {
    const { t } = useTranslation();
    const infoText = StringUtils.combineStrings([props.phone, props.email], ', ');

    return (
        <div className="guest-option-wrapper">
            <div className="row">
                <Label
                    type="label"
                    icon={props.guestStatus !== null ? <GuestStatusIcon guestStatus={props.guestStatus} /> : undefined}
                    value={<span className="highlight">{props.name}</span>}
                />
                {Boolean(props.reservationsCount) && (
                    <Label
                        type="info"
                        value={t('reservations.view.fields.totalVisitsCount', { count: props.reservationsCount })}
                    />
                )}
            </div>
            <Label type="info" value={infoText} />
        </div>
    );
}
