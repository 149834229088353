import { useEffect, useState } from 'react';

const useLocalStorageCollapseState = (localStorageKey: string) => {
    const [filtersCollapsed, setFiltersCollapsed] = useState(() => {
        return Boolean(window.localStorage.getItem(localStorageKey) === CollapseStateValues.Collapsed);
    });

    useEffect(() => {
        window.localStorage.setItem(
            localStorageKey,
            filtersCollapsed ? CollapseStateValues.Collapsed : CollapseStateValues.Expanded,
        );
    }, [filtersCollapsed, localStorageKey]);

    return [filtersCollapsed, setFiltersCollapsed] as const;
};

enum CollapseStateValues {
    Collapsed = 'collapsed',
    Expanded = 'expanded',
}

export { useLocalStorageCollapseState };
