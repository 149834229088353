import { yupResolver } from '@hookform/resolvers/yup';
import {
    ButtonGroup,
    FormField,
    IButtonGroupOption,
    ISupportAccountRestaurant,
    Label,
    RestaurantStatus,
    RestaurantSubscription,
    RestaurantUtils,
    Spinner,
    Switch,
    TAdditionalFeature,
    useConfirmContext,
} from '@localina/core';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Schema } from 'yup';
import { useUpdateRestaurantSubscription } from '../../api/queries/supportAccounts';
import { IUpdateRestaurantSubscriptionRequest } from '../../interfaces';
import { getRestaurantPlanIcon, getRestaurantTestIcon } from '../../utils';
import ButtonGroupContentWithIcon from '../ButtonGroupContentWithIcon/ButtonGroupContentWithIcon';
import { Drawer } from '../index';
import DeleteDatabaseSection from './DeleteDatabaseSection';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    restaurant?: ISupportAccountRestaurant;
}

type TAccountRestaurantEditForm = Omit<IUpdateRestaurantSubscriptionRequest, 'restaurantId'>;

const EditSingleRestaurantPlanDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { confirm, snackbar, alert } = useConfirmContext();

    const updateRestaurantSubscription = useUpdateRestaurantSubscription();
    const toggleFeature = (features: TAdditionalFeature[], toggledFeature: TAdditionalFeature) => {
        if (features?.includes(toggledFeature)) {
            return features.filter((feature) => toggledFeature !== feature);
        } else {
            return features ? [...features, toggledFeature] : [toggledFeature];
        }
    };

    const handleSubmit = async (values: TAccountRestaurantEditForm) => {
        if (
            props.restaurant &&
            (await confirm({
                title: t('supportAccounts.saveRestaurantDialog.title'),
                msg: t('supportAccounts.saveRestaurantDialog.message'),
            })) === 'yes'
        ) {
            updateRestaurantSubscription.mutate(
                {
                    restaurantId: props.restaurant.id,
                    ...values,
                },
                {
                    onSuccess: props.onSave,
                    onError: () => {
                        snackbar({
                            msg: t('supportAccounts.listOfRestaurants.editRestaurant.error'),
                            severity: 'error',
                        });
                    },
                },
            );
        }
    };

    const planOptions: IButtonGroupOption[] = [
        {
            label: (
                <ButtonGroupContentWithIcon icon={getRestaurantPlanIcon(RestaurantSubscription.FREE)} label="Free" />
            ),
            value: RestaurantSubscription.FREE,
        },
        {
            label: (
                <ButtonGroupContentWithIcon
                    icon={getRestaurantPlanIcon(RestaurantSubscription.STANDARD)}
                    label="Standard"
                />
            ),
            value: RestaurantSubscription.STANDARD,
        },
    ];
    const testOptions: IButtonGroupOption[] = [
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantTestIcon(RestaurantStatus.ACTIVE)} label="Active" />,
            value: RestaurantStatus.ACTIVE,
        },
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantTestIcon(RestaurantStatus.TEST)} label="Test" />,
            value: RestaurantStatus.TEST,
        },
        {
            label: (
                <ButtonGroupContentWithIcon icon={getRestaurantTestIcon(RestaurantStatus.INACTIVE)} label="Inactive" />
            ),
            value: RestaurantStatus.INACTIVE,
        },
    ];

    const editAccountRestaurantSchema: Record<keyof TAccountRestaurantEditForm, Schema> = {
        restaurantStatus: yup.string().required().oneOf(Object.values(RestaurantStatus)),
        subscription: yup.string().required().oneOf(Object.values(RestaurantSubscription)),
        additionalFeatures: yup.array().of(yup.string<TAdditionalFeature>()),
    };

    const methods = useForm<TAccountRestaurantEditForm>({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(editAccountRestaurantSchema)),
        defaultValues: {
            restaurantStatus: props.restaurant?.restaurantStatus,
            subscription: props.restaurant?.subscription,
            additionalFeatures: props.restaurant?.features,
        },
    });
    const { watch } = methods;
    const { isDirty, isValid } = methods.formState;

    const [subscription] = watch(['subscription']);

    useEffect(() => {
        if (subscription === RestaurantSubscription.FREE) {
            methods.setValue('additionalFeatures', [], { shouldDirty: true });
        }
    }, [subscription]);

    useEffect(() => {
        if (props.restaurant) {
            methods.reset({
                restaurantStatus: props.restaurant.restaurantStatus,
                subscription: props.restaurant.subscription,
                additionalFeatures: props.restaurant.features,
            });
        }
    }, [props.restaurant]);

    const isLoading = updateRestaurantSubscription.isLoading;

    return (
        <Drawer
            className="edit-restaurant-plan-drawer"
            open={props.isOpen}
            title={t('supportAccounts.listOfRestaurants.editRestaurant.title')}
            disabled={!(isDirty && isValid)}
            onClose={props.onClose}
            onSave={methods.handleSubmit(handleSubmit)}
        >
            <div className="edit-single-account-restaurant">
                {!!props.restaurant && (
                    <>
                        <Label type={'title'} value={props.restaurant.name} variant="h4" />
                        <Label
                            type={'text'}
                            value={RestaurantUtils.formatRestaurantAddress(props.restaurant.restaurantInfo)}
                        />
                    </>
                )}
                <FormProvider {...methods}>
                    <form>
                        <div className="version-title">
                            <Label
                                type={'title'}
                                value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.subscription')}
                                variant="h5"
                            />
                        </div>
                        <FormField
                            name="subscription"
                            accepter={ButtonGroup}
                            fullWidth
                            options={planOptions}
                            enableDeselect
                        />
                        <div className="version-title">
                            <Label
                                type={'title'}
                                value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.test')}
                                variant="h5"
                            />
                        </div>
                        <FormField
                            name="restaurantStatus"
                            accepter={ButtonGroup}
                            fullWidth
                            options={testOptions}
                            enableDeselect
                        />
                        <div className="version-title">
                            <Label
                                type={'title'}
                                value={t(
                                    'supportAccounts.listOfRestaurants.editRestaurant.labels.additionalFeatures.title',
                                )}
                                variant="h5"
                            />
                        </div>
                        <Controller
                            name={'additionalFeatures'}
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <Switch
                                    label={t(
                                        'supportAccounts.listOfRestaurants.editRestaurant.labels.additionalFeatures.requestMode',
                                    )}
                                    checked={value?.includes('requestMode')}
                                    onChange={() => {
                                        if (subscription === RestaurantSubscription.FREE) {
                                            alert({
                                                msg: t('common.features.requestMode'),
                                                title: t('common.features.missingFeatureTitle'),
                                            });
                                        } else {
                                            onChange(toggleFeature(value, 'requestMode'));
                                        }
                                    }}
                                />
                            )}
                        />
                    </form>
                </FormProvider>
                <DeleteDatabaseSection restaurant={props.restaurant} />
            </div>
            {isLoading && <Spinner />}
        </Drawer>
    );
};

export default EditSingleRestaurantPlanDrawer;
