import { DeviceUtils, IRestaurantArea, Label, useConfirmContext } from '@localina/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAreas, useUpdateAreaOrderingMutation } from '../../../api/queries/restaurantAreas';
import { useRestaurant } from '../../../api/queries/restaurants';
import { useSortableItemsWrapperUtils } from '../../../utils/SortableItemsUtils';
import { AreaListItem } from './AreaItem';

interface IProps {
    editRestaurantArea: (restaurantArea: IRestaurantArea, virtualAreaId: string) => void;
}

const Areas: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const restaurantQuery = useRestaurant();
    const areaListQuery = useAreas();
    const updateAreaOrderingMutation = useUpdateAreaOrderingMutation();

    const [isMobile, setMobile] = React.useState(DeviceUtils.isMobile());

    React.useEffect(() => {
        const checkIsMobile = () => setMobile(DeviceUtils.isMobile());
        window.addEventListener('resize', checkIsMobile, true);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const { snackbar } = useConfirmContext();

    const [areaList, setAreaList] = useState(areaListQuery.data?.areas || []);

    const { moveItem, findItemIndex } = useSortableItemsWrapperUtils<
        IRestaurantArea & {
            virtualAreaId: string;
        }
    >(areaList, setAreaList);

    useEffect(() => {
        if (areaListQuery.data) {
            setAreaList(areaListQuery.data.areas);
        }
    }, [areaListQuery.data]);

    useEffect(() => {
        // Setup initial area ordering list for restaurants that didn't use area orderings
        if (
            restaurantQuery.data &&
            areaListQuery.data &&
            !restaurantQuery.data.configuration.orderings.areas &&
            updateAreaOrderingMutation.isIdle
        ) {
            updateAreaOrderingMutation.mutate(areaListQuery.data?.areas.map((a) => a.id));
        }
    }, [Boolean(restaurantQuery.data), Boolean(restaurantQuery.data?.configuration.orderings?.areas)]);

    const handleDrop = () => {
        updateAreaOrderingMutation.mutate(
            areaList.map(({ id }) => id),
            {
                onError: () => {
                    snackbar({ severity: 'error', msg: t('sectors.updateAreasOrder.error') });
                    if (areaListQuery.data) {
                        setAreaList(areaListQuery.data.areas);
                    }
                },
            },
        );
    };

    return (
        <div className="restaurant-sectors-areas">
            <Label type="title" value={t('sectors.title')} variant={isMobile ? 'h4' : 'h6'} align="left" />
            <div>
                {areaList.map((area, index) => (
                    <AreaListItem
                        key={area.id}
                        area={area}
                        onClick={props.editRestaurantArea}
                        virtualAreaId={area.virtualAreaId}
                        moveItem={moveItem}
                        findItemIndex={findItemIndex}
                        index={index}
                        onDrop={handleDrop}
                    />
                ))}
            </div>
        </div>
    );
};

export default Areas;
