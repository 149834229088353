import { PlatformName } from '../enums';
var getLocalizedPlatformName = function (platformName) {
    if (!platformName) {
        return 'MyLOCALINA';
    }
    switch (platformName) {
        case PlatformName.GOOGLE:
            return 'Google';
        case PlatformName.LOCALCH:
            return 'local.ch';
        default:
            return platformName;
    }
};
export var PlatformUtils = {
    getLocalizedPlatformName: getLocalizedPlatformName,
};
