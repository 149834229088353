import { IRestaurant, RESERVATION_CHANGE_TABLE_DROP_TYPE, RESERVATION_DROP_TYPE, TLanguageKey } from '@localina/core';
import { TableIcon } from '@localina/icons';
import { ListSubheader, Menu as MuiMenu, MenuItem, MenuItemProps } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { IRestaurantShiftTablePlansPreview } from '../../../interfaces/store/restaurant/IRestaurantShiftTablePlansPreview';

interface ITablePlansMenuProps {
    shiftTablePlans: IRestaurantShiftTablePlansPreview[];
    selectedTablePlanId?: string;
    selectedShift?: IRestaurant['configuration']['shifts'][0];
    onTablePlanChange: (id: string) => void;
}

interface IOption {
    content: string;
    value: string;
    onClick?: () => void;
    header?: boolean;
    key?: string;
    menuItemProps?: MenuItemProps;
}

const TablePlansMenu = (props: ITablePlansMenuProps) => {
    const { i18n } = useTranslation();

    const anchorElRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpen = () => {
        setAnchorEl(anchorElRef.current);
    };

    const [isOver, drop] = useReservationDropTarget();

    const selected = props.shiftTablePlans
        .flatMap((s) => s.tablePlans)
        .find((tp) => tp.id === props.selectedTablePlanId);

    const showShifts = props.selectedShift === undefined;

    const onTablePlanMenuItemClick = useCallback(
        (optionId: string) => () => {
            props.onTablePlanChange(optionId);
            handleClose();
        },
        [props.onTablePlanChange],
    );

    const menuOptions: IOption[] = props.shiftTablePlans.flatMap((shift) => {
        const options = shift.tablePlans.map((option) => {
            const optionKey = `${shift.shiftId}.${option.id}`;
            return {
                value: option.id,
                content: `${option.areaName} / ${option.name}`,
                onClick: onTablePlanMenuItemClick(option.id),
                key: showShifts ? optionKey : undefined,
            };
        });
        if (showShifts) {
            return [
                {
                    value: shift.shiftId,
                    header: true,
                    content: shift.shiftName[i18n.language as TLanguageKey],
                },
                ...options,
            ];
        }
        return options;
    });

    const toggleMenuOnHoverTimeout = useRef<number>();

    useEffect(() => {
        clearTimeout(toggleMenuOnHoverTimeout.current);
        if (isOver) {
            toggleMenuOnHoverTimeout.current = window.setTimeout(() => {
                if (isOver) {
                    setAnchorEl(anchorElRef.current);
                }
            }, 300);
        } else {
            toggleMenuOnHoverTimeout.current = window.setTimeout(() => {
                if (!isOver) {
                    setAnchorEl(null);
                }
            }, 200);
        }
        return () => {
            clearTimeout(toggleMenuOnHoverTimeout.current);
        };
    }, [isOver]);

    return (
        <div ref={drop} className="menu-container">
            <div ref={anchorElRef} onClick={handleOpen}>
                <div className="flexRow">
                    <TableIcon empty={props.shiftTablePlans.length === 0} className="icon" />
                    {selected && <div className="flexRow">{`${selected.areaName} / ${selected.name}`}</div>}
                </div>
            </div>
            {Boolean(menuOptions.length) && (
                <MuiMenu
                    open={anchorEl !== null}
                    // open
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                    disablePortal
                    variant={'menu'}
                >
                    {menuOptions.map((option) => {
                        if (option.header) {
                            return (
                                <ListSubheader key={option.key || option.value} onClick={option.onClick}>
                                    {option.content}
                                </ListSubheader>
                            );
                        }
                        return (
                            <TablePlanMenuItem
                                key={option.key || option.value}
                                option={option}
                                closeMenu={handleClose}
                            />
                        );
                    })}
                </MuiMenu>
            )}
        </div>
    );
};

interface ITablePlanMenuItemProps {
    option: IOption;
    closeMenu: () => void;
}

const TablePlanMenuItem = (props: ITablePlanMenuItemProps) => {
    const [isOver, drop, isDragging] = useReservationDropTarget({ onDrop: props.option.onClick });
    const timeoutRef = useRef<number>();
    const focusTimeoutRef = useRef<number>();

    const [triggerFocus, setTriggerFocus] = useState(false);

    useEffect(() => {
        if (isOver) {
            timeoutRef.current = window.setTimeout(() => {
                setTriggerFocus(true);
            }, 1000);
        } else {
            clearTimeout(timeoutRef.current);
            setTriggerFocus(false);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isOver]);

    useEffect(() => {
        if (triggerFocus) {
            focusTimeoutRef.current = window.setTimeout(() => {
                if (props.option.onClick) {
                    props.option.onClick();
                    setTriggerFocus(false);
                }
            }, 500);
        } else {
            clearTimeout(focusTimeoutRef.current);
        }

        return () => {
            clearTimeout(focusTimeoutRef.current);
        };
    }, [triggerFocus]);

    return (
        <div ref={drop} className={triggerFocus ? 'focus-blinking' : undefined}>
            <MenuItem
                autoFocus={false}
                selected={false}
                sx={{
                    backgroundColor: isOver ? 'rgba(0, 0, 0, 0.04)' : undefined,
                    '&:hover': {
                        backgroundColor: isDragging && !isOver ? 'white' : undefined,
                    },
                }}
                onClick={() => {
                    if (props.option.onClick) {
                        props.option.onClick();
                    }
                    props.closeMenu();
                }}
            >
                {props.option.content}
            </MenuItem>
        </div>
    );
};

interface IUseReservationDropTargetProps {
    onDrop?: () => void;
}

const useReservationDropTarget = (props?: IUseReservationDropTargetProps) => {
    const { isDragging } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
    }));

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: [RESERVATION_DROP_TYPE, RESERVATION_CHANGE_TABLE_DROP_TYPE],
            drop: props?.onDrop,
            collect: (monitor) => ({
                isOver: Boolean(monitor.isOver()),
            }),
        }),
        [],
    );

    return [isOver, drop, isDragging] as const;
};

export default TablePlansMenu;

export { useReservationDropTarget };
