import React, { TouchEvent } from 'react';
import { IAreaOccupancyStats } from '@localina/core';
import { RestaurantStatsAreaTile } from './index';

interface IProps {
    stats: IAreaOccupancyStats;
    shifts: string[];
    date: string;
}

const isolateTouch = (e: TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
};

export const RestaurantStatsShiftTile: React.FC<IProps> = (props) => {
    return (
        <div onTouchMoveCapture={isolateTouch} className="restaurant-stats__date__shift">
            {props.shifts.map((it) =>
                props.stats.shiftOccupancyStats.find((shift) => it == shift.shift.id) ? (
                    <RestaurantStatsAreaTile
                        stats={props.stats.shiftOccupancyStats.find((shift) => it == shift.shift.id)}
                        date={props.date}
                        area={props.stats.area}
                        key={it}
                    />
                ) : (
                    <div key={it} className="restaurant-stats__empty-tile" />
                ),
            )}
        </div>
    );
};
