import { ButtonGroup, IAreaTime, IShiftForDate, Label, ReservationUtils, TLanguageKey } from '@localina/core';
import { RingBellIcon } from '@localina/icons';
import React from 'react';
import { useVirtualAreas } from '../../api/queries/restaurantAreas';
import { sortItemsBySortedIdsArray } from '../../utils/SortableItemsUtils';
import { hasAvailability } from './utils';

interface IReservationSlotsProps {
    shifts: IShiftForDate[];
    language: TLanguageKey;
    availableAreaTimes: IAreaTime[];
    reservationTime: string;
    value: ISingleReservationSlot[];
    participants: number;
    shiftId: string;
    onChange: (val: ISingleReservationSlot[]) => void;
    areasOrderings: string[];
    occupancyTime?: number | undefined;
}

interface ISingleReservationSlot {
    shiftId: string;
    areaId: string;
}

export const ReservationSlots = (props: IReservationSlotsProps) => {
    const virtualAreasQuery = useVirtualAreas();
    const virtualAreasIds = virtualAreasQuery.data?.virtualAreas.map((va) => va.id) || [];

    const handleChange = (val: TAreaTimes) => {
        if (val.length > 1) {
            props.onChange(
                // remove virtual area from selection if other area is chosen
                val.filter(({ areaId }) => !virtualAreasIds.includes(areaId)),
            );
        } else {
            props.onChange(val);
        }
    };

    return (
        <div className={'reservation-slots'}>
            {props.shifts
                .filter(
                    (shift) =>
                        (!props.shiftId || shift.id === props.shiftId) &&
                        props.availableAreaTimes.some((at) => at.shiftId === shift.id),
                )
                .map((shift) => {
                    const areas = props.availableAreaTimes.filter((at) =>
                        shift.areas.some(
                            (area) => area.id === at.areaId && at.shiftId === shift.id && at.timeSlots.length > 0,
                        ),
                    );
                    if (areas.length === 0) {
                        return null;
                    }
                    sortItemsBySortedIdsArray(areas, props.areasOrderings, 'areaId');
                    return (
                        <React.Fragment key={shift.id}>
                            <div className="shift-title-details-container">
                                <Label type="title" value={shift.shift.name[props.language]} />
                                <span className="shift-title-spacer" />
                                <Label type="info" value={`${shift.shift.from}-${shift.shift.to}`} align="center" />
                            </div>
                            <ButtonGroup
                                value={props.value}
                                onChange={handleChange}
                                equalityFn={(element, value) =>
                                    element.shiftId === value.shiftId && element.areaId === value.areaId
                                }
                                options={areas.map((area) => {
                                    const relevantSlot = ReservationUtils.getTimeSlotForReservationTime(
                                        props.availableAreaTimes.find(
                                            (at) => at.areaId === area.areaId && at.shiftId === shift.id,
                                        )?.timeSlots || [],
                                        props.reservationTime,
                                    );
                                    return {
                                        disabled: area.virtualAreaId === area.areaId,
                                        value: { shiftId: shift.id, areaId: area.areaId },
                                        className:
                                            relevantSlot &&
                                            !hasAvailability(
                                                relevantSlot,
                                                props.availableAreaTimes,
                                                props.occupancyTime,
                                                props.participants,
                                                shift.shift,
                                            )
                                                ? 'overbooked'
                                                : '',
                                        label: (
                                            <div className="buttons-group-button-content-container">
                                                {relevantSlot && relevantSlot.availability <= 0 && (
                                                    <div className="complex-button-icon-container">
                                                        <RingBellIcon />
                                                    </div>
                                                )}
                                                <div className="buttons-group-button-content-container-texts">
                                                    <span className="buttons-group-button-content-label">
                                                        {area.areaName}
                                                    </span>
                                                    <span className="buttons-group-button-content-extra-info">
                                                        {relevantSlot?.timeSlot || ''}
                                                    </span>
                                                </div>
                                            </div>
                                        ),
                                    };
                                })}
                            />
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

type TAreaTimes = ISingleReservationSlot[];

export type { ISingleReservationSlot, TAreaTimes };
