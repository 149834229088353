import { DATE_FORMAT, IAreaTime, IAreaTimeSlot, IRestaurantShift, TIME_FORMAT } from '@localina/core';
import { DateTime } from 'luxon';

const hasAvailability = (
    relevantSlot: IAreaTimeSlot,
    availableAreaTimes: IAreaTime[],
    occupancyTime: number | undefined,
    participants: number,
    shift: IRestaurantShift | undefined,
) => {
    if (!shift) {
        return false;
    }
    if (!occupancyTime) {
        occupancyTime = Math.min(shift.expectedOccupancyTime, calculateDifference(relevantSlot.timeSlot, shift.to));
    } else {
        occupancyTime = occupancyTime * 60;
    }
    const shiftId = shift.id;
    const slotInterval = shift.slotInterval;
    const availableAreaTime = availableAreaTimes.find(
        (aat) => aat.shiftId == shiftId && aat.areaId === relevantSlot.areaId,
    );
    for (let i = 0; i < occupancyTime; i += slotInterval) {
        const timeSlot = calculateTimeSlot(relevantSlot.timeSlot, i);
        const availability = availableAreaTime?.timeSlots.find((ts) => ts.timeSlot === timeSlot)?.availability ?? 0;
        if (availability < participants) return false;
    }
    return true;
};

const calculateTimeSlot = (timeSlot: string, seconds: number) => {
    const [hours, mins] = timeSlot.split(':').map(Number);

    const totalMinutes = hours * 60 + mins + seconds / 60;

    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;

    return (newHours < 10 ? '0' : '') + String(newHours) + ':' + (newMinutes < 10 ? '0' : '') + String(newMinutes);
};

const calculateDifference = (from: string, to: string) => {
    const [hours1, mins1] = from.split(':').map(Number);
    const [hours2, mins2] = to.split(':').map(Number);

    const totalMinutes1 = hours1 * 60 + mins1;
    const totalMinutes2 = hours2 * 60 + mins2;

    const timeDifferenceMinutes = totalMinutes2 - totalMinutes1;

    return timeDifferenceMinutes * 60;
};

const addMinutesToTime = (time: string, minutes: number) =>
    DateTime.fromFormat(time, TIME_FORMAT).plus({ minutes }).toFormat(TIME_FORMAT);

const getLowestDate = (date: string[]) => {
    if (date.length === 1) return date[0];
    const dateTimes = date.map((d) => DateTime.fromFormat(d, DATE_FORMAT));
    return dateTimes.reduce((a, b) => (a < b ? a : b), dateTimes[0])?.toFormat(DATE_FORMAT);
};

export { hasAvailability, getLowestDate, addMinutesToTime };
