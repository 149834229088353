import React from 'react';
import Box from '@mui/material/Box';
import { FoodBankIcon } from '@localina/icons';
import Typography from '@mui/material/Typography';
import { Switch } from '@localina/core';
import { IRestaurantAreaClosingDay } from '../../../interfaces';

interface IProps {
    area: IRestaurantAreaClosingDay;
    onSwitchChange: (value: boolean, areaId: string) => void;
}

export const AreaBox: React.FC<IProps> = (props) => {
    return (
        <Box className="area-box-item">
            <FoodBankIcon className="icon-style" />
            <Typography className="typography-style"> {props.area.name}</Typography>
            <Switch
                checked={props.area.closed}
                onChange={(value: boolean) => props.onSwitchChange(value, props.area.id)}
            />
        </Box>
    );
};
