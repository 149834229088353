import { DATE_FORMAT, StringUtils, TextField } from '@localina/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDailyNote, useSaveDailyNote } from '../../api/queries/restaurantDailyNote';

interface IProps {
    date: string;
}

let animationTimer: number;
const ANIMATION_HIGHLIGHT_DURATION = 30 * 1000;

const RestaurantDailyNote = (props: IProps) => {
    const { t } = useTranslation();
    const [noteValue, setNoteValue] = useState('');
    const [isAnimationDisplayed, setIsAnimationDisplayed] = useState(true);

    const dailyNoteQuery = useDailyNote(props.date);
    const saveDailyNote = useSaveDailyNote();

    const handleSaveNote = () => {
        if (dailyNoteQuery.data?.note !== noteValue) {
            saveDailyNote.mutate({ date: props.date, note: noteValue });
        }
    };

    const onFieldFocus = () => {
        setIsAnimationDisplayed(false);
    };

    const isLoading = dailyNoteQuery.isInitialLoading || saveDailyNote.isLoading;
    const isError = dailyNoteQuery.isError || saveDailyNote.isError;

    useEffect(() => {
        setNoteValue(dailyNoteQuery.data?.note || '');
    }, [dailyNoteQuery.data?.note, props.date]);

    useEffect(() => {
        if (dailyNoteQuery.data?.note && dailyNoteQuery.data.note !== saveDailyNote.variables?.note) {
            setIsAnimationDisplayed(true);
        }
    }, [dailyNoteQuery.data?.note]);

    useEffect(() => {
        if (isAnimationDisplayed) {
            clearTimeout(animationTimer);
            animationTimer = window.setTimeout(() => {
                setIsAnimationDisplayed(false);
            }, ANIMATION_HIGHLIGHT_DURATION);
        }
    }, [isAnimationDisplayed]);

    return (
        <div
            className={StringUtils.combineStrings([
                'restaurant_daily_note_wrapper',
                Boolean(noteValue) && 'highlight-border',
                isAnimationDisplayed && 'animated-highlight',
            ])}
            onBlur={handleSaveNote}
        >
            <TextField
                name={'note'}
                label={t('restaurant.dailyNote.label', { date: DateTime.fromISO(props.date).toFormat(DATE_FORMAT) })}
                multiline
                validate={false}
                rows={2}
                value={noteValue}
                onChange={setNoteValue}
                disabled={isLoading}
                hasError={isError}
                errorMessageKey={' '}
                onFocus={onFieldFocus}
            />
        </div>
    );
};

export default RestaurantDailyNote;
