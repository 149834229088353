import React, { useEffect } from 'react';

export function useMove<T extends HTMLElement>(ref: React.RefObject<T>, disabled?: boolean) {
    useEffect(() => {
        if (!ref.current || disabled) {
            return;
        }
        let prevMoveX = 0;
        let prevMoveY = 0;
        let mouseDown = false;

        const onMouseDown = (e: MouseEvent) => {
            e.stopPropagation();
            mouseDown = true;
            prevMoveX = e.clientX;
            prevMoveY = e.clientY;
        };
        const onMouseMove = (e: MouseEvent) => {
            if (!mouseDown) {
                return;
            }
            e.stopPropagation();
            ref.current?.scrollBy(prevMoveX - e.clientX, prevMoveY - e.clientY);
            prevMoveX = e.clientX;
            prevMoveY = e.clientY;
        };
        const onUp = () => {
            mouseDown = false;
        };
        ref.current.addEventListener('mousedown', onMouseDown);
        ref.current.addEventListener('mousemove', onMouseMove);
        ref.current.addEventListener('mouseup', onUp);

        const onTouchStart = (e: TouchEvent) => {
            if (e.touches.length !== 1) {
                return;
            }
            e.stopPropagation();
            prevMoveX = e.touches[0].clientX;
            prevMoveY = e.touches[0].clientY;
        };

        const onTouchMove = (e: TouchEvent) => {
            if (e.touches.length !== 1 || disabled) {
                return;
            }
            e.stopPropagation();
            ref.current?.scrollBy(prevMoveX - e.touches[0].clientX, prevMoveY - e.touches[0].clientY);
            prevMoveX = e.touches[0].clientX;
            prevMoveY = e.touches[0].clientY;
        };

        ref.current.addEventListener('touchstart', onTouchStart);
        ref.current.addEventListener('touchmove', onTouchMove);
        ref.current.addEventListener('touchend', onUp);

        return () => {
            if (!ref.current) {
                return;
            }

            ref.current.removeEventListener('mousedown', onMouseDown);
            ref.current.removeEventListener('mousemove', onMouseMove);
            ref.current.removeEventListener('mouseup', onUp);

            ref.current.removeEventListener('touchstart', onTouchStart);
            ref.current.removeEventListener('touchmove', onTouchMove);
            ref.current.removeEventListener('touchend', onUp);
        };
    }, [ref.current, disabled]);
}
