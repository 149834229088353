var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AsYouType, MuiTelInput } from 'mui-tel-input';
import React from 'react';
export var PhoneNumberField = function (_a) {
    var onChange = _a.onChange, value = _a.value, validateValue = _a.validateValue, props = __rest(_a, ["onChange", "value", "validateValue"]);
    var checkIfNumberExists = function (number) {
        if (number === void 0) { number = ''; }
        var asYouType = new AsYouType();
        asYouType.input(number);
        return !!asYouType.getNumber();
    };
    var fieldIsDirty = React.useRef(checkIfNumberExists(value));
    var handlePhoneChange = function (newPhone, telInputInfo) {
        if (onChange) {
            fieldIsDirty.current = true;
            onChange(telInputInfo.numberValue || newPhone);
        }
    };
    React.useEffect(function () {
        if (validateValue && fieldIsDirty.current && value !== undefined) {
            validateValue(value);
        }
    }, [value]);
    return (_jsx(MuiTelInput, __assign({}, props, { value: value, onChange: handlePhoneChange, defaultCountry: 'CH', preferredCountries: ['CH', 'DE'], MenuProps: {
            PaperProps: {
                style: {
                    maxHeight: 250,
                },
            },
        } })));
};
