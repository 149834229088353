import { ITablePlanObj, TextField } from '@localina/core';
import React, { useEffect, useState } from 'react';
import { Drawer } from './Drawer';
import { useTranslation } from 'react-i18next';

interface IProps {
    tableToEdit?: ITablePlanObj;
    open: boolean;
    onClose: () => void;
    onSave: (tableDetails: ITablePlanObj) => void;
    onDelete: () => void;
    hasError: boolean;
}

export const TablePlanEditorTableDetails: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [tableName, setTableName] = useState<string>('');
    const [numberOfSeats, setNumberOfSeats] = useState<number | undefined>();
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        setTableName(props.tableToEdit?.name || '');
        setNumberOfSeats(props.tableToEdit?.numberOfSeats);
        setDescription(props.tableToEdit?.description || '');
    }, [props.tableToEdit]);

    const isFormValid = (): boolean => {
        return !!tableName && tableName !== '' && !!numberOfSeats && numberOfSeats > 0 && props.tableToEdit?.id !== '';
    };

    const handleChangeTableName = (value: string | number) => {
        if (value.toString().length <= 5) setTableName(`${value}`);
    };

    const handleChangeDescription = (value: string | boolean | number) => {
        if (typeof value === 'string' || typeof value === 'number') {
            if (value.toString().length <= 20) setDescription(`${value}`);
        }
    };

    const buildTablePlanObjDetails = (): ITablePlanObj | undefined => {
        if (isFormValid() && numberOfSeats) {
            return {
                id: props.tableToEdit?.id || '',
                name: tableName,
                numberOfSeats,
                description: description || '',
            };
        }
        return undefined;
    };

    const onSave = () => {
        const tableDetails = buildTablePlanObjDetails();
        if (tableDetails) props.onSave(tableDetails);
    };

    return (
        <Drawer
            className="edit-restaurant-plan-drawer"
            open={props.open}
            title={t('sectors.table.view.title')}
            disabled={!isFormValid()}
            onClose={props.onClose}
            onSave={onSave}
            onDelete={props.onDelete}
            error={{
                isVisible: props.hasError,
                message: t('sectors.table.view.error'),
            }}
        >
            <div className="table-plan-editor-table-details">
                <TextField
                    required={true}
                    label={t('sectors.table.view.fields.name')}
                    name="tableName"
                    value={`${tableName || ''}`}
                    onChange={handleChangeTableName}
                    helperText={t('sectors.table.view.helperText.name')}
                />
                <TextField
                    required={true}
                    label={t('sectors.table.view.fields.seats')}
                    name="numberOfSeats"
                    value={`${numberOfSeats || ''}`}
                    onChange={(value) => setNumberOfSeats(+value)}
                />
                <TextField
                    required={false}
                    label={t('sectors.table.view.fields.description')}
                    name="description"
                    value={description}
                    onChange={handleChangeDescription}
                    helperText={t('sectors.table.view.helperText.description')}
                />
            </div>
        </Drawer>
    );
};
