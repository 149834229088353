import { ILocalizedError } from '@localina/core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';

const useDeleteRestaurantGuestsAndReservations = (
    options?: UseMutationOptions<void, ILocalizedError, { restaurantId: string }>,
) => {
    return useMutation({
        mutationFn: ({ restaurantId }) => {
            return LocalinaApiContext.supportApi.deleteRestaurantGuestsAndReservations(restaurantId);
        },
        ...options,
    });
};

const useDeleteRestaurantReservations = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            restaurantId: string;
        }
    >,
) => {
    return useMutation({
        mutationFn: ({ restaurantId }) => {
            return LocalinaApiContext.supportApi.deleteRestaurantReservations(restaurantId);
        },
        ...options,
    });
};

export { useDeleteRestaurantGuestsAndReservations, useDeleteRestaurantReservations };
