import {
    CANVAS_TABLE_DROP_TYPE,
    IObjTypes,
    RESERVATION_CHANGE_TABLE_DROP_TYPE,
    RESERVATION_DROP_TYPE,
} from '@localina/core';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import type { XYCoord } from 'react-dnd';
import { useDragLayer } from 'react-dnd';
import { queryKeys } from '../../api/queries/query-keys';
import { useReservations } from '../../api/queries/reservations';
import { ReservationItem } from '../../containers/Tableplans/ReservationItem';
import { objDetails } from '../TablePlan/FabricHelper';
import ReservationTouchDndPreview from './ReservationTouchDndPreview';

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none' as const,
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
        MozTransform: transform,
        MsTransform: transform,
    };
}

interface ItemType {
    id: string;
    tableNumbers: string[];
    areaCode: string;
    isVirtualArea: boolean;
    restaurantId: string;
    reservationDateServerFormat: string;
}

export function CustomDndPreview() {
    const { isDragging, item, initialOffset, currentOffset, itemType } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    const queryClient = useQueryClient();

    if (!isDragging) {
        return null;
    }

    const getPreview = () => {
        if (itemType === RESERVATION_DROP_TYPE || itemType === RESERVATION_CHANGE_TABLE_DROP_TYPE) {
            const reservationItem: ItemType = item;
            const reservationsQuery = queryClient.getQueryData<ReturnType<typeof useReservations>['data']>(
                queryKeys.restaurants.single.reservations.onDate(
                    reservationItem.restaurantId,
                    reservationItem.reservationDateServerFormat,
                ),
            );
            const reservation = reservationsQuery?.reservations.find(({ id }) => reservationItem.id === id);
            return (
                reservation && (
                    <>
                        {itemType === RESERVATION_DROP_TYPE && (
                            <ReservationItem
                                reservation={reservation}
                                tableNumbers={reservationItem.tableNumbers}
                                areaCode={reservationItem.areaCode}
                                isVirtualArea={reservationItem.isVirtualArea}
                            />
                        )}
                        {itemType === RESERVATION_CHANGE_TABLE_DROP_TYPE && (
                            <ReservationTouchDndPreview reservation={reservation} />
                        )}
                    </>
                )
            );
        }
        if (itemType === CANVAS_TABLE_DROP_TYPE) {
            const objType = item.objType as IObjTypes;
            return <TableObjPreview objType={objType} />;
        }
        return null;
    };
    return (
        <div className="dnd-preview-container">
            <div className="item" style={getItemStyles(initialOffset, currentOffset)}>
                {getPreview()}
            </div>
        </div>
    );
}

interface IPreviewProps {
    objType: IObjTypes;
}

const TableObjPreview: React.FC<IPreviewProps> = (props) => {
    const details = objDetails[props.objType];
    const styles: React.CSSProperties = {
        width: details.width,
        height: details.height,
        borderRadius: details.radius,
        backgroundColor: details.color,
    };
    if (details.imageUrl) {
        return (
            <div style={styles}>
                <img src={details.imageUrl} alt={details.imageUrl.split('/').at(-1) || ''} />
            </div>
        );
    }
    return <div style={styles}></div>;
};
