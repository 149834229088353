import { UserRole } from '@localina/core';
import { IAuthUser } from '../interfaces';

const hasAdminRole = (user: IAuthUser) => hasRole(user, UserRole.ADMIN);
const hasSupportRole = (user: IAuthUser) => hasRole(user, UserRole.SUPPORT);
const hasSalesRole = (user: IAuthUser) => hasRole(user, UserRole.SALES);

const canViewRestaurants = (user: IAuthUser) => hasAdminRole(user) || hasSupportRole(user) || hasSalesRole(user);
const canViewUsers = (user: IAuthUser) => hasAdminRole(user) || hasSupportRole(user) || hasSalesRole(user);
const hasRole = (user: IAuthUser, role: UserRole) => user.roles.includes(role);

export const RoleUtils = {
    canViewRestaurants,
    canViewUsers,
    hasSupportRole,
    hasSalesRole,
    hasRole,
};
