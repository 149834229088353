export enum ReservationsExportFormat {
    XLSX = 'XLSX',
    XLS = 'XLS',
}

export enum ReservationDetailsType {
    CREATE = 'create',
    EDIT = 'edit',
    WALKIN = 'walkin',
}
