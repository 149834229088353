import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccount } from '../../../api/queries/account';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';
import { Category, ICategoryItem } from './Category';

interface IProps {
    restaurantId: string;
}

const Settings: React.FC<IProps> = ({ restaurantId }) => {
    const { t } = useTranslation();
    const accountQuery = useGetAccount();

    let categoryItems: ICategoryItem[] = [
        {
            title: t('common.menu.categories.settings.menu.reporting'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_REPORTING, { restaurantId }),
            requiredFeature: 'reporting',
        },
        {
            title: t('common.menu.categories.settings.menu.sectors'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_AREAS, { restaurantId }),
            requiredFeature: 'areas',
        },
        {
            title: t('common.menu.categories.settings.menu.availability'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_AVAILABILITY, { restaurantId }),
        },
        {
            title: t('common.menu.categories.settings.menu.table.plan'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_TABLE_PLAN, { restaurantId }),
            requiredFeature: 'tablePlansEdit',
        },
        {
            title: t('common.menu.categories.settings.menu.messages'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGES, { restaurantId }),
        },
        {
            title: t('common.menu.categories.settings.menu.staff'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_STAFF, { restaurantId }),
            requiredFeature: 'staffTagsEdit',
        },
        {
            title: t('common.menu.categories.settings.menu.integrations'),
            url: PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_INTEGRATIONS, { restaurantId }),
        },
    ];

    categoryItems = categoryItems.filter(
        (val) =>
            !val.requiredFeature ||
            (accountQuery.isSuccess && accountQuery.data.hasFeature(val.requiredFeature, restaurantId)),
    );
    return <Category name="settings" title={t('common.menu.categories.settings.title')} items={categoryItems} />;
};

export default Settings;
