import React from 'react';
import { Label } from '@localina/core';

interface IProps {
    icon: React.ReactNode;
    label: string;
}

const ButtonGroupContentWithIcon: React.FC<IProps> = (props) => {
    return (
        <div className="button-group-content-with-icon">
            {props.icon}
            <Label type="info" value={props.label} extraClassName="margin-top"></Label>
        </div>
    );
};

export default ButtonGroupContentWithIcon;
