import { Rest } from '@localina/core';
import { ExportFormat } from '../../enums';
import { ILocalinaTracingApi } from '../../interfaces';
import { TracingApiConstants } from '../../utils';

export class LocalinaTracingApi implements ILocalinaTracingApi {
    async getTracingExport(restaurantId: string, format: ExportFormat): Promise<File> {
        return Rest.getRequest({
            url: TracingApiConstants.getTracingsExport(restaurantId, format).toString(),
            responseType: 'blob',
        });
    }
}
