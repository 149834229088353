import { ITablePlanCanvas, ITablePlanObj, Label, Spinner, useConfirmContext } from '@localina/core';
import { EditIcon } from '@localina/icons';
import { Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { useRestaurant } from '../../api/queries/restaurants';
import {
    useDeleteTableEditorTableDetails,
    useDeleteTablePlan,
    useSaveTableEditorTableDetails,
    useUpdateTablePlan,
} from '../../api/queries/restaurantTablePlans';
import TablePlanEditorElementDetails from '../../components/Drawer/TablePlanEditorElementDetails';
import { TablePlanEditorTableDetails } from '../../components/Drawer/TablePlanEditorTableDetails';
import { TablePlanCanvas } from '../../components/TablePlan/Canvas/TablePlanCanvas';
import { CanvasController } from '../../components/TablePlan/Controllers/CanvasController';
import { objDetails } from '../../components/TablePlan/FabricHelper';
import { TableObjectsView } from '../../components/TablePlan/TableObjectsView';
import { TablePlanCreationView } from '../../components/TablePlanCreationView';
import { Path } from '../../enums';
import { TableObjs } from '../../interfaces/entities/ITablePlan';
import { PathUtils } from '../../utils';
import { getAreaWithTablePlan } from '../../utils/AreasUtils';
import { generateTableName, getTablePlan } from '../../utils/TablePlanService';
import { TablePlanContext } from './context';

const TablePlanEditor: React.FC = () => {
    const tablePlanId = useParams().tablePlanId;
    const { t } = useTranslation();
    const [zoomCanvas, setZoomCanvas] = useState(1);
    const [openEditTablePlane, setOpenEditTablePlan] = useState(false);
    const [isTableObjectsOpen, setIsTableObjectsOpen] = useState(false);
    const [isCanvasModified, setIsCanvasModified] = useState(false);
    const [openEditTable, setOpenEditTable] = useState(false);
    const [openEditNonTableElement, setOpenEditNonTableElement] = useState(false);
    const [selectedCanvasElementId, setSelectedCanvasElementId] = useState('');
    const navigate = useNavigate();
    const { confirm, snackbar } = useConfirmContext();

    const restaurantQuery = useRestaurant();
    const deleteTablePlanMutation = useDeleteTablePlan();
    const updateTablePlanMutation = useUpdateTablePlan();
    const saveTableEditorTableDetails = useSaveTableEditorTableDetails();
    const deleteTableEditorTableDetails = useDeleteTableEditorTableDetails();

    const onMutationError = () => {
        snackbar({
            autoHideDuration: 2000,
            severity: 'error',
            msg: t('common.error'),
        });
    };

    const tablePlan = getTablePlan(restaurantQuery.data?.configuration.virtualAreas || [], tablePlanId);
    const area = getAreaWithTablePlan(restaurantQuery.data?.configuration.virtualAreas || [], tablePlan?.id || '');
    const [canvasState, setCanvasState] = useState<ITablePlanCanvas | undefined>(tablePlan?.canvas);
    const selectedCanvasElement = canvasState?.elements.find((e) => e.id === selectedCanvasElementId);

    useEffect(() => {
        if (!tablePlan?.canvas) {
            setCanvasState(undefined);
            return;
        }
        setCanvasState(tablePlan.canvas);
        setIsCanvasModified(false);
    }, [tablePlan?.canvas]);

    if (!tablePlan || !area) {
        return <Label type="text" value="No table plan" error={true} />;
    }

    const getCanvasIcons = () => {
        if (!selectedCanvasElement) {
            return [];
        }
        const onDelete = () => {
            setCanvasState((prev) => {
                if (!prev) {
                    return prev;
                }
                return {
                    ...prev,
                    elements: prev?.elements.filter((element) => {
                        return element.id !== selectedCanvasElementId;
                    }),
                };
            });
            setSelectedCanvasElementId('');
            setIsCanvasModified(true);
        };
        if (TableObjs.includes(selectedCanvasElement.objType)) {
            return [
                <EditIcon
                    key="edit"
                    onClick={() => {
                        setOpenEditTable(true);
                    }}
                />,
            ];
        }
        if (selectedCanvasElement.objType === 'floor') {
            return [
                <EditIcon
                    key="edit"
                    onClick={() => {
                        setOpenEditNonTableElement(true);
                    }}
                />,
                <Delete key="delete" onClick={onDelete} color="error" />,
            ];
        }
        return [<Delete key="delete" onClick={onDelete} color="error" />];
    };

    const onSave = (canvas: ITablePlanCanvas | undefined, tables: ITablePlanObj[]) => {
        saveTableEditorTableDetails.mutate(
            {
                tablePlanId: tablePlan.id,
                tablePlanDesignRequest: {
                    areaId: area.id,
                    canvas,
                    tables: tables.filter(
                        (table) =>
                            (canvas?.elements || []).find((element) => element.tableId === table.id) !== undefined,
                    ),
                },
            },
            {
                onError: onMutationError,
                onSuccess: () => {
                    setIsCanvasModified(false);
                },
            },
        );
    };

    const tablePlanEditorTableDetailsIsError =
        saveTableEditorTableDetails.isError || deleteTableEditorTableDetails.isError;

    const isLoading =
        deleteTablePlanMutation.isLoading ||
        updateTablePlanMutation.isLoading ||
        saveTableEditorTableDetails.isLoading ||
        deleteTableEditorTableDetails.isLoading;

    return (
        <TablePlanContext.Provider
            value={{
                zoomCanvas,
                onZoomChange: setZoomCanvas,
            }}
        >
            <div className="table-plan-editor">
                <CanvasController
                    tablePlanName={tablePlan.name}
                    onEditClick={() => setOpenEditTablePlan(true)}
                    onDisplayTableObjectsClick={() => setIsTableObjectsOpen(true)}
                    onSaveTablePlanEditorObjs={() => {
                        if (canvasState) {
                            onSave(canvasState, tablePlan.tables);
                        }
                    }}
                    isCanvasModified={isCanvasModified}
                />
                <TablePlanCanvas
                    tables={tablePlan.tables}
                    onElementClick={setSelectedCanvasElementId}
                    canvasState={canvasState}
                    selectedCanvasElement={selectedCanvasElement}
                    getCanvasIcons={getCanvasIcons}
                    onChange={(val) => {
                        setCanvasState((prev) => {
                            if (!prev) {
                                return prev;
                            }
                            return {
                                ...prev,
                                ...val,
                            };
                        });
                        setIsCanvasModified(true);
                    }}
                    onDrop={(obj, width, height) => {
                        let canvas = canvasState ? { ...canvasState } : undefined;
                        if (canvas) {
                            canvas = {
                                id: canvas.id,
                                width,
                                height,
                                elements: [...canvas.elements, obj],
                            };
                        } else {
                            canvas = {
                                id: v4(),
                                elements: [obj],
                                width,
                                height,
                            };
                        }
                        const tables = [...tablePlan.tables];
                        if (obj.tableId) {
                            const defaultOptions = objDetails[obj.objType];
                            tables.push({
                                id: obj.tableId,
                                name: generateTableName(tablePlan),
                                numberOfSeats: defaultOptions.numberOfSeats,
                            });
                            setOpenEditTable(true);
                            setSelectedCanvasElementId(obj.id || '');
                        }
                        onSave(canvas, tables);
                    }}
                />
                <TableObjectsView isOpen={isTableObjectsOpen} onClose={() => setIsTableObjectsOpen(false)} />

                <TablePlanEditorTableDetails
                    tableToEdit={tablePlan.tables.find((table) => table.id === selectedCanvasElement?.tableId)}
                    hasError={tablePlanEditorTableDetailsIsError}
                    onClose={() => {
                        setOpenEditTable(false);
                    }}
                    open={openEditTable}
                    onSave={(tableObj) => {
                        onSave(
                            canvasState,
                            tablePlan.tables.map((table) => {
                                if (table.id === tableObj.id) {
                                    return tableObj;
                                }
                                return table;
                            }),
                        );
                        setOpenEditTable(false);
                    }}
                    onDelete={() => {
                        deleteTableEditorTableDetails.mutate(
                            {
                                tablePlanId: tablePlanId || '',
                                tableId: selectedCanvasElement?.tableId || '',
                            },
                            {
                                onError: onMutationError,
                                onSuccess: () => {
                                    setSelectedCanvasElementId('');
                                    setOpenEditTable(false);
                                },
                            },
                        );
                    }}
                />
                <TablePlanEditorElementDetails
                    open={openEditNonTableElement}
                    onClose={() => {
                        setOpenEditNonTableElement(false);
                    }}
                    canvasElement={canvasState?.elements.find((e) => e.id === selectedCanvasElementId)}
                    onSave={(val) => {
                        if (!canvasState) {
                            return;
                        }
                        onSave(
                            {
                                ...canvasState,
                                elements: canvasState.elements.map((e) => {
                                    if (e.id === selectedCanvasElementId) {
                                        return {
                                            ...e,
                                            ...val,
                                        };
                                    }
                                    return e;
                                }),
                            },
                            tablePlan.tables,
                        );
                        setSelectedCanvasElementId('');
                        setOpenEditNonTableElement(false);
                    }}
                    hasError={saveTableEditorTableDetails.isError}
                    onDelete={() => {
                        if (!canvasState) {
                            return;
                        }
                        onSave(
                            {
                                ...canvasState,
                                elements: canvasState.elements.filter((e) => e !== selectedCanvasElement),
                            },
                            tablePlan.tables,
                        );
                        setSelectedCanvasElementId('');
                        setOpenEditNonTableElement(false);
                    }}
                />
                <TablePlanCreationView
                    hasError={deleteTablePlanMutation.isError}
                    tablePlan={tablePlan}
                    onClose={() => setOpenEditTablePlan(false)}
                    openDialog={openEditTablePlane}
                    onDelete={async () => {
                        const val = await confirm({
                            msg: t('table.plans.delete.confirm'),
                        });
                        if (val === 'yes' && tablePlanId) {
                            deleteTablePlanMutation.mutate(
                                { tablePlanId },
                                {
                                    onError: onMutationError,
                                    onSuccess: () => {
                                        navigate(
                                            PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_TABLE_PLAN, {
                                                restaurantId: restaurantQuery.data?.id || '',
                                            }),
                                        );
                                    },
                                },
                            );
                        }
                    }}
                    onSave={(tp) => {
                        if (tp) {
                            updateTablePlanMutation.mutate(
                                {
                                    tablePlanId: tablePlanId || '',
                                    tablePlan: tp,
                                },
                                {
                                    onError: onMutationError,
                                    onSuccess: () => {
                                        setOpenEditTablePlan(false);
                                    },
                                },
                            );
                        }
                    }}
                    areaId={area.id}
                />
            </div>
            {isLoading && <Spinner />}
        </TablePlanContext.Provider>
    );
};

export default TablePlanEditor;
