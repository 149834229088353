import { Label, Spinner, useConfirmContext } from '@localina/core';
import { DownloadIcon, TrashIcon } from '@localina/icons';
import { AttachFile } from '@mui/icons-material';
import { Button, Dialog as MuiDialog, FormControlLabel } from '@mui/material';
import * as process from 'process';
import React, { MouseEventHandler, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../api/queries/documents';

const ReservationFileUpload = () => {
    const { t } = useTranslation();

    const [fileSrc, setFileSrc] = useState<string | undefined>();
    const [openPreview, setOpenPreview] = useState<boolean>(false);

    const downloadDocument = useDownloadDocument();
    const { snackbar, confirm } = useConfirmContext();

    const { setValue, trigger, watch, formState } = useFormContext();
    const { errors } = formState;
    const error = errors.documents
        ? Array.isArray(errors.documents)
            ? errors.documents[0]
            : errors.documents
        : undefined;

    const documents = watch('documents') || [];

    const isNew = Boolean(documents && documents.length && typeof documents[0] === 'object');
    const documentName: string = documents.length ? (isNew ? documents[0].name : documents[0]) : '';
    const isImage = Boolean(documents && documents.length && extensionIsImage(documentName));

    const openPreviewImageDialog = () => {
        setOpenPreview(true);
    };
    const closePreviewImageDialog = () => {
        setOpenPreview(false);
    };
    const downloadFile = (variables: { url: string; fileName: string }) => {
        downloadDocument.mutate(variables, {
            onError: () => {
                snackbar({ severity: 'error', msg: t('reservations.view.fields.uploadFile.downloadingError') });
            },
            onSuccess: () => {
                snackbar({ severity: 'success', msg: t('reservations.view.fields.uploadFile.downloadStarted') });
            },
        });
    };
    const removeFile = async () => {
        if (
            isNew ||
            (await confirm({
                title: t('reservations.view.fields.uploadFile.removeFileDialog.title'),
                msg: t('reservations.view.fields.uploadFile.removeFileDialog.message'),
            })) === 'yes'
        ) {
            setValue('documents', [], { shouldDirty: true });
            void trigger('documents');
        }
    };

    const onImageThumbnailClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        openPreviewImageDialog();
    };

    const isLoading = downloadDocument.isLoading;

    return (
        <div className="reservation-fileupload">
            <div className="reservation-fileupload__input-wrapper">
                <Controller
                    name={'documents'}
                    render={({ field }) => (
                        <input
                            accept={allowedExtensions}
                            type="file"
                            disabled={isLoading}
                            id="reservation-file-upload"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                const item = e.target.files?.item(0);
                                if (item) {
                                    field.onChange([item]);
                                    setFileSrc(item ? URL.createObjectURL(item) : undefined);
                                }
                            }}
                        />
                    )}
                />
                <FormControlLabel
                    label={t('reservations.view.fields.uploadFile.label')}
                    htmlFor={'reservation-file-upload'}
                    control={
                        !documents.length ? (
                            <Button
                                className="localina-button secondary"
                                component="span"
                                variant="outlined"
                                disableRipple
                                disabled={isLoading}
                            >
                                <AttachFile />
                            </Button>
                        ) : (
                            <div className="preview-file-name">
                                {isImage && (
                                    <div className={'preview-image'} onClick={onImageThumbnailClick}>
                                        <img
                                            src={isNew ? fileSrc : generateFileUrl(documentName)}
                                            alt="reservation-image-upload-preview"
                                        />
                                    </div>
                                )}
                                <Label
                                    type="info"
                                    extraClassName="file-name"
                                    value={truncateFileName(
                                        isNew ? documentName : extractFileNameFromServer(documentName),
                                        isImage ? 15 : 30,
                                    )}
                                />
                                {!isNew && (
                                    <DownloadIcon
                                        className="icon-size"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!isLoading) {
                                                downloadFile({
                                                    url: generateFileUrl(documentName),
                                                    fileName: extractFileNameFromServer(documentName),
                                                });
                                            }
                                        }}
                                    />
                                )}
                                <TrashIcon
                                    className="icon-size"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        void removeFile();
                                    }}
                                />
                            </div>
                        )
                    }
                    labelPlacement="start"
                />
            </div>
            {isLoading && <Spinner />}
            {Boolean(error) && (
                <div className="helper-text">
                    <Label type="info" value={error.message} error />
                </div>
            )}
            <MuiDialog open={openPreview} disablePortal onClose={closePreviewImageDialog} PaperProps={{ elevation: 0 }}>
                <div className="preview-image-dialog-container" onClick={closePreviewImageDialog}>
                    <img src={isNew ? fileSrc : generateFileUrl(documentName)} alt="reservation-image-upload-preview" />
                </div>
            </MuiDialog>
        </div>
    );
};

const MAX_RESERVATION_DOCUMENT_SIZE = 20 * 1000 * 1000; //20MB
const validReservationDocumentExtensions = {
    image: ['jpg', 'png', 'jpeg'],
    document: ['xls', 'doc', 'xlsx', 'docx', 'csv', 'pdf'],
};

const extensionIsImage = (fileName?: string) => {
    if (fileName) {
        const extension = fileName.split('.').pop();
        return Boolean(extension && validReservationDocumentExtensions.image.includes(extension));
    }
    return false;
};

const generateFileUrl = (relativePath: string) =>
    `${process.env.NODE_ENV === 'development' ? 'https://dev.mylocalina.ch' : window.location.origin}/${relativePath}`;

const allowedExtensions = Object.values(validReservationDocumentExtensions)
    .flatMap((arr) => arr)
    .map((e) => `.${e}`)
    .toString();

export { MAX_RESERVATION_DOCUMENT_SIZE, validReservationDocumentExtensions };

const extractFileNameFromServer = (fullFileName: string) => fullFileName.split('/').slice(3).join('/');

const truncateFileName = (fileName: string, maxWidth: number) => {
    // Check if the file name needs truncation
    if (fileName.length <= maxWidth) {
        return fileName;
    }

    // Find the last dot to determine the length of the file extension
    const lastDotIndex = fileName.lastIndexOf('.');
    const extensionLength = lastDotIndex !== -1 ? fileName.length - lastDotIndex - 1 : 0;

    const startLength = maxWidth - extensionLength - 3; // Subtract the extension length and ellipsis

    // Extract the starting and ending parts of the file name
    const start = fileName.slice(0, startLength);
    const end = fileName.slice(-extensionLength);

    // Concatenate with ellipsis in the middle and the extension at the end
    return `${start}...${end}`;
};
export default ReservationFileUpload;
