import { DATE_FORMAT, IReportEntry, Label, SERVER_DATE_FORMAT, Tabs } from '@localina/core';
import { Box } from '@mui/material';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import { DateTime } from 'luxon';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const REPORTING_CHARTS_COLORS = {
    walkin: 'rgb(2, 178, 175)',
    online: 'rgb(46, 150, 255)',
    manual: 'rgb(184,52,225)',
    invalid: 'rgb(185,1,56)',
    valid: 'rgb(53,185,1)',
};

interface IChartsProps {
    graphicData: IReportEntry[];
    invalidIsVisible: boolean;
    validSegmentsAreVisible: boolean;
}

export const Charts = forwardRef((props: IChartsProps, ref) => {
    const { t } = useTranslation();

    const tabs: { label: string; value: 'bar' | 'line' }[] = [
        { label: t('reports.barChartTab'), value: 'bar' },
        { label: t('reports.lineChartTab'), value: 'line' },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    return (
        <Box sx={{ width: '100%' }}>
            <Tabs active={activeTab} tabs={tabs} onChange={setActiveTab} />
            <Box sx={{ width: '100%' }} ref={ref}>
                <div className={'chart bar-chart'}>
                    <BarAndLineCharts
                        data={props.graphicData}
                        invalidIsVisible={props.invalidIsVisible}
                        validSegmentsAreVisible={props.validSegmentsAreVisible}
                        activeTab={activeTab}
                    />
                </div>
                <div className={'chart pie-chart'}>
                    <Label type={'title'} value={t('reports.sectionTitle.totalSummary')} />
                    <ReservationsPieChart
                        data={props.graphicData}
                        invalidIsVisible={props.invalidIsVisible}
                        validSegmentsAreVisible={props.validSegmentsAreVisible}
                    />
                </div>
            </Box>
        </Box>
    );
});

interface IBarAndLineChartsProps {
    data: IReportEntry[];
    invalidIsVisible: boolean;
    validSegmentsAreVisible: boolean;
    activeTab: 'bar' | 'line';
}

const BarAndLineCharts = (props: IBarAndLineChartsProps) => {
    const { t } = useTranslation();
    const validReservations = !props.validSegmentsAreVisible
        ? [
              {
                  label: t('reports.legend.valid'),
                  data: props.data.map((data) => data.countWalkins + data.countManual + data.countOnline),
                  color: REPORTING_CHARTS_COLORS.valid,
              },
          ]
        : [
              {
                  label: t('reports.legend.walkins'),
                  data: props.data.map((data) => data.countWalkins),
                  color: REPORTING_CHARTS_COLORS.walkin,
                  stack: 'valid',
              },
              {
                  label: t('reports.legend.manual'),
                  data: props.data.map((data) => data.countManual),
                  color: REPORTING_CHARTS_COLORS.manual,
                  stack: 'valid',
              },
              {
                  label: t('reports.legend.online'),
                  data: props.data.map((data) => data.countOnline),
                  color: REPORTING_CHARTS_COLORS.online,
                  stack: 'valid',
              },
          ];
    const invalidReservations = {
        label: t('reports.legend.noShow'),
        data: props.data.map((data) => data.countNoShow),
        color: REPORTING_CHARTS_COLORS.invalid,
        highlightScope: { faded: 'global', highlighted: 'item' },
    };

    const xAxisLabels = props.data.map((data) =>
        DateTime.fromFormat(data.date, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT),
    );

    const series = props.invalidIsVisible ? [...validReservations, invalidReservations] : validReservations;

    return (
        <>
            {props.activeTab === 'bar' && (
                <BarChart
                    height={300}
                    series={series}
                    xAxis={[{ scaleType: 'band', data: xAxisLabels }]}
                    slotProps={{ legend: { padding: { bottom: 50 } } }}
                />
            )}
            {props.activeTab === 'line' && (
                <LineChart
                    height={300}
                    series={series.map((s) => ({
                        ...s,
                        stack: undefined,
                        showMark: false,
                        highlightScope: {
                            faded: 'global',
                            highlighted: 'series',
                        },
                    }))}
                    xAxis={[{ scaleType: 'point', data: xAxisLabels }]}
                />
            )}
        </>
    );
};

interface IReservationsPieChartProps {
    data: IReportEntry[];
    invalidIsVisible: boolean;
    validSegmentsAreVisible: boolean;
}

const ReservationsPieChart = (props: IReservationsPieChartProps) => {
    const { t } = useTranslation();
    const summarizedData = props.data?.reduce(
        (acc, data) => ({
            walkins: acc.walkins + data.countWalkins,
            manual: acc.manual + data.countManual,
            online: acc.online + data.countOnline,
            noShow: acc.noShow + data.countNoShow,
        }),
        {
            walkins: 0,
            manual: 0,
            online: 0,
            noShow: 0,
        },
    );

    const pieData = props.validSegmentsAreVisible
        ? [
              {
                  value: summarizedData?.walkins || 0,
                  label: t('reports.legend.walkins'),
                  color: REPORTING_CHARTS_COLORS.walkin,
              },
              {
                  value: summarizedData?.manual || 0,
                  label: t('reports.legend.manual'),
                  color: REPORTING_CHARTS_COLORS.manual,
              },
              {
                  value: summarizedData?.online || 0,
                  label: t('reports.legend.online'),
                  color: REPORTING_CHARTS_COLORS.online,
              },
          ]
        : [
              {
                  value: summarizedData ? summarizedData.online + summarizedData.manual + summarizedData.walkins : 0,
                  label: t('reports.legend.valid'),
                  color: REPORTING_CHARTS_COLORS.valid,
              },
          ];

    if (props.invalidIsVisible) {
        pieData.push({
            value: summarizedData?.noShow || 0,
            label: t('reports.legend.noShow'),
            color: REPORTING_CHARTS_COLORS.invalid,
        });
    }

    return (
        <PieChart
            series={[
                {
                    data: pieData,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    arcLabel: 'value',
                    arcLabelMinAngle: 10,
                },
            ]}
            height={300}
            slotProps={{
                legend: {
                    position: { horizontal: 'left', vertical: 'middle' },
                    direction: 'column',
                },
            }}
        />
    );
};
export { ReservationsPieChart, BarAndLineCharts };
