import { Rest, UserRole } from '@localina/core';
import { initializeApp } from 'firebase/app';
import {
    applyActionCode,
    Auth,
    browserLocalPersistence,
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    updateProfile,
    User,
} from 'firebase/auth';
import { IAuthUser, ICreateAuthUser, ILocalinaAuthApi } from '../../interfaces';
import { ServiceApiConstants } from '../../utils';

export class LocalinaAuthApi implements ILocalinaAuthApi {
    auth: Auth;

    constructor() {
        const firebaseApp = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
        });
        this.auth = getAuth(firebaseApp);
        this.auth.useDeviceLanguage();
    }

    async getUserInfo(): Promise<IAuthUser> {
        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(this.auth, async (user) => {
                unsubscribe();

                if (!user) {
                    return reject();
                }

                const userInfo = await this.convertFirebaseUser(user);
                if (!userInfo) {
                    return reject();
                }

                return resolve(userInfo);
            });
        });
    }

    async getAccessToken(forceRefresh = false): Promise<string | null> {
        const user = this.auth.currentUser;
        if (user) {
            return user.getIdToken(forceRefresh);
        }
        return null;
    }

    async login(email: string, password: string): Promise<IAuthUser> {
        await setPersistence(this.auth, browserLocalPersistence);
        const response = await signInWithEmailAndPassword(this.auth, email, password);
        if (!response.user) {
            return Promise.reject();
        }

        return this.convertFirebaseUser(response.user);
    }

    async sendPasswordResetEmail(email: string): Promise<void> {
        return sendPasswordResetEmail(this.auth, email);
    }

    async resetPassword(code: string, password: string): Promise<void> {
        return confirmPasswordReset(this.auth, code, password);
    }

    async registerAccountFromSupportUser(user: ICreateAuthUser): Promise<string> {
        try {
            const firebaseAppSecondary = initializeApp({
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_FIREBASE_APP_ID,
            });

            //save support user auth
            const supportUser = this.auth.currentUser;

            const secondaryFirebase = getAuth(firebaseAppSecondary);
            secondaryFirebase.useDeviceLanguage();

            const response = await createUserWithEmailAndPassword(secondaryFirebase, user.email, user.password);

            if (!response.user) {
                return Promise.reject();
            }

            //set back support user to this.auth because it was overridden with created user
            await this.auth.updateCurrentUser(supportUser);

            const accountId = await Rest.postRequest<string>({
                url: ServiceApiConstants.getAccount().toString(),
                payload: {
                    userId: response.user.uid,
                },
            });

            await updateProfile(response.user, { displayName: `${user.firstName}.${user.lastName}` });

            return Promise.resolve(accountId);
        } catch {
            return Promise.reject();
        }
    }

    async register(user: ICreateAuthUser): Promise<void> {
        const response = await createUserWithEmailAndPassword(this.auth, user.email, user.password);
        if (!response.user) {
            return Promise.reject();
        }

        try {
            await this.sendPardotMarketingAnalytics(user);
        } catch {
            await Promise.resolve();
        }

        try {
            await Rest.postRequest({
                url: ServiceApiConstants.getAccount().toString(),
                payload: {
                    userId: response.user.uid,
                },
            });

            await updateProfile(response.user, { displayName: `${user.firstName}.${user.lastName}` });
            await this.sendEmailVerification();

            // createUserWithEmailAndPassword automatically login the user when succeeding
            return this.logout();
        } catch {
            await response.user.delete();

            return Promise.reject();
        }
    }

    async sendPardotMarketingAnalytics(user: ICreateAuthUser) {
        await Rest.postRequest({
            url: ServiceApiConstants.getPardot().toString(),
            payload: {
                'e-mail': user.email,
                vorname: user.firstName,
                name: user.lastName,
            },
        });
    }

    async sendEmailVerification(): Promise<void> {
        const user = this.auth.currentUser;
        if (!user) {
            return Promise.reject();
        }

        return sendEmailVerification(user);
    }

    async verifyEmail(code: string): Promise<void> {
        return applyActionCode(this.auth, code);
    }

    async logout(): Promise<void> {
        return this.auth.signOut();
    }

    private async convertFirebaseUser(firebaseUser: User): Promise<IAuthUser> {
        const { email, displayName } = firebaseUser;
        const getNameAtIndex = (index: number) => {
            const names = displayName?.split('.');
            return names && names.length > index ? names[index] : '';
        };

        const jwt = await firebaseUser.getIdTokenResult();
        const roleClaim = 'ROLE_';
        const roles = Object.keys(jwt.claims)
            .filter((it) => it.startsWith(roleClaim))
            .map((it) => it.replace(roleClaim, '').toUpperCase()) as UserRole[];

        return Promise.resolve({
            email: email ?? '',
            roles,
            firstName: getNameAtIndex(0),
            lastName: getNameAtIndex(1),
            verified: firebaseUser.emailVerified,
            localina_account: (jwt.claims['localina_account'] as string | undefined) || null,
        });
    }
}
