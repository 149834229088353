var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ClickAwayListener, Tooltip as MUITooltip } from '@mui/material';
import React from 'react';
export var Tooltip = function (props) {
    var _a;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleTooltipClose = function () {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    };
    var handleTooltipOpen = function () {
        if (props.onOpen) {
            props.onOpen();
        }
        setOpen(true);
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleTooltipClose, touchEvent: false, mouseEvent: props.clickAwayMouseEvent }, { children: _jsx(MUITooltip, __assign({ className: props.className, arrow: props.arrow, title: props.content, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, onClose: handleTooltipClose, open: (_a = props.open) !== null && _a !== void 0 ? _a : open, placement: props.placement, PopperProps: {
                keepMounted: props.keepMounted,
                disablePortal: props.disablePortal,
                className: props.popperClassName,
                container: function () {
                    if (props.container) {
                        return props.container;
                    }
                    var root = document.getElementById('root');
                    if (root) {
                        return root;
                    }
                    return document.body;
                },
            } }, { children: _jsx("div", __assign({ className: 'localina-tooltip__wrapper', onClick: function (e) {
                    var _a;
                    if (props.stopPropagation) {
                        e.stopPropagation();
                    }
                    if ((_a = props.open) !== null && _a !== void 0 ? _a : open) {
                        handleTooltipClose();
                    }
                    else {
                        handleTooltipOpen();
                    }
                } }, { children: props.children })) })) })));
};
