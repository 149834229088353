import { FabButton, ISaveTablePlanRequest, List } from '@localina/core';
import { AddIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAreas } from '../../api/queries/restaurantAreas';
import { useRestaurant } from '../../api/queries/restaurants';
import { useCreateTablePlan } from '../../api/queries/restaurantTablePlans';
import { Page } from '../../components';
import { TablePlanCreationView } from '../../components/TablePlanCreationView';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';
import AreaTablePlansItem from './AreaTablePlansItem';

const TablePlans: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openTablePlanDrawer, setOpenTablePlanDrawer] = React.useState(false);

    const restaurantQuery = useRestaurant();
    const createTablePlanMutation = useCreateTablePlan();
    const areasQuery = useAreas();

    const openTablePlanDetails = () => {
        setOpenTablePlanDrawer(true);
    };

    const closeTablePlanDetails = () => {
        setOpenTablePlanDrawer(false);
    };

    const handleTablePlanCreationSave = (tablePlan?: ISaveTablePlanRequest) => {
        if (restaurantQuery.data && tablePlan) {
            createTablePlanMutation.mutate(
                { tablePlan },
                {
                    onSuccess: (tablePlanId) => {
                        closeTablePlanDetails();
                        navigate(
                            PathUtils.generateUrl(Path.RESTAURANT_TABLE_PLAN_DETAILS, {
                                restaurantId: restaurantQuery.data?.id,
                                tablePlanId,
                            }),
                        );
                    },
                },
            );
        }
    };

    const isLoading = createTablePlanMutation.isLoading || restaurantQuery.isInitialLoading;

    return (
        <Page name="table-plans" isLoading={isLoading} title={t('common.menu.categories.settings.menu.table.plan')}>
            <List>
                {areasQuery.data?.areas.map((area, index) => (
                    <AreaTablePlansItem restaurant={restaurantQuery.data} area={area} key={index} />
                ))}
            </List>
            <FabButton icon={<AddIcon />} onClick={openTablePlanDetails} className="table-plans-add-button" fixed />
            <TablePlanCreationView
                hasError={createTablePlanMutation.isError}
                openDialog={openTablePlanDrawer}
                onSave={handleTablePlanCreationSave}
                onClose={closeTablePlanDetails}
            />
        </Page>
    );
};

export default TablePlans;
