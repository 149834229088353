import { IRestaurant, useConfirmContext } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteRestaurantLogoMutation, useSaveRestaurantLogoMutation } from '../../api/queries/restaurantLogo';
import { Drawer } from '../Drawer';
import { RestaurantLogoIntegration } from './Integrations';

interface IProps {
    isOpen: boolean;
    restaurant: IRestaurant;
    onClose: () => void;
}

export const RestaurantLogoIntegrationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { snackbar } = useConfirmContext();
    const saveRestaurantLogoMutation = useSaveRestaurantLogoMutation();
    const deleteRestaurantLogoMutation = useDeleteRestaurantLogoMutation();

    const [logo, setLogo] = React.useState<File | undefined>(undefined);

    const onError = () => {
        snackbar({ msg: t('integrations.items.logo.error'), severity: 'error' });
    };
    const handleSave = () => {
        if (logo) {
            saveRestaurantLogoMutation.mutate({ logo }, { onSuccess: props.onClose, onError });
        } else {
            deleteRestaurantLogoMutation.mutate(undefined, { onSuccess: props.onClose, onError });
        }
    };

    return (
        <Drawer
            className="restaurantlogo-view"
            open={props.isOpen}
            title={t('integrations.items.logo.title')}
            disabled={logo?.size === 0}
            onClose={props.onClose}
            onSave={handleSave}
        >
            <RestaurantLogoIntegration restaurant={props.restaurant} onChange={setLogo} />
        </Drawer>
    );
};
