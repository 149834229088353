import { Label, Tooltip } from '@localina/core';
import { TableIcon } from '@localina/icons';
import React from 'react';

interface IReservationTableNumbersProps {
    tableNumbers: string[];
    tooltipContainer?: Element;
}

const ReservationTableNumbers = ({ tableNumbers, tooltipContainer }: IReservationTableNumbersProps) => {
    return (
        <React.Fragment>
            {tableNumbers.length === 0 && (
                <>
                    <TableIcon className="icon" empty />
                    <Label type="text" value="" />
                </>
            )}
            {tableNumbers.length > 0 && (
                <Tooltip
                    stopPropagation
                    container={tooltipContainer}
                    popperClassName="tooltip reservation-table-numbers"
                    clickAwayMouseEvent="onPointerDown"
                    content={
                        <div className="reservation-table-numbers">
                            {<Label type="label" value={tableNumbers.join(', ')} />}
                        </div>
                    }
                >
                    <div className="reservation-table-numbers-container">
                        <TableIcon className="icon" />
                        <Label type="text" extraClassName="elipsis" value={tableNumbers.join(', ')} />
                    </div>
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default ReservationTableNumbers;
