import { DATE_FORMAT, IMessage, ListItem, MessageType, SERVER_DATE_FORMAT, StringUtils } from '@localina/core';
import { EmailIcon, TextAfterIcon, TextBeforeIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    message: IMessage;
    onClick: (message: IMessage) => void;
}

export const MessageListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const title = props.message.name;
    let timeValidity = t('messages.list.subtitle');
    const languages = props.message.displayTexts.map((it) => it.language.toUpperCase()).join(', ');

    const from = DateTime.fromFormat(props.message.from ?? '', SERVER_DATE_FORMAT);
    const to = DateTime.fromFormat(props.message.to ?? '', SERVER_DATE_FORMAT);
    if (from.isValid || to.isValid) {
        timeValidity = StringUtils.combineStrings(
            [from.isValid && from.toFormat(DATE_FORMAT), to.isValid && to.toFormat(DATE_FORMAT)],
            ' - ',
        );
    }

    const handleClick = () => {
        props.onClick(props.message);
    };

    const getIcon = () => {
        switch (props.message.type) {
            case MessageType.WELCOME:
                return <TextBeforeIcon />;
            case MessageType.GOODBYE:
                return <TextAfterIcon />;
            case MessageType.CONFIRMATION_MAIL:
                return <EmailIcon />;
            case MessageType.EMAIL:
                return <EmailIcon />;
        }
    };

    return (
        <ListItem title={title} subtitle={`${timeValidity}\n${languages}`} leftIcon={getIcon()} onClick={handleClick} />
    );
};
