import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetAuthUser } from '../../api/queries/auth';
import { useGetRestaurants } from '../../api/queries/restaurants';
import { Path } from '../../enums';
import { SupportUtils } from '../../utils';

const RestaurantRoutes = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const authUserQuery = useGetAuthUser();

    const restaurantId = params.restaurantId || '';
    const restaurantsQuery = useGetRestaurants({ enabled: false });

    const currentRestaurant = useMemo(
        () => restaurantsQuery.data?.restaurants.find((restaurant) => restaurant.id === restaurantId),
        [restaurantsQuery.data, restaurantId],
    );

    const showChildren = Boolean(currentRestaurant);

    useEffect(() => {
        if (restaurantsQuery.data && restaurantsQuery.data.restaurants.length && !currentRestaurant) {
            const pathname = location.pathname.replace(restaurantId, restaurantsQuery.data.restaurants[0].id);
            navigate({ pathname }, { replace: true });
        }
    }, [currentRestaurant, restaurantsQuery.data]);

    useEffect(() => {
        if (authUserQuery.data && SupportUtils.isSupportPanel(authUserQuery.data.user)) {
            navigate(Path.SUPPORT_ACCOUNTS);
        }
    }, [authUserQuery.data]);

    return <>{showChildren && <Outlet />}</>;
};

export default RestaurantRoutes;
