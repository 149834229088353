import React from 'react';
import { IMessageDisplayText, ListItem } from '@localina/core';
import { LanguageIcon } from '@localina/icons';

interface IProps {
    displayText: IMessageDisplayText;
    onClick: (displayText: IMessageDisplayText) => void;
}

export const MessageDisplayTextListItem: React.FC<IProps> = (props) => {
    const handleClick = () => {
        props.onClick(props.displayText);
    };

    return (
        <ListItem
            title={props.displayText.text}
            subtitle={props.displayText.language.toUpperCase()}
            leftIcon={<LanguageIcon />}
            onClick={handleClick}
        />
    );
};
