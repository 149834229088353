import { ISalesStatisticsFilters } from '../api/queries/salesStatistics';

function relativeUrl(relative: string): URL {
    return new URL(`/api/v1/support${relative}`, window.location.origin);
}

export const SupportApiConstants = {
    getAccountList(page: number, limit: number, query?: string): URL {
        const url = relativeUrl('/accounts');
        const queryParams = new URLSearchParams();
        queryParams.append('page', `${page}`);
        queryParams.append('limit', `${limit}`);
        if (query && query.trim().length !== 0) {
            queryParams.append('q', `${query}`);
        }
        url.search = queryParams.toString();
        return url;
    },
    getStatisticsList(page: number, limit: number, filter: ISalesStatisticsFilters = {}): URL {
        const url = new URL(`/api/v1/restaurants/sales-statistics`, window.location.origin);
        const queryParams = new URLSearchParams();
        queryParams.append('page', `${page}`);
        queryParams.append('limit', `${limit}`);
        if (filter.query && filter.query.trim().length !== 0) {
            queryParams.append('searchQuery', filter.query);
        }
        if (filter.dateFrom) {
            queryParams.append('from', filter.dateFrom);
        }
        if (filter.dateTo) {
            queryParams.append('to', filter.dateTo);
        }
        url.search = queryParams.toString();
        return url;
    },

    getAccount(accountId: string): URL {
        return relativeUrl(`/accounts/${accountId}`);
    },

    getAccountRestaurants(accountId: string): URL {
        return relativeUrl(`/accounts/${accountId}/restaurants`);
    },

    getAccountUsers(accountId: string): URL {
        return relativeUrl(`/accounts/${accountId}/users`);
    },

    getUsers(): URL {
        return relativeUrl('/users');
    },

    getUser(userId: string): URL {
        return relativeUrl(`/users/${userId}`);
    },

    deleteRestaurantGuests(restaurantId: string): URL {
        return new URL(`/api/v1/restaurants/${restaurantId}/guests`, window.location.origin);
    },
    deleteRestaurantReservations(restaurantId: string): URL {
        return new URL(`/api/v1/restaurants/${restaurantId}/reservations`, window.location.origin);
    },

    getUpdateRestaurantSubscription(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/subscriptions`);
    },

    grantAccess(accountId: string): URL {
        return relativeUrl(`/accounts/${accountId}/grantAccess`);
    },

    revokeAccess(): URL {
        return relativeUrl(`/accounts/revokeAccess`);
    },
};
