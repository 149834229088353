import { createEmptySupportUser, ISupportUser, TextField, useConfirmContext, ValidationUtils } from '@localina/core';
import { EmailIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import {
    useCreateSupportUser,
    useDeleteSupportUser,
    useSupportUser,
    useUpdateSupportUser,
} from '../../../api/queries/supportUsers';
import { Page } from '../../../components';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';

interface IPathParams {
    userId: string;
}

const SupportUser: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { snackbar, confirm } = useConfirmContext();
    const params = useMatch(Path.SUPPORT_USER)?.params as IPathParams;
    const { userId } = params;

    const isEditing = userId !== 'new';

    const [user, setUser] = React.useState<ISupportUser | undefined>(undefined);
    const [isFormValid, setFormValid] = React.useState(false);

    const userQuery = useSupportUser(userId, {
        enabled: isEditing,
    });

    const createSupportUserMutation = useCreateSupportUser();
    const updateSupportUserMutation = useUpdateSupportUser();
    const deleteSupportUserMutation = useDeleteSupportUser();

    const handleBack = () => {
        navigate(Path.SUPPORT_USERS);
    };

    React.useEffect(() => {
        if (!isEditing) {
            setUser(createEmptySupportUser());
        }
    }, []);

    React.useEffect(() => {
        if (isEditing) {
            setUser(userQuery.data);
        }
    }, [userQuery.data, isEditing]);

    React.useEffect(() => {
        if (user) {
            setFormValid(ValidationUtils.validateObject(user, { optionalFields: ['id'] }));
        }
    }, [user]);

    const handleSave = () => {
        if (user) {
            if (isEditing) {
                handleUpdateUser();
            } else {
                handleCreateUser();
            }
        }
    };

    const handleCreateUser = () => {
        if (user) {
            createSupportUserMutation.mutate(user, {
                onSuccess: (createdUser) => {
                    snackbar({
                        msg: t('supportUser.saved'),
                        severity: 'success',
                    });
                    navigate(PathUtils.generateUrl(Path.SUPPORT_USER, { userId: createdUser.id }), {
                        replace: true,
                    });
                },
                onError: () => {
                    snackbar({
                        msg: t('supportUser.errors.save'),
                        severity: 'error',
                    });
                },
            });
        }
    };
    const handleUpdateUser = () => {
        if (user) {
            updateSupportUserMutation.mutate(user, {
                onSuccess: () => {
                    snackbar({
                        msg: t('supportUser.saved'),
                        severity: 'success',
                    });
                    return userQuery.refetch();
                },
                onError: () => {
                    snackbar({
                        msg: t('supportUser.errors.save'),
                        severity: 'error',
                    });
                },
            });
        }
    };

    const handleDelete = async () => {
        if (isEditing && user) {
            if (
                (await confirm({
                    msg: t('supportUser.dialog.message', {
                        name: user?.email,
                    }),
                    title: t('supportUser.dialog.title'),
                })) === 'yes'
            ) {
                deleteSupportUserMutation.mutate(user, {
                    onSuccess: handleBack,
                    onError: () => {
                        snackbar({
                            msg: t(`supportUser.errors.delete`),
                            severity: 'error',
                        });
                    },
                });
            }
        }
    };

    const handleUserChange = (field: string) => (value: string) => {
        if (user) {
            setUser({
                ...user,
                [field]: value,
            });
        }
    };

    const isLoading =
        userQuery.isInitialLoading ||
        createSupportUserMutation.isLoading ||
        updateSupportUserMutation.isLoading ||
        deleteSupportUserMutation.isLoading;

    return (
        <Page
            name="support-user"
            title={{
                value: t('supportUser.title'),
                onBack: handleBack,
            }}
            actions={{
                save: {
                    disabled: !isFormValid,
                    onClick: handleSave,
                },
                onCancel: handleBack,
                onDelete: handleDelete,
            }}
            isLoading={isLoading}
        >
            <div id="user">
                {user && (
                    <div className="form">
                        <TextField
                            name="firstName"
                            label={t('supportUser.fields.firstName')}
                            value={user.firstName}
                            required={true}
                            onChange={handleUserChange('firstName')}
                        />
                        <TextField
                            name="lastName"
                            label={t('supportUser.fields.lastName')}
                            value={user.lastName}
                            required={true}
                            onChange={handleUserChange('lastName')}
                        />
                        <TextField
                            name="email"
                            label={t('supportUser.fields.email')}
                            value={user.email}
                            required={true}
                            icon={<EmailIcon />}
                            onChange={handleUserChange('email')}
                        />
                    </div>
                )}
            </div>
        </Page>
    );
};

export default SupportUser;
