import { ILocalizedError } from '@localina/core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { downloadExportFile } from '../../utils';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';

const useUploadDocument = (options?: UseMutationOptions<string, ILocalizedError, { document: File }>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: saveRestaurantLogoMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};

const useDownloadDocument = (
    options?: UseMutationOptions<
        File,
        ILocalizedError,
        {
            url: string;
            fileName: string;
        }
    >,
) => {
    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.getRestaurantDocument(variables.url);
        },
        onSuccess: (data, variables) => {
            const lastDotIndex = variables.fileName.lastIndexOf('.');
            const type = variables.fileName.slice(lastDotIndex + 1);
            const name = variables.fileName.slice(0, lastDotIndex);
            downloadExportFile(data, { name, type });
        },
        ...options,
    });
};

const saveRestaurantLogoMutationFn = (restaurantId: string) => (variables: { document: File }) =>
    LocalinaApiContext.serviceApi.saveRestaurantDocument(restaurantId, variables.document);

export { useUploadDocument, useDownloadDocument };
