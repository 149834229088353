import { FabButton, IUser, List } from '@localina/core';
import { AddIcon } from '@localina/icons';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryKeys } from '../../api/queries/query-keys';
import { useUsers } from '../../api/queries/users';
import { Page, UserListItem } from '../../components';
import UserView from './UserView';

type TDrawerState = {
    open: boolean;
    userId: string;
};

const defaultDrawerState = {
    open: false,
    userId: 'new',
};

const Users: React.FC = () => {
    const { t } = useTranslation();
    const usersQuery = useUsers();
    const queryClient = useQueryClient();
    const [drawerState, setDrawerState] = useState<TDrawerState>(defaultDrawerState);

    const handleEditUser = (item: IUser) => {
        queryClient.setQueryData(queryKeys.users.single(item.id), (oldData) => (oldData ? undefined : item));
        setDrawerState({ open: true, userId: item.id });
    };

    const handleCreateUser = () => {
        setDrawerState({ open: true, userId: 'new' });
    };

    const onCloseDialog = () => {
        setDrawerState(defaultDrawerState);
    };

    return (
        <Page name="users" title={t('users.title')} isLoading={usersQuery.isInitialLoading}>
            <List>
                {usersQuery.data?.map((user, i) => (
                    <UserListItem key={i} user={user} onClick={handleEditUser} />
                ))}
            </List>
            <FabButton icon={<AddIcon />} onClick={handleCreateUser} fixed />
            <UserView open={drawerState.open} onClose={onCloseDialog} userId={drawerState.userId} />
        </Page>
    );
};

export default Users;
