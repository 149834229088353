var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { StringUtils } from '../../utils';
import { Label } from '../Label';
export var FieldWrapper = function (props) {
    var hasIcon = props.icon && typeof props.icon !== 'boolean';
    var useIcon = props.icon && typeof props.icon === 'boolean';
    var classNames = StringUtils.combineStrings([
        'localina-inputfield',
        Boolean(props.name) && props.name,
        Boolean(hasIcon) && 'has-icon',
        Boolean(useIcon) && 'use-icon',
    ]);
    return (_jsxs("div", __assign({ className: classNames }, { children: [_jsxs("div", __assign({ className: "field-container" }, { children: [props.icon, props.children] })), props.helperText && (_jsx("div", __assign({ className: "helper-text" }, { children: typeof props.helperText === 'string' ? (_jsx(Label, { type: "info", value: props.helperText })) : (props.helperText) })))] })));
};
