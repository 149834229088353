import {
    IReservation,
    IReservationWithUnassignedStatus,
    IRestaurant,
    RESERVATION_DROP_TYPE,
    SERVER_DATE_FORMAT,
    StringUtils,
} from '@localina/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { getTablesNamesByIds } from '../../utils/TablePlanService';
import { ReservationItem } from './ReservationItem';

interface IProps {
    reservations: IReservationWithUnassignedStatus[];
    restaurant?: IRestaurant;
    onReservationClick: (reservation: IReservation) => void;
}

export const ReservationGroup: React.FC<IProps> = (props) => {
    return (
        <div className="reservation-list-shiftgroup">
            {props.reservations.map((reservation) => (
                <Reservation
                    key={reservation.id}
                    onReservationClick={() => props.onReservationClick(reservation)}
                    reservation={reservation}
                    tableNumbers={getTablesNamesByIds(
                        reservation.tableIds,
                        props.restaurant?.configuration.virtualAreas,
                    )}
                    areaCode={
                        props.restaurant?.configuration.virtualAreas
                            .flatMap((v) => v.areas)
                            .find((a) => a.id == reservation.areaIds[0])?.code ||
                        props.restaurant?.configuration.virtualAreas.find((a) => a.id == reservation.areaIds[0])
                            ?.code ||
                        ''
                    }
                    isVirtualArea={
                        props.restaurant?.configuration.virtualAreas
                            .map((v) => v.id)
                            .includes(reservation.areaIds[0]) || false
                    }
                    isUnassigned={reservation.unassigned}
                />
            ))}
        </div>
    );
};

interface IReservationProps {
    reservation: IReservation;
    tableNumbers: string[];
    onReservationClick: () => void;
    areaCode: string;
    isVirtualArea: boolean;
    isUnassigned?: boolean;
}

const Reservation: React.FC<IReservationProps> = ({
    reservation,
    tableNumbers,
    areaCode,
    isVirtualArea,
    onReservationClick,
    isUnassigned,
}: IReservationProps) => {
    const restaurantId = useRestaurantId();
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: RESERVATION_DROP_TYPE,
            item: {
                id: reservation.id,
                tableIds: reservation.tableIds,
                tableNumbers,
                areaCode,
                isVirtualArea,
                restaurantId,
                reservationDateServerFormat: DateTime.fromISO(reservation.reservationDateTime).toFormat(
                    SERVER_DATE_FORMAT,
                ),
            },
            isDragging: (monitor) => monitor.getItem()?.id === reservation.id,
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
        }),
        [reservation.id, tableNumbers],
    );

    return (
        <div
            onClick={onReservationClick}
            className={StringUtils.combineStrings([
                isDragging && 'reservation-dragging',
                isUnassigned && 'unassigned-reservation',
            ])}
            ref={dragRef}
        >
            <ReservationItem
                reservation={reservation}
                tableNumbers={tableNumbers}
                areaCode={areaCode}
                isVirtualArea={isVirtualArea}
            />
        </div>
    );
};
