import { IReservation, IRestaurantArea, IRestaurantVirtualArea } from '@localina/core';
import { IRestaurantSelectedArea } from '../interfaces/store/restaurant/IRestaurantSelectedArea';

export const getAreaById = (
    virtualAreas: IRestaurantVirtualArea[] | undefined,
    id: string,
): IRestaurantArea | undefined => {
    return virtualAreas?.flatMap((virtualArea) => virtualArea.areas).find((area) => area.id === id);
};

export const isReservationInArea = (reservation: IReservation, selectedArea?: IRestaurantSelectedArea) => {
    if (!selectedArea) {
        return true;
    }
    if (selectedArea.type === 'area') {
        return reservation.areaIds.includes(selectedArea.area.id);
    }
    return selectedArea.area.areas.some((area) => reservation.areaIds.includes(area.id));
};

export const getAreaWithTypeById = (
    areaId: string,
    virtualAreas: IRestaurantVirtualArea[],
): IRestaurantSelectedArea | undefined => {
    const virtualArea = virtualAreas.find((v) => v.id === areaId);
    if (virtualArea) {
        return {
            area: virtualArea,
            type: 'virtual',
        };
    }
    const area = virtualAreas.flatMap((v) => v.areas).find((a) => a.id === areaId);
    if (area) {
        return {
            area,
            type: 'area',
        };
    }
    return undefined;
};
export const getAreaWithTablePlan = (virtualAreas: IRestaurantVirtualArea[], tablePlanId: string) => {
    return virtualAreas
        .flatMap((v) => v.areas)
        .find((area) => {
            return area.tablePlans.find((tp) => tp.id === tablePlanId) !== undefined;
        });
};

export function getSelectedAreaById(
    virtualAreas: IRestaurantVirtualArea[],
    areaId: string,
): IRestaurantSelectedArea | undefined {
    const virtualArea = virtualAreas.find((v) => v.id === areaId);
    if (virtualArea) {
        return {
            area: virtualArea,
            type: 'virtual',
        };
    }
    const selectedArea = virtualAreas.flatMap((v) => v.areas).find((area) => area.id === areaId);
    if (selectedArea) {
        return {
            area: selectedArea,
            type: 'area',
        };
    }
    return undefined;
}
