var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HighlightOff } from '@mui/icons-material';
import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFieldUtils, ValidationUtils } from '../../utils';
import { InputField } from '../InputField';
import { Label } from '../Label';
import { PhoneNumberField } from '../PhoneNumberField';
export var TextField = function (props) {
    var _a, _b;
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var ref = React.createRef();
    var t = useTranslation().t;
    var isPhoneNumberField = props.name === 'phoneNumber';
    var setError = function (isError, message) {
        if (message === void 0) { message = ''; }
        setErrorMessage(isError ? (props.errorMessageKey ? t(props.errorMessageKey) : message) : '');
    };
    var validatePhoneNumber = function (phoneNumber) {
        if (props.validate === undefined || props.validate) {
            var validateFieldOutput = ValidationUtils.validateField('phoneNumber', phoneNumber, true);
            setError(!validateFieldOutput.isValid, validateFieldOutput.invalidMessage);
        }
    };
    var handleChangeEvent = function (event) {
        handleChange(event.target.value);
    };
    var handleClear = function () {
        handleChange('');
    };
    var handleChange = function (text) {
        if (props.type && text !== '') {
            if (props.type === 'tel' && !/^\+?\d*$/.test(text)) {
                return;
            }
            else if (props.type === 'number' && !/^\d*$/.test(text)) {
                return;
            }
        }
        if (props.validate === undefined || props.validate) {
            var value = props.type === 'number' && text !== ''
                ? ['expectedOccupancyTime', 'slotInterval', 'minReservationNotice'].includes(props.name)
                    ? +text * 60
                    : +text
                : text;
            var validateFieldOutput = ValidationUtils.validateField(props.name, value, true);
            setError(!validateFieldOutput.isValid, validateFieldOutput.invalidMessage);
        }
        if (props.onChange) {
            props.onChange(text);
        }
    };
    useEffect(function () {
        if (props.hasError !== undefined) {
            setError(props.hasError, props.errorMessageKey || ' ');
        }
    }, [props.hasError]);
    var commonProps = {
        inputRef: ref,
        InputLabelProps: {
            shrink: props.shrink,
        },
        inputProps: {
            pattern: props.type === 'number' ? '[0-9]*' : undefined,
        },
        autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete),
        // avoid setting type attribute "number" because unwanted stepper will be displayed in the field
        type: isPhoneNumberField ? 'tel' : props.type !== 'number' ? props.type : undefined,
        label: props.label,
        value: !props.value && props.onChange ? '' : props.value,
        required: props.required,
        fullWidth: true,
        error: !!errorMessage,
        disabled: props.disabled,
        minRows: props.rows !== undefined || props.multiline ? undefined : (_a = props.minRows) !== null && _a !== void 0 ? _a : 4,
        multiline: props.multiline,
        rows: props.multiline ? (_b = props.rows) !== null && _b !== void 0 ? _b : 4 : undefined,
        autoFocus: props.autoFocus,
    };
    return (_jsxs(InputField, __assign({ name: "localina-".concat(props.multiline ? 'textarea' : 'textfield'), inputRef: ref, disableBlurOnEnter: props.multiline, icon: props.icon, helperText: errorMessage ? _jsx(Label, { type: "info", value: t(errorMessage), error: true }) : props.helperText, onEnter: props.onEnter }, { children: [!isPhoneNumberField && (_jsx(MuiTextField, __assign({ onBlur: props.onBlur, onFocus: props.onFocus }, commonProps, { variant: 'outlined', onChange: handleChangeEvent, InputProps: {
                    endAdornment: !(props.allowClear && commonProps.value) ? undefined : (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(HighlightOff, { onClick: handleClear, className: "textfield__clear-button" }) }))),
                } }))), isPhoneNumberField && (_jsx(PhoneNumberField, __assign({}, commonProps, { validate: props.validate, variant: 'outlined', onChange: props.onChange, validateValue: validatePhoneNumber })))] })));
};
