// Forcing visual viewport repositioning with window.scroll function when native keyboard is dismissed,
// in order to touch position offsets work as expected
// Issue: MYLO-1219, Reference: https://openradar.appspot.com/radar?id=5018321736957952
import { useEffect, useRef } from 'react';

const useForcingVisualViewportRepositioning = () => {
    const previousVisualViewportHeight = useRef(Infinity);
    const isPWA = Boolean('standalone' in window.navigator && window.navigator.standalone);
    useEffect(() => {
        if (isPWA) {
            const scrollWindowToResetVisualViewport = () => {
                if (previousVisualViewportHeight.current < (window.visualViewport?.height || 0)) {
                    setTimeout(() => {
                        window.scroll(0, 0);
                    }, 150);
                }
                previousVisualViewportHeight.current = window.visualViewport?.height || 0;
            };
            window.visualViewport?.addEventListener('resize', scrollWindowToResetVisualViewport);
            return () => {
                window.visualViewport?.removeEventListener('resize', scrollWindowToResetVisualViewport);
            };
        }
    }, []);
};

export { useForcingVisualViewportRepositioning };
