import { ISaveAreaRequest } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AreaCreationDetails from '../AreaCreationDetails';
import { Drawer } from '../Drawer';

interface IProps {
    restaurantArea?: ISaveAreaRequest;
    openDialog: boolean | undefined;
    hasError: boolean;
    onSave: (restaurantArea?: ISaveAreaRequest) => void;
    onClose: () => void;
    onDelete?: () => void;
    onChange: (restaurantArea: ISaveAreaRequest) => void;
    error?: any;
}

export const AreaCreationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [actionRequested, setActionRequested] = React.useState(false);
    const [isFormValid, setFormValid] = React.useState(false);

    React.useEffect(() => {
        setActionRequested(false);
        validateAreaFields();
    }, [props.restaurantArea]);

    const handleClose = () => {
        props.onClose();
    };

    const handleSave = () => {
        setActionRequested(true);
        props.onSave(props.restaurantArea);
    };

    const handleDelete = () => {
        if (props.restaurantArea && props.onDelete) {
            setActionRequested(true);
            props.onDelete();
        }
    };

    const validateAreaFields = () => {
        return props.restaurantArea?.name &&
            props.restaurantArea?.name !== '' &&
            props.restaurantArea?.name.length <= 20 &&
            props.restaurantArea?.name.length > 1 &&
            props.restaurantArea?.code &&
            props.restaurantArea?.code !== '' &&
            props.restaurantArea?.code.length <= 8 &&
            props.restaurantArea?.virtualAreaId !== '' &&
            props.restaurantArea?.capacity &&
            props.restaurantArea?.capacity > 0
            ? setFormValid(true)
            : setFormValid(false);
    };

    return (
        <Drawer
            open={!!props.openDialog}
            title={t('sectors.create.area')}
            error={{
                isVisible: actionRequested && props.hasError,
                message: props.error?.errorKey ? t(props.error.errorKey) : t('sectors.create.errorSavingRemovingArea'),
            }}
            disabled={!isFormValid}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={props.onDelete ? handleDelete : undefined}
        >
            <AreaCreationDetails restaurantAreaDetails={props.restaurantArea} onChange={props.onChange} />
        </Drawer>
    );
};
