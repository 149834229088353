import { IReservation, Label, List, ReservationStatus, StringUtils, Tabs, useConfirmContext } from '@localina/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { Drawer } from '../Drawer';
import { ReservationListItem } from '../ListItem';

export interface INotificationsViewProps {
    visible: boolean;
    reservations: IReservation[];
    hasError: boolean;
}

interface IProps extends INotificationsViewProps {
    onClose: () => void;
    onEdit: (reservation: IReservation) => void;
    onRead: (reservation: IReservation[]) => void; //mark the given reservation(s) as read
}

export const NotificationsView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { confirm } = useConfirmContext();
    const handleClose = () => {
        props.onClose();
    };

    const { recentlyProcessedReservations } = useReservationFormContext();

    const [canMarkRead] = useHaveAccountFeatures(['markRead'], undefined, true);

    const handleReadAll = async () => {
        if (!isRequestModeActive || selectedTab === ReservationStatus.APPROVED) {
            if (
                (await confirm({
                    msg: t('notifications.view.confirmDialog.message'),
                    title: t('notifications.view.confirmDialog.title'),
                    cancelLabel: t('common.buttons.cancel'),
                    confirmLabel: t('common.buttons.ok'),
                })) === 'yes'
            ) {
                props.onRead(
                    props.reservations.filter(
                        (reservation) => reservation.status !== ReservationStatus.APPROVAL_PENDING,
                    ),
                );
            }
        }
    };

    const handleEditReservation = (reservation: IReservation) => {
        props.onEdit(reservation);
    };

    const [selectedTab, setSelectedTab] = useState<ReservationStatus.APPROVED | ReservationStatus.APPROVAL_PENDING>(
        ReservationStatus.APPROVAL_PENDING,
    );

    const filteredReservations = useMemo(() => {
        return props.reservations.reduce(
            (acc, reservation) => {
                if (reservation.status === ReservationStatus.APPROVAL_PENDING) {
                    acc.requested.push(reservation);
                } else {
                    acc.approved.push(reservation);
                }
                return acc;
            },
            {
                approved: [] as IReservation[],
                requested: [] as IReservation[],
            },
        );
    }, [props.reservations]);

    const [hasRequestModeEnabled] = useHaveAccountFeatures(['requestMode'], undefined, true);

    const isRequestModeActive =
        hasRequestModeEnabled ||
        Boolean(filteredReservations.requested.length) ||
        Boolean(recentlyProcessedReservations.length);

    const processedReservationsIds = recentlyProcessedReservations.map((res) => res.id);

    const displayedReservations = useMemo(() => {
        if (isRequestModeActive) {
            if (selectedTab === ReservationStatus.APPROVED) {
                return [...filteredReservations.approved].sort(sortReservations('newest'));
            } else {
                const requestedAndRecentlyProcessedReservations = [
                    ...recentlyProcessedReservations,
                    ...filteredReservations.requested,
                ];
                return requestedAndRecentlyProcessedReservations.sort(sortReservations('oldest'));
            }
        } else {
            return props.reservations;
        }
    }, [selectedTab, isRequestModeActive, filteredReservations, recentlyProcessedReservations]);

    const getEmptyDataMessage = () => {
        if (isRequestModeActive) {
            if (selectedTab === ReservationStatus.APPROVED) {
                return t('notifications.view.requestMode.tabs.unseen.noDataMessage');
            } else {
                return t('notifications.view.requestMode.tabs.requested.noDataMessage');
            }
        } else {
            return t('notifications.view.noDataMessage');
        }
    };

    useEffect(() => {
        if (props.visible && isRequestModeActive) {
            // if selected tab is "Requested" and there are no reservations, and there are some in "Unseen" tab -> switch tab
            if (
                selectedTab === ReservationStatus.APPROVAL_PENDING &&
                !filteredReservations.requested.length &&
                filteredReservations.approved.length
            ) {
                setSelectedTab(ReservationStatus.APPROVED);
                // if selected tab is "Unseen" but there are some reservations in "Requested" tab -> switch tab
            } else if (selectedTab === ReservationStatus.APPROVED && filteredReservations.requested.length) {
                setSelectedTab(ReservationStatus.APPROVAL_PENDING);
            }
        }
    }, [props.visible]);

    const disabledReadAllButton =
        !filteredReservations.approved ||
        filteredReservations.approved.length === 0 ||
        !canMarkRead ||
        (isRequestModeActive && selectedTab !== ReservationStatus.APPROVED);

    return (
        <Drawer
            className={StringUtils.combineStrings(['notifications-view', !displayedReservations.length && 'no-data'])}
            open={props.visible}
            title={t('notifications.view.title')}
            error={{
                isVisible: props.hasError,
                message: t('notifications.view.error'),
            }}
            onClose={handleClose}
            extraButtons={[
                {
                    label: t('notifications.view.buttons.readAll'),
                    onClick: handleReadAll,
                    disabled: disabledReadAllButton,
                },
            ]}
        >
            {isRequestModeActive && (
                <Tabs
                    tabs={[
                        {
                            label: t('notifications.view.requestMode.tabs.unseen.title'),
                            value: ReservationStatus.APPROVED,
                            badgeProps: {
                                badgeContent: filteredReservations.approved.length,
                                max: 99,
                            },
                        },
                        {
                            label: t('notifications.view.requestMode.tabs.requested.title'),
                            value: ReservationStatus.APPROVAL_PENDING,
                            badgeProps: {
                                badgeContent: filteredReservations.requested.length,
                                max: 99,
                            },
                        },
                    ]}
                    onChange={setSelectedTab}
                    active={selectedTab}
                />
            )}
            <List>
                {displayedReservations.map((reservation) => (
                    <ReservationListItem
                        key={reservation.id}
                        reservation={reservation}
                        onClick={handleEditReservation}
                        isProcessed={processedReservationsIds.includes(reservation.id)}
                    />
                ))}
                {!displayedReservations.length && <Label type={'text'} value={getEmptyDataMessage()} />}
            </List>
        </Drawer>
    );
};

const sortReservations = (displayOnTop: 'oldest' | 'newest') => (res1: IReservation, res2: IReservation) => {
    const dir = displayOnTop === 'oldest' ? 1 : -1;
    if (res1.auditInfo && res2.auditInfo) {
        return res1.auditInfo?.createdAt > res2.auditInfo?.createdAt ? dir : -dir;
    }
    return 0;
};
