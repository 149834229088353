import { DeviceUtils, IconButton, IRestaurantArea, IRestaurantVirtualArea, Label } from '@localina/core';
import { ChevronRightIcon } from '@localina/icons';
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../../api/queries/account';
import { useVirtualAreas } from '../../../api/queries/restaurantAreas';
import VirtualAreaView from './VirtualAreaView';

const VirtualAreas: React.FC = () => {
    const { t } = useTranslation();
    const virtualAreasQuery = useVirtualAreas();
    const [isMobile, setMobile] = React.useState(DeviceUtils.isMobile());
    const [selectedVirtualArea, setSelectedVirtualArea] = useState<IRestaurantVirtualArea | undefined>(undefined);
    const [canEditAreas] = useHaveAccountFeatures(['areasEdit']);

    const onVirtualAreaViewClose = () => {
        setSelectedVirtualArea(undefined);
    };

    React.useEffect(() => {
        const checkIsMobile = () => setMobile(DeviceUtils.isMobile());
        window.addEventListener('resize', checkIsMobile, true);
        return () => {
            window.removeEventListener('resize', checkIsMobile, true);
        };
    }, []);

    return (
        <div className="restaurant-sectors-main-areas">
            <Label type="title" value={t('sectors.mainAreas')} variant={isMobile ? 'h4' : 'h6'} align="left" />
            <Grid container spacing={{ xs: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {virtualAreasQuery.isSuccess &&
                    virtualAreasQuery.data.virtualAreas.map((virtualArea, index) => (
                        <Grid item xs={8} sm={8} md={6} key={index}>
                            <Card
                                className="card-style"
                                onClick={() => {
                                    if (canEditAreas) {
                                        setSelectedVirtualArea(virtualArea);
                                    }
                                }}
                            >
                                <CardContent>
                                    <div className={'card-content__body'}>
                                        <Label
                                            type="text"
                                            icon={<AutoStoriesTwoToneIcon className="auto-stories-icon" />}
                                            value={virtualArea.name}
                                        />
                                        <Typography variant="body2" className="sub-areas-list">
                                            {virtualArea?.areas.map((area: IRestaurantArea, i) =>
                                                i !== virtualArea?.areas.length - 1 ? area.name + ', ' : area.name,
                                            )}
                                        </Typography>
                                    </div>
                                    {canEditAreas && (
                                        <CardActions className="card-actions-align">
                                            <IconButton
                                                icon={<ChevronRightIcon />}
                                                onClick={() => {
                                                    setSelectedVirtualArea(virtualArea);
                                                }}
                                            />
                                        </CardActions>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
            {canEditAreas && <VirtualAreaView virtualArea={selectedVirtualArea} onClose={onVirtualAreaViewClose} />}
        </div>
    );
};

export default VirtualAreas;
