import { Button, Label, StringUtils, TextField } from '@localina/core';
import { EmailIcon } from '@localina/icons';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSendResetPasswordEmailMutation } from '../../../api/queries/auth';

interface IProps {
    onNext: () => void;
    onLogin: () => void;
    sendResetPasswordEmailMutation: ReturnType<typeof useSendResetPasswordEmailMutation>;
}

const UserInfo: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [email, setEmail] = React.useState('');
    const [isFormValid, setFormValid] = React.useState(false);

    React.useEffect(() => {
        setFormValid(StringUtils.isValidEmail(email));
    }, [email]);

    const handleClick = () => {
        props.sendResetPasswordEmailMutation.mutate(email, {
            onSuccess: props.onNext,
        });
    };

    const handleBack = () => {
        props.onLogin();
    };

    return (
        <>
            <Typography className="reset-password__title">{t('resetPassword.title')}</Typography>
            <TextField
                label={t('resetPassword.fields.email')}
                name="email"
                autoComplete="username"
                type="email"
                icon={<EmailIcon />}
                required={true}
                value={email}
                onChange={setEmail}
            />
            {props.sendResetPasswordEmailMutation.isError && (
                <Label type="text" value={t('resetPassword.error')} error={true} />
            )}
            <div className="buttons">
                <Button label={t('resetPassword.buttons.back')} secondary={true} onClick={handleBack} />
                <Button label={t('resetPassword.buttons.confirm')} disabled={!isFormValid} onClick={handleClick} />
            </div>
        </>
    );
};

export default UserInfo;
