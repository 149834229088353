import { Button, Label, SERVER_DATE_FORMAT, Spinner, useConfirmContext } from '@localina/core';
import { DownloadIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReservationsExportForMultipleDates } from '../../api/queries/reservations';
import { IReservationsFilters } from '../../utils/ReservationsUtils';

interface IExportReservationsButtonProps {
    dateTime: DateTime;
    filters: IReservationsFilters;
}

const generateDailyReportName = (date: string, date2?: string) =>
    `${!date2 ? date : `${date} - ${date2}`} reservations.xlsx`;

const ExportReservationsButton = (props: IExportReservationsButtonProps) => {
    const { t } = useTranslation();
    const { snackbar, confirm } = useConfirmContext();
    const reservationsExportMultipleDatesMutation = useReservationsExportForMultipleDates();

    const onDownloadSuccess = () => {
        snackbar({
            msg: t('reservations.exportReservations.downloadStartedMessage'),
            severity: 'success',
        });
    };
    const onDownloadError = () => {
        snackbar({
            msg: t('reservations.exportReservations.downloadErrorMessage'),
            severity: 'error',
        });
    };
    const onExportDownloadClick = async () => {
        switch (
            await confirm({
                title: t('reservations.exportReservations.dialogTitle'),
                msg: t('reservations.exportReservations.dialogMessage'),
                confirmLabel: t('reservations.exportReservations.thisDayButtonLabel'),
                cancelLabel: t('reservations.exportReservations.thisWeekButtonLabel'),
                dialogClassName: 'reservations-export-choice',
            })
        ) {
            case 'yes':
                downloadReservationsExport();
                return;
            case 'no':
                downloadReservationsExport(true);
                return;
            case 'cancel':
            default:
                return;
        }
    };

    const downloadReservationsExport = (wholeWeek = false) => {
        const dates = [];
        if (wholeWeek) {
            const startOfWeek = props.dateTime.minus({ day: props.dateTime.localWeekday - 1 });
            for (let i = 0; i < 7; i++) {
                dates.push(startOfWeek.plus({ day: i }).toFormat(SERVER_DATE_FORMAT));
            }
        } else {
            dates.push(props.dateTime.toFormat(SERVER_DATE_FORMAT));
        }

        reservationsExportMultipleDatesMutation.mutate(
            {
                fileName: generateDailyReportName(dates[0], wholeWeek ? dates[dates.length - 1] : undefined),
                dates,
                filters: props.filters,
            },
            {
                onSuccess: onDownloadSuccess,
                onError: onDownloadError,
                onSettled: () => {
                    reservationsExportMultipleDatesMutation.reset();
                },
            },
        );
    };

    return (
        <>
            <Button
                label={<Label type="text" value="XLSX" icon={<DownloadIcon />} />}
                onClick={onExportDownloadClick}
                secondary={true}
            />
            {reservationsExportMultipleDatesMutation.isLoading && <Spinner />}
        </>
    );
};

export default ExportReservationsButton;
