import { DeviceUtils, IRestaurant, Label, StringUtils, Tabs } from '@localina/core';
import { CloseIcon, RestaurantMenuIcon } from '@localina/icons';
import { Collapse, Divider, List, SwipeableDrawer } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useGetAccount } from '../../api/queries/account';
import { useGetAuthUser } from '../../api/queries/auth';
import ReservationList from '../../containers/Tableplans/ReservationList';
import { MenuProvider } from '../../contexts';
import { Path } from '../../enums';
import { IAuthUser } from '../../interfaces';
import { SupportUtils } from '../../utils';
import { UserFeature } from '../UserFeature';
import { Account, Reservations, Restaurants, Settings, Support } from './Categories';

interface IProps {
    isOpen: boolean;
    restaurant?: IRestaurant;
    restaurants: IRestaurant[];
    authUser: IAuthUser;
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
    activeReservationSubmenuItem: TReservationSubmenuTabValues;
    setActiveReservationSubmenuItem: Dispatch<SetStateAction<TReservationSubmenuTabValues>>;
    isTableAssignPage: boolean;
    reservationSpeedDialPortal: boolean;
}

type TReservationSubmenuTabValues = 'reservations' | 'menu';

interface IReservationSubmenuTab {
    value: TReservationSubmenuTabValues;
    translationKey: string;
}

const reservationSubmenuTabs: IReservationSubmenuTab[] = [
    {
        value: 'menu',
        translationKey: 'common.menu.menuTab',
    },
    {
        value: 'reservations',
        translationKey: 'common.menu.reservationsTab',
    },
];
const AppMenuSwipeableDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [isMenuPermanent, setMenuPermanent] = React.useState(
        () => props.isTableAssignPage || !DeviceUtils.isMobile(),
    );
    const location = useLocation();

    const handleOpen = () => props.setMenuOpen(true);
    const handleClose = () => props.setMenuOpen(false);
    const classNames = StringUtils.combineStrings([
        props.isTableAssignPage ? 'reservations-left-side-app-menu' : 'app-menu',
        isMenuPermanent && 'permanent',
        props.isOpen && 'open',
    ]);

    const authQuery = useGetAuthUser();

    const isAccountNumberVisible = Boolean(
        authQuery.isSuccess &&
            (authQuery.data.accountId || !(authQuery.data.hasSupportRole || authQuery.data.hasSalesRole)) &&
            !props.isTableAssignPage,
    );

    const accountQuery = useGetAccount({
        enabled: isAccountNumberVisible,
    });

    useEffect(() => {
        if (!isMenuPermanent) {
            handleClose();
        }
    }, [location, isMenuPermanent]);

    useEffect(() => {
        if (props.isTableAssignPage) {
            props.setActiveReservationSubmenuItem('reservations');
        }
    }, [props.isTableAssignPage]);

    useEffect(() => {
        const checkMenuPermanent = () => {
            setMenuPermanent(props.isTableAssignPage || !DeviceUtils.isMobile());
        };

        checkMenuPermanent();
        window.addEventListener('resize', checkMenuPermanent, true);
        return () => {
            window.removeEventListener('resize', checkMenuPermanent, true);
        };
    }, [props.isTableAssignPage]);

    return (
        <SwipeableDrawer
            className={classNames}
            open={props.isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            variant={isMenuPermanent ? 'permanent' : 'temporary'}
        >
            {!isMenuPermanent && (
                <>
                    <div className="app-menu__header">
                        <div className="grow" />
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <Divider />
                </>
            )}
            <div className="app-menu__content">
                {SupportUtils.isSupportPanel(props.authUser) && (
                    <List disablePadding>
                        <MenuProvider>
                            <Support />
                        </MenuProvider>
                    </List>
                )}
                {props.isTableAssignPage && (
                    <div
                        className={`app-menu-submenu-container ${
                            props.activeReservationSubmenuItem === 'menu' ? 'submenu-padding' : ''
                        }`}
                    >
                        <div className="app-menu-submenu">
                            <Tabs
                                active={props.activeReservationSubmenuItem}
                                onChange={props.setActiveReservationSubmenuItem}
                                tabs={reservationSubmenuTabs.map(({ value, translationKey }) => ({
                                    value,
                                    label: t(translationKey),
                                }))}
                            />
                        </div>
                        <SwipeableViews
                            disabled
                            draggable={false}
                            className="content"
                            index={props.activeReservationSubmenuItem === 'reservations' ? 1 : 0}
                            onChangeIndex={(index) => {
                                props.setActiveReservationSubmenuItem(index === 1 ? 'reservations' : 'menu');
                            }}
                        >
                            <div className="padding-top">
                                <MenuInner
                                    authUser={props.authUser}
                                    restaurant={props.restaurant}
                                    restaurants={props.restaurants}
                                />
                            </div>
                            <ReservationList speedDialPortal={props.reservationSpeedDialPortal} />
                        </SwipeableViews>
                    </div>
                )}
                {!SupportUtils.isSupportPanel(props.authUser) && props.restaurant && !props.isTableAssignPage && (
                    <MenuInner
                        authUser={props.authUser}
                        restaurant={props.restaurant}
                        restaurants={props.restaurants}
                    />
                )}
            </div>
            {isAccountNumberVisible && accountQuery.isSuccess && (
                <div className="app-menu__footer">
                    <Label type="info" value={t('common.menu.account', accountQuery.data)} />
                </div>
            )}
        </SwipeableDrawer>
    );
};

type TWithCollapsedMenuTableAssignPageProps = Omit<
    IProps,
    | 'activeReservationSubmenuItem'
    | 'setActiveReservationSubmenuItem'
    | 'isTableAssignPage'
    | 'reservationSpeedDialPortal'
>;

const withCollapsedMenuTableAssignPage = (Component: FC<any>) => (props: TWithCollapsedMenuTableAssignPageProps) => {
    const { t } = useTranslation();

    const isTableAssignPage = Boolean(
        useMatch({
            path: Path.RESTAURANT_TABLE_PLAN_ASSIGN_RESERVATIONS,
        }),
    );
    const [activeReservationSubmenuItem, setActiveReservationSubmenuItem] =
        useState<TReservationSubmenuTabValues>('reservations');

    return (
        <>
            <Collapse in={props.isOpen} orientation={'horizontal'}>
                <Component
                    {...props}
                    activeReservationSubmenuItem={activeReservationSubmenuItem}
                    setActiveReservationSubmenuItem={setActiveReservationSubmenuItem}
                    isTableAssignPage={isTableAssignPage}
                    reservationSpeedDialPortal={!props.isOpen}
                />
            </Collapse>
            {!props.isOpen && isTableAssignPage && (
                <Collapse
                    in={!props.isOpen}
                    unmountOnExit
                    orientation={'horizontal'}
                    className={'app-menu-tabs-vertical-collapse'}
                    onClick={() => {
                        props.setMenuOpen(true);
                    }}
                >
                    <div className="app-menu-tabs-vertical">
                        <Tabs
                            active={activeReservationSubmenuItem}
                            onChange={setActiveReservationSubmenuItem}
                            orientation={'vertical'}
                            tabs={reservationSubmenuTabs.map(({ value, translationKey }) => ({
                                value,
                                label: t(translationKey),
                            }))}
                        />
                    </div>
                </Collapse>
            )}
        </>
    );
};

export const AppMenu = withCollapsedMenuTableAssignPage(AppMenuSwipeableDrawer);

interface IInnerProps {
    restaurants: IRestaurant[];
    restaurant?: IRestaurant;
    authUser: IAuthUser;
}

function MenuInner(props: IInnerProps) {
    if (!props.restaurant) {
        return null;
    }

    return (
        <>
            <div className="app-menu__title">
                <RestaurantMenuIcon />
                <Label type="title" variant="h5" value={props.restaurant.info.name} />
            </div>
            <List disablePadding={true}>
                <MenuProvider>
                    <Restaurants restaurantId={props.restaurant.id} restaurants={props.restaurants} />
                    <Reservations restaurantId={props.restaurant.id} />
                    <UserFeature filter={'settings'}>
                        <Settings restaurantId={props.restaurant.id} />
                    </UserFeature>
                    <Divider />
                    <Account authUser={props.authUser} />
                </MenuProvider>
            </List>
        </>
    );
}
