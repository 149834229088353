import { ILocalizedError, IRestaurantStats } from '@localina/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';

function useRestaurantStats(
    dates: string[],
    options?: UseQueryOptions<IRestaurantStats, ILocalizedError, IRestaurantStats, TQueryKey>,
) {
    const restaurantId = useRestaurantId();

    return useQuery({
        ...options,
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getRestaurantStats(restaurantId, dates);
        },
        queryKey: queryKeys.restaurants.single.stats(restaurantId, dates),
    });
}

type TQueryKey = ReturnType<typeof queryKeys.restaurants.single.stats>;

export { useRestaurantStats };
