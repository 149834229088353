import {
    IAreaTime,
    IAreaTimeSlot,
    IAvailableAreaTimeRequested,
    ILocalizedError,
    IRestaurant,
    IRestaurantArea,
    IRestaurantVirtualArea,
    ISaveAreaRequest,
    TIME_FORMAT,
} from '@localina/core';
import { useMutation, UseMutationOptions, useQueries, UseQueryOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { sortItemsBySortedIdsArray } from '../../utils/SortableItemsUtils';
import { useGetAccount } from './account';
import { queryKeys } from './query-keys';
import { usePreservedRestaurant, useRestaurant, useRestaurantById } from './restaurants';

function useVirtualAreas(specificRestaurantId?: string) {
    const accountQuery = useGetAccount({ enabled: false });
    const restaurantId = useRestaurantId(specificRestaurantId);

    return useRestaurantById(restaurantId, {
        enabled: false,
        select: (data) => {
            let virtualAreas: IRestaurantVirtualArea[] = [];
            if (data && accountQuery.isSuccess) {
                const canAccessAreas = accountQuery.data.hasFeature('areas', data.id);
                if (canAccessAreas) {
                    virtualAreas = data.configuration.virtualAreas.map((virtualArea) => {
                        const areas = sortItemsBySortedIdsArray(
                            virtualArea.areas,
                            data.configuration.orderings.areas || [],
                            'id',
                        );
                        return {
                            ...virtualArea,
                            areas,
                        };
                    });
                } else {
                    virtualAreas = data.configuration.virtualAreas.map((virtualArea) => ({
                        ...virtualArea,
                        areas: virtualArea.areas.filter((area) => area.id === data.configuration.defaultAreaId),
                    }));
                }
            }
            return { virtualAreas, restaurantConfiguration: data?.configuration } as const;
        },
    });
}

function useAreas(restaurantId?: string) {
    const accountQuery = useGetAccount({ enabled: false });
    const restaurantIdToUse = useRestaurantId(restaurantId);

    return useRestaurantById(restaurantIdToUse, {
        enabled: false,
        select: (data) => {
            const areaList: (IRestaurantArea & { virtualAreaId: string })[] = [];
            if (data && accountQuery.isSuccess) {
                const canAccessAreas = accountQuery.data.hasFeature('areas', data.id);
                data.configuration.virtualAreas?.forEach((virtualArea: IRestaurantVirtualArea) => {
                    let areas = virtualArea.areas.map((area) => ({ ...area, virtualAreaId: virtualArea.id }));
                    if (!canAccessAreas) {
                        areas = areas.filter((area) => area.id === data.configuration.defaultAreaId);
                    }
                    areaList.push(...areas);
                });

                sortItemsBySortedIdsArray(areaList, data.configuration.orderings.areas || []);
            }
            return { areas: areaList, defaultAreaId: data?.configuration.defaultAreaId } as const;
        },
    });
}

const useSaveAreaMutation = (options?: UseMutationOptions<string, ILocalizedError, ISaveAreaRequest>) => {
    const restaurantQuery = useRestaurant();
    return useAreaMutation(restaurantQuery, options);
};
const useSaveAreaMutationWithRestaurantId = (
    restaurantId: string,
    options?: UseMutationOptions<string, ILocalizedError, ISaveAreaRequest>,
) => {
    const restaurantQuery = useRestaurantById(restaurantId);
    return useAreaMutation(restaurantQuery, options);
};

const useAreaMutation = (
    restaurantQuery: ReturnType<typeof useRestaurant<IRestaurant | undefined>>,
    options?: UseMutationOptions<string, ILocalizedError, ISaveAreaRequest>,
) => {
    const updateAreaOrderingMutation = useUpdateAreaOrderingMutation();
    return useMutation({
        mutationFn:
            restaurantQuery.isSuccess && restaurantQuery.data
                ? saveAreaMutationFunction(restaurantQuery.data.id)
                : undefined,
        onSuccess: (data, variables) => {
            if (!variables.id && restaurantQuery.data?.configuration.orderings.areas) {
                // add areaId to orderings.areas array
                return updateAreaOrderingMutation.mutateAsync([
                    ...restaurantQuery.data.configuration.orderings.areas,
                    data,
                ]);
            } else {
                return restaurantQuery.refetch();
            }
        },
        ...options,
    });
};
const useUpdateVirtualArea = (options?: UseMutationOptions<string, ILocalizedError, IRestaurantVirtualArea>) => {
    const restaurantQuery = useRestaurant();

    return useMutation({
        mutationFn: restaurantQuery.data?.id
            ? (variables) => {
                  return LocalinaApiContext.serviceApi.updateRestaurantVirtualArea(restaurantQuery.data?.id || '', {
                      name: variables.name,
                      code: variables.code,
                      id: variables.id,
                  });
              }
            : undefined,
        onSuccess: () => restaurantQuery.refetch(),
        ...options,
    });
};
const useDeleteAreaMutation = (options?: UseMutationOptions<void, ILocalizedError, { areaId: string }>) => {
    const restaurantQuery = useRestaurant();
    const updateAreaOrderingMutation = useUpdateAreaOrderingMutation();
    return useMutation({
        mutationFn:
            restaurantQuery.isSuccess && restaurantQuery.data
                ? deleteAreaMutationFunction(restaurantQuery.data.id)
                : undefined,
        onSuccess: (data, variables) => {
            if (restaurantQuery.data?.configuration.orderings.areas) {
                // remove areaId from orderings.areas array
                const areasOrdering = restaurantQuery.data.configuration.orderings.areas.filter(
                    (areaId) => areaId !== variables.areaId,
                );
                return updateAreaOrderingMutation.mutateAsync(areasOrdering);
            } else {
                return restaurantQuery.refetch();
            }
        },
        ...options,
    });
};
const useUpdateAreaOrderingMutation = (options?: UseMutationOptions<string, ILocalizedError, string[]>) => {
    const restaurantQuery = useRestaurant();
    return useMutation({
        mutationFn:
            restaurantQuery.isSuccess && restaurantQuery.data
                ? updateAreaOrderingMutationFunction(restaurantQuery.data)
                : undefined,
        onSuccess: () => restaurantQuery.refetch(),
        ...options,
    });
};

const saveAreaMutationFunction = (restaurantId: string) => (area: ISaveAreaRequest) =>
    LocalinaApiContext.serviceApi.saveRestaurantArea(restaurantId, area);
const deleteAreaMutationFunction = (restaurantId: string) => (variables: { areaId: string }) =>
    LocalinaApiContext.serviceApi.deleteRestaurantArea(restaurantId, variables.areaId);
const updateAreaOrderingMutationFunction = (restaurant: IRestaurant) => (areas: string[]) =>
    LocalinaApiContext.serviceApi.updateRestaurant(restaurant.id, {
        orderings: {
            ...restaurant.configuration.orderings,
            areas,
        },
    });

type TAvailableAreaTimeResponse = { slots: IAreaTimeSlot[] };
const useAvailableAreaTimes = (
    data: IAvailableAreaTimeRequested[],
    options?: UseQueryOptions<TAvailableAreaTimeResponse, ILocalizedError, IAreaTime | undefined>,
) => {
    const restaurantQuery = usePreservedRestaurant();
    const restaurantId = restaurantQuery.data?.id || '';

    return useQueries({
        queries: data.map((variables) => ({
            queryFn: () => {
                return LocalinaApiContext.serviceApi.getAvailableAreaTimeSlots(
                    restaurantId,
                    variables.participants,
                    variables.reservationDateTime,
                    variables.areaId,
                    variables.shiftId,
                    variables.expectedOccupancyTime,
                );
            },
            queryKey: queryKeys.restaurants.single.availableAreaTimeSlot(restaurantId, variables),
            enabled: Boolean(restaurantId),
            select: (response: TAvailableAreaTimeResponse) => {
                if (restaurantQuery.data) {
                    const shift = restaurantQuery.data.configuration.shifts.find(({ id }) => id === variables.shiftId);
                    if (!shift) {
                        return undefined;
                    }
                    let areaName = '';
                    let virtualAreaId = '';
                    let virtualAreaName = '';

                    const virtualArea = restaurantQuery.data.configuration.virtualAreas.find(
                        ({ id }) => id === variables.areaId,
                    );
                    if (virtualArea) {
                        areaName = virtualArea.name;
                        virtualAreaId = virtualArea.id;
                        virtualAreaName = virtualArea.name;
                    } else {
                        const area = restaurantQuery.data.configuration.virtualAreas
                            .flatMap((va) => {
                                return va.areas.map((a) => ({
                                    ...a,
                                    virtualAreaId: va.id,
                                    virtualAreaName: va.name,
                                }));
                            })
                            .find(({ id }) => id === variables.areaId);
                        if (!area) {
                            return undefined;
                        }
                        areaName = area.name;
                        virtualAreaId = area.virtualAreaId;
                        virtualAreaName = area.virtualAreaName;
                    }
                    const { occupiedTables } = response.slots.reduce(
                        ({ occupiedTables: ot, lastSlot }, slot) => {
                            if (slot.tableOccupancy) {
                                Object.keys(slot.tableOccupancy).forEach((tableId: string) => {
                                    if (slot.tableOccupancy?.[tableId] === 0) {
                                        if (!ot[tableId]) {
                                            ot[tableId] = [
                                                {
                                                    from: slot.timeSlot,
                                                    to: addSecondsToTime(
                                                        slot.timeSlot,
                                                        variables.expectedOccupancyTime,
                                                    ),
                                                },
                                            ];
                                        } else if (lastSlot.tableOccupancy?.[tableId] === 0 && ot[tableId].length) {
                                            const length = ot[tableId].length - 1;
                                            ot[tableId][length].to = addSecondsToTime(
                                                slot.timeSlot,
                                                variables.expectedOccupancyTime,
                                            );
                                        } else {
                                            ot[tableId].push({
                                                from: slot.timeSlot,
                                                to: addSecondsToTime(slot.timeSlot, variables.expectedOccupancyTime),
                                            });
                                        }
                                    }
                                });
                            }
                            return { occupiedTables: ot, lastSlot: slot };
                        },
                        { occupiedTables: {}, lastSlot: {} } as {
                            occupiedTables: IAreaTime['occupiedTables'];
                            lastSlot: IAreaTimeSlot;
                        },
                    );

                    return {
                        timeSlots: response.slots,
                        areaId: variables.areaId,
                        shiftId: variables.shiftId,
                        shiftName: shift.name,
                        shiftFrom: shift.from,
                        shiftTo: shift.to,
                        expectedOccupancyTime: shift.expectedOccupancyTime,
                        slotInterval: shift.slotInterval,
                        areaName,
                        virtualAreaId,
                        virtualAreaName,
                        occupiedTables,
                    };
                }
                return undefined;
            },
            ...options,
        })),
    });
};

const addSecondsToTime = (time: string, seconds?: number) =>
    DateTime.fromFormat(time, TIME_FORMAT).plus({ seconds }).toFormat(TIME_FORMAT);

export {
    useVirtualAreas,
    useAreas,
    useUpdateAreaOrderingMutation,
    useDeleteAreaMutation,
    useSaveAreaMutation,
    useAvailableAreaTimes,
    useSaveAreaMutationWithRestaurantId,
    useUpdateVirtualArea,
};
