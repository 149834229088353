import { Localization } from '@localina/core';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { LocalinaAuthApi, LocalinaServiceApi, LocalinaSupportApi, LocalinaTracingApi } from './app/api';
import App from './app/containers/App';
import { IStoreContext } from './app/interfaces';
import './app/styles/index.scss';

//Google Tag Manager initialization
TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_TRACKING_CODE as string,
});

export const LocalinaApiContext: IStoreContext = {
    authApi: new LocalinaAuthApi(),
    serviceApi: new LocalinaServiceApi(),
    supportApi: new LocalinaSupportApi(),
    tracingApi: new LocalinaTracingApi(),
};

axios.interceptors.request.use(
    async (config) => {
        if (config.headers.Authorization === undefined) {
            const token = await LocalinaApiContext.authApi.getAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } else if (config.headers.Authorization === null) {
            config.headers.Authorization = undefined;
        }
        return config;
    },
    (err) => err,
);

const i18n = Localization.init('service');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App i18n={i18n} />
    </React.StrictMode>,
);
