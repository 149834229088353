import React from 'react';
import { useTranslation } from 'react-i18next';
import { createEmptyUserRoleMapping, IRestaurant, IUser, SelectField, UserRole } from '@localina/core';
import { useGetAccount } from '../../api/queries/account';

interface IProps {
    user: IUser;
    restaurant: IRestaurant;
    onChange: (user: IUser) => void;
}

export const UserRoleMappingListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const userRoleMapping = props.user.rolesMapping.find((it) => it.restaurantId === props.restaurant.id);
    const accountQuery = useGetAccount();

    const userRoles = [
        { label: t('user.roles.none'), value: undefined },
        { label: t(`user.roles.${UserRole.ADMIN}`), value: UserRole.ADMIN },
        { label: t(`user.roles.${UserRole.SERVICE}`), value: UserRole.SERVICE },
        { label: t(`user.roles.${UserRole.SERVER}`), value: UserRole.SERVER },
        { label: t(`user.roles.${UserRole.RECEPTION}`), value: UserRole.RECEPTION },
    ];

    const handleSwitchChange = () => (value: string) => {
        const newRole = userRoles.find((role) => role.label === value)?.value;
        const newRoles = newRole ? [newRole] : createEmptyUserRoleMapping(props.restaurant.id).roles;
        const newUserRoleMapping = { ...userRoleMapping, roles: newRoles, restaurantId: props.restaurant.id };

        props.onChange({
            ...props.user,
            rolesMapping: [
                ...props.user.rolesMapping.filter((it) => it.restaurantId !== newUserRoleMapping.restaurantId),
                ...(newUserRoleMapping.roles.length === 0 ? [] : [newUserRoleMapping]),
            ],
        });
    };

    return (
        <div className="user-role-list-item">
            <SelectField
                label={props.restaurant.info.name}
                options={userRoles
                    .filter(
                        (role) =>
                            role.value !== UserRole.RECEPTION ||
                            (accountQuery.isSuccess && accountQuery.data.hasFeature('reception', props.restaurant.id)),
                    )
                    .map((role) => role.label)}
                value={
                    userRoleMapping && userRoleMapping.roles.length > 0
                        ? t(`user.roles.${userRoleMapping.roles[0]}`)
                        : t('user.roles.none')
                }
                onChange={handleSwitchChange()}
            />
        </div>
    );
};
