import { Button, Checkbox, Label, TextField, ValidationUtils } from '@localina/core';
import { EmailIcon, LockIcon, PersonIcon } from '@localina/icons';
import { Typography } from '@mui/material';
import React from 'react';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRegisterMutation } from '../../../api/queries/auth';
import { Path } from '../../../enums';
import { ICreateAuthUser } from '../../../interfaces';

interface IProps {
    onNext: () => void;
    registerMutation: ReturnType<typeof useRegisterMutation>;
}

const UserInfo: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [user, setUser] = React.useState<ICreateAuthUser>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [isFormValid, setFormValid] = React.useState(false);
    const [isTermsAndConditionsAccepted, setTermsAndConditionsAccepted] = React.useState(false);

    const handleChanges = (field: string) => (value: string) => {
        setUser({
            ...user,
            [field]: value,
        });
    };

    React.useEffect(() => {
        setFormValid(ValidationUtils.validateObject(user) && isTermsAndConditionsAccepted);
    }, [user, isTermsAndConditionsAccepted]);

    const handleClick = () => {
        void props.registerMutation.mutate(user, {
            onSuccess: () => {
                sendGtmEvent();
                props.onNext();
            },
        });
    };

    const sendGtmEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'registrationSuccess',
            },
        });
    };

    return (
        <>
            <Typography className="register__title">{t('register.title')}</Typography>
            <TextField
                label={t('register.fields.firstName')}
                name="firstName"
                autoComplete={true}
                icon={<PersonIcon />}
                required={true}
                value={user.firstName}
                onChange={handleChanges('firstName')}
            />
            <TextField
                label={t('register.fields.lastName')}
                name="lastName"
                autoComplete={true}
                icon={true}
                required={true}
                value={user.lastName}
                onChange={handleChanges('lastName')}
            />
            <TextField
                label={t('register.fields.email')}
                name="email"
                autoComplete="username"
                type="email"
                icon={<EmailIcon />}
                required={true}
                value={user.email}
                onChange={handleChanges('email')}
            />
            <TextField
                label={t('register.fields.password')}
                name="password"
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                helperText={t('register.helperTexts.password')}
                icon={<LockIcon />}
                required={true}
                value={user.password}
                onChange={handleChanges('password')}
            />
            <Checkbox
                label={t('register.fields.showPassword')}
                checked={showPassword}
                onChange={setShowPassword}
                icon
                disableRipple={false}
                disableTouchRipple
            />
            <Checkbox
                checked={isTermsAndConditionsAccepted}
                onChange={setTermsAndConditionsAccepted}
                icon
                disableRipple={false}
                disableTouchRipple
                label={
                    <Typography>
                        <Trans i18nKey="register.termsAndConditions">
                            I accept the <a>terms of use</a> and the <a>conditions</a>. Read it.
                        </Trans>
                    </Typography>
                }
            />
            {props.registerMutation.isError && <Label type="text" value={t('register.error')} error />}
            <div className="buttons">
                <Button label={t('register.buttons.register')} disabled={!isFormValid} onClick={handleClick} />
            </div>
            <Label
                type="text"
                value={
                    <Trans i18nKey="register.helperTexts.login">
                        Already have an account? <Link to={Path.LOGIN}>Back to login</Link>
                    </Trans>
                }
            />
        </>
    );
};
export default UserInfo;
