import { DateTime, Info } from 'luxon';
import { TIME_FORMAT } from '../config';
import { StringUtils } from './StringUtils';
var getSubtitleForShift = function (shift, locale) {
    if (shift.shiftType === 'recurrent') {
        var weekdays = StringUtils.combineStrings(shift.occurrence.sort().map(function (weekday) { return Info.weekdays('short', { locale: locale })[weekday - 1]; }), ', ');
        var from = DateTime.fromFormat(shift.from, TIME_FORMAT).toFormat(TIME_FORMAT);
        var to = DateTime.fromFormat(shift.to, TIME_FORMAT).toFormat(TIME_FORMAT);
        var timerange = "".concat(from, "-").concat(to);
        return "".concat(weekdays, " \u2013 ").concat(timerange);
    }
    return '';
};
export var ShiftUtils = {
    getSubtitleForShift: getSubtitleForShift,
};
