import { ILocalizedError } from '@localina/core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';

const useSaveRestaurantLogoMutation = (options?: UseMutationOptions<void, ILocalizedError, { logo: File }>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: saveRestaurantLogoMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};
const useDeleteRestaurantLogoMutation = (options?: UseMutationOptions<void, ILocalizedError>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: deleteRestaurantLogoMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};

const saveRestaurantLogoMutationFn = (restaurantId: string) => (variables: { logo: File }) =>
    LocalinaApiContext.serviceApi.saveRestaurantLogo(restaurantId, variables.logo);
const deleteRestaurantLogoMutationFn = (restaurantId: string) => () =>
    LocalinaApiContext.serviceApi.deleteRestuarantLogo(restaurantId);

export { useSaveRestaurantLogoMutation, useDeleteRestaurantLogoMutation };
