import React from 'react';
import { Label, ITablePlanObjMetadata } from '@localina/core';
import { IPoint } from '../../../hooks/useZoom';
import { PlantsObjs, TablePlanObjTypes } from '../../../interfaces/entities/ITablePlan';
import { getCanvasElementStyles } from '../../../utils/TablePlanService';
import { getObjDetails } from '../FabricHelper';

interface IProps {
    canvasObj: ITablePlanObjMetadata;
    scaleX: number;
    scaleY: number;
    offset: IPoint;
}

export const CanvasObjAssignReservation: React.FC<IProps> = (props) => {
    const details = getObjDetails(props.canvasObj);
    if (!details) {
        return null;
    }
    const isWall = props.canvasObj.objType === TablePlanObjTypes.wall;

    const styles = getCanvasElementStyles(props.canvasObj, props.scaleX, props.scaleY, props.offset);
    if (PlantsObjs.includes(props.canvasObj.objType)) {
        return <img alt="" style={styles} draggable={false} src={details.imageUrl} />;
    }
    if (props.canvasObj.objType === 'floor') {
        return (
            <div
                style={styles}
                className={`floor-text ${(styles?.height || 0) > (styles?.width || 0) ? 'rotate-text' : ''}`}
            >
                <Label type="title" value={props.canvasObj.label} variant="subtitle1" />
            </div>
        );
    }
    return isWall ? <div style={styles} className={'wall-lines'} /> : <div style={styles} />;
};
