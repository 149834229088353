import { IReservation, Label, Tooltip } from '@localina/core';
import { MenuBookOutlined } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPlatformIcon } from '../../utils/PlatformUtils';

interface IReservationOriginIconProps {
    reservation: IReservation;
}

const ReservationOriginIcon = ({ reservation }: IReservationOriginIconProps) => {
    const { t } = useTranslation();
    const isManual = Boolean(reservation.auditInfo?.createdBy);

    return reservation.auditInfo ? (
        <Tooltip
            stopPropagation
            popperClassName="tooltip reservation-origin-icon-tooltip"
            clickAwayMouseEvent="onPointerDown"
            content={
                <Label type="label" value={isManual ? t('reservations.view.manual') : t('reservations.view.online')} />
            }
        >
            <div className="reservation-origin-icon">
                {isManual ? <MenuBookOutlined /> : getPlatformIcon(reservation.platformName?.toString())}
            </div>
        </Tooltip>
    ) : null;
};

export default ReservationOriginIcon;
