import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label } from '@localina/core';
import { Link } from 'react-router-dom';
import { Path } from '../../../enums';

export const Done: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Label type="title" value={t('register.title')} variant="h3" />
            <Label type="text" value={t('register.confirmation')} />
            <div className="grow" />
            <div className="buttons">
                <Link to={Path.LOGIN}>
                    <Button label={t('register.buttons.login')} />
                </Link>
            </div>
        </>
    );
};
