import { IRestaurant, Spinner, useConfirmContext } from '@localina/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurantById, useUpdateRestaurantMutation } from '../../api/queries/restaurants';
import { Drawer } from '../Drawer';
import { PlatformsIntegration } from './Integrations';

interface IProps {
    restaurant: IRestaurant;
    isOpen: boolean;
    onClose: () => void;
}

export const PlatformsIntegrationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { snackbar } = useConfirmContext();
    const updateRestaurantMutation = useUpdateRestaurantMutation(props.restaurant.id);
    const [activePlatforms, setActivePlatforms] = useState<string[]>(props.restaurant.configuration.platforms);

    const restaurantQuery = useRestaurantById(props.restaurant.id);
    const updatePlatforms = () => {
        updateRestaurantMutation.mutate(
            { platformIds: activePlatforms },
            {
                onSuccess: props.onClose,
                onError: () => {
                    snackbar({ msg: t('integrations.items.platforms.error'), severity: 'error' });
                },
            },
        );
    };

    useEffect(() => {
        if (restaurantQuery.isSuccess && restaurantQuery.data) {
            setActivePlatforms(restaurantQuery.data.configuration.platforms);
        }
    }, [restaurantQuery.status]);

    const isLoading = updateRestaurantMutation.isLoading;

    return (
        <Drawer
            className="integration-view"
            open={props.isOpen}
            title={t('integrations.items.platforms.title')}
            onClose={props.onClose}
            onSave={updatePlatforms}
        >
            <PlatformsIntegration
                restaurant={props.restaurant}
                setActivatedPlatforms={setActivePlatforms}
                activatedPlatforms={activePlatforms}
            />
            {isLoading && <Spinner />}
        </Drawer>
    );
};
