var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClickAwayListener, List, ListItemButton, Paper, Popper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormTextField } from './FormTextField';
export function FormAutocomplete(props) {
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var inputContainerRef = useRef(null);
    var _c = useState(null), selectedOption = _c[0], setSelectedOption = _c[1];
    var fieldValue = watch([props.name])[0];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleFocus = function () {
        handleOpen();
        if (props.onFocus) {
            props.onFocus(props.name, fieldValue);
        }
    };
    var handleBlur = function () {
        handleClose();
    };
    useEffect(function () {
        props.onSearch(fieldValue);
    }, [fieldValue]);
    useEffect(function () {
        if (selectedOption) {
            setValue(props.name, props.getOptionLabel(selectedOption));
        }
    }, [selectedOption]);
    useEffect(function () {
        if (props.dirty) {
            handleBlur();
        }
    }, [props.dirty]);
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs("div", __assign({ className: "localina-autocomplete", onFocus: handleFocus, ref: inputContainerRef }, { children: [_jsx(FormTextField, { label: props.label, name: props.name, icon: props.icon, type: props.type, inputProps: {
                        autoComplete: 'new-password',
                    }, required: props.required }), _jsx(Popper, __assign({ open: open && props.options.length > 0, anchorEl: inputContainerRef.current, disablePortal: true, onBlur: handleClose, placement: 'top-start' }, { children: _jsx(Paper, { children: _jsx(List, { children: props.options.map(function (option, index) { return (_jsx(ListItemButton, __assign({ onClick: function () {
                                    setSelectedOption(option);
                                    props.onSelect(option);
                                    handleClose();
                                } }, { children: props.renderOption(option) }), index)); }) }) }) }))] })) })));
}
