import { DATE_FORMAT, ListItem, SERVER_DATE_FORMAT, StringUtils, TIME_FORMAT } from '@localina/core';
import { RemoveMinusIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { TRestaurantClosingDayTransformed } from '../../api/queries/restaurantShifts';

interface IProps {
    closingDay: TRestaurantClosingDayTransformed;
    onClick: (closingDay: TRestaurantClosingDayTransformed) => void;
}

export const ClosingDayListItem: React.FC<IProps> = (props) => {
    const title = props.closingDay.name;
    const dateFrom = DateTime.fromFormat(props.closingDay.from, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT);
    const dateTo = DateTime.fromFormat(props.closingDay.to, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT);
    const timeFrom = DateTime.fromFormat(props.closingDay.fromTime ?? '', TIME_FORMAT);
    const timeTo = DateTime.fromFormat(props.closingDay.toTime ?? '', TIME_FORMAT);

    let subtitle = '';
    if (props.closingDay.isRecurrent) {
        subtitle = StringUtils.combineStrings(
            [
                StringUtils.combineStrings([dateFrom, dateTo], ' - '),
                StringUtils.combineStrings(
                    [
                        timeFrom.isValid && timeFrom.toFormat(TIME_FORMAT),
                        timeTo.isValid && timeTo.toFormat(TIME_FORMAT),
                    ],
                    '-',
                ),
            ],
            ', ',
        );
    } else {
        subtitle = StringUtils.combineStrings(
            [
                StringUtils.combineStrings([dateFrom, timeFrom.isValid && timeFrom.toFormat(TIME_FORMAT)]),
                StringUtils.combineStrings([dateTo, timeTo.isValid && timeTo.toFormat(TIME_FORMAT)]),
            ],
            ' - ',
        );
    }

    const handleClick = () => {
        props.onClick(props.closingDay);
    };

    return <ListItem title={title} subtitle={subtitle} leftIcon={<RemoveMinusIcon />} onClick={handleClick} />;
};
