import { defaultShiftNames } from '@localina/core';

const regularShiftOrderByNameEn = [
    defaultShiftNames.wholeDay.en,
    defaultShiftNames.breakfast.en,
    defaultShiftNames.brunch.en,
    defaultShiftNames.lunch.en,
    defaultShiftNames.afternoon.en,
    defaultShiftNames.dinner.en,
];

export { regularShiftOrderByNameEn };
