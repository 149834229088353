import { Button, Checkbox, Label, StringUtils, TextField } from '@localina/core';
import { LockIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResetPasswordMutation } from '../../../api/queries/auth';

interface IProps {
    code: string;
    onNext: () => void;
    onLogin: () => void;
    resetPasswordMutation: ReturnType<typeof useResetPasswordMutation>;
}

const DefinePassword: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [isFormValid, setFormValid] = React.useState(false);

    React.useEffect(() => {
        setFormValid(StringUtils.isValidPassword(password));
    }, [password]);

    const handleClick = () => {
        props.resetPasswordMutation.mutate(
            { code: props.code, password },
            {
                onSuccess: props.onNext,
            },
        );
    };

    const handleLogin = () => {
        props.onLogin();
    };

    return (
        <>
            <Label type="title" value={t('resetPassword.title')} variant="h3" />
            <TextField
                label={t('resetPassword.fields.password')}
                name="password"
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                icon={<LockIcon />}
                required={true}
                value={password}
                onChange={setPassword}
            />
            <Checkbox
                label={t('resetPassword.fields.showPassword')}
                checked={showPassword}
                icon={true}
                onChange={setShowPassword}
            />
            {props.resetPasswordMutation.isError && <Label type="text" value={t('resetPassword.error')} error={true} />}
            <div className="buttons">
                <Button label={t('resetPassword.buttons.login')} secondary={true} onClick={handleLogin} />
                <Button label={t('resetPassword.buttons.confirm')} disabled={!isFormValid} onClick={handleClick} />
            </div>
        </>
    );
};
export default DefinePassword;
