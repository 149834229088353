import { CANVAS_TABLE_DROP_TYPE, Label } from '@localina/core';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { TablePlanObjTypes, TablePlanPlantImageSources } from '../../interfaces/entities/ITablePlan';
import { Drawer } from '../index';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const TableObjectsView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Drawer keepMounted open={props.isOpen} title={t('table.plans.objects.view.title')} onClose={props.onClose}>
            <Label
                extraClassName="table-objects-view-section-title"
                type="title"
                value={t('table.plans.objects.view.tables.title')}
            />
            <div className="table-objects-view-row">
                <DraggableItem
                    onClose={props.onClose}
                    className="table small-table "
                    objType={TablePlanObjTypes.smallTable}
                >
                    {t('table.plans.objects.view.tables.table')}
                </DraggableItem>
                <DraggableItem
                    onClose={props.onClose}
                    className="table big-table "
                    objType={TablePlanObjTypes.bigTable}
                >
                    {t('table.plans.objects.view.tables.table')}
                </DraggableItem>
            </div>
            <div className="table-objects-view-row">
                <DraggableItem
                    onClose={props.onClose}
                    className="table small-table small-table-rounded"
                    objType={TablePlanObjTypes.smallTableRounded}
                >
                    {t('table.plans.objects.view.tables.table')}
                </DraggableItem>
                <DraggableItem
                    onClose={props.onClose}
                    className="table big-table big-table-rounded"
                    objType={TablePlanObjTypes.bigTableRounded}
                >
                    {t('table.plans.objects.view.tables.table')}
                </DraggableItem>
            </div>
            <Label
                extraClassName="table-objects-view-section-title"
                type="title"
                value={t('table.plans.objects.view.buildings.title')}
            />
            <div className="table-objects-view-row">
                <DraggableItem
                    onClose={props.onClose}
                    className="buildings-obj floor"
                    objType={TablePlanObjTypes.floor}
                >
                    {t('table.plans.objects.view.tables.custom')}
                </DraggableItem>
                <DraggableItem onClose={props.onClose} className="buildings-obj wall" objType={TablePlanObjTypes.wall}>
                    {t('table.plans.objects.view.tables.wall')}
                </DraggableItem>
            </div>
            <Label
                extraClassName="table-objects-view-section-title"
                type="title"
                value={t('table.plans.objects.view.plants.title')}
            />
            <div className="table-objects-view-row">
                <DraggableItem onClose={props.onClose} className="plant-wrapper" objType={TablePlanObjTypes.plant1}>
                    <img className="plant" src={TablePlanPlantImageSources.plant1} alt="plant1" />
                </DraggableItem>
                <DraggableItem onClose={props.onClose} className="plant-wrapper" objType={TablePlanObjTypes.plant2}>
                    <img className="plant" src={TablePlanPlantImageSources.plant2} alt="plant2" />
                </DraggableItem>
                <DraggableItem onClose={props.onClose} className="plant-wrapper" objType={TablePlanObjTypes.plant3}>
                    <img className="plant" src={TablePlanPlantImageSources.plant3} alt="plant3" />
                </DraggableItem>
            </div>
        </Drawer>
    );
};

interface IDragItemProps {
    objType: string;
    children: React.ReactNode;
    className: string;
    onClose: () => void;
}

const DraggableItem: React.FC<IDragItemProps> = (props) => {
    const [{ dragging }, drag] = useDrag(
        () => ({
            isDragging: (monitor) => {
                return monitor.getItem()?.objType === props.objType;
            },

            collect: (monitor) => ({ dragging: monitor.isDragging() }),
            type: CANVAS_TABLE_DROP_TYPE,
            item: {
                objType: props.objType,
            },
        }),
        [props.objType],
    );
    useEffect(() => {
        if (dragging) {
            props.onClose();
        }
    }, [dragging]);

    return (
        <div className={props.className} ref={drag}>
            {props.children}
        </div>
    );
};
