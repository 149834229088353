var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '../Label';
export var FormField = function (_a) {
    var _b, _c;
    var name = _a.name, className = _a.className, injectErrorMessage = _a.injectErrorMessage, accepter = _a.accepter, props = __rest(_a, ["name", "className", "injectErrorMessage", "accepter"]);
    var formState = useFormContext().formState;
    var errors = formState.errors;
    var Component = accepter;
    var errorMessage = (typeof ((_b = errors[name]) === null || _b === void 0 ? void 0 : _b.message) === 'string' ? (_c = errors[name]) === null || _c === void 0 ? void 0 : _c.message : '');
    var baseProps = {
        helperText: errorMessage && injectErrorMessage ? _jsx(Label, { type: "info", value: errorMessage, error: true }) : undefined,
        error: Boolean(errorMessage),
    };
    return (_jsxs("div", __assign({ className: "form-field" }, { children: [_jsx("div", __assign({ className: className }, { children: _jsx(Controller, { name: name, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, otherProps = __rest(_b, ["ref"]);
                        return (_jsx(Component, __assign({}, otherProps, props, baseProps)));
                    } }) })), !injectErrorMessage && errorMessage && (_jsx("div", __assign({ className: "helper-text" }, { children: _jsx(Label, { type: "info", value: errorMessage, error: true }) })))] })));
};
