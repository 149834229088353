import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextField, ILocalizedError, IStaffTag, Spinner, useConfirmContext } from '@localina/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Schema } from 'yup';
import {
    useCreateStaffTagMutation,
    useDeleteStaffTagMutation,
    useUpdateStaffTagMutation,
} from '../../api/queries/staffTags';
import { Drawer } from '../../components';

interface IStaffTagViewProps {
    open: boolean;
    onClose: () => void;
    selectedTag?: IStaffTag;
}

type TStaffTagForm = Omit<IStaffTag, 'id'>;

const emptyTag: IStaffTag = {
    firstName: '',
    lastName: '',
    staffTag: '',
    id: '',
};

const StaffTagView = ({ selectedTag = emptyTag, ...props }: IStaffTagViewProps) => {
    const { t } = useTranslation();
    const { confirm, snackbar } = useConfirmContext();

    const createStaffTag = useCreateStaffTagMutation();
    const updateStaffTag = useUpdateStaffTagMutation();
    const deleteStaffTag = useDeleteStaffTagMutation();

    const onError = (err: ILocalizedError) => {
        snackbar({
            msg: t(err?.errorKey || 'staffTags.form.errorMessage'),
            severity: 'error',
        });
    };

    const staffTagSchema: Record<keyof TStaffTagForm, Schema> = {
        firstName: yup.string().required().min(2).max(40),
        lastName: yup.string().required().min(2).max(40),
        staffTag: yup.string().required().max(4),
    };

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(staffTagSchema)),
        defaultValues: {
            staffTag: selectedTag?.staffTag,
            firstName: selectedTag?.firstName,
            lastName: selectedTag?.lastName,
        },
    });

    const { isDirty, isValid } = methods.formState;

    const confirmCloseDrawer = async () => {
        if (!isDirty || (await confirm({ title: t('staffTags.form.confirmDiscardChanges.title') })) === 'yes') {
            props.onClose();
        }
    };
    const handleSubmit = () => {
        if (isValid) {
            if (selectedTag.id) {
                updateStaffTag.mutate(
                    { ...methods.getValues(), id: selectedTag.id },
                    {
                        onError,
                        onSuccess: props.onClose,
                    },
                );
            } else {
                createStaffTag.mutate(methods.getValues(), { onSuccess: props.onClose, onError });
            }
        }
    };

    const handleDelete = async () => {
        if (selectedTag?.id) {
            if ((await confirm({ title: t('staffTags.form.confirmDeleteTag.title') })) === 'yes') {
                deleteStaffTag.mutate(selectedTag.id, { onSuccess: props.onClose, onError });
            }
        }
    };

    useEffect(() => {
        if (props.open) {
            methods.reset({
                firstName: selectedTag?.firstName,
                lastName: selectedTag?.lastName,
                staffTag: selectedTag?.staffTag,
            });
        }
    }, [props.open]);

    const isLoading = createStaffTag.isLoading || updateStaffTag.isLoading || deleteStaffTag.isLoading;

    return (
        <>
            <Drawer
                open={props.open}
                onClose={confirmCloseDrawer}
                title={t('staffTags.form.title')}
                disabled={!(isDirty && isValid)}
                onSave={methods.handleSubmit(handleSubmit)}
                onDelete={selectedTag?.id ? handleDelete : undefined}
            >
                <FormProvider {...methods}>
                    <form>
                        <FormTextField name={'firstName'} label={t('staffTags.form.fields.firstName')} />
                        <FormTextField name={'lastName'} label={t('staffTags.form.fields.lastName')} />
                        <FormTextField name={'staffTag'} label={t('staffTags.form.fields.staffTag')} />
                    </form>
                </FormProvider>
            </Drawer>
            {isLoading && <Spinner />}
        </>
    );
};

export default StaffTagView;
