import { Button, Drawer as CoreDrawer, Label } from '@localina/core';
import { CloseIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    className?: string;
    title: string;
    onClose?: () => void;
    children?: React.ReactNode;
}

export const SimpleDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <CoreDrawer open={props.open} onClose={props.onClose} className={props.className} position="right">
            <div className="localina-drawer__header">
                <CloseIcon onClick={props.onClose} />
                <Label type="title" value={props.title} variant="h5" />
            </div>
            <div className="localina-drawer__body">
                <div className="localina-drawer__body__content">{props.children}</div>
                <div className="grow" />
                {props.onClose && (
                    <div className="localina-drawer__footer">
                        <div className="buttons">
                            <Button label={t('common.buttons.close')} secondary={true} onClick={props.onClose} />
                        </div>
                    </div>
                )}
            </div>
        </CoreDrawer>
    );
};
