import { useLocation, useNavigate } from 'react-router-dom';
export function useUrlParams() {
    var location = useLocation();
    var navigate = useNavigate();
    var params = new URLSearchParams(location.search);
    var onChange = function (val) {
        Object.keys(val).forEach(function (key) {
            if (val[key] !== undefined) {
                params.set(key, val[key]);
            }
            else {
                params.delete(key);
            }
        });
        navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    };
    return [params, onChange];
}
