import { IRestaurantBaseShift, IShiftBlockedSlots, TIME_FORMAT, TimeField } from '@localina/core';
import { AddCircleIcon, CheckboxDisabledIcon, TrashIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

interface IBlockedSlotWithId extends IShiftBlockedSlots {
    id: string;
}

interface IBlockedSlotsProps {
    readonly shift: IRestaurantBaseShift;
    readonly onChange: (blockedSlots: IShiftBlockedSlots[]) => void;
}

const createEmptySlot = () => ({ from: '', to: '', id: v4() });
const BlockedSlots = (props: IBlockedSlotsProps) => {
    const { t } = useTranslation();
    const [shouldUpdateShift, setShouldUpdateShift] = React.useState(false);
    const [blockedSlots, setBlockedSlots] = React.useState<IBlockedSlotWithId[]>(() =>
        props.shift.blockedSlots.map((slot) => ({ ...slot, id: v4() })),
    );
    const handleBlockedTimeChange = (field: 'from' | 'to', slotId: string) => (time: string) => {
        setBlockedSlots((prevState) =>
            prevState.map((slot) => (slotId === slot.id ? { ...slot, [field]: time } : slot)),
        );
        setShouldUpdateShift(true);
    };
    const removeBlockedTime = (slotId: string) => () => {
        setBlockedSlots((prevState) => prevState.filter(({ id }) => id !== slotId));
        setShouldUpdateShift(true);
    };
    const clearBlockedTimes = () => {
        setBlockedSlots([createEmptySlot()]);
        setShouldUpdateShift(true);
    };

    const handleAddBlockedTimeRow = () => {
        setBlockedSlots((prevState) => [...prevState, createEmptySlot()]);
        setShouldUpdateShift(true);
    };

    React.useEffect(() => {
        setBlockedSlots(
            props.shift.blockedSlots.length
                ? props.shift.blockedSlots.map((slot) => ({ ...slot, id: v4() }))
                : [createEmptySlot()],
        );
    }, [props.shift.id]);

    React.useEffect(() => {
        if (props.onChange && shouldUpdateShift) {
            props.onChange(
                blockedSlots.length &&
                    (blockedSlots.length > 1 || Boolean(blockedSlots[0].from) || Boolean(blockedSlots[0].to))
                    ? blockedSlots.map(({ from, to }) => ({
                          from,
                          to,
                      }))
                    : [],
            );
            setShouldUpdateShift(false);
        }
    }, [blockedSlots, shouldUpdateShift]);

    return (
        <React.Fragment>
            {blockedSlots.map(({ from, to, id }) => (
                <div className="availability-blocked-slot-wrapper" key={id}>
                    <div className="time-fields-wrapper">
                        <TimeField
                            name="blocked-time-from"
                            label={t('availability.shiftView.fields.from')}
                            value={from}
                            required={Boolean(from) || Boolean(to)}
                            onChange={handleBlockedTimeChange('from', id)}
                            TimePickerProps={{
                                minTime: DateTime.fromFormat(props.shift.from, TIME_FORMAT),
                                maxTime: DateTime.fromFormat(to || props.shift.to, TIME_FORMAT).minus({ minutes: 1 }),
                            }}
                        />
                        <TimeField
                            name="blocked-time-to"
                            label={t('availability.shiftView.fields.to')}
                            value={to}
                            required={Boolean(from) || Boolean(to)}
                            onChange={handleBlockedTimeChange('to', id)}
                            TimePickerProps={{
                                minTime: DateTime.fromFormat(from || props.shift.from, TIME_FORMAT).plus({
                                    minutes: 1,
                                }),
                                maxTime: DateTime.fromFormat(props.shift.to, TIME_FORMAT),
                            }}
                        />
                    </div>
                    {blockedSlots.length > 1 && (
                        <TrashIcon className="trash-icon-style" onClick={removeBlockedTime(id)} />
                    )}
                    {blockedSlots.length === 1 && (from || to) && (
                        <CheckboxDisabledIcon className="trash-icon-style" onClick={clearBlockedTimes} />
                    )}
                </div>
            ))}
            <div className="row">
                <AddCircleIcon className="add-icon-style" onClick={handleAddBlockedTimeRow} />
            </div>
        </React.Fragment>
    );
};

export default BlockedSlots;
