var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slider as MuiSlider } from '@mui/material';
import React, { useEffect } from 'react';
import { Label } from '../Label';
export var Slider = function (props) {
    var _a = React.useState(props.value), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    var handleChange = function (committed) { return function (_, result) {
        if (!props.triggerChangeEventForLastValueOnly || committed) {
            props.onChange(result);
        }
        else {
            setValue(result);
        }
    }; };
    return (_jsxs("div", __assign({ className: "localina-slider" }, { children: [_jsx(MuiSlider, { name: props.name, disabled: props.disabled, marks: props.marks, step: props.step, min: props.min, max: props.max, value: value, valueLabelDisplay: props.valueLabelDisplay, onChange: handleChange(false), onChangeCommitted: handleChange(true), valueLabelFormat: props.getLabelForValue, className: props.className }), props.label && _jsx(Label, { type: "info", value: props.label }), props.helperText && _jsx(Label, { type: "info", value: props.helperText })] })));
};
