import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import toImg from 'react-svg-to-image';
import { Button, IRestaurant, Label, Spinner, TextField } from '@localina/core';
import { Tooltip } from '@mui/material';

interface IProps {
    restaurant: IRestaurant;
}

export const QRCodeIntegration: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const tracingLink = `${window.location.origin}/widget/?id=${props.restaurant.id}&tracing`;
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    const handleCopyLink = async () => {
        await navigator.clipboard.writeText(tracingLink);
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    };

    const handleDownloadQRCode = () => {
        setLoading(true);
        setTimeout(async () => {
            await toImg('#qrcode', props.restaurant.id);
            setLoading(false);
        });
    };

    return (
        <div className="integration qrcode">
            {isLoading && <Spinner disableShrink={true} />}
            <div className="group">
                <TextField
                    label={t('integrations.items.qrcode.fields.link')}
                    name="link"
                    disabled={true}
                    multiline={true}
                    minRows={2}
                    value={tracingLink}
                />
                <div className="qrcode">
                    <QRCode id="qrcode" level="H" size={300} value={tracingLink} />
                </div>
                <div className="buttons">
                    <Tooltip
                        open={showTooltip}
                        title={<Label type="info" value={t('integrations.items.qrcode.feedback')} />}
                    >
                        <Button
                            label={t('integrations.items.qrcode.buttons.copy')}
                            secondary={true}
                            onClick={handleCopyLink}
                        />
                    </Tooltip>
                    <Button
                        label={t('integrations.items.qrcode.buttons.download')}
                        secondary={true}
                        onClick={handleDownloadQRCode}
                    />
                </div>
            </div>
        </div>
    );
};
