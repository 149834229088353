import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload, IRestaurant, LocalinaLogo, TextField } from '@localina/core';
import { CloseIcon } from '@localina/icons';

interface IProps {
    restaurant: IRestaurant;
    onChange: (image?: File) => void;
}

export const RestaurantLogoIntegration: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [image, setImage] = React.useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const { logo } = props.restaurant.configuration;
        if (logo) {
            const substrings = logo.split('/');
            const name = substrings[substrings.length - 1];
            const file = new File([], name);
            setImage(file);
            setImageSrc(`${window.location.origin}/${logo}`);
            props.onChange(file);
        }
    }, []);

    const handleImageChange = (item?: File) => {
        setImage(item);
        setImageSrc(item ? URL.createObjectURL(item) : undefined);
        props.onChange(item);
    };

    const handleImageClear = () => {
        handleImageChange(undefined);
    };

    return (
        <div className="integration logo">
            <div className="logo-preview">{imageSrc ? <img className="logo" src={imageSrc} /> : <LocalinaLogo />}</div>
            <div className="group">
                <div className="row">
                    <TextField
                        label={t('integrations.items.logo.fields.image')}
                        name="image"
                        helperText={t('integrations.items.logo.helperTexts.image')}
                        value={image?.name ?? ''}
                        disabled={true}
                    />
                    {image && <CloseIcon onClick={handleImageClear} />}
                </div>
                <div className="buttons">
                    <FileUpload
                        accept="image/*"
                        label={t('integrations.items.logo.buttons.upload')}
                        onChange={handleImageChange}
                    />
                </div>
            </div>
        </div>
    );
};
