import { Button, Label, ReservationStatus, Spinner, useConfirmContext, useUrlParams } from '@localina/core';
import { Paper } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePublicReservationByToken, useUpdateReservationStatusByToken } from '../../api/queries/reservations';
import RequestedReservationDetails from '../../components/ReservationView/RequestedReservationDetails';
import { Path } from '../../enums';

enum QueryParamAction {
    APPROVE = 'approve',
    DECLINE = 'decline',
}

const ReviewPendingReservation = () => {
    const { t } = useTranslation();
    const [urlParams, setUrlParams] = useUrlParams();
    const actionInUrl = urlParams.get('action');
    const restaurantId = urlParams.get('restaurantId');
    const reservationToken = urlParams.get('token');

    const { confirm, snackbar } = useConfirmContext();
    const navigate = useNavigate();

    const action = useMemo(() => {
        switch (actionInUrl) {
            case QueryParamAction.APPROVE:
                return QueryParamAction.APPROVE;
            case QueryParamAction.DECLINE:
                return QueryParamAction.DECLINE;
            default:
                return null;
        }
    }, [actionInUrl]);

    const reservationQuery = usePublicReservationByToken(restaurantId || '', reservationToken || '', {
        enabled: Boolean(reservationToken && restaurantId),
    });
    const updateReservationStatusByTokenMutation = useUpdateReservationStatusByToken();

    const reservation = reservationQuery.data;

    const reservationIsValidForReviewPage =
        reservation &&
        [
            ReservationStatus.APPROVAL_PENDING,
            ReservationStatus.DECLINED,
            ReservationStatus.APPROVED,
            ReservationStatus.GUEST_CANCELLED,
        ].includes(reservation.status);
    const isProcessed = reservation && reservation.status !== ReservationStatus.APPROVAL_PENDING;
    const isLoading = reservationQuery.isInitialLoading || updateReservationStatusByTokenMutation.isLoading;

    const redirectToDashboard = () => {
        navigate(Path.RESTAURANT_DASHBOARD);
    };
    const handleButtonActionClick =
        (newStatus: ReservationStatus.APPROVED | ReservationStatus.DECLINED) =>
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (!isProcessed) {
                e.stopPropagation();
                void confirmActionTaken(
                    newStatus === ReservationStatus.APPROVED ? QueryParamAction.APPROVE : QueryParamAction.DECLINE,
                );
            }
        };

    const confirmActionTaken = useCallback(
        async (a: QueryParamAction) => {
            if (reservation) {
                const isAccepting = a === QueryParamAction.APPROVE;
                if (
                    (await confirm({
                        msg: isAccepting
                            ? t('reservations.reviewPendingReservation.confirmMessage.accept')
                            : t('reservations.reviewPendingReservation.confirmMessage.reject'),
                    })) === 'yes'
                ) {
                    updateReservationStatus(isAccepting ? ReservationStatus.APPROVED : ReservationStatus.DECLINED);
                } else {
                    clearUrlParams();
                }
            }
        },
        [reservation],
    );
    const updateReservationStatus = (status: ReservationStatus) => {
        if (reservation && reservationToken && restaurantId) {
            updateReservationStatusByTokenMutation.mutate(
                { status, token: reservationToken, restaurantId, reservationId: reservation.reservationId },
                {
                    onSuccess: () => {
                        snackbar({
                            msg:
                                status === ReservationStatus.APPROVED
                                    ? t('reservations.reviewPendingReservation.infoMessage.accepted')
                                    : t('reservations.reviewPendingReservation.infoMessage.rejected'),
                            severity: 'success',
                        });
                        return reservationQuery.refetch();
                    },
                    onError: () => {
                        snackbar({
                            msg: t('common.infoMessages.commonError'),
                            severity: 'error',
                        });
                        return reservationQuery.refetch();
                    },
                },
            );
        }
    };

    const clearUrlParams = useCallback(() => {
        setUrlParams({ action: undefined });
    }, []);

    useEffect(() => {
        if (reservation) {
            if (action !== null && reservation.status === ReservationStatus.APPROVAL_PENDING) {
                void confirmActionTaken(action);
            } else {
                clearUrlParams();
            }
        }
    }, [action, reservation]);

    return (
        <div className={'review-pending-reservation-container'}>
            <Label
                type={'title'}
                variant={'h5'}
                value={t('reservations.reviewPendingReservation.title')}
                align={'center'}
            />
            {reservationIsValidForReviewPage && (
                <Paper>
                    <RequestedReservationDetails
                        reservationDateTime={reservation?.reservationDateTime}
                        restaurantName={reservation?.restaurantName}
                        shiftName={reservation?.shift.name.en}
                        area={{
                            name: reservation?.area.name,
                            code: reservation?.area.code,
                        }}
                        guestInfo={reservation?.guestInfo}
                        comment={reservation?.comment}
                        status={reservation?.status}
                    />
                    {!isProcessed && (
                        <div className={'buttons'}>
                            <Button
                                label={t('reservations.requestedReservationView.actions.decline')}
                                onClick={handleButtonActionClick(ReservationStatus.DECLINED)}
                                secondary
                            />
                            <Button
                                label={t('reservations.requestedReservationView.actions.accept')}
                                onClick={handleButtonActionClick(ReservationStatus.APPROVED)}
                            />
                        </div>
                    )}
                </Paper>
            )}
            {!reservation && !isLoading && (
                <Label
                    type={'text'}
                    align={'center'}
                    value={t('reservations.reviewPendingReservation.reservationNotFound')}
                />
            )}
            {reservation && !reservationIsValidForReviewPage && (
                <Label
                    type={'text'}
                    align={'center'}
                    value={t('reservations.reviewPendingReservation.reservationIsNotValidForReview')}
                />
            )}
            <Label
                type={'link'}
                value={t('reservations.reviewPendingReservation.navigateToApplicationLink')}
                onClick={redirectToDashboard}
            />
            {isLoading && <Spinner />}
        </div>
    );
};

export default ReviewPendingReservation;
