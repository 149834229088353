var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
export var FileUpload = function (props) {
    var handleFileChange = function (e) {
        var _a;
        var file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a.item(0);
        if (file) {
            props.onChange(file);
        }
    };
    return (_jsxs("div", __assign({ className: "localina-fileupload" }, { children: [_jsx("input", { accept: props.accept, type: "file", id: "file-upload", style: { display: 'none' }, onChange: handleFileChange }), _jsx("label", __assign({ htmlFor: "file-upload" }, { children: _jsx(Button, __assign({ className: "localina-button secondary", component: "span", disableRipple: true, variant: "outlined" }, { children: props.label })) }))] })));
};
