import { ReactComponent as addCircle } from './add_circle.svg';
import { ReactComponent as add } from './add.svg';
import { ReactComponent as arrowDown } from './arrow_down.svg';
import { ReactComponent as arrowLeft } from './arrow_left.svg';
import { ReactComponent as arrowUp } from './arrow_up.svg';
import { ReactComponent as article } from './article.svg';
import { ReactComponent as bookNew } from './book_new.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as cancel } from './cancel.svg';
import { ReactComponent as checkboxDisabledSelected } from './checkbox_disabled_selected.svg';
import { ReactComponent as checkboxDisabled } from './checkbox_disabled.svg';
import { ReactComponent as checkboxSelected } from './checkbox_selected.svg';
import { ReactComponent as checkbox } from './checkbox.svg';
import { ReactComponent as chevronDown } from './chevron_down.svg';
import { ReactComponent as chevronDownAlt } from './chevron_down_alt.svg';
import { ReactComponent as chevronLeft } from './chevron_left.svg';
import { ReactComponent as chevronRight } from './chevron_right.svg';
import { ReactComponent as chevronUp } from './chevron_up.svg';
import { ReactComponent as chevronUpAlt } from './chevron_up_alt.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as company } from './company.svg';
import { ReactComponent as contactTracing } from './contact_tracing.svg';
import { ReactComponent as cookie } from './cookie.svg';
import { ReactComponent as date } from './date.svg';
import { ReactComponent as disabled } from './disabled.svg';
import { ReactComponent as done } from './done.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as fileUpload } from './file_upload.svg';
import { ReactComponent as foodBank } from './food_bank.svg';
import { ReactComponent as importExportHorizontal } from './import_export_horizontal.svg';
import { ReactComponent as importExportVertical } from './import_export_vertical.svg';
import { ReactComponent as info } from './info.svg';
import { ReactComponent as language } from './language.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as logoLocalina } from './logo_localina.svg';
import { ReactComponent as logoLocalsearch } from './logo_localsearch.svg';
import { ReactComponent as menu } from './menu.svg';
import { ReactComponent as mobile } from './mobile.svg';
import { ReactComponent as nightLife } from './night_life.svg';
import { ReactComponent as notification } from './notification.svg';
import { ReactComponent as notificationsAvailable } from './notifications_available.svg';
import { ReactComponent as openInNew } from './open_in_new.svg';
import { ReactComponent as people } from './people.svg';
import { ReactComponent as person } from './person.svg';
import { ReactComponent as question } from './question.svg';
import { ReactComponent as removeMinus } from './remove_minus.svg';
import { ReactComponent as restaurantLogo } from './restaurant_logo.svg';
import { ReactComponent as restaurantMenu } from './restaurant_menu.svg';
import { ReactComponent as restaurantQrCode } from './restaurant_qrcode.svg';
import { ReactComponent as restaurant } from './restaurant.svg';
import { ReactComponent as ringBell } from './ring_bell.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as table } from './table.svg';
import { ReactComponent as textAfter } from './text_after.svg';
import { ReactComponent as textBefore } from './text_before.svg';
import { ReactComponent as textblock } from './textblock.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as unpublished } from './unpublished.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as walkin } from './walkin.svg';
import { ReactComponent as emptyCircle } from './empty_circle.svg';
import { ReactComponent as redEmptyCircle } from './red_empty_circle.svg';
import { ReactComponent as redDollarCircle } from './red_dollar_circle.svg';
import { ReactComponent as brownDollarCircle } from './brown_dollar_circle.svg';
import { ReactComponent as brownEmptyCircle } from './brown_empty_circle.svg';
import { ReactComponent as dollarCircle } from './dollar_circle.svg';
import { ReactComponent as houseWithDots } from './house_with_dots.svg';
import { ReactComponent as verticalDots } from './vertical_dots.svg';
import { ReactComponent as save } from './save.svg';
import { ReactComponent as tableObject } from './table_objects.svg';
import { ReactComponent as switchTablePlanReservation } from './switch_table_plan_reservations.svg';
import { ReactComponent as switchReservationsTablePlan } from './switch_reservations_table_plan.svg';
import { ReactComponent as favorite } from './favorite.svg';
import { ReactComponent as phoneContact } from './phone_contact.svg';
import { ReactComponent as favoriteBorder } from './favorite_border.svg';
import { ReactComponent as tableEmpty } from './table-empty.svg';
import { ReactComponent as genderSymbol } from './gender_symbol.svg';
import { ReactComponent as maintenace } from './maintenance.svg';
import { ReactComponent as google } from './google.svg';
export var Icons = {
    addCircle: addCircle,
    add: add,
    arrowDown: arrowDown,
    arrowLeft: arrowLeft,
    arrowUp: arrowUp,
    article: article,
    bookNew: bookNew,
    calendar: calendar,
    cancel: cancel,
    checkboxDisabledSelected: checkboxDisabledSelected,
    checkboxDisabled: checkboxDisabled,
    checkboxSelected: checkboxSelected,
    checkbox: checkbox,
    chevronDown: chevronDown,
    chevronDownAlt: chevronDownAlt,
    chevronLeft: chevronLeft,
    chevronRight: chevronRight,
    chevronUp: chevronUp,
    chevronUpAlt: chevronUpAlt,
    clock: clock,
    close: close,
    company: company,
    contactTracing: contactTracing,
    cookie: cookie,
    date: date,
    disabled: disabled,
    done: done,
    download: download,
    edit: edit,
    email: email,
    favorite: favorite,
    favoriteBorder: favoriteBorder,
    phoneContact: phoneContact,
    fileUpload: fileUpload,
    foodBank: foodBank,
    genderSymbol: genderSymbol,
    google: google,
    importExportHorizontal: importExportHorizontal,
    importExportVertical: importExportVertical,
    info: info,
    language: language,
    lock: lock,
    logoLocalina: logoLocalina,
    logoLocalsearch: logoLocalsearch,
    maintenace: maintenace,
    menu: menu,
    mobile: mobile,
    nightLife: nightLife,
    notification: notification,
    notificationsAvailable: notificationsAvailable,
    openInNew: openInNew,
    people: people,
    person: person,
    question: question,
    removeMinus: removeMinus,
    restaurantLogo: restaurantLogo,
    restaurantMenu: restaurantMenu,
    restaurantQrCode: restaurantQrCode,
    restaurant: restaurant,
    ringBell: ringBell,
    search: search,
    table: table,
    tableEmpty: tableEmpty,
    textAfter: textAfter,
    textBefore: textBefore,
    textblock: textblock,
    trash: trash,
    unpublished: unpublished,
    upload: upload,
    walkin: walkin,
    houseWithDots: houseWithDots,
    emptyCircle: emptyCircle,
    redEmptyCircle: redEmptyCircle,
    brownEmptyCircle: brownEmptyCircle,
    dollarCircle: dollarCircle,
    redDollarCircle: redDollarCircle,
    brownDollarCircle: brownDollarCircle,
    verticalDots: verticalDots,
    save: save,
    tableObject: tableObject,
    switchTablePlanReservation: switchTablePlanReservation,
    switchReservationsTablePlan: switchReservationsTablePlan,
};
