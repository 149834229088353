import { IRestaurant, Label, TIME_FORMAT } from '@localina/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurantShiftTablePlansPreview } from '../../../interfaces/store/restaurant/IRestaurantShiftTablePlansPreview';
import { ZoomSlider } from '../../Sliders/ZoomSlider';
import TablePlansMenu from './TablePlansMenu';

interface IProps {
    zoomValue: number;
    onZoomChange: (value: number) => void;
    selectedTime: number;
    onTimeChange: (value: number) => void;
    selectedTablePlanId?: string;
    onTablePlanChange: (id: string) => void;
    shiftTablePlans: IRestaurantShiftTablePlansPreview[];
    selectedShift?: IRestaurant['configuration']['shifts'][0];
}

export const TablePlanAssignReservationsController: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="table-plan-assign-reservations-controller">
            <TablePlansMenu
                shiftTablePlans={props.shiftTablePlans}
                selectedTablePlanId={props.selectedTablePlanId}
                selectedShift={props.selectedShift}
                onTablePlanChange={props.onTablePlanChange}
            />
            <div className="spacer" />
            <Label
                type={'info'}
                value={t('reservations.table.displayedTablePlanTime', {
                    time: DateTime.now().startOf('day').set({ minute: props.selectedTime }).toFormat(TIME_FORMAT),
                })}
            />
            {/*<TablePlanShiftSlider selectedTime={props.selectedTime} onTimeChange={props.onTimeChange} />*/}
            <ZoomSlider min={1} max={10} value={props.zoomValue} onZoomChange={props.onZoomChange} />
        </div>
    );
};
