import { ISelectOption, ITablePlan, Label, SelectField, Slider, Switch } from '@localina/core';
import { FoodBankIcon } from '@localina/icons';
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurantAreaAccordionPreview } from '../../interfaces';

interface IProps {
    area: IRestaurantAreaAccordionPreview;
    showTablePlan: boolean;
    onSliderChange: (value: number, areaId: string) => void;
    onDirectlyBookableChange: (value: boolean, areaId: string) => void;
    onAssignAreaChange: (value: boolean, areaId: string) => void;
    onTablePlanChange: (value: string, areaId: string) => void;
}

export const AreaAccordionItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const handleSliderLabelForValue = (value: number) => `${Math.round((props.area.capacity * value) / 100)}`;
    const tablePlanOptions: ISelectOption[] = props.area.tablePlans.map((tablePlan: ITablePlan) => ({
        label: tablePlan.name,
        value: tablePlan.id,
    }));
    const [selectedTablePlan] = useState(props.area.tablePlanId);

    if (selectedTablePlan !== '' && selectedTablePlan !== null) {
        tablePlanOptions.unshift({
            label: t('availability.shiftView.fields.noTablePlanAssigned'),
            value: '',
        });
    }

    return (
        <div className="area-accordion-item">
            <MuiAccordion className="accordion-style" expanded={props.area.isEnabled}>
                <MuiAccordionSummary
                    expandIcon={
                        <Switch
                            checked={props.area.isEnabled}
                            onChange={(e: boolean) => props.onAssignAreaChange(e, props.area.id)}
                        />
                    }
                    className="accordion-summary-style"
                >
                    <FoodBankIcon />
                    <Typography className="typography-margin-left"> {props.area.name}</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails className="accordion-details-style">
                    <Label
                        type="text"
                        value={t('availability.shiftView.fields.capacityPercentage')}
                        extraClassName={'capacity-percentage-label'}
                        tooltip={t('availability.shiftView.fields.capacityPercentageInfo')}
                    />
                    <Slider
                        name="capacityPercentage"
                        value={props.area.capacityPercentage}
                        onChange={(e: number) => props.onSliderChange(e, props.area.id)}
                        step={100 / props.area.capacity}
                        getLabelForValue={handleSliderLabelForValue}
                        valueLabelDisplay={'on'}
                        triggerChangeEventForLastValueOnly
                        marks={[
                            { value: 2, label: '0%' },
                            { value: 25, label: '25%' },
                            { value: 50, label: '50%' },
                            { value: 75, label: '75%' },
                            { value: 96, label: '100%' },
                        ]}
                    />
                    {props.showTablePlan && (
                        <SelectField
                            label={
                                props.area.tablePlans.length === 0
                                    ? t('availability.shiftView.fields.noTablePlanAssigned')
                                    : t('availability.shiftView.fields.tablePlan')
                            }
                            value={props.area.tablePlanId || ''}
                            disabled={props.area.tablePlans.length === 0}
                            options={tablePlanOptions}
                            onChange={(e: string) => props.onTablePlanChange(e, props.area.id)}
                        />
                    )}

                    <Switch
                        label={t('availability.shiftView.fields.directlyBookable')}
                        checked={!props.area.directlyBookable}
                        onChange={(e: boolean) => props.onDirectlyBookableChange(!e, props.area.id)}
                        tooltip={t('availability.shiftView.fields.directlyBookableInfo')}
                    />
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
};
