import { ILocalizedError, ISaveTablePlanRequest, ITablePlanDesignRequest } from '@localina/core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { useAreas } from './restaurantAreas';

const useDeleteTablePlan = (options?: UseMutationOptions<void, ILocalizedError, { tablePlanId: string }>) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.deleteTablePlan(restaurantId, variables.tablePlanId);
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKeys.restaurants.all);
        },
    });
};
const useCreateTablePlan = (
    options?: UseMutationOptions<
        string,
        ILocalizedError,
        {
            tablePlan: ISaveTablePlanRequest;
        }
    >,
) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.saveTablePlan(restaurantId, variables.tablePlan);
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKeys.restaurants.all);
        },
    });
};
const useUpdateTablePlan = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            tablePlanId: string;
            tablePlan: ISaveTablePlanRequest;
        }
    >,
) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.updateTablePlan(
                restaurantId,
                variables.tablePlanId,
                variables.tablePlan,
            );
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKeys.restaurants.all);
        },
    });
};

const useSaveTableEditorTableDetails = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            tablePlanId: string;
            tablePlanDesignRequest: ITablePlanDesignRequest;
        }
    >,
) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.saveTablePlanDesign(
                restaurantId,
                variables.tablePlanId,
                variables.tablePlanDesignRequest,
            );
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKeys.restaurants.all);
        },
    });
};

const useDeleteTableEditorTableDetails = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            tableId: string;
            tablePlanId: string;
        }
    >,
) => {
    const restaurantId = useRestaurantId();
    const areasQuery = useAreas();
    const saveTableEditorTableDetails = useSaveTableEditorTableDetails();
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.deleteTableFromTableEditor(
                restaurantId,
                variables.tablePlanId,
                variables.tableId,
            );
        },
        onSuccess: (_, variables) => {
            const area = areasQuery.data?.areas.find(
                ({ tablePlans }) => tablePlans.find(({ id }) => id === variables.tablePlanId) !== undefined,
            );
            const tablePlan = area?.tablePlans.find(({ id }) => id === variables.tablePlanId);
            if (tablePlan) {
                return saveTableEditorTableDetails.mutateAsync({
                    tablePlanId: variables.tablePlanId,
                    tablePlanDesignRequest: {
                        areaId: area?.id || '',
                        tables: tablePlan.tables.filter((t) => t.id !== variables.tableId),
                        canvas: {
                            ...tablePlan.canvas,
                            width: tablePlan.canvas?.width || 0,
                            height: tablePlan.canvas?.height || 0,
                            elements: (tablePlan.canvas?.elements || []).filter((e) => e.tableId !== variables.tableId),
                        },
                    },
                });
            } else {
                return queryClient.invalidateQueries(queryKeys.restaurants.all);
            }
        },
    });
};

export {
    useDeleteTablePlan,
    useCreateTablePlan,
    useUpdateTablePlan,
    useSaveTableEditorTableDetails,
    useDeleteTableEditorTableDetails,
};
