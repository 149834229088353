import {
    DateTimeUtils,
    DAY_DATE_FORMAT,
    IRestaurantShift,
    Label,
    ListItem,
    SERVER_DATE_FORMAT,
    TIME_FORMAT,
    TLanguageKey,
} from '@localina/core';
import { NightLifeIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    specialOpeningHour: IRestaurantShift;
    onClick: (specialOpeningHour: IRestaurantShift) => void;
}

export const SpecialOpeningHourListItem: React.FC<IProps> = (props) => {
    const { i18n } = useTranslation();

    const title = props.specialOpeningHour.name[i18n.language as TLanguageKey];

    const handleClick = () => {
        props.onClick(props.specialOpeningHour);
    };

    const dates = props.specialOpeningHour.shiftType === 'soh' ? props.specialOpeningHour.dates : [];

    const datesAreConsecutive = useMemo(
        () => dates && DateTimeUtils.areDatesConsecutive(dates.map((v) => DateTime.fromFormat(v, SERVER_DATE_FORMAT))),
        [JSON.stringify(dates)],
    );

    const formatDate = (date: string) =>
        DateTime.fromFormat(date, SERVER_DATE_FORMAT).toFormat(DAY_DATE_FORMAT, { locale: i18n.language });

    const datesSubtitle =
        datesAreConsecutive && dates.length > 1
            ? `${formatDate(dates[0])} - ${formatDate(dates[dates.length - 1])}`
            : dates.map(formatDate).join(', ');

    const timeFrom = DateTime.fromFormat(props.specialOpeningHour.from, TIME_FORMAT).toFormat(TIME_FORMAT);
    const timeTo = DateTime.fromFormat(props.specialOpeningHour.to, TIME_FORMAT).toFormat(TIME_FORMAT);
    const hoursSubtitle = `${timeFrom} - ${timeTo}`;

    return props.specialOpeningHour.shiftType !== 'soh' ? null : (
        <ListItem
            title={title}
            subtitle={
                <div className={'list-item__specialopeninghour__subtitle'}>
                    <Label type={'info'} value={datesSubtitle} />
                    <Label type={'info'} value={`–`} />
                    <Label type={'info'} value={hoursSubtitle} />
                </div>
            }
            leftIcon={<NightLifeIcon />}
            onClick={handleClick}
        />
    );
};
