import {
    IFinishedImportMetadataItem,
    IImportMetadataItem,
    IImportMetadataResponse,
    ILocalizedError,
    ImportStatus,
    IOngoingImportMetadataItem,
    IScheduledImportMetadataItem,
} from '@localina/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { LocalinaApiContext } from '../../../index';
import { queryKeys } from './query-keys';

type IImportMetadataQueryTransformation = {
    all: IImportMetadataItem[];
    ongoing: IOngoingImportMetadataItem[];
    scheduled: IScheduledImportMetadataItem[];
    finished: IFinishedImportMetadataItem[];
};

const REFETCH_IMPORT_METADATA_INTERVAL = 1500;

const useImportMetadata = (
    restaurantId: string,
    forceWaitingForActiveImport?: boolean,
    options?: UseQueryOptions<IImportMetadataResponse, ILocalizedError, IImportMetadataQueryTransformation>,
) => {
    const [refetchInterval, setRefetchInterval] = useState<number | undefined>(undefined);

    const query = useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getImportMetadata(restaurantId);
        },
        queryKey: queryKeys.importProgress.all,
        refetchInterval,
        select: (data) => {
            const restaurantData = data.sort((itemA, itemB) =>
                DateTime.fromISO(itemA.createdDateTime).diff(DateTime.fromISO(itemB.createdDateTime)).toMillis() > 0
                    ? -1
                    : 1,
            );
            return {
                all: restaurantData,
                ongoing: restaurantData.filter(
                    (item): item is IOngoingImportMetadataItem => item.importStatus === ImportStatus.ONGOING,
                ),
                scheduled: restaurantData.filter(
                    (item): item is IScheduledImportMetadataItem => item.importStatus === ImportStatus.SCHEDULED,
                ),
                finished: restaurantData.filter(
                    (item): item is IFinishedImportMetadataItem => item.importStatus === ImportStatus.SUCCESS,
                ),
            };
        },
        ...options,
    });

    useEffect(() => {
        if (!query.data?.ongoing.length && !forceWaitingForActiveImport) {
            setRefetchInterval(undefined);
        } else {
            setRefetchInterval(REFETCH_IMPORT_METADATA_INTERVAL);
        }
    }, [query.data?.ongoing.length, forceWaitingForActiveImport]);

    return query;
};

export { useImportMetadata };
