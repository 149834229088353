import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components';
import AvailabilityContent from './AvailabilityContent';

const Availability = () => {
    const { t } = useTranslation();

    return (
        <Page name="availability" title={t('availability.title')}>
            <AvailabilityContent />
        </Page>
    );
};

export default Availability;
