import { ISaveAreaRequest, SelectField, TextField } from '@localina/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useRestaurant } from '../../api/queries/restaurants';

interface IProps {
    restaurantAreaDetails?: ISaveAreaRequest;
    onChange: (restaurantArea: ISaveAreaRequest) => void;
}

const AreaCreationDetails: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [restaurantAreaDetails, setRestaurantAreaDetails] = React.useState<ISaveAreaRequest>({
        id: '',
        capacity: 0,
        name: '',
        code: '',
        virtualAreaId: '',
    });

    const [canEditAreas] = useHaveAccountFeatures(['areasEdit']);
    const restaurantQuery = useRestaurant();

    const virtualAreasButtonOptions = useMemo(
        () =>
            (restaurantQuery.data?.configuration.virtualAreas || []).map(({ name, id }) => ({
                label: name,
                value: id,
            })),
        [restaurantQuery.data?.configuration.virtualAreas],
    );

    React.useEffect(() => {
        if (props.restaurantAreaDetails) {
            setRestaurantAreaDetails(props.restaurantAreaDetails);
        }
    }, []);

    const getStringFromNumber = (value?: number | undefined) => (value ? `${value}` : '');

    const handleChangeAreaCreation = (field: string) => (value: string | boolean | number) => {
        if (field == 'code' && typeof value === 'string') value = value.toUpperCase();
        if (restaurantAreaDetails) {
            const updatedRestaurantAreas = {
                ...restaurantAreaDetails,
                [field]: typeof value === 'number' ? +value : value,
            };

            setRestaurantAreaDetails(updatedRestaurantAreas);
            props.onChange(updatedRestaurantAreas);
        }
    };

    return (
        <div className="area-creation-details">
            <TextField
                name="areaName"
                label={t('sectors.create.areaName')}
                value={restaurantAreaDetails?.name}
                type="text"
                required={true}
                onChange={handleChangeAreaCreation('name')}
            />
            <TextField
                name="areaCode"
                label={t('sectors.create.areaCode')}
                value={restaurantAreaDetails?.code}
                type="text"
                required={true}
                helperText={t('sectors.create.areaCodeHelperText')}
                onChange={handleChangeAreaCreation('code')}
            />
            <TextField
                name="areaCapacity"
                label={t('sectors.create.areaCapacity')}
                type="number"
                required={true}
                helperText={t('sectors.create.howManySeatedGuests')}
                value={getStringFromNumber(restaurantAreaDetails?.capacity)}
                onChange={handleChangeAreaCreation('capacity')}
            />

            {virtualAreasButtonOptions && (
                <SelectField
                    label={t('sectors.create.belongsTo')}
                    options={virtualAreasButtonOptions}
                    disabled={!canEditAreas}
                    value={restaurantAreaDetails?.virtualAreaId}
                    onChange={handleChangeAreaCreation('virtualAreaId')}
                />
            )}
        </div>
    );
};

export default AreaCreationDetails;
