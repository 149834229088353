import {
    Button,
    IPlatform,
    IRestaurant,
    Label,
    PlatformName,
    RestaurantSubscription,
    Switch,
    useConfirmContext,
} from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccount } from '../../../api/queries/account';
import { useActivePlatforms } from '../../../api/queries/platforms';
import { AccountUtils } from '../../../utils';
import { getPlatformIcon } from '../../../utils/PlatformUtils';

interface IPlatformsIntegration {
    restaurant: IRestaurant;
    setActivatedPlatforms: (platforms: string[]) => void;
    activatedPlatforms: string[];
    buttons?: IButtonProps[];
    wizard?: boolean;
}

interface IButtonProps {
    label: string;
    variant: 'primary' | 'secondary';
    onClick: () => void;
}

export const PlatformsIntegration = (props: IPlatformsIntegration) => {
    const { t } = useTranslation();
    const { alert, confirm } = useConfirmContext();

    const platformsQuery = useActivePlatforms();
    const accountQuery = useGetAccount();

    const handlePlatformEnabled = (platform: IPlatform) => async (value: boolean) => {
        if (!isPlatformAllowed(platform.name)) {
            alert({
                msg: t('common.features.platforms'),
                title: t('common.features.missingFeatureTitle'),
            });
            return;
        }
        if (platform.name === PlatformName.GOOGLE && value) {
            if (
                (await confirm({
                    title: t('integrations.items.platforms.googleTermsAlert.title'),
                    msg: t('integrations.items.platforms.labels.infoGoogle'),
                    cancelLabel: t('common.buttons.cancel'),
                    confirmLabel: t('common.buttons.ok'),
                })) === 'yes'
            ) {
                props.setActivatedPlatforms([...props.activatedPlatforms, platform.platformId]);
            }
        } else {
            props.setActivatedPlatforms(
                value
                    ? [...props.activatedPlatforms, platform.platformId]
                    : props.activatedPlatforms.filter((it) => it !== platform.platformId),
            );
        }
    };

    const isPlatformAllowed = (platformName: PlatformName): boolean => {
        return (
            AccountUtils.getSubscription(accountQuery.data, props.restaurant.id) === RestaurantSubscription.STANDARD ||
            platformName !== PlatformName.GOOGLE
        );
    };
    const getInfoText = (platformName: string): string | undefined => {
        switch (platformName) {
            case PlatformName.GOOGLE:
                return t('integrations.items.platforms.labels.infoGoogle');
            case PlatformName.LOCALCH:
                return t('integrations.items.platforms.labels.infoLocalCh');
        }
    };

    return (
        <>
            <div className={'platforms-content-container'}>
                {platformsQuery.data?.map((platform) => (
                    <Switch
                        key={platform.platformId}
                        icon={getPlatformIcon(platform.name)}
                        label={t([`integrations.items.platforms.labels.${platform.name}`, platform.name])}
                        checked={props.activatedPlatforms.includes(platform.platformId)}
                        onChange={handlePlatformEnabled(platform)}
                        tooltip={getInfoText(platform.name)}
                        tooltipProps={{ className: 'margin-right', popperClassName: 'multiline-text' }}
                    />
                ))}
                {props.wizard === true && <Label type={'text'} value={t('integrations.items.platforms.info')} />}
            </div>
            {props.buttons !== undefined && (
                <div className="buttons">
                    {props.buttons.map(({ label, variant, onClick }) => (
                        <Button label={label} onClick={onClick} secondary={variant === 'secondary'} key={label} />
                    ))}
                </div>
            )}
        </>
    );
};
