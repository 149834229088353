var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { StringUtils } from '../../utils';
import { Label } from '../Label';
export var InputField = function (props) {
    var handleKeyEvent = useDebouncedCallback(function (event) {
        var _a, _b;
        if (!props.disableBlurOnEnter && event.key === 'Enter') {
            (_b = (_a = props.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.blur();
            if (props.onEnter) {
                props.onEnter();
            }
        }
    });
    React.useEffect(function () {
        var _a, _b;
        (_b = (_a = props.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.addEventListener('keyup', handleKeyEvent);
        return function () {
            var _a, _b;
            (_b = (_a = props.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('keyup', handleKeyEvent);
        };
    }, [props.inputRef]);
    var hasIcon = props.icon && typeof props.icon !== 'boolean';
    var useIcon = props.icon && typeof props.icon === 'boolean';
    var classNames = StringUtils.combineStrings([
        'localina-inputfield',
        props.name,
        !!hasIcon && 'has-icon',
        !!useIcon && 'use-icon',
    ]);
    return (_jsxs("div", __assign({ className: classNames }, { children: [_jsxs("div", __assign({ className: "field-container" }, { children: [props.icon, props.children] })), props.helperText && (_jsx("div", __assign({ className: "helper-text" }, { children: typeof props.helperText === 'string' ? (_jsx(Label, { type: "info", value: props.helperText })) : (props.helperText) })))] })));
};
