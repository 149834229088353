import { Button, IAccountRestaurant, Label, Spinner, useConfirmContext } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useDeleteRestaurantGuestsAndReservations,
    useDeleteRestaurantReservations,
} from '../../api/queries/supportGuestDatabase';

interface IDeleteDatabaseSectionProps {
    restaurant?: IAccountRestaurant;
}

const DeleteDatabaseSection = (props: IDeleteDatabaseSectionProps) => {
    const { t } = useTranslation();
    const { confirm, confirmWithTextCopy, snackbar } = useConfirmContext();

    const deleteGuestsAndReservationsMutation = useDeleteRestaurantGuestsAndReservations();
    const deleteReservationsOnlyMutation = useDeleteRestaurantReservations();

    const onDeleteDatabase = (type: 'reservations' | 'guestsAndReservations') => async () => {
        if (
            props.restaurant?.id &&
            (await confirm({
                title: t(`supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.${type}.title`),
                msg: t(`supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.${type}.confirmDialogMessage`, {
                    restaurantName: props.restaurant?.name,
                }),
            })) === 'yes'
        ) {
            if (
                (await confirmWithTextCopy({
                    title: t(
                        `supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.${type}.confirmByTypingCorrectText`,
                    ),
                    textToCopy: t(`supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.${type}.textToType`),
                })) === 'yes'
            ) {
                const activeMutation =
                    type === 'reservations' ? deleteReservationsOnlyMutation : deleteGuestsAndReservationsMutation;
                activeMutation.mutate(
                    { restaurantId: props.restaurant.id },
                    {
                        onSuccess: () => {
                            snackbar({
                                msg: t(
                                    `supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.${type}.deletedSuccessfully`,
                                ),
                                severity: 'success',
                            });
                        },
                    },
                );
            }
        }
    };

    const isLoading = deleteGuestsAndReservationsMutation.isLoading || deleteReservationsOnlyMutation.isLoading;

    return (
        <>
            <div className="version-title">
                <Label
                    type={'title'}
                    value={t('supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.sectionLabel')}
                    variant="h5"
                />
            </div>
            <div className="delete-db-buttons">
                <Button
                    label={t(
                        'supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.guestsAndReservations.title',
                    )}
                    onClick={onDeleteDatabase('guestsAndReservations')}
                />
                <Button
                    label={t('supportAccounts.listOfRestaurants.editRestaurant.deleteDatabase.reservations.title')}
                    onClick={onDeleteDatabase('reservations')}
                />
            </div>
            {isLoading && <Spinner />}
        </>
    );
};

export default DeleteDatabaseSection;
