import * as React from 'react';
export function createUseFunction(context) {
    return function () {
        var _a;
        var value = React.useContext(context);
        if (!value) {
            throw Error("Tried to access ".concat((_a = context.displayName) !== null && _a !== void 0 ? _a : '', ", but it has not been initialized yet."));
        }
        return value;
    };
}
