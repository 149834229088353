import { IRestaurantShift, ITablePlan, ReservationUtils, SERVER_DATE_FORMAT, useUrlParams } from '@localina/core';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { useReservations } from '../../../api/queries/reservations';
import { useMove } from '../../../hooks/useMove';
import { useZoom } from '../../../hooks/useZoom';
import { getBoundingRect } from '../../../utils/TablePlanService';
import { CanvasObjAssignReservation } from '../AssignReservationObjects/CanvasObjAssignReservation';
import { TableObjAssignReservation } from '../AssignReservationObjects/TableObjAssignReservation';

interface IProps {
    zoomCanvas: number;
    selectedTime: number;
    reservationEnabled: boolean;
    walkinEnabled: boolean;
    onDrop?: (reservationId: string, tableId: string, itemType: string, previousTableId?: string) => void;
    onReserveTable?: (tableId: string, isWalkin: boolean) => void;
    onZoomChange?: React.Dispatch<React.SetStateAction<number>>;
    tablePlan?: ITablePlan;
    selectedWorkingHours?: IRestaurantShift;
    disableListeners?: boolean;
}

const TablePlanAssignReservationsCanvas: React.FC<IProps> = (props) => {
    const [params] = useUrlParams();
    const dateParam = params.get('date');

    const reservationsQuery = useReservations(dateParam || '', {
        enabled: DateTime.fromFormat(dateParam || '', SERVER_DATE_FORMAT).isValid,
        select: (data) => {
            return {
                reservations: data.reservations.filter((reservation) => {
                    if (ReservationUtils.isReservationCancelled(reservation)) {
                        return false;
                    }
                    const reservationTime = DateTime.fromISO(reservation.reservationDateTime);
                    const reservationTimeStart = reservationTime.hour * 60 + reservationTime.minute;
                    return (
                        reservationTimeStart > props.selectedTime ||
                        (reservationTimeStart <= props.selectedTime &&
                            reservationTimeStart + (reservation.occupancyTime || 0) / 60 > props.selectedTime)
                    );
                }),
            };
        },
    });

    const { width, height, ref: parentRef } = useObserveDivSize(props.disableListeners);

    const zoomHandler = useCallback(
        (val: number) => {
            if (props.onZoomChange) {
                props.onZoomChange((prev) => {
                    const ratio = prev * val;
                    return Math.max(1, Math.min(5, ratio));
                });
            }
        },
        [props.onZoomChange],
    );
    useZoom(parentRef, zoomHandler, props.disableListeners);
    useMove(parentRef, props.disableListeners);

    const { boundingRect, baseScale } = useMemo(() => {
        const boundingRectVar = props.tablePlan?.canvas
            ? getBoundingRect(props.tablePlan?.canvas)
            : [
                  { x: 0, y: 0 },
                  { x: 0, y: 0 },
              ];
        const rectWidth = boundingRectVar[1].x - boundingRectVar[0].x;
        const rectHeight = boundingRectVar[1].y - boundingRectVar[0].y;
        const parentWidth = (width || 0) - 100;
        const parentHeight = (height || 0) - 100;
        const baseScaleVar = Math.min(
            parentWidth ? parentWidth / rectWidth : 1,
            parentHeight ? parentHeight / rectHeight : 1,
            1,
        );

        return {
            boundingRect: boundingRectVar,
            baseScale: baseScaleVar,
        };
    }, [props.tablePlan?.canvas?.id, width, height]);

    const scaleX = props.zoomCanvas * baseScale;
    const scaleY = props.zoomCanvas * baseScale;
    return (
        <div ref={parentRef} className="table-plan-assign-reservations-canvas-wrapper">
            {props.tablePlan?.canvas?.elements.map((canvasElement) => {
                if (canvasElement.tableId) {
                    return (
                        <TableObjAssignReservation
                            offset={boundingRect[0]}
                            walkinEnabled={props.walkinEnabled}
                            reservationEnabled={props.reservationEnabled}
                            onReserve={(isWalkin) => {
                                if (!canvasElement.tableId || !props.onReserveTable) {
                                    return;
                                }
                                props.onReserveTable(canvasElement.tableId, isWalkin);
                            }}
                            onDrop={(reservationId, itemType, pt) => {
                                if (props.onDrop) {
                                    props.onDrop(reservationId, canvasElement.tableId || '', itemType, pt);
                                }
                            }}
                            selectedTime={props.selectedTime}
                            reservations={(reservationsQuery.data?.reservations || [])
                                .filter(
                                    (res) =>
                                        res.tableIds?.includes(canvasElement.tableId || '') &&
                                        (!props.selectedWorkingHours || props.selectedWorkingHours.id === res.shiftId),
                                )
                                .sort(ReservationUtils.compareReservationsByTime('asc'))}
                            tableObj={props.tablePlan?.tables.find((table) => table.id === canvasElement.tableId)}
                            key={canvasElement.id}
                            canvasElement={canvasElement}
                            scaleX={scaleX}
                            scaleY={scaleY}
                        />
                    );
                }
                return (
                    <CanvasObjAssignReservation
                        offset={boundingRect[0]}
                        key={canvasElement.id}
                        canvasObj={canvasElement}
                        scaleX={scaleX}
                        scaleY={scaleY}
                    />
                );
            })}
        </div>
    );
};

function useObserveDivSize<T extends HTMLDivElement>(
    disabled?: boolean,
    initialWidth?: number,
    initialHeight?: number,
) {
    const [width, setWidth] = React.useState(initialWidth || 0);
    const [height, setHeight] = React.useState(initialHeight || 0);
    const ref = React.useRef<T>(null);

    React.useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setWidth(entries[0].contentRect.width);
            setHeight(entries[0].contentRect.height);
        });

        if (ref.current) {
            if (disabled) {
                setWidth(ref.current?.offsetWidth);
                setHeight(ref.current?.offsetHeight);
            } else {
                observer.observe(ref.current);
            }
        }

        return () => {
            ref.current && observer.unobserve(ref.current);
        };
    }, [disabled]);

    return {
        width,
        height,
        ref,
    };
}

export default TablePlanAssignReservationsCanvas;
