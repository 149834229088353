var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer as MuiDrawer } from '@mui/material';
import { StringUtils } from '../../utils';
export var Drawer = function (props) { return (_jsx(MuiDrawer, __assign({ anchor: props.position, ModalProps: {
        keepMounted: props.keepMounted,
    }, className: StringUtils.combineStrings(['localina-drawer', props.position, props.className]), open: props.open, onClose: props.onClose }, { children: props.children }))); };
