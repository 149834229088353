import { Button, IRestaurant, ValidationUtils } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RestaurantInfo } from '../../Restaurant';

interface IProps {
    restaurant: IRestaurant;
    onChange: (restaurant: IRestaurant) => void;
    onNext: () => void;
}

export const Info: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [isFormValid, setFormValid] = React.useState(false);

    React.useEffect(() => {
        setFormValid(ValidationUtils.validateObject(props.restaurant.info));
    }, [props.restaurant]);

    return (
        <>
            <RestaurantInfo restaurant={props.restaurant} onChange={props.onChange} />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('wizard.buttons.next')} disabled={!isFormValid} onClick={props.onNext} />
            </div>
        </>
    );
};
