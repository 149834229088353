import { Label } from '@localina/core';
import { ArrowLeftIcon, EditIcon, SaveIcon, TableObjectsIcon } from '@localina/icons';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TablePlanContext } from '../../../containers/Tableplans/context';
import { ZoomSlider } from '../../Sliders/ZoomSlider';

interface IProps {
    onEditClick: () => void;
    onDisplayTableObjectsClick: () => void;
    onSaveTablePlanEditorObjs: () => void;
    tablePlanName: string;
    isCanvasModified: boolean;
}

export const CanvasController: React.FC<IProps> = (props) => {
    const { zoomCanvas, onZoomChange } = useContext(TablePlanContext);
    const navigate = useNavigate();
    const goBackToSettings = () => {
        navigate(-1);
    };
    return (
        <div className="canvas-controller">
            <ArrowLeftIcon onClick={goBackToSettings} />
            <div className="table-plane-name">
                <Label type={'title'} value={props.tablePlanName} />
            </div>
            <ZoomSlider
                min={1}
                max={80}
                value={zoomCanvas * 40}
                onZoomChange={(val) => {
                    if (onZoomChange) {
                        onZoomChange(val / 40);
                    }
                }}
            />
            <SaveIcon
                onClick={props.onSaveTablePlanEditorObjs}
                className={props.isCanvasModified ? 'save-icon save-icon-canvas-modified' : 'save-icon'}
            />
            <TableObjectsIcon onClick={props.onDisplayTableObjectsClick} />
            <EditIcon onClick={props.onEditClick} />
        </div>
    );
};
