import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReservation } from '../../api/queries/reservations';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { Path, ReservationDetailsType } from '../../enums';
import { PathUtils } from '../../utils';

interface IPathParams {
    restaurantId: string;
    guestId: string;
    reservationId: string;
}

const GuestReservation: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams() as unknown as IPathParams;
    const { restaurantId, guestId, reservationId } = params;

    const navigateToGuestDetails = useCallback(() => {
        navigate(PathUtils.generateUrl(Path.RESTAURANT_GUEST, { restaurantId, guestId }));
    }, []);

    const reservationQuery = useReservation(reservationId);

    const { openReservationForm, isReservationFormOpen, closeReservationForm } = useReservationFormContext();

    const formWasInitiallyOpen = useRef(false);

    useEffect(() => {
        if (reservationQuery.data) {
            openReservationForm(reservationQuery.data, ReservationDetailsType.EDIT);
        }
    }, [reservationQuery.data]);

    useEffect(() => {
        if (reservationQuery.isError) {
            navigateToGuestDetails();
            closeReservationForm();
        }
    }, [reservationQuery.isError]);

    useEffect(() => {
        if (!isReservationFormOpen) {
            if (formWasInitiallyOpen.current) {
                navigateToGuestDetails();
            }
        } else {
            formWasInitiallyOpen.current = true;
        }
    }, [isReservationFormOpen, navigateToGuestDetails]);

    return null;
};

export default GuestReservation;
