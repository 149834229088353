import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { DATE_FORMAT, IReservation, ListItem, TIME_FORMAT } from '@localina/core';

interface IProps {
    reservation: IReservation;
    onClick: (reservation: IReservation) => void;
}

export const GuestReservationListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { reservationDateTime, participants } = props.reservation;
    const datetime = DateTime.fromISO(reservationDateTime, { zone: 'utc' }).toLocal();
    const date = datetime.isValid ? datetime.toFormat(DATE_FORMAT) : '';
    const time = datetime.isValid ? datetime.toFormat(TIME_FORMAT) : '';

    const handleClick = () => {
        props.onClick(props.reservation);
    };

    return (
        <ListItem
            title={`${date}, ${time}`}
            subtitle={t('guest.reservations.list.participants', { participants })}
            onClick={handleClick}
        />
    );
};
