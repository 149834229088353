import {
    DEFAULT_INFINITE_LIST_LIMIT,
    IGuestDetails,
    IGuestInfo,
    IGuestList,
    IGuestSearchFields,
    IImportResult,
    ILocalizedError,
    IReservationList,
    ISort,
} from '@localina/core';
import {
    useInfiniteQuery,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { GenericAbortSignal } from 'axios';
import { LocalinaApiContext } from '../../../index';
import { GuestsExportFormat } from '../../enums';
import { downloadExportFile } from '../../utils';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { getPaginatedListPageParam, getSlicedListPageParam } from './utils';

interface IGuestListFilters {
    sort?: ISort;
    field?: IGuestSearchFields;
    query: string;
}

type TPageParam = {
    page?: number;
};

function useGuestList(
    filters: IGuestListFilters,
    restaurantId?: string,
    options?: UseInfiniteQueryOptions<IGuestList, ILocalizedError>,
) {
    const restaurantIdFromUrl = useRestaurantId();

    const restaurantIdToUse = restaurantId || restaurantIdFromUrl;

    return useInfiniteQuery({
        queryFn: ({ pageParam = { page: 0 }, signal }: { pageParam?: TPageParam; signal?: GenericAbortSignal }) => {
            return LocalinaApiContext.serviceApi.getGuestList(
                restaurantIdToUse,
                pageParam.page || 0,
                DEFAULT_INFINITE_LIST_LIMIT,
                filters.sort,
                filters.query,
                filters.field,
                signal,
            );
        },
        queryKey: queryKeys.restaurants.single.guests.filtered(restaurantIdToUse, filters),
        enabled: Boolean(restaurantIdToUse),
        ...options,
        select: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
                ...page,
                sort: filters.sort,
            })),
        }),
        staleTime: Infinity,
        keepPreviousData: true,
        getNextPageParam: getSlicedListPageParam.next,
        getPreviousPageParam: getSlicedListPageParam.prev,
    });
}

function useGuestReservationList(
    guestId: string,
    options?: UseInfiniteQueryOptions<IReservationList, ILocalizedError>,
) {
    const restaurantId = useRestaurantId();

    return useInfiniteQuery({
        queryFn: ({ pageParam = { page: 0 } }: { pageParam?: TPageParam }) => {
            return LocalinaApiContext.serviceApi.getGuestReservationList(
                restaurantId,
                guestId,
                pageParam.page || 0,
                DEFAULT_INFINITE_LIST_LIMIT,
            );
        },
        queryKey: queryKeys.restaurants.single.guests.guestReservations(restaurantId, guestId),
        ...options,
        keepPreviousData: true,

        getNextPageParam: getPaginatedListPageParam.next,
        getPreviousPageParam: getPaginatedListPageParam.prev,
    });
}

const useGuest = (
    guestId: string,
    restaurantId?: string,
    options?: UseQueryOptions<IGuestDetails, ILocalizedError>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const restaurantIdToUse = restaurantId || restaurantIdFromUrl;

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getGuest(restaurantIdToUse, guestId);
        },
        queryKey: queryKeys.restaurants.single.guests.single(restaurantIdToUse, guestId),
        ...options,
    });
};

const useUpdateGuest = (options?: UseMutationOptions<void, ILocalizedError, IGuestInfo>) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (guest) => {
            return LocalinaApiContext.serviceApi.updateGuest(restaurantId, guest);
        },
        onSuccess: async (_, guest) => {
            void queryClient.invalidateQueries(queryKeys.restaurants.single.guests.all(restaurantId));
            return queryClient.invalidateQueries(queryKeys.restaurants.single.guests.single(restaurantId, guest.id));
        },
        ...options,
    });
};
const useDeleteGuest = (options?: UseMutationOptions<void, ILocalizedError, IGuestInfo>) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (guest) => {
            return LocalinaApiContext.serviceApi.deleteGuest(restaurantId, guest);
        },
        onSuccess: (_, guest) => {
            queryClient.removeQueries(queryKeys.restaurants.single.guests.single(restaurantId, guest.id));
            return queryClient.invalidateQueries(queryKeys.restaurants.single.guests.all(restaurantId));
        },
        ...options,
    });
};

const useGuestsDownload = (
    options?: UseMutationOptions<
        File,
        ILocalizedError,
        {
            format: GuestsExportFormat;
            fileName: string;
        }
    >,
) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.getGuestsExport(restaurantId, variables.format);
        },
        onSuccess: (data, variables) => {
            downloadExportFile(data, { name: variables.fileName, type: variables.format });
        },
        ...options,
    });
};

const useGuestsImportPreview = (options?: UseMutationOptions<IImportResult, ILocalizedError, File>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (file) => {
            return LocalinaApiContext.serviceApi.getGuestsImportPreview(restaurantId, file);
        },
        ...options,
    });
};
const useGuestsImport = (options?: UseMutationOptions<IImportResult, ILocalizedError, File>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (file) => {
            return LocalinaApiContext.serviceApi.saveGuestsImport(restaurantId, file);
        },
        ...options,
    });
};

export {
    useGuestList,
    useGuest,
    useGuestReservationList,
    useUpdateGuest,
    useDeleteGuest,
    useGuestsDownload,
    useGuestsImportPreview,
    useGuestsImport,
};
export type { IGuestListFilters };
