import { ILocalizedError, IReportEntry } from '@localina/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { ExportFormat } from '../../enums';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { getOptionsEnabled } from './utils';

function useRestaurantReport(
    variables: IGetReportFilters,
    options?: UseQueryOptions<
        IReportEntry[],
        ILocalizedError,
        IReportEntry[],
        ReturnType<typeof queryKeys.restaurants.single.reports.single.get>
    >,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: () =>
            LocalinaApiContext.serviceApi.getReport(
                restaurantId,
                variables.shiftIds,
                variables.areaId,
                variables.dateFrom,
                variables.dateTo,
                variables.creationTime,
            ),
        queryKey: queryKeys.restaurants.single.reports.single.get(restaurantId, variables),
        ...options,
        enabled: Boolean(restaurantId && optionsEnabled),
    });
}

function useRestaurantReportExport(
    variables: IGetReportExportFilters,
    options?: UseQueryOptions<
        File,
        ILocalizedError,
        File,
        ReturnType<typeof queryKeys.restaurants.single.reports.single.export>
    >,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: () =>
            LocalinaApiContext.serviceApi.getReportExport(
                restaurantId,
                variables.shiftIds,
                variables.areaId,
                variables.dateFrom,
                variables.dateTo,
                variables.creationTime,
                variables.format,
            ),
        queryKey: queryKeys.restaurants.single.reports.single.export(restaurantId, variables),
        ...options,
        enabled: Boolean(restaurantId && optionsEnabled),
    });
}

interface IGetReportExportFilters extends IGetReportFilters {
    format: ExportFormat;
}

interface IGetReportFilters {
    shiftIds: string[];
    areaId: string;
    dateFrom: string;
    dateTo: string;
    creationTime: boolean;
}

export { useRestaurantReport, useRestaurantReportExport };
export type { IGetReportFilters, IGetReportExportFilters };
