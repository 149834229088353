import { IRestaurantShift, ITablePlan, Label, StringUtils, TIME_FORMAT, TLanguageKey } from '@localina/core';
import { Box, Collapse, ListSubheader } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDndScrolling } from 'react-dnd-scrolling';
import { useTranslation } from 'react-i18next';
import { useAreas } from '../../api/queries/restaurantAreas';
import { useRestaurant } from '../../api/queries/restaurants';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import TablePlanAssignReservationsCanvas from '../../components/TablePlan/Canvas/TablePlanAssignReservationCanvas';
import { useReservationDropTarget } from '../../components/TablePlan/Controllers/TablePlansMenu';
import { ITablePlanPreview } from '../../interfaces/store/restaurant/IRestaurantShiftTablePlansPreview';
import { useTablePlansOfShift } from '../../utils/ReservationsUtils';

interface ICollapsibleTablePlansOverviewProps {
    currentTime: number;
    setSelectedTablePlanId: Dispatch<SetStateAction<string>>;
}

interface IMenuSection {
    shift?: IRestaurantShift;
    displayName: string;
    key: string;
    tablePlans: ITablePlanInShiftMenu[];
}

interface ITablePlanInShiftMenu {
    tablePlan?: ITablePlan;
    tablePlanPreview: ITablePlanPreview;
    displayName: string;
    id: string;
    key: string;
}

const CollapsibleTablePlansOverview = (props: ICollapsibleTablePlansOverviewProps) => {
    const { t, i18n } = useTranslation();
    const restaurantQuery = useRestaurant();
    const areasQuery = useAreas();
    const allTablePlans = areasQuery.data?.areas.flatMap((area) => area.tablePlans);
    const [collapsed, setCollapsed] = useState(true);

    const tablePlans = useTablePlansOfShift();

    const menuSections: IMenuSection[] = tablePlans.map((shift) => ({
        shift: restaurantQuery.data?.configuration.shifts.find(({ id }) => id === shift.shiftId),
        displayName: shift.shiftName[i18n.language as TLanguageKey],
        key: shift.shiftId,
        tablePlans: shift.tablePlans.map((tablePlanPreview) => ({
            displayName: `${tablePlanPreview.areaName} / ${tablePlanPreview.name}`,
            tablePlan: allTablePlans?.find((tp) => tablePlanPreview.id === tp.id),
            tablePlanPreview,
            id: tablePlanPreview.id,
            key: `${shift.shiftId}.${tablePlanPreview.id}`,
        })),
    }));

    const [isOver, dropTargetRef, isDragging] = useReservationDropTarget();

    const openOverviewOnHoverTimeout = useRef<number>();

    useEffect(() => {
        clearTimeout(openOverviewOnHoverTimeout.current);
        if (isDragging) {
            if (isOver) {
                openOverviewOnHoverTimeout.current = window.setTimeout(() => {
                    if (isOver) {
                        setCollapsed(false);
                    }
                }, 300);
            } else {
                openOverviewOnHoverTimeout.current = window.setTimeout(() => {
                    if (!isOver) {
                        setCollapsed(true);
                    }
                }, 400);
            }
        }
        return () => {
            clearTimeout(openOverviewOnHoverTimeout.current);
        };
    }, [isOver]);

    const dndScrollingRef = useRef<HTMLDivElement>(null);

    useDndScrolling(dndScrollingRef, {});

    return (
        <>
            {menuSections.length > 0 && (
                <div className={'collapsible-table-plans-overview'} ref={dropTargetRef}>
                    <Collapse orientation={'horizontal'} in={!collapsed}>
                        <div ref={dndScrollingRef} id={'scrolling-container'}>
                            {menuSections.map((menuSection) => {
                                let selectedTime = props.currentTime;
                                if (menuSection.shift) {
                                    const shiftFromDateTime = DateTime.fromFormat(menuSection.shift.from, TIME_FORMAT);
                                    const shiftFromMinutes = shiftFromDateTime.hour * 60 + shiftFromDateTime.minute;
                                    if (selectedTime > shiftFromMinutes) {
                                        const shiftToDateTime = DateTime.fromFormat(menuSection.shift.to, TIME_FORMAT);
                                        const shiftToMinutes = shiftToDateTime.hour * 60 + shiftToDateTime.minute;
                                        if (shiftToMinutes < selectedTime) {
                                            selectedTime = shiftFromMinutes;
                                        }
                                    } else {
                                        selectedTime = shiftFromMinutes;
                                    }
                                }
                                return (
                                    <React.Fragment key={menuSection.key}>
                                        <ListSubheader>
                                            <Box justifyContent={'space-between'} display={'flex'}>
                                                <Label type={'title'} value={menuSection.displayName} />
                                                {Boolean(menuSection.shift) && (
                                                    <Label
                                                        type={'info'}
                                                        value={t('reservations.table.displayedTablePlanTime', {
                                                            time: DateTime.now()
                                                                .startOf('day')
                                                                .set({ minute: selectedTime })
                                                                .toFormat(TIME_FORMAT),
                                                        })}
                                                    />
                                                )}
                                            </Box>
                                        </ListSubheader>
                                        {menuSection.tablePlans.map((tablePlan) => (
                                            <MiniTablePlanCanvasWrapper
                                                key={tablePlan.key}
                                                tablePlan={tablePlan}
                                                selectedTime={selectedTime}
                                                onTablePlanClick={() => {
                                                    props.setSelectedTablePlanId(tablePlan.id);
                                                    setCollapsed(true);
                                                }}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </Collapse>
                    <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
                </div>
            )}
        </>
    );
};

interface IMiniTablePlanCanvasWrapperProps {
    tablePlan: ITablePlanInShiftMenu;
    selectedTime: number;
    onTablePlanClick: () => void;
}

const MiniTablePlanCanvasWrapper = (props: IMiniTablePlanCanvasWrapperProps) => {
    const [isOver, dropTargetRef, isDragging] = useReservationDropTarget({ onDrop: props.onTablePlanClick });
    const timeoutRef = useRef<number>();
    const focusTimeoutRef = useRef<number>();

    const [triggerFocus, setTriggerFocus] = useState(false);

    useEffect(() => {
        if (isOver) {
            timeoutRef.current = window.setTimeout(() => {
                setTriggerFocus(true);
            }, 1000);
        } else {
            clearTimeout(timeoutRef.current);
            setTriggerFocus(false);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isOver]);

    useEffect(() => {
        if (triggerFocus) {
            focusTimeoutRef.current = window.setTimeout(() => {
                if (props.onTablePlanClick) {
                    props.onTablePlanClick();
                    setTriggerFocus(false);
                }
            }, 500);
        } else {
            clearTimeout(focusTimeoutRef.current);
        }

        return () => {
            clearTimeout(focusTimeoutRef.current);
        };
    }, [triggerFocus]);

    return (
        <div
            className={StringUtils.combineStrings([
                'mini-table-plan-canvas-wrapper',
                isDragging && triggerFocus && 'focus-blinking',
                isDragging && isOver && 'hovering-with-reservation',
                isDragging && !isOver && 'hovering-outside',
            ])}
            ref={dropTargetRef}
        >
            <Label type={'info'} value={props.tablePlan.displayName} />
            <TablePlanAssignReservationsCanvas
                tablePlan={props.tablePlan.tablePlan}
                selectedTime={props.selectedTime}
                zoomCanvas={1}
                reservationEnabled={false}
                walkinEnabled={false}
                disableListeners
            />
        </div>
    );
};

export default CollapsibleTablePlansOverview;
