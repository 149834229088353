import { yupResolver } from '@hookform/resolvers/yup';
import {
    DATE_FORMAT,
    DateField,
    FormTextField,
    Label,
    SERVER_DATE_FORMAT,
    Spinner,
    Switch,
    TimeField,
    useConfirmContext,
} from '@localina/core';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAreas } from '../../api/queries/restaurantAreas';
import {
    TRestaurantClosingDayTransformed,
    useCreateRestaurantClosingDay,
    useDeleteRestaurantClosingDay,
    useUpdateRestaurantClosingDay,
} from '../../api/queries/restaurantShifts';
import { IRestaurantAreaClosingDay } from '../../interfaces';
import { Drawer } from '../Drawer';
import { AreaBox } from './AreaBox';
import { useClosingDaySchema } from './closing-day-utils';

export enum ClosingDayViewType {
    CREATE = 'create',
    EDIT = 'edit',
}

export interface IClosingDayViewProps {
    type?: ClosingDayViewType;
    closingDay?: TRestaurantClosingDayTransformed;
}

interface IProps extends IClosingDayViewProps {
    restaurantId?: string;
    canAccessAreas?: boolean;
    disableConfirmation?: boolean;
    onSuccess: () => void;
    onClose: () => void;
}

export const ClosingDayView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { confirm, snackbar } = useConfirmContext();

    const createRestaurantClosingDayMutation = useCreateRestaurantClosingDay(props.restaurantId);
    const updateRestaurantClosingDayMutation = useUpdateRestaurantClosingDay(props.restaurantId);
    const deleteRestaurantClosingDayMutation = useDeleteRestaurantClosingDay(props.restaurantId);

    const [isWholeDay, setWholeDay] = React.useState(false);
    const [calendarFocusDate, setCalendarFocusDate] = React.useState<DateTime>(DateTime.local());

    const areasQuery = useAreas(props.restaurantId);

    const areaList = useMemo(
        () =>
            areasQuery.data?.areas.map((area) => ({
                id: area.id,
                name: area.name,
                closed:
                    (area.id === areasQuery.data.defaultAreaId && props.type === 'create') ||
                    Boolean(
                        props.type === 'edit' && props.closingDay && area.areaClosingDays.includes(props.closingDay.id),
                    ),
            })) || [],
        [props.canAccessAreas, areasQuery.data?.defaultAreaId, props.closingDay, areasQuery.data],
    );

    const closingDaySchema = useClosingDaySchema();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(closingDaySchema)),
        defaultValues: {
            name: props.closingDay?.name,
            from: props.closingDay?.from
                ? DateTime.fromFormat(props.closingDay.from, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                : '',
            to: props.closingDay?.to
                ? DateTime.fromFormat(props.closingDay.to, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                : '',
            fromTime: props.closingDay?.fromTime || '',
            toTime: props.closingDay?.toTime || '',
            isRecurrent: props.closingDay?.isRecurrent,
            areas: [],
        },
    });

    const [isRecurrent, from, to] = methods.watch(['isRecurrent', 'from', 'to']);
    const datesAreEqual = from === to;

    const { formState } = methods;
    const { isValid: isFormValid, isDirty, dirtyFields } = formState;

    const handleClose = async () => {
        if (
            !isDirty ||
            (await confirm({
                title: t(`availability.closingDayView.confirmDiscardChanges.title`),
            })) === 'yes'
        ) {
            props.onClose();
        }
    };

    const onDelete = async () => {
        if (props.disableConfirmation) {
            confirmDelete();
        } else {
            if (
                props.closingDay &&
                (await confirm({
                    title: t(`availability.dialog.delete.title`),
                    msg: t(`availability.dialog.delete.message`, { name: props.closingDay.name }),
                    confirmLabel: t(`availability.dialog.delete.buttons.confirm`),
                    cancelLabel: t(`availability.dialog.delete.buttons.cancel`),
                })) === 'yes'
            ) {
                confirmDelete();
            }
        }
    };

    const confirmDelete = () => {
        if (props.closingDay) {
            deleteRestaurantClosingDayMutation.mutate(
                { closingDayId: props.closingDay.id },
                {
                    onSuccess: props.onSuccess,
                    onError: () => {
                        snackbar({ msg: t(`availability.closingDayView.errors.delete`), severity: 'error' });
                    },
                },
            );
        }
    };

    const onSave = async () => {
        if (methods.formState.isValid) {
            if (props.disableConfirmation || props.type === ClosingDayViewType.CREATE) {
                confirmSave();
            } else {
                const values = methods.getValues();
                if (
                    values &&
                    (await confirm({
                        title: t(`availability.dialog.edit.title`),
                        msg: t(`availability.dialog.edit.message`, { name: values.name }),
                        confirmLabel: t(`availability.dialog.edit.buttons.confirm`),
                        cancelLabel: t(`availability.dialog.edit.buttons.cancel`),
                    })) === 'yes'
                ) {
                    confirmSave();
                }
            }
        }
    };

    const confirmSave = () => {
        const values = methods.getValues();
        if (values && methods.formState.isValid && values.from && values.to) {
            const activeMutation =
                props.type === ClosingDayViewType.CREATE
                    ? createRestaurantClosingDayMutation
                    : updateRestaurantClosingDayMutation;

            activeMutation.mutate(
                {
                    closingDay: {
                        id: props.closingDay?.id || '',
                        ...values,
                        from: DateTime.fromFormat(values.from as string, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                        to: DateTime.fromFormat(values.to as string, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                        fromTime: values.fromTime || undefined,
                        toTime: values.toTime || undefined,
                    },
                },
                {
                    onSuccess: props.onSuccess,
                    onError: () => {
                        snackbar({ msg: t(`availability.closingDayView.errors.${props.type}`), severity: 'error' });
                    },
                },
            );
        }
    };

    useEffect(() => {
        void methods.trigger('fromTime');
        void methods.trigger('toTime');
    }, [datesAreEqual]);

    useEffect(() => {
        if (isRecurrent) {
            setWholeDay(false);
        }
    }, [isRecurrent]);

    useEffect(() => {
        if (isWholeDay) {
            methods.setValue('fromTime', '', { shouldDirty: true });
            methods.setValue('toTime', '', { shouldDirty: true });
        }
    }, [isWholeDay]);

    useEffect(() => {
        methods.reset({
            name: props.closingDay?.name,
            isRecurrent: props.closingDay?.isRecurrent,
            from: props.closingDay?.from
                ? DateTime.fromFormat(props.closingDay.from, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                : '',
            to: props.closingDay?.to
                ? DateTime.fromFormat(props.closingDay.to, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                : '',
            fromTime: props.closingDay?.fromTime || '',
            toTime: props.closingDay?.toTime || '',
            areas: areaList
                .filter(({ closed }) => closed)
                .map(({ id }) => id)
                .sort(),
        });
        setWholeDay(!props.closingDay?.isRecurrent && !props.closingDay?.fromTime && !props.closingDay?.toTime);
    }, [props.closingDay, areaList]);

    const isLoading =
        createRestaurantClosingDayMutation.isLoading ||
        updateRestaurantClosingDayMutation.isLoading ||
        deleteRestaurantClosingDayMutation.isLoading;

    const submitDisabled = !(isDirty && isFormValid);

    return (
        <Drawer
            className="closingday-view"
            open={!!props.closingDay}
            title={t('availability.closingDayView.title')}
            disabled={submitDisabled}
            onClose={handleClose}
            onSave={onSave}
            onDelete={props.type === ClosingDayViewType.EDIT ? onDelete : undefined}
        >
            {props.closingDay && (
                <FormProvider {...methods}>
                    <form>
                        <div className="group">
                            <div className="title-date-time">
                                <Label
                                    type="title"
                                    value={t('availability.closingDayView.subtitles.dateTime')}
                                    variant="h6"
                                    align="left"
                                />
                            </div>
                            <Controller
                                name={'isRecurrent'}
                                render={({ field: { onChange, value } }) => (
                                    <Switch
                                        label={t('availability.closingDayView.fields.repeated')}
                                        onChange={(val) => {
                                            onChange(val);
                                            if (!val || dirtyFields['toTime']) {
                                                void methods.trigger('toTime');
                                            }
                                            if (!val || dirtyFields['fromTime']) {
                                                void methods.trigger('fromTime');
                                            }
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                            <Label
                                type="text"
                                value={t(
                                    `availability.closingDayView.subtitles.${isRecurrent ? 'repeated' : 'period'}`,
                                )}
                            />
                            <FormTextField
                                name={'name'}
                                label={t('availability.closingDayView.fields.name')}
                                required
                            />
                            {!isRecurrent && (
                                <Switch
                                    label={t('availability.closingDayView.fields.wholeDay')}
                                    checked={isWholeDay}
                                    onChange={(val) => {
                                        setWholeDay(val);
                                        void methods.trigger('fromTime');
                                        void methods.trigger('toTime');
                                    }}
                                />
                            )}
                            <Label
                                type="info"
                                value={t(
                                    `availability.closingDayView.subtitles.${
                                        isRecurrent ? 'dateRange' : 'startDateTime'
                                    }`,
                                )}
                            />
                            <div className="row">
                                <Controller
                                    name={'from'}
                                    key={'from'}
                                    render={({ field: { onChange, value } }) => (
                                        <DateField
                                            name="from"
                                            label={t('availability.closingDayView.fields.dateFrom')}
                                            value={value}
                                            required
                                            maxDate={to ? DateTime.fromFormat(to as string, DATE_FORMAT) : undefined}
                                            onChange={(val) => {
                                                const date = DateTime.fromFormat(val, DATE_FORMAT);
                                                onChange(date.isValid ? val : '');
                                                void methods.trigger('to');
                                                setCalendarFocusDate(date);
                                            }}
                                            focusDate={calendarFocusDate}
                                        />
                                    )}
                                />
                                {isRecurrent && (
                                    <Controller
                                        name={'to'}
                                        key={'to'}
                                        render={({ field: { onChange, value } }) => (
                                            <DateField
                                                name="to"
                                                label={t('availability.closingDayView.fields.dateTo')}
                                                value={value}
                                                required
                                                minDate={
                                                    from ? DateTime.fromFormat(from as string, DATE_FORMAT) : undefined
                                                }
                                                onChange={(val) => {
                                                    const date = DateTime.fromFormat(val, DATE_FORMAT);
                                                    onChange(date.isValid ? val : '');
                                                    void methods.trigger('from');
                                                    setCalendarFocusDate(date);
                                                }}
                                                focusDate={calendarFocusDate}
                                            />
                                        )}
                                    />
                                )}
                                {!isRecurrent && (
                                    <Controller
                                        name={'fromTime'}
                                        key={'fromTime'}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TimeField
                                                disabled={isWholeDay}
                                                name="fromTime"
                                                label={t('availability.closingDayView.fields.timeFrom')}
                                                value={value}
                                                required={isRecurrent}
                                                allowClear={!isRecurrent}
                                                onChange={(val) => {
                                                    onChange(val);
                                                    void methods.trigger('toTime');
                                                }}
                                                error={Boolean(error)}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <Label
                                type="info"
                                value={t(
                                    `availability.closingDayView.subtitles.${
                                        isRecurrent ? 'timeRange' : 'endDateTime'
                                    }`,
                                )}
                            />
                            <div className="row">
                                {isRecurrent && (
                                    <Controller
                                        name={'fromTime'}
                                        key={'fromTime'}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TimeField
                                                disabled={isWholeDay}
                                                name="fromTime"
                                                label={t('availability.closingDayView.fields.timeFrom')}
                                                value={value}
                                                required={isRecurrent}
                                                allowClear={!isRecurrent}
                                                error={Boolean(error)}
                                                onChange={(val) => {
                                                    onChange(val);
                                                    void methods.trigger('toTime');
                                                }}
                                            />
                                        )}
                                    />
                                )}
                                {!isRecurrent && (
                                    <Controller
                                        name={'to'}
                                        key={'to'}
                                        render={({ field: { onChange, value } }) => (
                                            <DateField
                                                name="to"
                                                label={t('availability.closingDayView.fields.dateTo')}
                                                value={value}
                                                required
                                                minDate={
                                                    from ? DateTime.fromFormat(from as string, DATE_FORMAT) : undefined
                                                }
                                                onChange={(val) => {
                                                    const date = DateTime.fromFormat(val, DATE_FORMAT);
                                                    onChange(date.isValid ? val : '');
                                                    void methods.trigger('from');
                                                    setCalendarFocusDate(date);
                                                }}
                                                focusDate={calendarFocusDate}
                                            />
                                        )}
                                    />
                                )}
                                <Controller
                                    name={'toTime'}
                                    key={'toTime'}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TimeField
                                            disabled={isWholeDay}
                                            name="toTime"
                                            label={t('availability.closingDayView.fields.timeTo')}
                                            value={value}
                                            required={isRecurrent}
                                            allowClear={!isRecurrent}
                                            error={Boolean(error)}
                                            onChange={(val) => {
                                                onChange(val);
                                                void methods.trigger('fromTime');
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {areaList.length > 0 && (
                                <>
                                    <div className="title-areas">
                                        <Label type="title" value={t('sectors.title')} variant="h6" align="left" />
                                    </div>
                                    <Controller
                                        name={'areas'}
                                        render={({ field: { onChange, value = [] } }) => (
                                            <>
                                                {areaList.map((area: IRestaurantAreaClosingDay, index) => (
                                                    <AreaBox
                                                        key={index}
                                                        area={{
                                                            name: area.name,
                                                            id: area.id,
                                                            closed: value.includes(area.id),
                                                        }}
                                                        onSwitchChange={(areaClosed: boolean, areaId: string) => {
                                                            const areaIndex = value.findIndex(
                                                                (id: string) => id === areaId,
                                                            );
                                                            if (areaClosed && areaIndex === -1) {
                                                                onChange([...value, areaId].sort());
                                                            } else {
                                                                onChange(value.filter((id: string) => id !== areaId));
                                                            }
                                                        }}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </form>
                </FormProvider>
            )}
            {isLoading && <Spinner />}
        </Drawer>
    );
};
