import { List, ListItem } from '@localina/core';
import { RestaurantLogoIcon } from '@localina/icons';
import { Hub, Language } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page, PlatformsIntegrationView, RestaurantLogoIntegrationView, WidgetIntegrationView } from '../../components';

enum IntegrationItem {
    WIDGET = 'widget',
    LOGO = 'logo',
    PLATFORMS = 'platforms',
    // QRCODE = 'qrcode',
}

const Integrations: React.FC = () => {
    const { t } = useTranslation();

    const [openDrawer, setOpenDrawer] = React.useState<false | IntegrationItem>(false);

    const restaurantQuery = useRestaurant();
    const handleItemClick = (item: IntegrationItem) => () => {
        setOpenDrawer(item);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const getIconForIntegrationItem = (item: IntegrationItem) => {
        switch (item) {
            case IntegrationItem.WIDGET:
                return <Language />;
            case IntegrationItem.LOGO:
                return <RestaurantLogoIcon />;
            case IntegrationItem.PLATFORMS:
                return <Hub />;
            //case IntegrationItem.QRCODE:
            //    return <RestaurantQrCodeIcon />;
        }
    };

    return (
        <Page name="integrations" title={t('integrations.title')} isLoading={restaurantQuery.isInitialLoading}>
            <List>
                {[
                    IntegrationItem.WIDGET,
                    IntegrationItem.LOGO,
                    IntegrationItem.PLATFORMS,
                    //IntegrationItem.QRCODE,
                ].map((item) => (
                    <ListItem
                        key={item}
                        leftIcon={getIconForIntegrationItem(item)}
                        title={t(`integrations.items.${item}.title`)}
                        subtitle={t(`integrations.items.${item}.subtitle`)}
                        onClick={handleItemClick(item)}
                    />
                ))}
            </List>
            {restaurantQuery.data && (
                <>
                    <WidgetIntegrationView
                        isOpen={openDrawer === IntegrationItem.WIDGET}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <RestaurantLogoIntegrationView
                        isOpen={openDrawer === IntegrationItem.LOGO}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <PlatformsIntegrationView
                        isOpen={openDrawer === IntegrationItem.PLATFORMS}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                </>
            )}
        </Page>
    );
};

export default Integrations;
