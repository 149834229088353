var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LocalizationProvider, MobileDatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DAY_DATE_FORMAT, MONTH_DATE } from '../../config';
import { DateTimeUtils, InputFieldUtils, StringUtils } from '../../utils';
import { InputField } from '../InputField';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';
export var DateRangeField = function (props) {
    var _a, _b, _c;
    var _d = useTranslation('core'), i18n = _d.i18n, t = _d.t;
    var ref = React.createRef();
    var _e = React.useState((_a = props.isOpen) !== null && _a !== void 0 ? _a : false), isOpen = _e[0], setOpen = _e[1];
    var _f = React.useState((_c = (_b = props.value) === null || _b === void 0 ? void 0 : _b.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _c !== void 0 ? _c : []), dateArray = _f[0], _setDateArray = _f[1];
    var _g = useState(null), pickerType = _g[0], _setPickerType = _g[1];
    var _h = useState('start'), rangeDayType = _h[0], _setRangeDayType = _h[1];
    var setDateArray = function (newDateArray) {
        _setDateArray(function (prevState) {
            return Array.isArray(newDateArray) ? newDateArray.sort() : newDateArray(prevState).sort();
        });
    };
    var setPickerType = function (event, type) {
        if (type === null) {
            return;
        }
        if (type === 'range') {
            setDateArray([]);
        }
        _setPickerType(type);
    };
    var setRangeDayType = function (event, type) {
        if (type === null) {
            return;
        }
        _setRangeDayType(type);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleDisableDate = function (value) {
        if (!value || !props.shouldDisableDate) {
            return false;
        }
        return props.shouldDisableDate(value);
    };
    var handleChange = function (val) {
        var value = val ? val : null;
        if (value) {
            if (pickerType === 'range') {
                setDateArray(function (prevState) {
                    if (prevState.length) {
                        if (rangeDayType === 'start') {
                            _setRangeDayType('end');
                            if (value < prevState[prevState.length - 1]) {
                                return getDatesFromRange(value, prevState[prevState.length - 1]);
                            }
                            else {
                                return [value];
                            }
                        }
                        else {
                            if (value > prevState[0]) {
                                _setRangeDayType('start');
                                return getDatesFromRange(prevState[0], value);
                            }
                            else {
                                _setRangeDayType('end');
                                return [value];
                            }
                        }
                    }
                    else {
                        _setRangeDayType('end');
                        return [value];
                    }
                });
            }
            else {
                setDateArray(function (array) {
                    return array.some(function (date) { return date.hasSame(value, 'day'); })
                        ? array.filter(function (date) { return !date.hasSame(value, 'day'); })
                        : __spreadArray(__spreadArray([], array, true), [value], false);
                });
            }
        }
    };
    var handleOk = function () {
        if (props.onChange) {
            props.onChange(dateArray.map(function (value) { return ((value === null || value === void 0 ? void 0 : value.isValid) ? value.toFormat(DATE_FORMAT) : ''); }));
        }
        handleClose(true);
    };
    var getDatesFromRange = function (from, to) {
        if (from > to)
            return [];
        var datesList = [];
        var currentDate = from;
        while (currentDate <= to) {
            datesList.push(currentDate);
            currentDate = currentDate.plus({ days: 1 });
        }
        return datesList;
    };
    var handleCancel = function () {
        handleClose(false);
    };
    var handleClear = function () {
        setDateArray([]);
    };
    var resetDatePickerValue = function () {
        var _a, _b;
        setDateArray((_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _b !== void 0 ? _b : []);
    };
    var handleClose = function (valueAccepted) {
        if (valueAccepted === void 0) { valueAccepted = false; }
        if (!valueAccepted) {
            resetDatePickerValue();
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    var valueDatesAreConsecutive = useMemo(function () {
        return props.value &&
            DateTimeUtils.areDatesConsecutive(props.value.map(function (v) { return DateTime.fromFormat(v, DATE_FORMAT); }));
    }, [JSON.stringify(props.value)]);
    var displayValue = useMemo(function () {
        var _a;
        var formatDate = function (date) {
            return DateTime.fromFormat(date, DATE_FORMAT).toFormat(DAY_DATE_FORMAT, { locale: i18n.language });
        };
        if ((_a = props.value) === null || _a === void 0 ? void 0 : _a.length) {
            if (props.value.length > 1) {
                if (valueDatesAreConsecutive) {
                    return t('dateRangeField.fieldDisplayValue.consecutiveDates', {
                        date1: formatDate(props.value[0]),
                        date2: formatDate(props.value[props.value.length - 1]),
                    });
                }
                else if (props.value.length === 2) {
                    return t('dateRangeField.fieldDisplayValue.twoDates', {
                        date1: formatDate(props.value[0]),
                        date2: formatDate(props.value[1]),
                    });
                }
                else {
                    return t('dateRangeField.fieldDisplayValue.multipleDates', {
                        date: formatDate(props.value[0]),
                        count: props.value.length - 1,
                    });
                }
            }
            else {
                return t('dateRangeField.fieldDisplayValue.singleDate', {
                    date: formatDate(props.value[0]),
                });
            }
        }
        else {
            return '';
        }
    }, [JSON.stringify(props.value)]);
    var getPickersDayClassNames = function (_a) {
        var day = _a.day;
        var disableDate = props.shouldDisableDate && props.shouldDisableDate(day);
        var highlightDate = props.shouldHighlightDate && props.shouldHighlightDate(day);
        var shouldSelectDate = dateArray.some(function (date) { return date.hasSame(day, 'day'); });
        return StringUtils.combineStrings([
            !disableDate && highlightDate ? (typeof highlightDate === 'string' ? highlightDate : 'highlight') : '',
            !disableDate && shouldSelectDate && 'Mui-selected date-range-field-selected',
        ]);
    };
    React.useEffect(function () {
        var _a;
        if (props.value) {
            var newDateArray = (_a = props.value.map(function (date) { return DateTime.fromFormat(date !== null && date !== void 0 ? date : '', DATE_FORMAT); })) !== null && _a !== void 0 ? _a : [];
            setDateArray(newDateArray);
        }
    }, [JSON.stringify(props.value)]);
    React.useEffect(function () {
        if (props.isOpen !== undefined) {
            setOpen(props.isOpen);
        }
    }, [props.isOpen]);
    useEffect(function () {
        var _a;
        if (!isOpen) {
            if (!((_a = props.value) === null || _a === void 0 ? void 0 : _a.length)) {
                if (pickerType !== null) {
                    _setPickerType(null);
                }
            }
            else {
                _setPickerType(valueDatesAreConsecutive ? 'range' : 'single');
            }
        }
    }, [JSON.stringify(props.value), isOpen]);
    return (_jsx(React.Fragment, { children: _jsx(InputField, __assign({ name: "localina-datefield", inputRef: ref, icon: props.icon, helperText: props.value && props.value.length > 2 && !valueDatesAreConsecutive ? (_jsx(Tooltip, __assign({ content: _jsx(Label, { type: 'info', value: props.value.join(', ') }), disablePortal: true, popperClassName: 'helper-text-tooltip' }, { children: _jsx(Label, { type: 'info', value: t('dateRangeField.helperTextTooltip') }) }))) : undefined, onEnter: props.onEnter }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterLuxon, adapterLocale: i18n.language }, { children: _jsx(MobileDatePicker, { inputRef: ref, open: isOpen, format: DAY_DATE_FORMAT, label: props.label, value: null, minDate: props.minDate, disabled: props.disabled, shouldDisableDate: handleDisableDate, className: pickerType === null ? 'hidden' : undefined, onOpen: handleOpen, closeOnSelect: false, onClose: handleClose, slots: {
                        day: (PickersDay),
                        actionBar: function () { return (_jsxs("div", __assign({ className: StringUtils.combineStrings([
                                'date-picker-action-bar',
                                pickerType === null && 'hidden',
                            ]) }, { children: [_jsx(Button, __assign({ onClick: handleClear }, { children: t('clear') })), _jsxs("div", { children: [_jsx(Button, __assign({ onClick: handleCancel }, { children: t('buttons.cancel') })), _jsx(Button, __assign({ onClick: handleOk }, { children: t('buttons.ok') }))] })] }))); },
                        toolbar: function () { return (_jsxs("div", __assign({ className: 'date-range-toolbar' }, { children: [pickerType === null && (_jsx(Label, { type: 'text', value: t('dateRangeField.initialDialog.title'), extraClassName: 'toggle-buttons-info-message' })), _jsxs(ToggleButtonGroup, __assign({ value: pickerType, onChange: setPickerType, exclusive: true, color: "primary", orientation: pickerType === null ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: 'range', selected: pickerType === 'range' || pickerType === null }, { children: _jsx(Label, { type: pickerType === null ? 'text' : 'info', value: t('dateRangeField.buttons.dateRange') }) })), _jsx(ToggleButton, __assign({ value: 'single', selected: pickerType === 'single' || pickerType === null }, { children: _jsx(Label, { type: pickerType === null ? 'text' : 'info', value: t('dateRangeField.buttons.dateSelection') }) }))] })), pickerType === 'range' && (_jsxs(ToggleButtonGroup, __assign({ className: 'date-range-toolbar__dates-button-group', value: rangeDayType, onChange: setRangeDayType, exclusive: true, color: 'primary' }, { children: [_jsx(ToggleButton, __assign({ value: 'start' }, { children: _jsx(Label, { type: 'info', value: dateArray.length
                                                    ? dateArray[0].toFormat(MONTH_DATE)
                                                    : t('dateRangeField.buttons.start') }) })), _jsx(Label, { type: 'info', value: '-' }), _jsx(ToggleButton, __assign({ value: 'end' }, { children: _jsx(Label, { type: 'info', value: dateArray.length > 1
                                                    ? dateArray[dateArray.length - 1].toFormat(MONTH_DATE)
                                                    : t('dateRangeField.buttons.end') }) }))] })))] }))); },
                    }, slotProps: {
                        textField: {
                            inputProps: {
                                value: displayValue,
                                placeholder: '',
                            },
                            variant: 'outlined',
                            autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete),
                            required: props.required,
                            fullWidth: true,
                            placeholder: '',
                        },
                        toolbar: {
                            hidden: false,
                        },
                        dialog: {
                            className: StringUtils.combineStrings([
                                'date-picker-dialog',
                                pickerType === null && 'hidden-calendar',
                            ]),
                        },
                        day: function (ownerState) { return ({
                            className: getPickersDayClassNames(ownerState),
                            onDaySelect: handleChange,
                        }); },
                    } }) })) })) }));
};
