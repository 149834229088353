import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@localina/core';
import { TrashIcon } from '@localina/icons';

export interface IActionProp {
    disabled: boolean;
    label?: string;
    onClick: () => void;
}

export interface IActionsProps {
    save: IActionProp;
    onCancel?: () => void;
    onDelete?: () => void;
}

export const PageActions: React.FC<IActionsProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="page-actions">
            <div className="page-actions__content">
                <div className="buttons">
                    {props.onDelete && <IconButton icon={<TrashIcon size="big" />} onClick={props.onDelete} />}
                    {props.onCancel && (
                        <Button label={t('common.buttons.cancel')} secondary={true} onClick={props.onCancel} />
                    )}
                    <Button
                        label={props.save.label ?? t('common.buttons.save')}
                        disabled={props.save.disabled}
                        onClick={props.save.onClick}
                    />
                </div>
            </div>
        </div>
    );
};
