import { IReservation, Label, Tooltip } from '@localina/core';
import { FoodBankIcon } from '@localina/icons';
import React, { useMemo } from 'react';
import { useAreas, useVirtualAreas } from '../../api/queries/restaurantAreas';

interface IReservationAreaCodesProps {
    reservation: IReservation;
}

const ReservationAreaCodes = ({ reservation }: IReservationAreaCodesProps) => {
    const areasQuery = useAreas();
    const virtualAreasQuery = useVirtualAreas();

    const areaCodesForReservation = useMemo(
        () => [
            ...(areasQuery.data?.areas.filter((a) => reservation.areaIds.includes(a.id)) || []).map((a) => ({
                id: a.id,
                code: a.code,
                error: false,
            })),
            ...(virtualAreasQuery.data?.virtualAreas.filter((va) => reservation.areaIds.includes(va.id)) || []).map(
                (a) => ({
                    id: a.id,
                    code: a.code,
                    error: true,
                }),
            ),
        ],
        [areasQuery.data, virtualAreasQuery.data, reservation.areaIds],
    );

    return (
        <Tooltip
            stopPropagation
            container={undefined}
            popperClassName="tooltip basic-blue-tooltip-container"
            clickAwayMouseEvent="onPointerDown"
            content={
                <div className="reservation-area-codes">
                    {<Label type="label" value={areaCodesForReservation.map((a) => a.code).join(', ')} />}
                </div>
            }
        >
            <div className="reservation__area">
                <FoodBankIcon />
                <div className="reservation__area__codesList">
                    {areaCodesForReservation.map((area) => (
                        <Label key={`${reservation.id}-${area.id}`} type="info" error={area.error} value={area.code} />
                    ))}
                </div>
            </div>
        </Tooltip>
    );
};

export default ReservationAreaCodes;
