var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckboxDisabledIcon, CheckboxDisabledSelectedIcon, CheckboxIcon, CheckboxSelectedIcon, } from '@localina/icons';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { StringUtils } from '../../utils';
var Checkbox = function (props) {
    var _a, _b;
    var handleChange = function (event, checked) {
        if (props.onChange) {
            props.onChange(checked);
        }
    };
    var uncheckedIcon = props.disabled ? _jsx(CheckboxDisabledIcon, {}) : _jsx(CheckboxIcon, {});
    var checkedIcon = props.disabled ? _jsx(CheckboxDisabledSelectedIcon, {}) : _jsx(CheckboxSelectedIcon, {});
    return (_jsx(MuiCheckbox, { checked: props.checked, disabled: props.disabled, disableRipple: props.disableRipple !== false, disableTouchRipple: props.disableTouchRipple, icon: (_a = props.uncheckedIcon) !== null && _a !== void 0 ? _a : uncheckedIcon, checkedIcon: (_b = props.checkedIcon) !== null && _b !== void 0 ? _b : checkedIcon, onChange: handleChange }));
};
var withLabelAndIcon = function (Component) { return function (props) {
    var hasLabel = props.label !== undefined;
    var hasIcon = props.icon && typeof props.icon !== 'boolean';
    var useIcon = props.icon && typeof props.icon === 'boolean';
    var classNames = StringUtils.combineStrings([
        'localina-checkbox',
        hasLabel && 'has-label',
        !!hasIcon && 'has-icon',
        !!useIcon && 'use-icon',
    ]);
    return (_jsx("div", __assign({ className: classNames }, { children: _jsxs(_Fragment, { children: [props.icon, props.label ? (_jsx(FormControlLabel, { control: _jsx(Component, __assign({}, props)), disabled: props.disabled, label: props.label })) : (_jsx(Component, __assign({}, props)))] }) })));
}; };
export default withLabelAndIcon(Checkbox);
