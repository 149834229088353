var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField as MuiTextField } from '@mui/material';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { InputFieldUtils } from '../../utils';
import { Label } from '../Label';
import { PhoneNumberField } from '../PhoneNumberField';
import { FieldWrapper } from './FormFieldWrapper';
var FormTextField = function (props) {
    var _a;
    var _b = useFormContext(), control = _b.control, formState = _b.formState;
    var errors = formState.errors;
    var errorMessage = (_a = get(errors, props.name)) === null || _a === void 0 ? void 0 : _a.message;
    return (_jsx(Controller, { name: props.name, control: control, render: function (_a) {
            var _b = _a.field, ref = _b.ref, controllerProps = __rest(_b, ["ref"]), error = _a.fieldState.error;
            return (_jsx(FormTextFieldBase, __assign({}, props, { errorMessage: errorMessage, controllerProps: controllerProps, error: error })));
        } }));
};
var FormTextFieldBase = function (_a) {
    var _b;
    var controllerProps = _a.controllerProps, errorMessage = _a.errorMessage, icon = _a.icon, helperText = _a.helperText, props = __rest(_a, ["controllerProps", "errorMessage", "icon", "helperText"]);
    var errorMessageText = errorMessage ? _jsx(Label, { type: "info", value: errorMessage, error: true }) : undefined;
    var commonProps = __assign(__assign({}, props), { variant: 'outlined', autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete), type: props.type && !['number', 'phone'].includes(props.type) ? props.type : undefined, label: props.label, required: props.required, fullWidth: true, disabled: props.disabled, inputProps: __assign({ pattern: props.type === 'number' || props.type === 'phone' ? '[0-9]*' : undefined }, props.inputProps) });
    return (_jsxs(FieldWrapper, __assign({ icon: icon, helperText: errorMessageText || helperText }, { children: [props.type !== 'phone' && (_jsx(MuiTextField, __assign({}, commonProps, controllerProps, { value: (_b = controllerProps.value) !== null && _b !== void 0 ? _b : '', onChange: function (event) {
                    if (props.uppercase) {
                        event.target.value = event.target.value.toUpperCase();
                    }
                    controllerProps.onChange(event);
                }, multiline: props.multiline, maxRows: props.maxRows || 6, error: Boolean(props.error) }))), props.type === 'phone' && (_jsx(PhoneNumberField, __assign({}, commonProps, controllerProps, { error: Boolean(props.error) })))] })));
};
export { FormTextFieldBase, FormTextField };
