import {
    ButtonGroup,
    FormField,
    FormTextField,
    FormTextFieldBase,
    GuestStatus,
    IGuestInfo,
    Label,
} from '@localina/core';
import {
    AddCircleIcon,
    CheckboxDisabledIcon,
    CheckboxDisabledSelectedIcon,
    CookieIcon,
    FavoriteBorderIcon,
    FavoriteIcon,
    TextBlockIcon,
    TrashIcon,
} from '@localina/icons';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserFeature } from '../UserFeature';

interface IProps {
    guestInfo: IGuestInfo;
}

export const GuestInfo: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { formState } = useFormContext();
    const { defaultValues } = formState;

    const salutationOptions = [
        { value: 'MALE', label: t('guest.details.view.fields.salutation.male') },
        { value: 'FEMALE', label: t('guest.details.view.fields.salutation.female') },
        { value: 'OTHER', label: t('guest.details.view.fields.salutation.other') },
    ];

    const guestStatusOptions = [
        { value: GuestStatus.VIP, label: t('guest.details.view.fields.guestStatus.vip') },
        { value: GuestStatus.BLACKLIST, label: t('guest.details.view.fields.guestStatus.blacklist') },
    ];

    return (
        <div className="guest-info">
            <div className="guest-info-title">
                <Label type="title" value={t('guest.details.view.fields.contact')} variant="h6" />
            </div>
            <UserFeature filter="guestStatus">
                <FormField
                    name="guestStatus"
                    className="guest-info-guest-status"
                    accepter={ButtonGroup}
                    fullWidth
                    options={guestStatusOptions}
                    enableDeselect
                />
            </UserFeature>
            <FormField
                name="salutation"
                className="guest-info-salutation"
                accepter={ButtonGroup}
                fullWidth
                options={salutationOptions}
            />
            <FormTextField name="lastName" label={t('guest.details.view.fields.lastName')} required />
            <FormTextField name="firstName" label={t('guest.details.view.fields.firstName')} />
            <FormTextField name="company" label={t('guest.details.view.fields.company')} />
            <FormTextField
                name="email"
                label={t('guest.details.view.fields.email')}
                required={Boolean(defaultValues?.email)}
            />
            <FormTextField
                name="allergies"
                label={t('guest.details.view.fields.allergies')}
                multiline
                icon={<CookieIcon />}
            />
            <FormTextField
                name="restaurantComment"
                label={t('guest.details.view.fields.restaurantComment')}
                multiline
                icon={<TextBlockIcon />}
            />
            <div className="guest-info-title">
                <Label type="title" value={t('guest.details.view.list.phone')} variant="h6" />
            </div>
            <GuestPhoneNumbers />
            <div className="guest-info-title">
                <Label type="title" value={t('guest.details.view.fields.advertising')} variant="h6" />
            </div>
            <div className="row">
                {props.guestInfo.subscribeToNewsletter ? (
                    <Label
                        type={'text'}
                        value={t('guest.details.view.fields.newsletter.subscribed')}
                        icon={<CheckboxDisabledSelectedIcon />}
                    />
                ) : (
                    <Label
                        type={'text'}
                        value={t('guest.details.view.fields.newsletter.notSubscribed')}
                        icon={<CheckboxDisabledIcon />}
                    />
                )}
            </div>
        </div>
    );
};

const GuestPhoneNumbers = () => {
    const { t } = useTranslation();
    const { formState } = useFormContext();
    const { fields, append, remove, swap } = useFieldArray({
        name: 'phoneNumbers',
    });
    const { defaultValues } = formState;

    const noInitialPhoneNumbers = Boolean(!defaultValues?.['phoneNumbers'].length);

    return (
        <div className="guest-info-phone-numbers">
            <Grid container>
                {fields.map((item, index) => (
                    <Controller
                        key={item.id}
                        name={`phoneNumbers.${index}.phone`}
                        render={({ field, fieldState }) => (
                            <React.Fragment>
                                <Grid item md={8}>
                                    <FormTextFieldBase
                                        controllerProps={{
                                            value: field.value,
                                            onChange: field.onChange,
                                            name: field.name,
                                        }}
                                        type="phone"
                                        name={field.name}
                                        errorMessage={fieldState.error?.message || ''}
                                        label={t('guest.details.view.fields.phoneNumber')}
                                        icon={
                                            index === 0 ? (
                                                <FavoriteIcon className="favorite-icon-style" />
                                            ) : (
                                                <FavoriteBorderIcon
                                                    className="favorite-icon-style"
                                                    onClick={() => swap(0, index)}
                                                />
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TrashIcon
                                        className={
                                            noInitialPhoneNumbers || (fields.length > 1 && index > 0)
                                                ? 'trash-icon-style'
                                                : 'trash-icon-disabled-style'
                                        }
                                        onClick={() => {
                                            if (noInitialPhoneNumbers || (fields.length > 1 && index)) {
                                                remove(index);
                                            }
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    />
                ))}
            </Grid>
            <AddCircleIcon className="add-icon-style" onClick={() => append({ phone: '' })} />
        </div>
    );
};
