import { IRestaurant, ISelectOption, Label, SelectField, StringUtils, Switch, TextField } from '@localina/core';
import { EmailIcon, LanguageIcon, MobileIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IRestaurantInfoProps {
    restaurant: IRestaurant;
    onChange: (restaurant: IRestaurant) => void;
}

const RestaurantInfo: React.FC<IRestaurantInfoProps> = (props) => {
    const { t } = useTranslation();

    const handleRestaurantInfoChange = (field: string) => (value: string) => {
        if (field == 'phoneNumber') value = StringUtils.removeSpace(value);
        if (field == 'city') value = StringUtils.removeDigits(value);
        props.onChange({
            ...props.restaurant,
            info: {
                ...props.restaurant.info,
                [field]: value,
            },
        });
    };

    const handleReceiveEmailsChange = (value: boolean) => {
        props.onChange({
            ...props.restaurant,
            configuration: {
                ...props.restaurant.configuration,
                receiveReservationsEmails: value,
            },
        });
    };

    const supportedLanguageOptions: ISelectOption[] = [
        { value: 'de', label: t('core:languages.de') },
        { value: 'fr', label: t('core:languages.fr') },
        { value: 'it', label: t('core:languages.it') },
        { value: 'en', label: t('core:languages.en') },
    ];

    return (
        <div className="restaurant-info">
            <TextField
                name="restaurantName"
                label={t('restaurant.fields.name')}
                value={props.restaurant.info.name}
                required={true}
                onChange={handleRestaurantInfoChange('name')}
            />
            <Grid container={true}>
                <Grid item={true} xs={9}>
                    <TextField
                        name="street"
                        label={t('restaurant.fields.street')}
                        value={props.restaurant.info.street}
                        required={true}
                        onChange={handleRestaurantInfoChange('street')}
                    />
                </Grid>
                <Grid item={true} xs={3}>
                    <TextField
                        name="streetNumber"
                        label={t('restaurant.fields.streetNumber')}
                        value={props.restaurant.info.streetNumber}
                        required={true}
                        onChange={handleRestaurantInfoChange('streetNumber')}
                    />
                </Grid>
            </Grid>
            <Grid container={true}>
                <Grid item={true} xs={3}>
                    <TextField
                        name="postalCode"
                        label={t('restaurant.fields.postalCode')}
                        value={props.restaurant.info.postalCode}
                        required={true}
                        onChange={handleRestaurantInfoChange('postalCode')}
                    />
                </Grid>
                <Grid item={true} xs={9}>
                    <TextField
                        name="city"
                        label={t('restaurant.fields.city')}
                        value={props.restaurant.info.city}
                        required={true}
                        onChange={handleRestaurantInfoChange('city')}
                    />
                </Grid>
            </Grid>
            <TextField
                name="phoneNumber"
                label={t('restaurant.fields.phoneNumber')}
                value={props.restaurant.info.phoneNumber}
                required={true}
                icon={<MobileIcon />}
                onChange={handleRestaurantInfoChange('phoneNumber')}
            />
            <TextField
                name="email"
                label={t('restaurant.fields.email')}
                value={props.restaurant.info.email}
                required={true}
                icon={<EmailIcon />}
                onChange={handleRestaurantInfoChange('email')}
            />
            <Switch
                label={t('restaurant.fields.receiveReservationsEmails')}
                checked={props.restaurant.configuration.receiveReservationsEmails}
                onChange={handleReceiveEmailsChange}
            />
            <div className="row">
                <Label value={t('restaurant.fields.cancelReservationNotice')} type="text" />
                <div className="cancel-notice">
                    <TextField
                        name="cancelReservationNotice"
                        shrink
                        label={''}
                        value={`${props.restaurant.configuration.cancelReservationNotice}`}
                        required={true}
                        type="number"
                        onChange={(val) => {
                            props.onChange({
                                ...props.restaurant,
                                configuration: {
                                    ...props.restaurant.configuration,
                                    cancelReservationNotice: Number(val),
                                },
                            });
                        }}
                    />
                </div>
            </div>
            <SelectField
                label={t('restaurant.fields.language')}
                value={props.restaurant.info.language}
                options={supportedLanguageOptions}
                icon={<LanguageIcon />}
                onChange={handleRestaurantInfoChange('language')}
            />
        </div>
    );
};

export type { IRestaurantInfoProps };
export default RestaurantInfo;
