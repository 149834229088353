var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SpeedDial as MuiSpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React from 'react';
import { StringUtils } from '../../utils';
import { Label } from '../Label';
export var SpeedDial = function (props) {
    var _a = React.useState(false), isOpen = _a[0], setOpen = _a[1];
    var handleActionClick = function (action) { return function () {
        if (action.disabled) {
            return;
        }
        setOpen(false);
        action.onClick();
    }; };
    return (_jsx(MuiSpeedDial, __assign({ className: StringUtils.combineStrings([
            'localina-speeddial',
            props.className,
            props.fixed && 'position-fixed',
        ]), ariaLabel: props.label, icon: _jsx(SpeedDialIcon, { icon: props.icon, openIcon: props.openIcon }), open: isOpen, onClick: function () { return setOpen(!isOpen); }, onOpen: function () { return setOpen(true); }, onClose: function () { return setOpen(false); } }, { children: props.actions.map(function (action, index) { return (_jsx(SpeedDialAction, { classes: { staticTooltipLabel: action.disabled ? 'Mui-disabled' : undefined }, icon: action.icon, FabProps: {
                disabled: action.disabled,
            }, tooltipTitle: Boolean(action.label) ? _jsx(Label, { type: "info", value: action.label, align: "center" }) : undefined, tooltipOpen: Boolean(action.label), onClick: handleActionClick(action) }, index)); }) })));
};
