import React from 'react';
import { createUseFunction } from '@localina/core';

// properties that provides this context
interface IMenuContext {
    categoryOpen: string;
    setCategoryOpen: (categoryOpen: string) => void;
}

interface IProps {
    children?: React.ReactNode;
}

const MenuContext = React.createContext<null | IMenuContext>(null);
MenuContext.displayName = 'Menu Context';

export const MenuProvider: React.FC<IProps> = ({ children }) => {
    const [categoryOpen, setCategoryOpen] = React.useState('');

    return (
        <MenuContext.Provider
            value={{
                categoryOpen,
                setCategoryOpen,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

export const useMenu = createUseFunction<IMenuContext>(MenuContext);
