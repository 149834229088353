import { IconButton, Label, Spinner } from '@localina/core';
import { UploadIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import {
    useReservationsImport,
    useReservationsImportPreview,
    useValidatedReservationsExport,
} from '../../api/queries/reservations';
import { ReservationsExportFormat } from '../../enums';
import { downloadExportFile } from '../../utils';
import { Drawer } from '../Drawer';

interface IProps {
    restaurantId: string;
    onClose: () => void;
    onStart: () => void;
}

enum ImportReservationsContentType {
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const ReservationsImportView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: Object.values(ImportReservationsContentType),
        maxFiles: 1,
        multiple: false,
        noClick: true,
        onDropAccepted: (acceptedFiles: File[]) => {
            if (acceptedFiles.length) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                handleUploadSelect(selectedFile);
            }
        },
    });

    const importPreviewMutation = useReservationsImportPreview();
    const importMutation = useReservationsImport();
    const validatedReservationsExportMutation = useValidatedReservationsExport();

    const handleUploadSelect = (file: File) => {
        importPreviewMutation.mutate(file);
    };

    const [file, setFile] = React.useState<File | undefined>(undefined);

    const handleSave = () => {
        if (file && importPreviewMutation.isSuccess && importPreviewMutation.data.numberOfValidImports) {
            importMutation.mutate(file);
            props.onStart();
        }
    };
    const handleDownloadValidatedReservations = () => {
        if (file && !validatedReservationsExportMutation.isLoading) {
            validatedReservationsExportMutation.mutate(file, {
                onSuccess: (validatedFile) => {
                    downloadExportFile(validatedFile, {
                        name: `reservations_validation_${props.restaurantId}`,
                        type:
                            file.type == ImportReservationsContentType.XLS
                                ? ReservationsExportFormat.XLS
                                : ReservationsExportFormat.XLSX,
                    });
                },
            });
        }
    };

    const handleClose = () => {
        importPreviewMutation.reset();
        importMutation.reset();
        props.onClose();
    };
    const isLoading =
        importPreviewMutation.isLoading || importMutation.isLoading || validatedReservationsExportMutation.isLoading;
    const isError =
        importPreviewMutation.isError || importMutation.isError || validatedReservationsExportMutation.isError;

    const uploadDisabled =
        isLoading ||
        !importPreviewMutation.isSuccess ||
        (importMutation.isSuccess && !importMutation.data.numberOfValidImports);

    return (
        <Drawer
            className="reservationsimport-view"
            open
            title={t('guests.reservationsImport.title')}
            disabled={uploadDisabled}
            error={{
                isVisible: isError,
                message: t('guests.reservationsImport.error'),
            }}
            saveButtonLabel={t('guests.reservationsImport.saveButtonLabel')}
            onClose={handleClose}
            onSave={importMutation.data === undefined ? handleSave : undefined}
        >
            <div className="preview-container">
                <div className="upload-container" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-container__content">
                        <Label type="text" value={t('guests.reservationsImport.fields.upload')} />
                        <Label type="text" value={t('guests.reservationsImport.fields.accept')} />
                        <IconButton icon={<UploadIcon />} onClick={open} />
                    </div>
                </div>
                {importPreviewMutation.isSuccess && (
                    <div className="preview-info">
                        <Label type="text" value={t('guests.reservationsImport.fields.info')} />
                        <Grid container={true}>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={t('guests.reservationsImport.fields.entries')} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={importPreviewMutation.data.numberOfValidImports} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={t('guests.reservationsImport.fields.duplicates')} />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Label type="text" value={importPreviewMutation.data.numberOfInvalidImports} />
                            </Grid>
                        </Grid>
                        <Label type="text" value={t('guests.reservationsImport.fields.waiver')} />
                        <Label
                            type="text"
                            value={
                                <Trans
                                    i18nKey={'guests.reservationsImport.fields.download'}
                                    components={[
                                        <span
                                            onClick={handleDownloadValidatedReservations}
                                            key="download-validated-reservations"
                                            className="download-validated-reservations"
                                        />,
                                    ]}
                                />
                            }
                        />
                    </div>
                )}
            </div>
            {isLoading && <Spinner />}
        </Drawer>
    );
};

export default ReservationsImportView;
