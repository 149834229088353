var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SketchPicker } from 'react-color';
import { Label } from '../Label';
import { ClickAwayListener, Popper } from '@mui/material';
export var ColorPicker = function (props) {
    var _a = React.useState(false), displayColorPicker = _a[0], setDisplayColorPicker = _a[1];
    var _b = React.useState(props.defaultColor ? props.defaultColor : '#000000'), color = _b[0], setColor = _b[1];
    var _c = React.useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    React.useEffect(function () {
        if (props.value) {
            setColor(props.value);
        }
    }, [props.value]);
    var handleClick = function (event) {
        setDisplayColorPicker(function (displayColorPicker) { return !displayColorPicker; });
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        if (props.onClose && displayColorPicker) {
            props.onClose();
        }
        setDisplayColorPicker(false);
    };
    var handleChange = function (color) {
        if (props.onChange) {
            props.onChange(color.hex);
        }
        setColor(color.hex);
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs("div", __assign({ className: "localina-colorpicker" }, { children: [props.title && _jsx(Label, { type: "label", extraClassName: "label", value: props.title }), _jsx("div", __assign({ className: "swatch", onClick: handleClick }, { children: _jsx("div", { className: "color", style: { backgroundColor: String(color) } }) })), _jsx(Popper, __assign({ open: displayColorPicker, anchorEl: anchorEl, className: 'localina-colorpicker__popper' }, { children: _jsxs("div", __assign({ className: "popover" }, { children: [_jsx("div", { className: "cover", onClick: handleClose }), _jsx(SketchPicker, { color: color, onChange: handleChange })] })) }))] })) })));
};
