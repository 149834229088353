import { IAccount } from '@localina/core';
import { EditIcon } from '@localina/icons';
import React from 'react';
import { getRestaurantTestPlanIcon } from '../../utils';

interface IProps {
    account: IAccount;
    onClick: () => void;
    onGoToAccountClick: (account: IAccount, restaurantId?: string) => void;
}

export const SupportAccountListItem: React.FC<IProps> = (props) => {
    return (
        <div className="support-accounts-row">
            <div className="support-accounts-row-restaurant-info">
                <div className="support-accounts-row-top" onClick={() => props.onGoToAccountClick(props.account)}>
                    {props.account.accountNumber}
                </div>
                <div className="support-accounts-row-bottom">
                    {props.account.restaurants.map((restaurant) => (
                        <div
                            key={restaurant.id}
                            className="support-accounts-bottom-row-element"
                            onClick={() => props.onGoToAccountClick(props.account, restaurant.id)}
                        >
                            {getRestaurantTestPlanIcon(
                                restaurant.subscription,
                                restaurant.restaurantStatus,
                                'margin-right',
                            )}
                            <label className="label">{restaurant.name}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="support-accounts-redirect-icon">
                <EditIcon onClick={props.onClick} />
            </div>
        </div>
    );
};
