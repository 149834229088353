var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Spinner } from '../Spinner';
export function InfiniteTable(props) {
    return (_jsx(TableVirtuoso, { className: 'localina-infinite-table', data: props.items, components: TableComponents, fixedHeaderContent: props.headerContent, increaseViewportBy: 200, itemContent: props.itemContent, endReached: props.loadNext, fixedFooterContent: props.isFetchingNextPage ? LoadingFooter : undefined }));
}
var LoadingFooter = function () { return (_jsx("tr", __assign({ className: 'infinite-table-loading-footer' }, { children: _jsx("th", __assign({ colSpan: 1000 }, { children: _jsx(Spinner, { position: "relative" }) })) }))); };
var TableComponents = {
    Scroller: React.forwardRef(function (props, ref) { return (_jsx(TableContainer, __assign({ component: Paper }, props, { ref: ref }))); }),
    Table: function (props) { return _jsx(Table, __assign({}, props, { style: { borderCollapse: 'separate' } })); },
    TableHead: TableHead,
    TableRow: TableRow,
    TableBody: React.forwardRef(function (props, ref) { return _jsx(TableBody, __assign({}, props, { ref: ref })); }),
};
