import { useUrlParams } from '@localina/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { Path } from '../../enums';

const Auth: React.FC = () => {
    const navigate = useNavigate();
    const [queryParams] = useUrlParams();

    React.useEffect(() => {
        const mode = queryParams.get('mode');
        const code = queryParams.get('oobCode');

        if (code && mode) {
            if (mode === 'resetPassword') {
                navigate(`${Path.RESET_PASSWORD}?code=${code}`);
            } else if (mode === 'verifyEmail') {
                navigate(`${Path.VERIFY_EMAIL}?code=${code}`);
            } else {
                navigate(Path.LOGIN);
            }
        } else {
            navigate(Path.LOGIN);
        }
    }, []);

    return <Page name="auth" isLoading={true} />;
};

export default Auth;
