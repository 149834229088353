import React, { useLayoutEffect } from 'react';

export interface IPoint {
    x: number;
    y: number;
}

export function useZoom<T extends HTMLElement>(
    ref: React.RefObject<T>,
    handler: (val: number, center?: IPoint) => void,
    disabled?: boolean,
) {
    useLayoutEffect(() => {
        if (!ref.current || disabled) {
            return;
        }
        let currDiff = 0;
        const onTouchStart = (e: TouchEvent) => {
            if (e.touches.length !== 2) {
                return;
            }

            e.preventDefault();
            currDiff = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY,
            );
        };

        const onTouchMove = (e: TouchEvent) => {
            if (e.touches.length !== 2) {
                return;
            }

            e.preventDefault();
            const newDiff = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY,
            );
            if (currDiff > 0) {
                const ratio = newDiff / currDiff;
                handler(ratio, {
                    x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
                    y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
                });
            }

            currDiff = newDiff;
        };
        const onTouchUp = () => {
            currDiff = 0;
        };

        ref.current.addEventListener('touchstart', onTouchStart);
        ref.current.addEventListener('touchmove', onTouchMove);
        ref.current.addEventListener('touchend', onTouchUp);

        return () => {
            if (!ref.current) {
                return;
            }
            ref.current.removeEventListener('touchstart', onTouchStart);
            ref.current.removeEventListener('touchmove', onTouchMove);
            ref.current.removeEventListener('touchend', onTouchUp);
        };
    }, [ref.current, disabled, handler]);
}
