import {
    IReservation,
    ITablePlanObj,
    ITablePlanObjMetadata,
    Label,
    RESERVATION_CHANGE_TABLE_DROP_TYPE,
    RESERVATION_DROP_TYPE,
    ReservationStatus,
    SERVER_DATE_FORMAT,
    Spinner,
    StringUtils,
    TIME_FORMAT,
    Tooltip,
    useConfirmContext,
    useUrlParams,
} from '@localina/core';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { queryKeys } from '../../../api/queries/query-keys';
import { useUpdateReservationTables, useUpdateWalkinTables } from '../../../api/queries/reservations';
import { queryClient } from '../../../containers/App';
import { IPoint } from '../../../hooks/useZoom';
import { useRestaurantId } from '../../../utils/RestaurantUtils';
import { getCanvasElementStyles } from '../../../utils/TablePlanService';
import TableDetails from './TableDetails';

const elements = document.getElementsByClassName('localina-page');

interface IProps {
    tableObj?: ITablePlanObj;
    canvasElement: ITablePlanObjMetadata;
    selectedTime: number;
    reservations: IReservation[];
    scaleX: number;
    scaleY: number;
    reservationEnabled: boolean;
    walkinEnabled: boolean;
    onReserve: (isWalkin: boolean) => void;
    onDrop: (reservationId: string, itemType: string, previousTableId?: string) => void;
    offset: IPoint;
}

export const TableObjAssignReservation: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const updateReservationTablesMutation = useUpdateReservationTables();
    const updateWalkinTablesMutation = useUpdateWalkinTables();
    const restaurantId = useRestaurantId();
    const { snackbar } = useConfirmContext();

    const [params] = useUrlParams();
    const dateParam = params.get('date');

    const isSelectedTimeCurrentTime = useMemo(() => {
        const dateFromParam = dateParam ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT).startOf('day') : undefined;
        if (dateFromParam && dateFromParam.isValid) {
            const today = DateTime.now();
            if (today.startOf('day').equals(dateFromParam)) {
                const currentTime = today.hour * 60 + today.minute;
                return currentTime === props.selectedTime;
            }
        }
        return false;
    }, [props.selectedTime]);

    const { isDragging } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
    }));

    const [, drop] = useDrop(
        () => ({
            accept: [RESERVATION_DROP_TYPE, RESERVATION_CHANGE_TABLE_DROP_TYPE],
            drop: (item: { id: string; currentTableId?: string }, monitor) => {
                props.onDrop(item.id, String(monitor.getItemType()), item.currentTableId);
            },
        }),
        [props.onDrop],
    );
    const [openTooltip, setOpenTooltip] = useState(false);
    // needed to hide the tooltip when dragging start
    // closing it caused issues on some touch devices, due to losing the ref of drag object (MYLO-1087)
    const [isTooltipHidden, setIsTooltipHidden] = useState(false);
    const reservationInTime = props.reservations.find((reservation) => {
        const reservationTime = DateTime.fromISO(reservation.reservationDateTime);
        const reservationTimeStart = reservationTime.hour * 60 + reservationTime.minute;
        return (
            reservationTimeStart <= props.selectedTime &&
            reservationTimeStart + (reservation.occupancyTime || 0) / 60 > props.selectedTime
        );
    });

    // check if there is a reservation that is fulfilled, or a reservation without status (walk-in)
    const someReservationsFulfilled = props.reservations.some(
        ({ status }) => status === ReservationStatus.FULFILLED || status === null,
    );

    const reservationsCssClass = StringUtils.combineStrings([
        isSelectedTimeCurrentTime && 'current-time',
        someReservationsFulfilled && 'arrived',
        reservationInTime && reservationInTime.guestInfo && 'has-reservations',
        Boolean(props.reservations.length) && 'has-reservations-today',
    ]);

    const onRemoveReservationFromTable = (reservation: IReservation) => {
        if (props.tableObj) {
            setOpenTooltip(false);
            const mutation = reservation.guestInfo ? updateReservationTablesMutation : updateWalkinTablesMutation;
            mutation.mutate(
                {
                    reservationId: reservation.id,
                    tableIds: reservation.tableIds?.filter((tableId) => tableId !== props.tableObj!.id) ?? null,
                    mode: 'merge',
                },
                {
                    onSuccess: () => {
                        snackbar({
                            severity: 'success',
                            msg: t('reservations.table.successMessages.removedTable'),
                            autoHideDuration: 3000,
                        });
                        return queryClient.invalidateQueries(
                            queryKeys.restaurants.single.reservations.onDate(
                                restaurantId,
                                DateTime.fromISO(reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT),
                            ),
                        );
                    },
                },
            );
        }
    };

    const isLoading = updateReservationTablesMutation.isLoading || updateWalkinTablesMutation.isLoading;

    useEffect(() => {
        if (isDragging) {
            setOpenTooltip(false);
        }
    }, [isDragging]);

    if (!props.tableObj) {
        return null;
    }
    return (
        <div
            ref={drop}
            className={`table-plan-assign-reservations-table-container ${reservationsCssClass}`}
            style={getCanvasElementStyles(props.canvasElement, props.scaleX, props.scaleY, props.offset)}
        >
            <div className={`table-plan-assign-reservations-table-info-box center ${reservationsCssClass}`}>
                <Label type="info" value={`${props.tableObj.name} (${props.tableObj.numberOfSeats})`} />
            </div>
            <div
                className={`table-plan-assign-reservations-table-info-box reservations-container ${reservationsCssClass}`}
            >
                <Tooltip
                    className="table-plan-assign-reservations-tooltip"
                    popperClassName="table-plan-assign-reservations-popper"
                    container={elements.length === 1 ? elements[0] : undefined}
                    open={openTooltip}
                    onClose={() => {
                        setOpenTooltip(false);
                    }}
                    onOpen={() => {
                        setOpenTooltip(true);
                    }}
                    placement="top"
                    keepMounted
                    content={
                        <div style={{ display: isTooltipHidden ? 'none' : 'block' }}>
                            <TableDetails
                                onStartDrag={() => {
                                    setIsTooltipHidden(true);
                                }}
                                onEndDrag={() => {
                                    setOpenTooltip(false);
                                    setTimeout(() => {
                                        setIsTooltipHidden(false);
                                    }, 150);
                                }}
                                tableObj={props.tableObj}
                                walkinEnabled={props.walkinEnabled}
                                reservationEnabled={props.reservationEnabled}
                                onReserve={props.onReserve}
                                reservations={props.reservations}
                                onRemoveReservation={onRemoveReservationFromTable}
                            />
                        </div>
                    }
                >
                    {props.reservations
                        .slice()
                        .sort((a, b) => {
                            return (
                                new Date(a.reservationDateTime).getTime() - new Date(b.reservationDateTime).getTime()
                            );
                        })
                        .slice(0, 3)
                        .map((reservation) => {
                            return <TableReservation reservation={reservation} key={reservation.id} />;
                        })}
                </Tooltip>
            </div>
            {isLoading && <Spinner />}
        </div>
    );
};

interface TableReservationProps {
    reservation: IReservation;
}

const formatReservationTimeInfo = (reservation: IReservation) => {
    const startTime = DateTime.fromISO(reservation.reservationDateTime);
    return (
        <>
            {startTime.toFormat(TIME_FORMAT)}
            <span>{`- ${startTime.plus({ second: reservation.occupancyTime }).toFormat(TIME_FORMAT)}`}</span>
        </>
    );
};
const TableReservation: React.FC<TableReservationProps> = ({ reservation }) => {
    return (
        <div key={reservation.id} className="table-reservation-description-row table-preview row">
            <div>
                <Label type="info" value={formatReservationTimeInfo(reservation)} />
            </div>
            <div>
                <Label type="info" value={`(${reservation.participants})`} />
            </div>
        </div>
    );
};
