import { yupResolver } from '@hookform/resolvers/yup';
import { IGuestInfo, useConfirmContext } from '@localina/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { GuestUtils } from '../../utils';
import { Drawer } from '../Drawer';
import { GuestInfo } from '../GuestInfo';
import { useGuestSchema } from './guest-schema';

interface IProps {
    guest?: IGuestInfo;
    onSave: (guest: IGuestInfo) => void;
    onClose: () => void;
    onDelete: (guest: IGuestInfo) => void;
}

export const GuestView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const confirmContext = useConfirmContext();

    const guestInfoSchema = useGuestSchema();
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(guestInfoSchema)),
        defaultValues: {
            guestStatus: props.guest?.guestStatus || null,
            restaurantComment: props.guest?.restaurantComment,
            emailRequired: Boolean(props.guest?.email),
            email: props.guest?.email || '',
            firstName: props.guest?.firstName || '',
            lastName: props.guest?.lastName || '',
            salutation: props.guest?.salutation,
            company: props.guest?.company || '',
            allergies: props.guest?.allergies,
            phoneNumbers: props.guest?.phoneNumbers.map((phone) => ({ phone })) || [],
        },
    });

    const { formState } = methods;
    const { isValid, isDirty } = formState;

    const handleClose = async () => {
        if (
            !isDirty ||
            (await confirmContext.confirm({ msg: t('guest.details.view.dialog.confirmDiscard.title') })) === 'yes'
        ) {
            props.onClose();
        }
    };
    const handleSave = async () => {
        if (props.guest && isValid && confirmContext) {
            const values = methods.getValues();
            if ((await confirmContext.confirm({ msg: t('guest.details.view.dialog.save.message') })) === 'yes') {
                props.onSave({
                    allergies: values.allergies,
                    guestStatus: values.guestStatus,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    company: values.company,
                    restaurantComment: values.restaurantComment,
                    salutation: values.salutation || undefined,
                    phoneNumbers: values.phoneNumbers.map(({ phone }) => phone),
                    id: props.guest.id,
                    subscribeToNewsletter: props.guest.subscribeToNewsletter,
                });
            }
        }
    };

    const handleDelete = async () => {
        if (
            props.guest &&
            confirmContext &&
            (await confirmContext.confirm({ msg: t('guest.details.view.dialog.message') })) === 'yes'
        ) {
            props.onDelete(props.guest);
        }
    };

    useEffect(() => {
        if (props.guest) {
            methods.reset({
                guestStatus: props.guest?.guestStatus || null,
                restaurantComment: props.guest?.restaurantComment,
                emailRequired: Boolean(props.guest?.email),
                email: props.guest?.email || '',
                firstName: props.guest?.firstName || '',
                lastName: props.guest?.lastName || '',
                salutation: props.guest?.salutation,
                company: props.guest?.company || '',
                allergies: props.guest?.allergies,
                phoneNumbers: props.guest?.phoneNumbers.map((phone) => ({ phone })) || [],
            });
        }
    }, [props.guest]);

    return (
        <Drawer
            className="guest-view"
            open={Boolean(props.guest)}
            title={GuestUtils.formatGuestName(props.guest)}
            disabled={!isValid || !isDirty}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={handleDelete}
        >
            {props.guest && (
                <FormProvider {...methods}>
                    <form>
                        <div className="group">
                            <GuestInfo guestInfo={props.guest} />
                        </div>
                    </form>
                </FormProvider>
            )}
        </Drawer>
    );
};
