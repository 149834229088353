import React from 'react';
import { IAccountRestaurant } from '@localina/core';

interface IProps {
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    restaurantInfo: IAccountRestaurant;
    onEditClick: (restaurantInfo: IAccountRestaurant) => void;
}

const EditRestaurantPlanDrawersRow: React.FC<IProps> = (props) => {
    return (
        <div className="list-item-edit-restaurant-plan">
            <div className="left-icon">{props.leftIcon}</div>
            <div className="name">{props.restaurantInfo.name}</div>
            <div className="right-icon clickable" onClick={() => props.onEditClick(props.restaurantInfo)}>
                {props.rightIcon}
            </div>
        </div>
    );
};

export default EditRestaurantPlanDrawersRow;
