import React from 'react';
import { IRestaurant, ListItem } from '@localina/core';

interface IProps {
    restaurant: IRestaurant;
    onClick: (restaurant: IRestaurant) => void;
}

export const RestaurantListItem: React.FC<IProps> = (props) => {
    const { street, streetNumber, postalCode, city } = props.restaurant.info;
    const address = `${street} ${streetNumber}, ${postalCode} ${city}`;

    const handleClick = () => {
        props.onClick(props.restaurant);
    };

    return <ListItem title={props.restaurant.info.name} subtitle={address} onClick={handleClick} />;
};
