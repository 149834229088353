import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';
export var FormErrorMessagesProvider = function () {
    var t = useTranslation('core').t;
    useEffect(function () {
        setLocale({
            string: {
                min: function (_a) {
                    var min = _a.min;
                    return t('validation.string_too_short', { min: min });
                },
                max: function (_a) {
                    var max = _a.max;
                    return t('validation.string_too_long', { max: max });
                },
            },
            mixed: {
                required: t('validation.field_required'),
                notType: function (params) { return 'Not proper type'; },
            },
            array: {
                min: function (_a) {
                    var min = _a.min;
                    return (min === 1 ? t('validation.field_required') : undefined);
                },
            },
            number: {
                min: function (_a) {
                    var min = _a.min;
                    return t('validation.number_too_short', { min: min });
                },
            },
        });
    }, []);
    return null;
};
