import { IFeature, Label, StringUtils } from '@localina/core';
import { Collapse, List, ListItemButton } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenu } from '../../../contexts';

export interface ICategoryItem {
    title: string;
    icon?: React.ReactElement;
    requiredFeature?: IFeature;
    url?: string;
    onClick?: () => void;
}

interface IProps {
    name: string;
    title: string;
    items: ICategoryItem[];
    open?: boolean;
}

export const Category: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { categoryOpen, setCategoryOpen } = useMenu();
    const open = props.open || categoryOpen === props.name;

    React.useEffect(() => {
        if (props.items.some((it) => it.url && location.pathname.includes(it.url))) {
            setCategoryOpen(props.name);
        }
    }, [location.pathname]);

    const toggleOpen = () => {
        setCategoryOpen(open ? '' : props.name);
    };

    const goTo = (url?: string) => () => {
        if (url) {
            navigate(url);
        }
    };

    return (
        <>
            <ListItemButton
                className={StringUtils.combineStrings(['app-menu__category', open && 'open'])}
                disableGutters
                onClick={toggleOpen}
            >
                <Label type="text" value={props.title} />
            </ListItemButton>
            <Collapse in={open}>
                <List disablePadding={true}>
                    {props.items.map((item, index) => (
                        <ListItemButton
                            key={index}
                            selected={item.url ? location.pathname.includes(item.url) : false}
                            className="app-menu__category__item"
                            onClick={item.onClick ?? goTo(item.url)}
                        >
                            {item.icon}
                            <Label type="label" value={item.title} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>
    );
};
