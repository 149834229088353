import { IDailyNote, ILocalizedError } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';

const useDailyNote = (date: string, options?: UseQueryOptions<IDailyNote, ILocalizedError>) => {
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getDailyNote(restaurantId, date);
        },
        queryKey: queryKeys.restaurants.single.dailyNotes.single(restaurantId, date),
        ...options,
    });
};

const useSaveDailyNote = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            date: string;
            note: string;
        }
    >,
) => {
    const restaurantId = useRestaurantId();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ date, note }) => {
            return LocalinaApiContext.serviceApi.saveDailyNote(restaurantId, date, note);
        },
        ...options,
        onSuccess: (_, { date, note }) => {
            queryClient.setQueryData(queryKeys.restaurants.single.dailyNotes.single(restaurantId, date), { note });
        },
    });
};

export { useDailyNote, useSaveDailyNote };
