import { IAccount, IFeature } from '@localina/core';
import React, { useMemo } from 'react';
import { useGetAccount } from '../../api/queries/account';
import { useRestaurant } from '../../api/queries/restaurants';
import { AccountUtils } from '../../utils';

interface IProps {
    filter: IFeature | IFeature[] | ((account?: IAccount) => boolean);
    children: React.ReactNode;
}

const UserFeature: React.FC<IProps> = ({ children, filter }) => {
    const accountQuery = useGetAccount({ enabled: false });
    const restaurantQuery = useRestaurant({ enabled: accountQuery.isSuccess });

    const available = useMemo(() => {
        if (accountQuery.data && restaurantQuery.data) {
            if (typeof filter === 'string') {
                return AccountUtils.hasFeature(filter, accountQuery.data, restaurantQuery.data.id);
            }
            if (Array.isArray(filter)) {
                return filter.every((element) =>
                    AccountUtils.hasFeature(element, accountQuery.data, restaurantQuery.data?.id),
                );
            }
            return filter(accountQuery.data);
        }
        return false;
    }, [restaurantQuery.data, accountQuery.data, filter]);

    return available ? <>{children}</> : null;
};
export default UserFeature;
