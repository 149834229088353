import { IPaginatedList, ISliceList } from '@localina/core';

type TOptionsWithEnabled = {
    enabled?: boolean;
};
const getOptionsEnabled = (options?: TOptionsWithEnabled) => {
    return Boolean(!options || options.enabled === undefined || options.enabled);
};

const getSlicedSourcesPageParam = <T>(page: ISliceList<T>, direction: 'prev' | 'next') => {
    if (direction === 'prev') {
        return page.hasPrevious ? { page: Math.max(0, page.currentPage - 1), limit: page.limit } : undefined;
    }
    if (direction === 'next') {
        return page.hasNext ? { page: page.currentPage + 1, limit: page.limit } : undefined;
    }
};

const getPaginatedSourcesPageParam = <T>(page: IPaginatedList<T>, direction: 'next' | 'prev') => {
    if (page.pages === 0) return undefined;
    if (direction === 'next' && page.currentPage < page.pages - 1) {
        return { page: page.currentPage + 1, limit: page.limit };
    } else if (direction === 'prev' && page.currentPage > 0) {
        return { page: page.currentPage - 1, limit: page.limit };
    }
    return undefined;
};

const getPaginatedListPageParam = {
    next: <T>(page: IPaginatedList<T>) => getPaginatedSourcesPageParam<T>(page, 'next'),
    prev: <T>(page: IPaginatedList<T>) => getPaginatedSourcesPageParam<T>(page, 'prev'),
};
const getSlicedListPageParam = {
    next: <T>(page: ISliceList<T>) => getSlicedSourcesPageParam<T>(page, 'next'),
    prev: <T>(page: ISliceList<T>) => getSlicedSourcesPageParam<T>(page, 'prev'),
};

export { getOptionsEnabled, getSlicedListPageParam, getPaginatedListPageParam };
