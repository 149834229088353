import { FormTextField, IMessageDisplayText, Label, MessageType } from '@localina/core';
import { ArticleIcon } from '@localina/icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useMessageSchema } from '../message-utils';
import ConfirmationMailTemplate from './ConfirmationMailTemplate/ConfirmationMailTemplate';

interface IProps {
    type: MessageType;
    messageDisplayText: IMessageDisplayText & { index: number };
}

export const MessageDisplayText: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const messageSchema = yup.object(useMessageSchema(props.type));
    type TFormValues = InferType<typeof messageSchema>;

    const methods = useFormContext<TFormValues>();

    const isConfirmationMail = props.type === MessageType.CONFIRMATION_MAIL;
    const fieldNames: Record<'language' | 'link' | 'text', `displayTexts.${number}.${'language' | 'link' | 'text'}`> = {
        language: `displayTexts.${props.messageDisplayText.index}.language`,
        link: `displayTexts.${props.messageDisplayText.index}.link`,
        text: `displayTexts.${props.messageDisplayText.index}.text`,
    };

    const [language, link, text, messageLink] = methods.watch([
        fieldNames.language,
        fieldNames.link,
        fieldNames.text,
        'link',
    ]);

    return (
        <div className={'form'}>
            <FormTextField name={fieldNames.language} label={t('message.fields.language')} icon disabled />
            {messageLink && (
                <FormTextField
                    name={fieldNames.link}
                    label={t('message.fields.linkName')}
                    icon={<ArticleIcon />}
                    helperText={t('message.helperTexts.linkName')}
                />
            )}
            <FormTextField
                name={fieldNames.text}
                label={t('message.fields.text')}
                icon={<ArticleIcon />}
                multiline
                required
                minRows={4}
            />
            {isConfirmationMail && language && (
                <>
                    <Label
                        type="title"
                        value={t('message.confirmationMailPreview.preview')}
                        extraClassName={'preview-confirmation-mail'}
                    />
                    <ConfirmationMailTemplate lng={language} text={text ?? undefined} link={link ?? undefined} />
                </>
            )}

            <div></div>
        </div>
    );
};

const CONFIRMATION_MAIL_TEXT_CHAR_LIMIT = 600;
const CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT = 50;
export const validateMessageDisplayText = (messageDisplayText: IMessageDisplayText, messageType?: MessageType) => {
    const hasText = messageDisplayText.text !== '';
    const textLengthIsValid =
        messageType === MessageType.CONFIRMATION_MAIL
            ? messageDisplayText.text.length <= CONFIRMATION_MAIL_TEXT_CHAR_LIMIT
            : true;
    const linkLengthIsValid =
        messageType === MessageType.CONFIRMATION_MAIL
            ? (messageDisplayText.link?.length || 0) <= CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT
            : true;

    return hasText && textLengthIsValid && linkLengthIsValid;
};
