export var RestaurantStatus;
(function (RestaurantStatus) {
    RestaurantStatus["ACTIVE"] = "active";
    RestaurantStatus["INACTIVE"] = "inactive";
    RestaurantStatus["TEST"] = "test";
})(RestaurantStatus || (RestaurantStatus = {}));
export var RestaurantSubscription;
(function (RestaurantSubscription) {
    RestaurantSubscription["STANDARD"] = "standard";
    RestaurantSubscription["FREE"] = "free";
})(RestaurantSubscription || (RestaurantSubscription = {}));
