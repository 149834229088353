import { Slider } from '@localina/core';
import React from 'react';

interface IProps {
    min: number;
    max: number;
    value: number;
    onZoomChange: (value: number) => void;
}

export const ZoomSlider: React.FC<IProps> = (props) => {
    return (
        <div className="zoom-slider">
            <div className="minus-plus-container">
                <span>-</span>
            </div>
            <Slider
                name="zoom-slider"
                step={0.01}
                min={props.min}
                max={props.max}
                value={props.value}
                className="slider"
                onChange={props.onZoomChange}
            />
            <div className="minus-plus-container">
                <span>+</span>
            </div>
        </div>
    );
};
