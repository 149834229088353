var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, Dialog as MuiDialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Label } from '../Label';
import { TextField } from '../TextField';
export var ConfirmWithTextCopyDialog = function (props) {
    var t = useTranslation('core').t;
    var _a = useState(''), value = _a[0], setValue = _a[1];
    useEffect(function () {
        if (props.open) {
            setValue('');
        }
    }, [props.open]);
    return (_jsxs(MuiDialog, __assign({ className: "localina-dialog confirm-with-text-copy", open: props.open, onClose: props.onClose }, { children: [_jsx(Label, { type: "text", value: props.title }), _jsx("div", __assign({ className: 'text-to-copy-container' }, { children: _jsx(Chip, { variant: 'outlined', label: props.textToCopy }) })), _jsx(TextField, { label: 'Type here', name: 'text-field-to-confirm', value: value, onChange: setValue }), _jsxs("div", __assign({ className: props.onCancel && props.onConfirm ? 'buttons' : 'button' }, { children: [props.onCancel && (_jsx(Button, { label: props.cancelLabel || t('buttons.cancel'), secondary: true, onClick: props.onCancel })), props.onConfirm && (_jsx(Button, { label: props.confirmLabel || t('buttons.ok'), onClick: props.onConfirm, disabled: value !== props.textToCopy }))] }))] })));
};
