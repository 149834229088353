import { yupResolver } from '@hookform/resolvers/yup';
import {
    DATE_FORMAT,
    EMAIL_REGEX,
    GuestStatus,
    IReservation,
    ISalutationType,
    ReservationStatus,
    SERVER_DATE_FORMAT,
    TIME_FORMAT,
    useUrlParams,
} from '@localina/core';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
    MAX_RESERVATION_DOCUMENT_SIZE,
    validReservationDocumentExtensions,
} from '../components/ReservationFormFields/ReservationFileUpload';
import { ReservationDetailsType } from '../enums';
import { IReservationForm } from './interfaces';

interface IUseReservationSchemaProps {
    isWalkin: boolean;
    isStaffTagMandatory: boolean;
    open: boolean;
    reservationType: ReservationDetailsType;
    reservation?: IReservation;
    defaultFormValues?: Partial<IReservationForm>;
}

export function useReservationForm(props: IUseReservationSchemaProps) {
    const { t } = useTranslation('core');
    const [params] = useUrlParams();
    const dateParam = params.get('date');

    const schema = useMemo(() => {
        const walkinReservationFieldsValidation = {
            participants: yup
                .string()
                .required()
                .matches(/^\d+$/, t('errors.invalidNumber'))
                .test('max-number', t('validation.number_too_long'), (value) => +value < 1000)
                .test('min-number', t('validation.number_too_short', { min: 1 }), (value) => +value >= 1),
            date: yup.array(yup.string().required()).min(1).required(),
            time: yup.string().required(),
            areaTimes: yup
                .array(
                    yup
                        .object({
                            shiftId: yup.string(),
                            areaId: yup.string(),
                        })
                        .required(),
                )
                .min(1)
                .required(),
            occupancyTime: yup
                .number()
                .required()
                .min(300 / 60, t('validation.number_too_short', { min: 300 / 60 })),
            tableIds: yup.array().of(yup.string().required()).required(),
            color: yup.string().optional(),
            walkin: yup.bool(),
        };

        const regularReservationAdditionalFieldsValidation = {
            walkin: yup.bool().oneOf([false]).required(),
            allergies: yup.string().max(200, t('validation.string_too_long', { max: 200 })),
            comment: yup.string().max(1000, t('validation.string_too_long', { max: 1000 })),
            company: yup
                .string()
                .nullable()
                .max(30, t('validation.string_too_long', { max: 30 })),
            email: yup
                .string()
                .matches(EMAIL_REGEX, t('errors.invalidEmail'))
                .min(6, t('validation.string_too_short', { min: 6 }))
                .max(50, t('validation.string_too_long', { max: 50 }))
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr)),
            sendEmailReminder: yup.boolean().test({
                name: 'is-email-filled',
                test(value, ctx) {
                    if (value && !ctx.parent.email) {
                        return ctx.createError({ message: t('validation.reservation_email_reminder') });
                    }
                    return true;
                },
            }),
            firstName: yup.string().max(40, t('validation.string_too_long', { max: 40 })),
            guestStatus: yup.string<GuestStatus>().nullable().defined(),
            documents: yup.array().of(
                yup.lazy((va) => {
                    switch (typeof va) {
                        case 'string':
                            return yup.string();
                        case 'object':
                            return yup
                                .mixed<File>()
                                .test('is-valid-type', t('validation.file_not_supported'), (value) =>
                                    isValidFileType(value && value.name.toLowerCase()),
                                )
                                .test(
                                    'is-valid-size',
                                    t('validation.file_size_too_big', {
                                        size: MAX_RESERVATION_DOCUMENT_SIZE / 1000000,
                                    }),
                                    (value) => value && value.size <= MAX_RESERVATION_DOCUMENT_SIZE,
                                );
                        default:
                            return yup.mixed();
                    }
                }),
            ),
            lastName: yup
                .string()
                .required()
                .min(2, t('validation.string_too_short', { min: 2 }))
                .max(40, t('validation.string_too_long', { max: 40 })),
            salutation: yup.string<ISalutationType>().nullable(),
            phoneNumber: yup
                .string()
                .test({
                    skipAbsent: true,
                    name: 'is-phone-number',
                    test(value, ctx) {
                        if (!value) {
                            return true;
                        }
                        if (!value.startsWith('+') || !isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
                            return ctx.createError({ message: t('validation.field_not_phone') });
                        }
                        return true;
                    },
                })
                .min(10, t('validation.string_too_short', { min: 10 }))
                .max(15, t('validation.string_too_long', { max: 15 }))
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr)),
            sendSmsReminder: yup.boolean().test({
                name: 'is-phone-filled',
                test(value, ctx) {
                    if (value && !ctx.parent.phoneNumber) {
                        return ctx.createError({ message: t('validation.reservation_sms_reminder') });
                    }
                    return true;
                },
            }),
            reminderDate: yup.string().nullable(),
            restaurantComment: yup.string().max(200, t('validation.string_too_long', { max: 200 })),
            status: yup.string<ReservationStatus>(),
            staffTagId: yup.string(),
            staffTagMandatory: yup.bool().oneOf([true, false]),
            sendConfirmationMail: yup
                .boolean()
                .nullable()
                .test({
                    name: 'is-email-filled',
                    test(value, ctx) {
                        if (value && !ctx.parent.email) {
                            return ctx.createError({ message: t('validation.reservation_email_confirmation') });
                        }
                        return true;
                    },
                }),
        };
        let finalSchemaObject = { ...walkinReservationFieldsValidation };

        if (!props.isWalkin) {
            finalSchemaObject = { ...finalSchemaObject, ...regularReservationAdditionalFieldsValidation };

            if (props.isStaffTagMandatory) {
                const staffTagMandatorySchemaObject = {
                    staffTagId: yup.string().required(),
                    staffTagMandatory: yup.bool().oneOf([true]).required(),
                };
                finalSchemaObject = { ...finalSchemaObject, ...staffTagMandatorySchemaObject };
            } else {
                const staffTagNotMandatorySchemaObject = {
                    staffTagId: yup.string().nullable(),
                    staffTagMandatory: yup.bool().oneOf([false]).required(),
                };
                finalSchemaObject = { ...finalSchemaObject, ...staffTagNotMandatorySchemaObject };
            }
        }

        return yup.object(finalSchemaObject);
    }, [props.isWalkin, props.isStaffTagMandatory, props.reservationType]);

    const defaultValues: IReservationForm = useMemo(() => {
        const defaultState = { ...defaultFormValues, ...props.defaultFormValues };
        if (props.reservation) {
            const resDateTime = DateTime.fromISO(props.reservation.reservationDateTime);
            return {
                participants: String(props.reservation.participants),
                date: [resDateTime.toFormat(DATE_FORMAT)],
                time: resDateTime.toFormat(TIME_FORMAT),
                areaTimes: props.reservation.areaIds.map((areaId) => ({ shiftId: props.reservation!.shiftId, areaId })),
                occupancyTime: props.reservation.occupancyTime / 60 || 0,
                tableIds: props.reservation.tableIds || [],
                color: props.reservation.color || '',
                walkin: true,
                ...(props.isWalkin
                    ? {}
                    : {
                          walkin: false,
                          documents: props.reservation.documents || [],
                          guestStatus: props.reservation.guestInfo?.guestStatus || null,
                          phoneNumber: props.reservation.phoneNumber || '',
                          sendSmsReminder: props.reservation.sendSmsReminder,
                          reminderDate: props.reservation.reminderDate,
                          status: props.reservation.status,
                          sendConfirmationMail: false,
                          allergies: props.reservation.guestInfo?.allergies,
                          firstName: props.reservation.guestInfo?.firstName || '',
                          lastName: props.reservation.guestInfo?.lastName || '',
                          email: props.reservation.guestInfo?.email || '',
                          sendEmailReminder: props.reservation.sendEmailReminder,
                          salutation: props.reservation.guestInfo?.salutation,
                          company: props.reservation.guestInfo?.company || '',
                          comment: props.reservation.comment || '',
                          restaurantComment: props.reservation.guestInfo?.restaurantComment,
                          ...(props.isStaffTagMandatory
                              ? {
                                    staffTagMandatory: true,
                                    staffTagId: props.reservation.staffTagId || '',
                                }
                              : {
                                    staffTagMandatory: false,
                                    staffTagId: props.reservation.staffTagId || undefined,
                                }),
                      }),
            };
        } else {
            const now = DateTime.now();
            return {
                participants: defaultState.participants,
                date: [
                    !props.isWalkin && dateParam && DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT).isValid
                        ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                        : now.toFormat(DATE_FORMAT),
                ],
                time: props.isWalkin ? now.toFormat(TIME_FORMAT) : defaultState.time,
                areaTimes: defaultState.areaTimes,
                occupancyTime: defaultState.occupancyTime || 0,
                tableIds: defaultState.tableIds || [],
                color: defaultState.color || '',
                ...(props.isWalkin
                    ? { walkin: true }
                    : {
                          walkin: false,
                          documents: defaultState.documents || [],
                          comment: defaultState.comment,
                          status: defaultState.status,
                          sendConfirmationMail: defaultState.sendConfirmationMail,
                          phoneNumber: defaultState.phoneNumber,
                          sendSmsReminder: defaultState.sendSmsReminder,
                          sendEmailReminder: defaultState.sendEmailReminder,
                          guestStatus: defaultState.guestStatus || null,
                          allergies: defaultState.allergies,
                          firstName: defaultState.firstName || '',
                          lastName: defaultState.lastName,
                          email: defaultState.email,
                          salutation: defaultState.salutation,
                          company: defaultState.company,
                          restaurantComment: defaultState.restaurantComment,

                          ...(props.isStaffTagMandatory
                              ? {
                                    staffTagMandatory: true,
                                    staffTagId: defaultState.staffTagId || '',
                                }
                              : {
                                    staffTagMandatory: false,
                                    staffTagId: defaultState.staffTagId || undefined,
                                }),
                      }),
            };
        }
    }, [props.reservation, props.isWalkin, props.isStaffTagMandatory, props.defaultFormValues, dateParam]);

    const methods = useForm<IReservationForm>({
        mode: 'all',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        methods.reset(defaultValues, { keepDefaultValues: props.reservationType === ReservationDetailsType.CREATE });
        if (props.reservationType === ReservationDetailsType.EDIT) {
            void methods.trigger();
        }
    }, [defaultValues, props.open, props.reservationType]);

    return methods;
}

const isValidFileType = (fileName?: string) => {
    if (fileName) {
        const extension = fileName.split('.').pop();
        return Boolean(
            extension && Object.values(validReservationDocumentExtensions).some((extArr) => extArr.includes(extension)),
        );
    }
    return false;
};

const defaultFormValues = {
    allergies: '',
    areaTimes: [],
    comment: '',
    company: '',
    color: '',
    date: [],
    firstName: '',
    lastName: '',
    occupancyTime: 0,
    participants: '',
    phoneNumber: '',
    restaurantComment: '',
    tableIds: [],
    documents: [],
    email: '',
    time: '',
    status: undefined,
    sendConfirmationMail: null,
    staffTagId: null,
    guestStatus: null,
    salutation: undefined,
    sendSmsReminder: false,
    sendEmailReminder: false,
};
