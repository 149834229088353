import {
    Drawer,
    IReservation,
    Label,
    ReservationStatus,
    SERVER_DATE_FORMAT,
    TIME_FORMAT,
    TLanguageKey,
    useConfirmContext,
} from '@localina/core';
import { CloseIcon } from '@localina/icons';
import { Replay } from '@mui/icons-material';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReservation } from '../../api/queries/reservations';
import { useAreas, useVirtualAreas } from '../../api/queries/restaurantAreas';
import { usePreservedRestaurant } from '../../api/queries/restaurants';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { ReservationDetailsType } from '../../enums';
import RequestedReservationActionButtons from '../ReservationStatus/RequestedReservationActionButtons';
import RequestedReservationDetails from './RequestedReservationDetails';

interface IRequestedReservationViewProps {
    open: boolean;
    onClose: () => void;
    reservation?: IReservation;
}

const RequestedReservationView = (props: IRequestedReservationViewProps) => {
    const { t, i18n } = useTranslation();
    const { confirm } = useConfirmContext();
    const [reservationProcessed, setReservationProcessed] = useState<boolean>(false);
    const { openReservationForm } = useReservationFormContext();

    const reservationQuery = useReservation(props.reservation?.id || '', {
        enabled: Boolean(props.reservation?.id),
        initialData: props.reservation,
    });

    const reservation = reservationQuery.data;

    const isProcessed = reservation && reservation.status !== ReservationStatus.APPROVAL_PENDING;

    const areasQuery = useAreas();
    const virtualAreasQuery = useVirtualAreas();

    const restaurantQuery = usePreservedRestaurant(false);

    const shift = useMemo(
        () => restaurantQuery.data?.configuration.shifts.find((s) => s.id === reservation?.shiftId),
        [restaurantQuery.data, reservation?.shiftId],
    );
    const shiftName = shift?.name[i18n.language as TLanguageKey];

    const area = useMemo(() => {
        const targetArea = areasQuery.data?.areas.find((a) => reservation?.areaIds.includes(a.id));
        return targetArea ?? virtualAreasQuery.data?.virtualAreas.find((a) => reservation?.areaIds.includes(a.id));
    }, [areasQuery.data, virtualAreasQuery.data, reservation?.areaIds]);

    const onSuccess = () => {
        setReservationProcessed(true);
        props.onClose();
    };

    const confirmCloseDrawer = async () => {
        if (
            reservationProcessed ||
            isProcessed ||
            (await confirm({
                title: t('reservations.requestedReservationView.confirmClose.title'),
            })) === 'yes'
        ) {
            props.onClose();
        }
    };

    const recreateDeclinedReservation = () => {
        if (props.reservation) {
            const reservationDateTime = DateTime.fromISO(props.reservation.reservationDateTime);
            const shiftId = props.reservation.shiftId;
            openReservationForm(undefined, ReservationDetailsType.CREATE, {
                color: props.reservation.color || undefined,
                participants: `${props.reservation.participants}`,
                date: [reservationDateTime.toFormat(SERVER_DATE_FORMAT)],
                time: reservationDateTime.toFormat(TIME_FORMAT),
                tableIds: props.reservation.tableIds || undefined,
                comment: props.reservation.comment,
                walkin: false,
                sendConfirmationMail: true,
                sendEmailReminder: props.reservation.sendEmailReminder,
                sendSmsReminder: props.reservation.sendSmsReminder,
                areaTimes: props.reservation.areaIds.map((areaId) => ({ areaId, shiftId })),

                firstName: props.reservation.guestInfo?.firstName,
                lastName: props.reservation.guestInfo?.lastName,
                allergies: props.reservation.guestInfo?.allergies,
                company: props.reservation.guestInfo?.company,
                email: props.reservation.guestInfo?.email,
                guestStatus: props.reservation.guestInfo?.guestStatus,
                phoneNumber: props.reservation.guestInfo?.phoneNumbers[0],
                salutation: props.reservation.guestInfo?.salutation,
                restaurantComment: props.reservation.guestInfo?.restaurantComment,
            });
        }
    };

    useEffect(() => {
        if (props.open) {
            setReservationProcessed(false);
        }
    }, [props.open]);

    return (
        <Drawer open={props.open} onClose={confirmCloseDrawer} position="right" className={'reservation-view'}>
            <div className="localina-drawer__header">
                <CloseIcon onClick={confirmCloseDrawer} />
                <Label type="title" value={t('reservations.requestedReservationView.title')} variant="h5" />
            </div>
            {!!reservation && (
                <div className="localina-drawer__body__content">
                    <RequestedReservationDetails
                        reservationDateTime={reservation.reservationDateTime}
                        guestInfo={reservation.guestInfo}
                        comment={reservation.comment}
                        area={area}
                        shiftName={shiftName}
                        status={reservation.status}
                    />
                    {[ReservationStatus.DECLINED, ReservationStatus.GUEST_CANCELLED].includes(reservation.status) && (
                        <Label
                            value={t('reservations.requestedReservationView.actions.recreate')}
                            onClick={recreateDeclinedReservation}
                            type={'link'}
                            icon={<Replay />}
                            extraClassName={'recreate-reservation-link'}
                        />
                    )}
                </div>
            )}
            {reservation && reservation.status === ReservationStatus.APPROVAL_PENDING && (
                <div className="localina-drawer__footer">
                    <RequestedReservationActionButtons
                        reservation={reservation}
                        onSuccess={onSuccess}
                        isProcessed={false}
                    />
                </div>
            )}
        </Drawer>
    );
};

export default RequestedReservationView;
