import { TextField, ITablePlanObjMetadata } from '@localina/core';
import React, { useEffect, useState } from 'react';
import { Drawer } from './Drawer';
import { useTranslation } from 'react-i18next';

interface ISaveElement {
    label: string;
}
interface IProps {
    canvasElement?: ITablePlanObjMetadata;
    open: boolean;
    onClose: () => void;
    onSave: (objDetails: ISaveElement) => void;
    onDelete: () => void;
    hasError: boolean;
}
export default function TablePlanEditorElementDetails(props: IProps) {
    const [label, setLabel] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        setLabel(props.canvasElement?.label || '');
    }, [props.canvasElement]);
    return (
        <Drawer
            className="edit-restaurant-plan-drawer"
            open={props.open}
            title={t('table.canvasView.title.edit')}
            onClose={props.onClose}
            onSave={() => {
                props.onSave({
                    label,
                });
            }}
            onDelete={props.onDelete}
            error={{
                isVisible: props.hasError,
                message: 'Error editing table details',
            }}
        >
            <div className="table-plan-editor-table-details">
                <TextField
                    required={true}
                    label={t('table.canvasView.fields.label')}
                    name="label"
                    value={`${label || ''}`}
                    onChange={setLabel}
                />
            </div>
        </Drawer>
    );
}
