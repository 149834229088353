import React from 'react';

interface IProps {
    top: number;
    left: number;
    icons: React.ReactNode[];
    zoomCanvas: number;
}

export const CustomTableControls: React.FC<IProps> = (props) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: props.top * props.zoomCanvas - 25,
                left: (props.left - props.icons.length * 20) * props.zoomCanvas,
                zIndex: 100,
                cursor: 'pointer',
            }}
        >
            <div className="flex">{props.icons}</div>
        </div>
    );
};
